
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Configuração dos banners da home - master
//


import React, { Component } from "react";

import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";
import Loader from "../../../Loader/index.js";

import BannerItemImage from "./BannerItemImage";
import BannerItemBody from "./BannerItemBody";

import StatItemBody from "./StatItemBody";

import ScreenSize from "../../../Commons/ScreenSize";

import masterAccess from "../../../../lib/masterAccess";

import List from "../../List/Zebra";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 992;



export default class HomeBanners extends Component {
  handlePublishButtonClick = item => {
    const action = item.published ? dict.t("Despublicar") : dict.t("Publicar");
    const message = `${dict.t("Tem certeza que deseja")} ${action.toLowerCase()} ${dict.t(
      "este banner"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        item.published = !item.published;

        const url = `/master/home/banners/${item._id}`;

        Request.post(url, item, msg => {
          this.fetchBanners();
          Alert.success(dict.t("Alterações salvas com sucesso"));
        });
      }
    });
  };

  handleDeleteButtonClick = item => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("excluir")} ${dict.t(
      "este banner"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/home/banners/${item._id}`;

        Request.delete(url, item, msg => {
          this.fetchBanners();

          // Forçar renderização após exclusão
          this.setState({ bannersReady: false });
          Alert.success(dict.t("Banner deletado com sucesso"));
        });
      }
    });
  };

  bannerButtons = [
    {
      type: "text",
      getLabel: item => (item.published ? dict.t("Despublicar") : dict.t("Publicar")),
      onClick: this.handlePublishButtonClick,
    },

    {
      type: "edit",
      route: "/master/configuracao/home-banners/banner/editar",
    },

    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },

    {
      type: "move",
    },
  ];

  statButtons = [
    {
      type: "move",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      bannersReady: false,
      statsReady: false,
      banners: [],
      stats: [],
      smallScreen: false,
    };
  }

  componentWillMount() {
    this.fetchBanners();
    this.fetchStats();
  }

  render() {
    if (!this.state.bannersReady || !this.state.statsReady) return <Loader />;

    let buttonsStyle;

    let addRoute;
    let editStatRoute;
    let canEdit = false;

    if (masterAccess("settings")) {
      addRoute = "/master/configuracao/home-banners/banner/novo";
      editStatRoute = "/master/configuracao/home-banners/estatisticas/editar";
      canEdit = true;
    }

    if (this.state.smallScreen) {
      buttonsStyle = { marginBottom: "20px", float: "none" };
    }

    return (
      <div className="container footer-margin-logged">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="banner-list-container">
          {this.state.smallScreen ? (
            <List
              title="Banners"
              addRoute={addRoute}
              items={this.state.banners}
              itemHeaderComponent={BannerItemImage}
              extraRowComponent={BannerItemBody}
              extraRowProps={{ smallScreen: this.state.smallScreen }}
              bodyStyle={{ width: "calc(100% - 550px)" }}
              buttons={canEdit ? this.bannerButtons : null}
              buttonsStyle={buttonsStyle}
              buttonsAlternatePos={true}
              onSort={this.handleBannersSort}
            />
          ) : (
            <List
              title="Banners"
              addRoute={addRoute}
              items={this.state.banners}
              itemHeaderComponent={BannerItemImage}
              itemBodyComponent={BannerItemBody}
              bodyStyle={{ width: "calc(100% - 550px)" }}
              buttons={canEdit ? this.bannerButtons : null}
              onSort={this.handleBannersSort}
              style={{ height: "140px" }}
            />
          )}
        </div>

        <div className="stats-list-container" style={{ marginBottom: "60px" }}>
          <List
            title={dict.t("Estatísticas")}
            editRoute={canEdit ? editStatRoute : null}
            items={this.state.stats}
            itemHeaderComponent={this.getStatHeaderComponent}
            itemBodyComponent={StatItemBody}
            buttons={canEdit ? this.statButtons : null}
            onSort={this.handleStatsSort}
            style={{ height: "60px" }}
          />
        </div>
      </div>
    );
  }

  getStatHeaderComponent(props) {
    return <div className="list-zebra-item-header-text">{props.item.order + 1 + "."}</div>;
  }

  fetchBanners() {
    Request.get("/master/home/banners", values => {
      this.setState({ bannersReady: true, banners: values });
    });
  }

  fetchStats() {
    Request.get("/master/home/statistics", values => {
      this.setState({ statsReady: true, stats: values });
    });
  }

  handleBannersSort = items => {
    Request.post("/master/home/banners/all", items, msg => {
      this.fetchBanners();
      Alert.success(dict.t("Alterações salvas com sucesso"));
    });
  };

  handleStatsSort = items => {
    Request.post("/master/home/statistics/all", items, msg => {
      this.fetchStats();
      Alert.success(dict.t("Alterações salvas com sucesso"));
    });
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}


