
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Modal de espera de envio de arquivo
//


import React, { Component } from "react";

import Modal from "react-responsive-modal";
import LoaderSpinner from "react-loader-spinner";

import "./style.css";



export default class WaitUploadModal extends Component {
  render() {
    return (
      <Modal open={this.props.open} showCloseIcon={false} center>
        <div className="waituploadmodal-container">
          <div className="waituploadmodal-loader">
            <LoaderSpinner type="Oval" color="#24A862" height="100" width="100" />
          </div>

          <div className="waituploadmodal-message">{this.props.message}</div>
        </div>
      </Modal>
    );
  }
}


