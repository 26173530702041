/**
 * Only numbers
 * @param {*} value
 * @param {*} previousValue
 */
const normalizerNumber = (value, previousValue) => {
  if (!value) return value;

  return value.replace(/[^\d]/g, "");
};

export default normalizerNumber;
