
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de proponente
//


import React, { Component } from "react";
import { Link } from "react-router";

import InfoIcon from "../InfoIcon";
import Checkbox from "../Commons/Checkbox";

import Request from "../../lib/BackendRequest";
import Alert from "../../lib/Alert";

import multiLang from "../../lib/multilang/multiLang";

import ProponentForm from "./ProponentForm";
import ProponentUserForm from "./ProponentUserForm";

import Submit from "../../lib/Forms/Submit";

import ScreenSize from "../Commons/ScreenSize";



const dict = new multiLang(multiLang.getLanguage());

const smallScreenWidth = 768;



export default class SupporterRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      termsOfuse: false,
    };

    this.formValues = {};
  }

  render() {
    let backStyle;
    let paddingBottom = "60px";

    if (this.state.smallScreen) {
      backStyle = { bottom: "-50px" };
      paddingBottom = "90px";
    }

    return (
      <div className="container form-container" style={{ paddingBottom }}>
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="row page-title">
          <div className="col-md-12">
            {dict.t("Novo Proponente")}
            <InfoIcon info={"Preencha os dados de sua organização."} />
          </div>
        </div>

        <div className="row">
          <ProponentForm
            ref="proponent-form"
            initialValues={this.formValues.company}
            onSubmit={this.handleProponentSubmit}
          />
        </div>

        <div className="row page-title" style={{ marginTop: "30px" }}>
          <div className="col-md-12">
            {dict.t("Usuário Principal")}
            <InfoIcon
              info={dict.t(
                "Preencha os dados do principal usuário do perfil de sua organização dentro da nossa plataforma."
              )}
            />
          </div>
        </div>

        <div className="row">
          <ProponentUserForm
            ref="proponent-user-form"
            initialValues={this.formValues.user}
            onSubmit={this.handleProponentUserSubmit}
          />
        </div>

        <div className="row" style={{ marginTop: "15px", marginBottom: "30px" }}>
          <div className="col-md-12 termsofuse-container">
            <Checkbox onChange={this.handleCheckboxChange} />

            <span className="supporter-text" style={{ marginLeft: "15px" }}>
              {dict.t("Li e aceito os")}{" "}
            </span>

            <Link to="/termo-de-uso/proponente">
              <span className="text-link">{dict.t("Termos de uso")}</span>
            </Link>
          </div>
        </div>

        <Submit
          router={this.props.router}
          buttonLabel={dict.t("Salvar")}
          onSubmit={this.submitForms}
          backStyle={backStyle}
        />
      </div>
    );
  }

  composeRecord() {
    if (this.state.termsOfUse) {
      if (this.supporter && this.supporterUser) {
        const record = {
          ...this.supporter,
          users: [this.supporterUser],
        };

        this.supporter = null;
        this.supporterUser = null;

        Request.post("/user/proponent/register", record, msg => {
          Alert.success(dict.t("Cadastro efetuado com sucesso"), () => {
            this.props.router.push("/proponente/habilitacao");
          });
        });
      }
    } else {
      Alert.error(dict.t("Você precisa aceitar os termos de uso"));
    }
  }

  handleCheckboxChange = value => {
    this.setState({ termsOfUse: value });
  };

  submitForms = () => {
    this.refs["proponent-form"].submit();
    this.refs["proponent-user-form"].submit();
  };

  handleProponentSubmit = values => {
    values.role = "proponent";

    this.supporter = values;

    this.composeRecord();
  };

  handleProponentUserSubmit = values => {
    values.role = "proponentUser";
    values.admin = true;

    this.supporterUser = values;

    this.composeRecord();
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}


