
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Mateus Haas
//
// Descrição: Formulário de edição de senha para usuários
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../constants/ValidationFields";
import Input from "../../../lib/Forms/Input";
import Submit from "../../..//lib/Forms/Submit";

import ScreenSize from "../../Commons/ScreenSize";

import Loader from "../../Loader";
import Request from "../../../lib/BackendRequest";
import Alert from "../../../lib/Alert";

import multiLang from "../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());

const smallScreenWidth = 768;



class UserPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = { smallScreen: false };
  }

  render() {
    let backStyle;
    let marginBottom = "50px";

    if (this.state.smallScreen) {
      backStyle = { bottom: "-45px" };
      marginBottom = "80px";
    }

    return (
      <div className="container form-container page-margin-top footer-margin-logged">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">{dict.t("Editar Senha")}</div>

        <div className="col-md-12 form-body" style={{ marginBottom }}>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="old"
                  label={dict.t("Senha antiga")}
                  type="password"
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="new"
                  label={dict.t("Senha nova")}
                  type="password"
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div style={{ marginLeft: "15px" }}>
              <Submit router={this.props.router} backStyle={backStyle} />
            </div>
          </form>
        </div>
      </div>
    );
  }

  updateRecord(values) {
    const url = `/me/change-password`;

    Request.put(url, values, msg => {
      Alert.success(dict.t("Edição efetuada com sucesso"), () => {
        this.props.router.goBack();
      });
    });
  }

  handleSubmit = values => {
    this.updateRecord(values);
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "supporterUserPasswordForm",
})(UserPasswordForm);


