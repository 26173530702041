
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de usuário - master
//


import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import accessModules from "../../../../constants/accessModules";
import masterAccess from "../../../../lib/masterAccess";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import Checkbox from "../../../../lib/Forms/Checkbox";
import Submit from "../../../../lib/Forms/Submit";

import ScreenSize from "../../../Commons/ScreenSize";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());

const smallScreenWidth = 768;



class Form extends Component {
  constructor(props) {
    super(props);

    this.state = { ready: false, access: null };
  }

  componentWillMount() {
    if (this.props.params.id) {
      // edit mode
      this.fecthRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", ready: true });
    }
  }

  render() {
    const { ready, smallScreen } = this.state;

    if (!ready) return <Loader />;

    let backStyle;
    let paddingBottom = "30px";

    if (smallScreen) {
      backStyle = { bottom: "-50px" };
      paddingBottom = "80px";
    }

    const canEdit = masterAccess("admin", this.state.record ? this.state.record._id : null);

    return (
      <div className="container form-container footer-margin-logged" style={{ paddingBottom }}>
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">
          {this.state.mode === "create" ? dict.t("Novo Perfil") : dict.t("Editar Perfil")}
        </div>

        <div className="col-sm-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row">
              <div className="col-sm-6">
                <Field
                  name="name"
                  label={dict.t("Nome")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            {masterAccess("admin") ? (
              <Fragment>
                <div className="form-input-label" style={{ marginBottom: "10px" }}>
                  {dict.t("Permissões para leitura e escrita nas seguintes páginas") + ":"}
                </div>

                <div className="row">{this.getAccessFields(accessModules)}</div>
              </Fragment>
            ) : null}

            <div style={{ marginTop: "30px" }}>
              <Submit router={this.props.router} backStyle={backStyle} backOnly={!canEdit} />
            </div>
          </form>
        </div>
      </div>
    );
  }

  getAccessFields(items) {
    return items.map((item, index) => {
      return (
        <div key={index} className="col-md-4 col-sm-6">
          <Field
            name={`access.${item.name}`}
            label={dict.t(item.label)}
            style={{ marginBottom: "15px" }}
            cssClass="form-input-checkbox"
            cssClassError="form-input-error"
            component={Checkbox}
          />
        </div>
      );
    });
  }

  fecthRecord(id) {
    const url = `/master/profiles/${id}`;

    Request.get(url, values => {
      const formValues = { ...values };

      if (values.access) {
        formValues.access = {};
        values.access.forEach(
          item => (formValues.access[`${item}`] = { name: `access.${item}`, value: true })
        );
      }

      this.props.initialize(formValues);

      this.setState({ record: values, access: values.access || [], ready: true });
    });
  }

  createRecord(values) {
    const url = "/master/profiles";

    Request.post(url, values, res => {
      if (res.success) {
        Alert.success(dict.t("Cadastro efetuado com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  updateRecord(values) {
    const url = `/master/profiles/${this.state.record._id}`;

    Request.post(url, values, res => {
      if (res.success) {
        Alert.success(dict.t("Edição efetuada com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  handleSubmit = values => {
    const access = [];

    for (let key in values.access) {
      const item = values.access[key];

      if (item.value) access.push(key);
    }

    values.access = access;

    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "masterProfileForm",
})(Form);


