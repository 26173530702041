
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de banner
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import TextArea from "../../../../lib/Forms/TextArea";
import ImageInput from "../../../../lib/Forms/ImageInput";
import Submit from "../../../..//lib/Forms/Submit";

import ScreenSize from "../../../Commons/ScreenSize";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import fileUpload from "../../../../lib/fileUpload";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 768;



class BannerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      record: {},
      imageChange: false,
      uploading: false,
    };
  }

  componentWillMount() {
    this.formValues = {};

    if (this.props.params.id) {
      // edit mode
      this.fecthRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", ready: true });
    }
  }

  render() {
    if (!this.state.ready) return <Loader />;

    let backStyle;
    let paddingBottom = "50px";

    if (this.state.smallScreen) {
      backStyle = { bottom: "-50px" };
      paddingBottom = "80px";
    }

    return (
      <div
        className="container form-container page-margin-top footer-margin-logged"
        style={{ paddingBottom }}
      >
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">
          {this.state.mode === "create" ? dict.t("Novo Banner") : dict.t("Editar Banner")}
        </div>

        <div className="col-md-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <Field
                  name={`${language}.title`}
                  label={dict.t("Título")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Field
                  name={`${language}.text`}
                  label={dict.t("Texto")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={TextArea}
                />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "30px" }}>
              <div className="banner-form-input-text">
                <div>
                  <Field
                    name={`${language}.buttonLabel`}
                    label={dict.t("Texto do botão")}
                    validate={[VF.Required]}
                    cssClass="form-input"
                    cssClassError="form-input-error"
                    component={Input}
                  />
                </div>

                <div style={{ marginTop: "-14px" }}>
                  <Field
                    name="buttonRoute"
                    label={dict.t("Link do botão")}
                    validate={[VF.Required]}
                    cssClass="form-input"
                    cssClassError="form-input-error"
                    component={Input}
                  />
                </div>
              </div>

              <div className="banner-form-input-image">
                <Field
                  name="image"
                  label={`${dict.t("Imagem")} - 1920x840px`}
                  image={this.state.record.image}
                  validate={[VF.Required]}
                  component={ImageInput}
                  onChange={this.handleImageChange}
                  style={{ backgroundSize: "cover" }}
                />
              </div>
            </div>

            <Submit
              backRoute="/master/configuracao/home-banners"
              sending={this.state.uploading}
              backStyle={backStyle}
            />
          </form>
        </div>
      </div>
    );
  }

  fecthRecord(id) {
    const url = `/master/home/banners/${id}`;

    Request.get(url, values => {
      this.setState({ record: values, ready: true });
      this.props.initialize(this.state.record);
    });
  }

  submitData(values) {
    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  }

  createRecord(values) {
    const url = "/master/home/banners";

    Request.post(url, values, msg => {
      Alert.success("Cadastro efetuado com sucesso", () => {
        this.props.router.push("/master/configuracao/home-banners");
      });
    });
  }

  updateRecord(values) {
    const url = `/master/home/banners/${this.state.record._id}`;

    Request.post(url, values, msg => {
      Alert.success("Edição efetuada com sucesso", () => {
        this.props.router.push("/master/configuracao/home-banners");
      });
    });
  }

  handleImageChange = image => {
    const record = this.state.record;
    record.image = image;
    this.setState({ record: record, imageChange: true });
  };

  handleSubmit = values => {
    if (this.state.imageChange) {
      this.formValues = values;
      this.setState({ uploading: true });

      fileUpload(values.image[0]).then(res => {
        this.formValues.image = res[0].url;
        this.setState({ uploading: false });
        this.submitData(this.formValues);
      });
    } else {
      this.submitData(values);
    }
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "bannerForm",
})(BannerForm);


