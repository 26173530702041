
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Regis Ebeling
//
// Descrição: Corpo do item de lista do tipo Área de Interesse
//


import React from "react";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();



export default function(props) {
  props.item[language] = languageTools.merge(props.item.PT, props.item[language]);

  return <div className="list-item-title">{props.item[language].name}</div>;
}


