
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de área de interesse
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import ImageInput from "../../../../lib/Forms/ImageInput";
import Submit from "../../../..//lib/Forms/Submit";

import ScreenSize from "../../../Commons/ScreenSize";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import fileUpload from "../../../../lib/fileUpload";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 768;



class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      record: {},
      imageChange: false,
    };
  }

  componentWillMount() {
    this.formValues = {};

    if (this.props.params.id) {
      // edit mode
      this.fecthRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", ready: true });
    }
  }

  render() {
    if (!this.state.ready) return <Loader />;

    let backStyle;
    let paddingBottom = "50px";

    if (this.state.smallScreen) {
      backStyle = { bottom: "-50px" };
      paddingBottom = "80px";
    }

    return (
      <div
        className="container form-container page-margin-top footer-margin-logged"
        style={{ paddingBottom }}
      >
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">
          {this.state.mode === "create"
            ? dict.t("Nova Área de Interesse")
            : dict.t("Editar Área de Interesse")}
        </div>

        <div className="col-md-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row" style={{ marginBottom: "30px" }}>
              <div className="areaofinterest-form-input-text">
                <Field
                  name={`${language}.name`}
                  label={dict.t("Nome")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="areaofinterest-form-input-image">
                <Field
                  name="image"
                  label="Imagem - 200x200px"
                  image={this.state.record.image}
                  validate={[VF.Required]}
                  component={ImageInput}
                  onChange={this.handleImageChange}
                />
              </div>
            </div>

            <Submit backRoute="/master/configuracao/areas-de-interesse" backStyle={backStyle} />
          </form>
        </div>
      </div>
    );
  }

  fecthRecord(id) {
    const url = `/master/projects/areas-of-interest/${id}`;

    Request.get(url, values => {
      this.setState({ record: values, ready: true });
      this.props.initialize(this.state.record);
    });
  }

  submitData(values) {
    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  }

  createRecord(values) {
    const url = "/master/projects/areas-of-interest";

    Request.post(url, values, msg => {
      Alert.success(dict.t("Cadastro efetuado com sucesso"), () => {
        this.props.router.push("/master/configuracao/areas-de-interesse");
      });
    });
  }

  updateRecord(values) {
    const url = `/master/projects/areas-of-interest/${this.state.record._id}`;

    Request.post(url, values, msg => {
      Alert.success(dict.t("Edição efetuada com sucesso"), () => {
        this.props.router.push("/master/configuracao/areas-de-interesse");
      });
    });
  }

  handleImageChange = image => {
    const record = this.state.record;
    record.image = image;
    this.setState({ record: record, imageChange: true });
  };

  handleSubmit = values => {
    if (this.state.imageChange) {
      this.formValues = values;
      fileUpload(values.image).then(res => {
        this.formValues.image = res[0].url;
        this.submitData(this.formValues);
      });
    } else {
      this.submitData(values);
    }
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "areaOfInterestForm",
})(Form);


