
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de botões inferior
//


import React, { Component } from "react";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const dict = new multiLang(multiLang.getLanguage());



export default class ButtonBar extends Component {
  render() {
    return (
      <div className="new-project-button-bar">
        <div className="row">
          {!this.props.first ? (
            <div className="col-sm-3 col-md-2">
              <button className="button new-project-base-button" onClick={this.props.onPrevClick}>
                {dict.t("Voltar")}
              </button>
            </div>
          ) : (
            <div className="col-sm-3 col-md-2"></div>
          )}

          <div className="col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-3">
            <button
              className="button new-project-base-button new-project-save-button"
              onClick={this.props.onSaveClick}
            >
              <img className="button-icon" src="https://vbio.blob.core.windows.net/public/assets/img/save-icon.png" alt={dict.t("Salvar")} />
              {dict.t("Salvar")}
            </button>
          </div>

          {!this.props.last ? (
            <div
              className="col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-3"
              onClick={this.props.onNextClick}
            >
              <button className="button new-project-base-button">{dict.t("Avançar")}</button>
            </div>
          ) : (
            <div
              className="col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-3"
              onClick={this.props.onSubmitClick}
            >
              <button className="button new-project-base-button">{dict.t("Submeter")}</button>
            </div>
          )}
        </div>
      </div>
    );
  }
}


