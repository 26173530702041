
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de submit para form de login.
//


import React, { Component } from "react";
import { Link } from "react-router";

import multiLang from "../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class List extends Component {
  render() {
    return (
      <div className="row login-submit">
        <div className="col-sm-6">
          <Link to={this.props.forgotPasswordRoute}>
            <div className="login-forget-password-text">{dict.t("Esqueceu sua senha?")}</div>
          </Link>
        </div>

        <div className="col-sm-6">
          <button type="submit" className="button login-submit-button">
            {dict.t("Entrar")}
          </button>
        </div>
      </div>
    );
  }
}


