import Moment from "moment";
import MomentTz from "moment-timezone";

export default class Date {
  static format(date) {
    let dateTimeZone = MomentTz(date)
      .tz("America/Sao_Paulo")
      .format();

    let dateNTime = dateTimeZone.split("T");
    let newDate = dateNTime[0].split("-");

    return `${newDate[2]}/${newDate[1]}/${newDate[0]} ${dateNTime[1]}`;
  }

  static formatWithoutTime(date) {
    let formatted = Date.format(date);

    return formatted.substring(0, 10);
  }

  static formatTimeOnly(date) {
    let formatted = Date.format(date);

    return formatted.substring(11, formatted.length - 6);
  }

  static formatWithoutSeconds(date) {
    let formatted = Date.format(date);

    return formatted.substring(0, 16);
  }

  static getTimeWithoutSeconds(date) {
    let formatted = Date.format(date);

    return formatted.substring(10, 16);
  }

  static formatToServer(date) {
    return Date.formatToServerUsingMoment(Date.Ymd2Moment(date));
  }

  static formatToServerUsingMoment(date) {
    if (date !== undefined) {
      return date.format("YYYY-MM-DD");
    } else return "0000-00-00";
  }

  static dmY2Ymd(date) {
    let newDate = date.split("/");

    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  }

  static getDateFormat() {
    return "DD/MM/YYYY";
  }

  static getYear(date) {
    let formatted = Date.format(date);

    return formatted.substring(6, 11);
  }

  static getMonth(date) {
    let formatted = Date.format(date);

    return formatted.substring(3, 5);
  }

  static get30DaysAgo() {
    return Moment().subtract(30, "days");
  }

  static get15DaysAfter(date = null) {
    let moment = Moment().add(15, "days");

    if (date !== null) {
      let dateMoment = Moment(`${date}T00:00:00`).add(15, "days");

      if (dateMoment >= moment) {
        moment = dateMoment;
      }
    }

    return moment;
  }

  static getTomorrow() {
    return Moment().add(1, "days");
  }

  static getMoment() {
    return Moment();
  }

  static Ymd2Moment(dateString) {
    return Moment(`${dateString}T00:00:00`);
  }

  static isAfterToday(date) {
    return this.daysLeftToday(date) > 0;
  }

  static daysLeftToday(date) {
    return Moment(date).diff(Moment(), "days");
  }
}
