const dev = {
  backend_url: "http://localhost:8082",
};

const prod = {
  backend_url: "https://api.vbio.app"
};

const config = process.env.NODE_ENV === "dev" ? dev : prod;

export default {
  project_title: "Vbio",
  version: "1.0.0",

  generalSupportFee: 0.18,

  defaultLanguage: "PT",

  googleMaps: {
    apiKey: "AIzaSyCz4Z0E22-KtaqraVkKNEVOJiftxjgKYLI",
  },

  api: {
    cep: param => `https://viacep.com.br/ws/${param}/json/`,
  },

  file: {
    upload: "/file/upload",
    remove: "/file/remove",
  },

  ...config,
};
