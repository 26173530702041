
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de post no blog
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import Input from "../../../../lib/Forms/Input";

import TextFormatted from "../../../../lib/Forms/TextFormatted";

import ImageInput from "../../../../lib/Forms/ImageInput";

import VF from "../../../../constants/ValidationFields";

import { normalizerUrl } from "../../../../lib/Normalizer";

import multiLang from "../../../../lib/multilang/multiLang";
import TextArea from "../../../../lib/Forms/TextArea";
import Dropdown from "../../../../lib/Forms/Dropdown";



const dict = new multiLang(multiLang.getLanguage());
const language = multiLang.getLanguage();



class Form extends Component {
  componentWillMount() {
    this.setState({ image: this.props.defaultValues.image });
    this.props.initialize(this.props.defaultValues);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ image: newProps.defaultValues.image });
  }

  render() {
    return (
      <div className="form-body" style={{ marginBottom: "30px" }}>
        <form>
          <div className="row">
            <div className="col-md-6">
              <Field
                name={`${language}.title`}
                label={dict.t("Título")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-6">
              <Field
                name="link"
                label={dict.t("Link da URL")}
                validate={[VF.Required]}
                normalize={normalizerUrl}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Field
                name={`${language}.summary`}
                label={dict.t("Resumo")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={TextArea}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Field
                name={`${language}.fullText`}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={TextFormatted}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Field
                name="author"
                label={dict.t("Autor")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Dropdown}
                data={this.props.authors}
                dataLabel="name"
                dataKey="_id"
              />
            </div>

            <div className="col-md-6">
              <Field
                name="category"
                label={dict.t("Categoria")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                data={this.props.categories}
                dataLabel="name"
                dataKey="_id"
                component={Dropdown}
              />
            </div>
          </div>

          <div className="row">
            <div className="areaofinterest-form-input-text">
              <Field
                name={`${language}.tags`}
                label={dict.t("Tags")}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={TextArea}
              />
            </div>

            <div className="areaofinterest-form-input-image">
              <Field
                name="image"
                label="Imagem - 1140x560px"
                validate={[VF.Required]}
                image={this.state.image}
                component={ImageInput}
                onChange={this.handleImageChange}
                style={{ backgroundSize: "cover" }}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  handleImageChange = image => {
    if (this.props.onImageChange) this.props.onImageChange(image);
  };
}



export default reduxForm({
  form: "postForm",
})(Form);


