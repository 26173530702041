
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Corpo do item de lista do tipo stats
//


import React from "react";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default function(props) {
  props.item[language] = languageTools.merge(props.item.PT, props.item[language]);

  return (
    <div className="stat-list-item-container row" style={{ marginLeft: "20px", width: "100%" }}>
      <div className="col-md-6">
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>{dict.t("Numeral")}: </span>
        {props.item[language].value}
      </div>

      <div className="col-md-6">
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>{dict.t("Descritivo")}: </span>
        {props.item[language].label}
      </div>
    </div>
  );
}


