
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Corrosel - Seta
//


import React, { Component } from "react";



export default class Arrow extends Component {
  render() {
    const className = "carousel-arrow carousel-arrow-" + this.props.side;

    let style =
      this.props.side === "left"
        ? { marginLeft: this.props.margin }
        : { marginRight: this.props.margin };

    style.top = this.props.top;

    if (this.props.size) style.height = this.props.size;

    return (
      <img
        className={className}
        style={style}
        src={this.props.image}
        alt="Anterior"
        onClick={this.props.onClick}
      />
    );
  }
}


