
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de usuário - master
//


import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import Authentication from "../../../../lib/Authentication";
import accessModules from "../../../../constants/accessModules";
import masterAccess from "../../../../lib/masterAccess";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import Checkbox from "../../../../lib/Forms/Checkbox";
import Submit from "../../../../lib/Forms/Submit";
import Dropdown from "../../../../lib/Forms/Dropdown";

import ScreenSize from "../../../Commons/ScreenSize";

import Forward from "../../../Commons/Forward";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());

const smallScreenWidth = 768;



class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      access: null,
    };
  }

  componentWillMount() {
    this.fetchProfiles();

    if (this.props.params.id) {
      // edit mode
      this.fecthRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", ready: true });
    }
  }

  render() {
    const { ready, smallScreen } = this.state;

    if (!ready) return <Loader />;

    let forgotStyle = { marginBottom: "30px" };
    let backStyle;
    let paddingBottom = "30px";

    if (smallScreen) {
      backStyle = { bottom: "-50px" };
      paddingBottom = "80px";
    }

    const canEdit = masterAccess("admin", this.state.record ? this.state.record._id : null);

    return (
      <div className="container form-container footer-margin-logged" style={{ paddingBottom }}>
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">
          {this.state.mode === "create" ? dict.t("Novo Usuário") : dict.t("Editar Usuário")}
        </div>

        <div className="col-sm-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row">
              <div className="col-sm-6">
                <Field
                  name="name"
                  label={dict.t("Nome")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-sm-6">
                <Field
                  name="email"
                  label={dict.t("E-mail")}
                  validate={[VF.Required, VF.Email]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "10px" }}>
              {masterAccess("admin") ? (
                <div className="col-md-6">
                  <Field
                    name="profile"
                    label={dict.t("Perfil")}
                    cssClass="form-input"
                    data={this.state.profiles}
                    dataLabel="name"
                    dataKey="_id"
                    component={Dropdown}
                  />
                </div>
              ) : null}

              {this.state.mode === "create" ? (
                <div className="col-sm-6">
                  <Field
                    name="password"
                    label={dict.t("Senha")}
                    type="password"
                    validate={[VF.Required]}
                    cssClass="form-input"
                    cssClassError="form-input-error"
                    component={Input}
                  />
                </div>
              ) : null}
            </div>

            {this.state.mode !== "create" && canEdit ? (
              <Forward
                style={forgotStyle}
                forwardLabel="Alterar Senha"
                forwardRoute={`/master/configuracao/usuarios/alterar-senha/${this.props.params.id}`}
              />
            ) : null}

            <div style={{ marginTop: "30px" }}>
              <Submit router={this.props.router} backStyle={backStyle} backOnly={!canEdit} />
            </div>
          </form>
        </div>
      </div>
    );
  }

  fetchProfiles() {
    let url = "/master/profiles";

    Request.get(url, values => {
      this.setState({ profiles: values });
    });
  }

  fecthRecord(id) {
    const url = `/master/users/${id}`;

    Request.get(url, values => {
      const formValues = { ...values };

      if (values.profile) formValues.profile = values.profile._id;

      this.props.initialize(formValues);

      this.setState({ record: values, ready: true });
    });
  }

  createRecord(values) {
    const url = "/master/users";

    values.role = "master";

    Request.post(url, values, res => {
      if (res.success) {
        Alert.success(dict.t("Cadastro efetuado com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  updateRecord(values) {
    const url = `/master/users/${this.state.record._id}`;

    Request.post(url, values, res => {
      if (res.success) {
        Alert.success(dict.t("Edição efetuada com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  handleSubmit = values => {
    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "masterUserForm",
})(Form);


