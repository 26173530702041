
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Componente checkbox
//


import React, { Component } from "react";

import "./style.css";



export default class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = { value: false };
  }

  render() {
    const checkedIcon = this.state.value
      ? { backgroundImage: "url(/assets/img/checked-icon.png)" }
      : { backgroundImage: "none" };

    return (
      <div className="form-input-checkbox" style={checkedIcon}>
        <input
          type="checkbox"
          onChange={this.handleChange}
        />
      </div>
    );
  }

  handleChange = e => {
    const value = e.target.checked;

    this.setState({ value });

    if (this.props.onChange) this.props.onChange(value);
  };
}


