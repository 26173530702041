/**
 * 8 num
 * 00/00/0000
 * @param {*} value
 * @param {*} previousValue
 */
const normalizerDate = (value, previousValue) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length <= 2) return onlyNums.slice(0, 2);

  if (onlyNums.length <= 4) return onlyNums.slice(0, 2) + "/" + onlyNums.slice(2);

  return onlyNums.slice(0, 2) + "/" + onlyNums.slice(2, 4) + "/" + onlyNums.slice(4, 8);
};

export default normalizerDate;
