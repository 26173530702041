
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Redefinição de senha
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../constants/ValidationFields";
import Input from "../../lib/Forms/Input";

import Request from "../../lib/BackendRequest";
import Alert from "../../lib/Alert";

import multiLang from "../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



class ChangeForm extends Component {
  render() {
    return (
      <div className="container login-container">
        <div className="form-container login-form-container">
          <div className="col-md-12 form-body">
            <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
              <div className="row">
                <Field
                  name="password"
                  type="password"
                  label={dict.t("Nova senha")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="row">
                <Field
                  name="password2"
                  type="password"
                  label={dict.t("Repetir senha")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="row">
                <button
                  type="submit"
                  className="login-submit-button button"
                  style={{ width: "100%" }}
                >
                  {dict.t("Alterar senha")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  handleSubmit = values => {
    if (values.password !== values.password2) {
      Alert.error(dict.t("As senhas não batem. Por gentileza, confira se as senhas correspondem."));
      return;
    }

    Request.put(`/user/forgot-password/${this.props.params.id}`, values, res => {
      if (res.success) {
        Alert.success(dict.t("Sua senha foi redefinida com sucecsso."));
        this.props.router.push("/login");
      } else
        Alert.error(
          dict.t(
            "Ocorreu um erro ao redefinir sua senha. Tenha certeza que você informou o código de verificação correto."
          )
        );
    });
  };
}



export default reduxForm({
  form: "changeForm",
})(ChangeForm);


