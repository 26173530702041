
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Marco, Régis, Diego
//
// Descrição: Formulário de busca de projetos padrão
//


import React, { Component } from "react";

import Dropdown from "../Dropdown";

import SearchBar from "../SearchBar";

import multiLang from "../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class ProponentSearchForm extends Component {
  componentWillMount() {
    const { proponents, status } = this.props;
    this.processProponents(proponents, status);
  }

  render() {
    let { states } = this.state;

    return (
      <div>
        <div className="page-margin-top page-title" style={{ marginLeft: "-15px" }}>
          {this.props.title ? this.props.title : ""}
        </div>

        <div className="container searchOptionsContainer">
          <div className="row">
            <div className="col-md-2">
              <Dropdown
                style={{ height: "40px" }}
                label={dict.t("Estado")}
                selectClass="searchOptionsSelect"
                options={states}
                onChange={ev => this.handleChangeFilter(ev, "state")}
              />
            </div>

            <div className="col-md-10">
              <div style={{ marginTop: "20px" }}>
                <SearchBar
                  button={{
                    title: this.props.buttonLabel,
                    onClick: this.handleSearchButtonClick,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  processProponents(proponents, status) {
    if (status) {
      status = status.map(item => {
        return { status: item };
      });
    }

    this.setState({
      records: proponents,
      recordsCache: proponents.concat(),
      states: this.buildStates(proponents),

      filters: {
        state: "all",
      },
    });
  }

  buildStates = records => {
    const obj = {};
    const result = [{ title: dict.t("Todos"), value: "all" }];

    records.forEach(record => {
      if (record.state && !obj[record.state]) {
        result.push({ title: record.state, value: record.state });
        obj[record.state] = true;
      }
    });

    return result;
  };

  filterByName = (records, searchValue) => {
    let translated = dict.t(searchValue);

    if (!translated || typeof translated !== "string") return;

    translated = translated.toLowerCase().trim();

    return records.filter(item => {
      const current = item;

      if (!current) return false;

      let company = current.company.toLowerCase().trim();
      let trade = current.trade.toLowerCase().trim();

      return company.includes(translated) || trade.includes(translated);
    });
  };

  filterByState = (records, state) => {
    return records.filter(item => item.state === state);
  };

  handleChangeFilter = (ev, key) => {
    let { filters } = this.state;

    filters[key] = ev.target.value;

    this.setState({ filters });
  };

  handleSearchButtonClick = searchValue => {
    const { filters } = this.state;

    console.log(this.props.proponents);
    let records = this.props.proponents;

    if (this.props.status) {
      records.map((item, index) => {
        item.status = this.props.status[index];
        return item;
      });
    }

    if (searchValue !== "") records = this.filterByName(records, searchValue);

    if (filters.state !== "all") records = this.filterByState(records, filters.state);

    if (this.props.onFilter) this.props.onFilter(records);
  };
}


