import multiLang from "../lib/multilang/multiLang";

const dict = new multiLang(multiLang.getLanguage());

export default {
  Required: (value, all, props, name) => {
    return value || value === 0 ? undefined : dict.t("Campo obrigatório");
  },

  DropdownRequired: (value, all, props, name) => {
    return value ? undefined : dict.t("Campo obrigatório");
  },

  isNumber: value => (typeof value === "number" ? undefined : "Selecione"),

  MaxLength: max => value => (value && value.length > max ? "max" : undefined),

  Number: value => (value && isNaN(Number(value)) ? "number" : undefined),

  MinValue: min => value => (value && value < min ? "min" : undefined),

  Email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Por favor, insira um E-mail válido"
      : undefined,

  OptionalPassword: value =>
    value
      ? value.length >= 8 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value)
        ? undefined
        : "password"
      : undefined,

  Password: value =>
    value
      ? value.length >= 8 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value)
        ? undefined
        : "password"
      : dict.t("Campo obrigatório"),

  CNPJ: value => {
    const validateCNPJ = require("../lib/validateCNPJ");
    if (!validateCNPJ(value)) return dict.t("Por favor, insira um CNPJ válido");
  },
};
