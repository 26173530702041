
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Pilhas de badges
//


import React, { Component, Fragment } from "react";

import Tooltip from "./Tooltip";

import "./style.css";



const delta = 4;
const tooltipOffset = 10;



export default class BadgeStack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipShow: false,
      tooltipPosition: { top: 0, left: 0 },
      tooltipItems: [],
    };
  }

  refCallback = element => {
    if (element && this.props.onResize) this.props.onResize(element.getBoundingClientRect().width);
  };

  render() {
    let { items, group } = this.props;

    if (!items || items.length === 0 || !items[0]) return null;

    const badgeSize = `${this.props.badgeWidth}px`;
    const style = Object.assign({}, this.props.style, { width: badgeSize, height: badgeSize });

    return (
      <div style={{ display: "inline-block" }} ref={this.refCallback}>
        {!group || items.length === 1 ? (
          this.getItems(items, badgeSize)
        ) : (
          <div
            className="badgestack-container"
            style={style}
            onMouseMove={e => this.handleMouseMove(e, items)}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {this.getGroupedItems(items)}
          </div>
        )}

        <Tooltip
          show={this.state.tooltipShow}
          position={this.state.tooltipPosition}
          items={this.state.tooltipItems}
        />
      </div>
    );
  }

  getItems(items, badgeSize) {
    if (!items) return null;

    return (
      <Fragment>
        {items.map((item, index) => {
          return (
            <img
              key={index}
              className="vitrine-project-badge"
              src={item.icon}
              alt=""
              style={{ width: badgeSize }}
              onMouseMove={e => this.handleMouseMove(e, [item])}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            />
          );
        })}
      </Fragment>
    );
  }

  getGroupedItems(items) {
    if (!items) return null;

    let count = items.length - 1;

    return items.map((item, index) => {
      const badgeWidth = this.props.badgeWidth || 60;
      const iconOffset = -15;

      const top = count * delta;
      const left = count * delta;

      const badgeStyle = { top: `${top}px`, left: `${left}px`, width: `${badgeWidth}px` };

      const iconStyle = {
        top: `${badgeWidth - top + iconOffset}px`,
        left: `${badgeWidth - left + iconOffset}px`,
      };

      count--;

      return (
        <Fragment key={index}>
          <img className="badgestack-item" src={item.icon} style={badgeStyle} alt="" />

          {count < 0 ? (
            <img
              className="badgestack-plus-icon"
              src="https://vbio.blob.core.windows.net/public/assets/img/add-icon-small.png"
              style={iconStyle}
              alt=""
            />
          ) : null}
        </Fragment>
      );
    });
  }

  handleMouseMove = (e, items) => {
    this.setState({
      tooltipPosition: { top: e.clientY + tooltipOffset, left: e.clientX + tooltipOffset },
      tooltipItems: items.map(item => item.label),
    });
  };

  handleMouseEnter = () => {
    this.setState({ tooltipShow: true });
  };

  handleMouseLeave = () => {
    this.setState({ tooltipShow: false });
  };
}


