
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Formulário de post no blog
//


import React, { Component } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import masterAccess from "../../../../lib/masterAccess";

import Form from "./Form";

import ScreenSize from "../../../Commons/ScreenSize";

import multiLang from "../../../../lib/multilang/multiLang";

import SaveOrSubmit from "../../../..//lib/Forms/SaveOrSubmit";

import fileUpload from "../../../../lib/fileUpload";



const dict = new multiLang(multiLang.getLanguage());
const language = multiLang.getLanguage();

const smallScreenWidth = 768;



export default class PostForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      record: null,
      authors: null,
      categories: null,
      imageChange: false,
      smallScreen: false,
    };
  }

  componentWillMount() {
    this.fetchAuthors();
    this.fetchCategories();

    if (this.props.params.id) {
      // edit mode
      this.fecthRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", record: {} });
    }
  }

  render() {
    if (!this.state.record || !this.state.authors || !this.state.categories) return <Loader />;

    let buttonStyle;
    let backStyle;
    let containerStyle;

    if (this.state.smallScreen) {
      buttonStyle = { float: "none" };
      backStyle = { bottom: "-50px", left: "15px" };
      containerStyle = { marginBottom: "280px" };
    }

    const canEdit = masterAccess("blogPost");

    return (
      <div
        className="container form-container page-margin-top footer-margin-logged"
        style={containerStyle}
      >
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">
          {this.state.mode === "create" ? dict.t("Novo Post") : dict.t("Editar Post")}
        </div>

        <div>
          <Form
            ref="blog-post-form"
            defaultValues={this.state.record}
            authors={this.state.authors}
            categories={this.state.categories}
            onImageChange={this.handleImageChange}
            onSubmit={this.handleFormSubmit}
          />
        </div>

        <div>
          <SaveOrSubmit
            router={this.props.router}
            buttonStyle={buttonStyle}
            backStyle={backStyle}
            onSave={this.handleSaveClick}
            onSubmit={this.handleSubmitClick}
            style={{ marginBottom: "30px" }}
            backOnly={!canEdit}
          />
        </div>
      </div>
    );
  }

  fecthRecord(id) {
    const url = `/master/blog/posts/${id}`;

    Request.get(url, values => {
      values.author = values.author && values.author[0] ? values.author[0]._id : null;
      values.category = values.category[0];

      let tags = values[language].tags.map(t => t.description);
      values[language].tags = tags;

      this.setState({ record: values });
    });
  }

  fetchAuthors() {
    let url = "/master/blog/authors";

    Request.get(url, values => {
      this.setState({ authors: values });
    });
  }

  fetchCategories() {
    let url = "/master/blog/categories";

    Request.get(url, values => {
      let values2 = values.filter(e => {
        if (typeof e[language] !== "undefined") {
          return e;
        }
      });

      values = values2.map(e => {
        return { name: e[language].name, _id: e._id };
      });

      this.setState({ categories: values });
    });
  }

  submitRecord(values) {
    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  }

  createRecord(values) {
    const postValues = JSON.parse(JSON.stringify(values));

    if (values[language] && values[language].tags) {
      let arrayTags = values[language].tags.split(",").map(e => {
        return { description: e.trim() };
      });
      postValues[language].tags = arrayTags;
    }

    const url = "/master/blog/posts";

    Request.post(url, postValues, res => {
      if (res.success) {
        Alert.success(dict.t("Cadastro efetuado com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  updateRecord(values) {
    const postValues = JSON.parse(JSON.stringify(values));

    if (values[language].tags) {
      let arrayTags;
      if (typeof values[language].tags === "string")
        arrayTags = values[language].tags.split(",").map(e => {
          return { description: e.trim() };
        });
      else if (typeof values[language].tags === "object")
        arrayTags = values[language].tags.map(e => {
          return { description: e.trim() };
        });

      postValues[language].tags = arrayTags;
    }

    const url = `/master/blog/posts/${this.props.params.id}`;

    Request.post(url, postValues, res => {
      if (res.success) {
        Alert.success(dict.t("Edição efetuada com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  handleImageChange = image => {
    const record = this.state.record;
    record.image = image;
    this.setState({ record: record, imageChange: true });
  };

  handleSubmitClick = () => {
    this.submit = true;
    this.refs["blog-post-form"].submit();
  };

  handleSaveClick = () => {
    this.submit = false;
    this.refs["blog-post-form"].submit();
  };

  handleFormSubmit = values => {
    if (this.submit) {
      const date = new Date();
      values.publishDate = date.toISOString();
      values.enabled = true;
    }

    // Testa se o artigo de post possui algum campo que não está em branco para poder salvar
    if (
      (values.author !== "0" && values.author) ||
      (values.category !== "0" && values.category) ||
      (values.title && values.title.trim() !== "") ||
      (values.link && values.link.trim() !== "") ||
      (values.summary && values.summary.trim() !== "") ||
      (values.fullText && values.fullText !== "<p><br></p>") ||
      (values.tags && values.tags.trim() !== "") ||
      !this.submit
    ) {
      if (this.state.imageChange && typeof values.image !== "string") {
        fileUpload(values.image).then(res => {
          values.image = res[0].url;
          this.submitRecord(values);
        });
      } else {
        this.submitRecord(values);
      }
    } else {
    }
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}


