
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Detalhes do item
//


import React, { Fragment } from "react";
import moment from "moment";
import urlencode from "urlencode";

import CustomDate from "../../../../lib/Date";

import downloadFile from "../../../../lib/downloadFile";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



function renderJustifications(items) {
  if (!items) return null;

  return items.map((item, index) => {
    return (
      <Fragment key={index}>
        <div className="proponent-documents-list-item-expanded-separator" />

        <strong>{`${dict.t("Justificativa")} ${CustomDate.formatWithoutTime(item.date)}`}</strong>
        <p>{item.text}</p>
      </Fragment>
    );
  });
}



function handleDownloadClick(url) {
  //window.open(url);
  downloadFile(url);
}



function getFileNameFromUrl(url, removeS3Hash) {
  let filename = "";

  if (typeof url !== "string") {
    if (url) filename = url.name;
  } else {
    filename = url.substring(url.lastIndexOf("/") + 1);
  }

  if (removeS3Hash) filename = filename.substring(72);

  return urlencode.decode(filename);
}



export default function(props) {
  const date = props.item.date;
  let label;

  if (date) label = `${dict.t("Arquivo submetido em")} ${moment(date).format("DD/MM/YYYY")}`;
  else label = dict.t("Arquivo submetido");

  return (
    <div className="proponent-documents-list-item-expanded-container">
      <div className="row">
        <div className="col-md-10">
          {props.item.url ? (
            <Fragment>
              <p>
                <strong>{label}</strong>
              </p>
              <p>{getFileNameFromUrl(props.item.url, true)}</p>
            </Fragment>
          ) : (
            <p>
              <strong>{dict.t("Documento não enviado")}</strong>
            </p>
          )}
        </div>

        <div className="col-md-2">
          {props.item.url ? (
            <Fragment>
              <img
                className="proponent-documents-list-item-expanded-download-icon"
                src="https://vbio.blob.core.windows.net/public/assets/img/download-icon-strong.png"
                alt={dict.t("Baixar documento")}
                title={dict.t("Baixar documento")}
                onClick={() => handleDownloadClick(props.item.url)}
              />

              <div className="proponent-documents-list-item-expanded-download-label">
                {dict.t("Baixar documento")}
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>

      {renderJustifications(props.item.history)}
    </div>
  );
}

