
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Por que Vbio
//


import React, { Component } from "react";

import { IndexLink as Link } from "react-router";

import multiLang from "../../lib/multilang/multiLang";

import YoutubeModal from "react-youtube-modal";

import "./style.css";



const language = multiLang.getLanguage();
const videoId = language === "EN" ? "JHZH3xlhdJI" : "im-M3Bkxbps";
const dict = new multiLang(language);



export default class WhyVbio extends Component {
  render() {
    return (
      <div className="why-container">
        <div className="row card why-card">
          <div
            className="col-sm-12 col-md-6 why-banner"
            style={{ backgroundImage: "url(/assets/img/home/porque-banner.jpg)" }}
            title={dict.t("Por que a VBIO?")}
          >
            <img
              className="why-logo"
              src="https://app.vbio.eco/assets/img/header/logo.svg"
              alt={dict.t("Por que a VBIO?")}
              title={dict.t("Por que a VBIO?")}
            />
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="why-title">{dict.t("Por que a VBIO?")}</div>

            <div className="why-text">
              <p>{dict.t("vbio-descricao-1")}</p>
              <br />
              <p>{dict.t("vbio-descricao-2")}</p>
            </div>

            <Link to="/sobre">
              <button className="button why-button">{dict.t("Saiba Mais")}</button>
            </Link>
          </div>

          <YoutubeModal videoId={videoId} width="80%">
            <img
              className="why-play-button"
              src="https://vbio.blob.core.windows.net/public/assets/img/home/play-button.png"
              alt={dict.t("Assista o vídeo")}
              title={dict.t("Assista o vídeo")}
            />
          </YoutubeModal>
        </div>
      </div>
    );
  }
}


