
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de busca de projetos
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import Input from "../../../lib/Forms/Input";
import Search from "../../../lib/Forms/Search";
import Dropdown from "../../../lib/Forms/Dropdown";

import { normalizerDate } from "../../../lib/Normalizer";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const dict = new multiLang(multiLang.getLanguage());



class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: {},
    };
  }

  render() {
    const dateLabelStyle = {
      float: "left",
      marginTop: "17px",
      marginRight: "8px",
      textAlign: "center",
    };

    const dateInputStyle = {
      float: "right",
      width: "calc(100% - 30px)",
    };

    const status = this.props.status.map(item => {
      item.label = dict.t(item.label);
      return item;
    });

    return (
      <div className="form-container">
        <form>
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <Field
                name="status"
                placeholder={dict.t("Status")}
                data={status}
                dataKey="name"
                dataLabel="label"
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Dropdown}
                onChange={this.handleStatusChange}
              />
            </div>

            <div className="col-sm-2">
              <div className="form-group form-input-label" style={dateLabelStyle}>
                {dict.t("de")}
              </div>

              <div style={dateInputStyle}>
                <Field
                  name="startDate"
                  normalize={normalizerDate}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                  onChange={this.handleStartDateChange}
                />
              </div>
            </div>

            <div className="col-sm-2">
              <div className="form-group  form-input-label" style={dateLabelStyle}>
                {dict.t("à")}
              </div>

              <div style={dateInputStyle}>
                <Field
                  name="endDate"
                  normalize={normalizerDate}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                  onChange={this.handleEndDateChange}
                />
              </div>
            </div>

            <div className="col-xs-12 col-sm-4">
              <Field
                name="search"
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Search}
                onChange={this.handleSearchChange}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  handleStatusChange = e => {
    let search = this.state.search;
    search.status = e.target.value;
    this.setState({ search });

    if (this.props.onSearch) this.props.onSearch(this.state.search);
  };

  handleStartDateChange = e => {
    let search = this.state.search;
    search.startDate = e.target.value;
    this.setState({ search });

    if (this.props.onSearch) this.props.onSearch(this.state.search);
  };

  handleEndDateChange = e => {
    let search = this.state.search;
    search.endDate = e.target.value;
    this.setState({ search });

    if (this.props.onSearch) this.props.onSearch(this.state.search);
  };

  handleSearchChange = e => {
    let search = this.state.search;
    search.search = e.target.value;
    this.setState({ search });

    if (this.props.onSearch) this.props.onSearch(this.state.search);
  };
}



export default reduxForm({
  form: "projectSearchBarForm",
})(SearchBar);


