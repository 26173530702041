
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de apoios - master
//


import React, { Component } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Dropdown from "../../../Commons/Dropdown";

import Authentication from "../../../../lib/Authentication";

import moment from "moment";
import Back from "../../../Commons/Back";

import List from "../../List/Zebra";
import ProjectSearchForm from "../../../Commons/ProjectSearchForm";

import ItemBody from "./ItemBody";

import reportTableConfig from "../../../../constants/reportTableConfig";
import generateReport from "../../../../lib/generateReport";

import supportStatus from "../../../../constants/supportStatus";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class PendingSupports extends Component {
  buttons = [
    {
      type: "edit",
      route: "/master/apoios",
    },
  ];

  sortByOptions = [
    {
      title: dict.t("Todos"),
      value: "all",
    },
    {
      title: dict.t("Nome"),
      value: `name`,
    },
    {
      title: dict.t("Data de cadastro"),
      value: "createdAt",
    },
    {
      title: dict.t("Data de edição"),
      value: "updatedAt",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      filteredSupports: [],
      supports: null,
    };
  }

  handleSortBy = ev => {
    let { filteredSupports } = this.state;

    switch (ev.target.value) {
      case "createdAt":
        filteredSupports.sort((opt1, opt2) =>
          moment(opt1.createdAt).diff(moment(opt2.createdAt), "minutes")
        );
        this.setState({ filteredSupports });
        break;

      case "updatedAt":
        filteredSupports.sort((opt1, opt2) =>
          moment(opt1.updatedAt).diff(moment(opt2.updatedAt), "minutes")
        );
        this.setState({ filteredSupports });
        break;

      case "name":
        filteredSupports.sort((opt1, opt2) =>
          (opt1.project[dict.lang].shortName || "").localeCompare(
            opt2.project[dict.lang].shortName || ""
          )
        );
        this.setState({ filteredSupports });
        break;
      default:
        break;
    }
  };

  componentWillMount() {
    this.setListFilter(this.props.router.params.filter);
    this.updateUserRecord();
  }

  componentWillReceiveProps(newProps) {
    this.setListFilter(newProps.router.params.filter);
    this.updateUserRecord();
  }

  updateUserRecord() {
    let date = new Date();
    date = date.toISOString();

    localStorage.setItem("userLastCheckSupports", date);

    const url = `/master/users/lastCheckSupports/${Authentication.getUser().personal.id}`;

    Request.post(url, { role: "master", lastCheckSupports: date }, () => {});
  }

  setListFilter(param) {
    this.setState({ supports: null });

    let fetchUrl = "/master/supports";

    if (param === "pendencias") fetchUrl += "/pending";
    else if (param === "todos") fetchUrl += "";
    else this.props.router.push("/");

    this.fetchSupports(fetchUrl);
  }

  render() {
    const { supports, filteredSupports } = this.state;

    if (!supports) return <Loader />;

    const projects = supports.map(support => {
      return {
        ...support.project,
        supportId: support._id,
      };
    });

    const listItems = filteredSupports.map(support => {
      const project = support.project;
      project[language] = languageTools.merge(support.project.PT, support.project[language]);

      return {
        _id: support._id,
        projectName: project[language].shortName,
        proponent: project.proponent ? project.proponent.trade : "",
        supporter: support.supporter.trade || support.supporter.company,
        createDate: support.createdAt,
      };
    });

    return (
      <div className="container footer-margin-logged">
        <div className="page-title" style={{ marginTop: "30px" }}>
          {dict.t("Apoios")}
        </div>

        <ProjectSearchForm
          buttonLabel={dict.t("Buscar Apoios")}
          projects={projects}
          status={supports.map(support => support.status)}
          statusType="support"
          onFilter={this.handleSupportFilter}
        />

        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <Dropdown
              label={dict.t("Ordenar por")}
              selectClass="searchOptionsSelect"
              options={this.sortByOptions}
              onChange={this.handleSortBy}
            />
          </div>
        </div>

        <div style={{ marginRight: "15px", marginLeft: "15px" }}>
          <List
            items={listItems}
            itemBodyComponent={ItemBody}
            buttons={this.buttons}
            style={{ height: "60px" }}
            bodyStyle={{ width: "calc(100% - 75px)" }}
          />
        </div>

        <div>
          <div className="master-list-button-container">
            <button className="button supports-list-report-button" onClick={this.handleReportClick}>
              {dict.t("Baixar relatório de apoios")}
            </button>
          </div>

          <div className="master-list-back-container">
            <Back router={this.props.router} />
          </div>
        </div>
      </div>
    );
  }

  fetchSupports(url) {
    Request.get(url, values => {
      values = values.filter(support => support.project);
      this.setState({ supports: values, filteredSupports: values });
    });
  }

  handleSupportFilter = filteredProjects => {
    const { supports } = this.state;

    const filteredSupports = filteredProjects.map(project =>
      supports.find(support => support._id === project.supportId)
    );

    this.setState({ filteredSupports });
  };

  handleReportClick = () => {
    let reportData = JSON.parse(JSON.stringify(this.state.supports));

    reportData = reportData.map(support => {
      support.project = support.project[language].shortName;
      support.supporter = support.supporter.trade;
      support.status = supportStatus.find(item => item.name === support.status).label;
      return support;
    });

    generateReport(reportData, reportTableConfig["support"], "relatório-apoios.xlsx");
  };
}


