export default {
  support: [
    { key: "project", label: "Projeto" },
    { key: "supporter", label: "Apoiador" },
    { key: "createdAt", label: "Data" },
    { key: "value", label: "Valor" },
    { key: "token", label: "Código" },
    { key: "status", label: "Status" },
  ],

  project: [
    { key: "name", label: "Nome" },
    { key: "proponent", label: "Proponente" },
    { key: "createdAt", label: "Data de criação" },
    { key: "totalValue", label: "Valor" },
    { key: "supportedValue", label: "Valor Apoiado" },
    { key: "city", label: "Cidade" },
    { key: "state", label: "Estado" },
    { key: "status", label: "Status" },
  ],

  proponent: [
    { key: "company", label: "Razão Social" },
    { key: "trade", label: "Nome Fantasia" },
    { key: "cnpj", label: "CNPJ" },
    { key: "sector", label: "Setor" },
    { key: "phone", label: "Telefone" },
    { key: "city", label: "Cidade" },
    { key: "state", label: "Estado" },
    { key: "status", label: "Status" },
  ],

  evolutionData: [
    { key: "date", label: "Data" },
    { key: "value", label: "Valor" },
    { key: "numOfShares", label: "Cotas" },
  ],

  distributionData: [
    { key: "date", label: "Data" },
    { key: "raising", label: "Projetos em captação" },
    { key: "pending", label: "Projetos em análise" },
    { key: "approved", label: "Projetos aprovados" },
    { key: "total", label: "Total" },
  ],

  biomeData: [{ key: "biome", label: "Bioma" }, { key: "projects", label: "Projetos" }],

  statusData: [{ key: "status", label: "Status" }, { key: "value", label: "Quantidade" }],

  rankingData: [{ key: "name", label: "Empresa" }, { key: "projects", label: "Projetos" }],
};
