import React, { Component } from "react";
import moment from "moment";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const dict = new multiLang(multiLang.getLanguage());



export default class DateRange extends Component {
  state = {};

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  handleIniDateChange = ev => {
    this.setState({ iniDate: ev.target.value !== "" ? moment(ev.target.value) : "" });
  };

  handleEndDateBlur = ev => {
    this.setState({ endDate: ev.target.value !== "" ? moment(ev.target.value) : "" });
  };

  render() {
    const { inputClass } = this.props.css;

    return (
      <div className="date-range-container">
        <div
          className="date-range-item date-range-label form-input-label"
          style={{ marginLeft: 0 }}
        >
          {dict.t("De")}
        </div>

        <div className="date-range-item date-range-input">
          <input
            type="date"
            onChange={this.handleIniDateChange}
            className={`form-input ${inputClass}`}
            style={{ padding: "2px", backgroundColor: "#F7F8F9" }}
          />
        </div>

        <div className="date-range-item date-range-label form-input-label">{dict.t("à")}</div>

        <div className="date-range-item date-range-input">
          <input
            type="date"
            onChange={this.handleEndDateBlur}
            className={`form-input ${inputClass}`}
            style={{ padding: "2px", backgroundColor: "#F7F8F9" }}
          />
        </div>
      </div>
    );
  }
}
