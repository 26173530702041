import axios from "axios";
import urlencode from "urlencode";

import config from "../config";

function getFileNameFromUrl(url, removeS3Hash) {
  let filename = "";

  if (typeof url !== "string") {
    if (url) filename = url.name;
  } else {
    filename = url.substring(url.lastIndexOf("/") + 1);
  }

  if (removeS3Hash) filename = filename.substring(72);

  return urlencode.decode(filename);
}

export default function(s3file, filename) {
  if (!filename) filename = getFileNameFromUrl(s3file, true);

  axios({
    url: config.backend_url + "/file/download/" + filename,
    method: "POST",
    responseType: "blob", // important
    data: { file: s3file },
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });
}
