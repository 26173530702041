
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Haas
//
// Descrição: Sobre a VBIO
//


import React, { Component, Fragment } from "react";

import Loader from "../Loader";

import BackButton from "../Commons/Back";

import Authentication from "../../lib/Authentication";
import Request from "../../lib/BackendRequest";
import Accordion from "../Commons/Accordion";
import "./style.css";

import languageTools from "../../lib/languageTools";

import { ProposeBody as Propose } from "../HowTo/Propose";
import { SupportBody as Support } from "../HowTo/Support";

import multiLang from "../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = { areas: null };
  }

  componentWillMount() {
    this.fetchAreasOfInterest();
  }

  fetchAreasOfInterest() {
    const url = "/home/areas-of-interest";

    Request.get(url, values => {
      this.setState({ areas: values });
    });
  }

  render() {
    if (!this.state.areas) return <Loader />;

    return (
      <div className="about-container">
        <div className="container back-button-generic">
          <BackButton router={this.props.router} />
        </div>

        <WhyVbio areasOfInterest={this.state.areas} />

        <Differentials />

        {this.howTo}
      </div>
    );
  }

  get howTo() {
    if (!Authentication.isAuthenticated()) return <div className="footer-margin" />;

    const personalData = Authentication.getUser().personal;

    if (personalData.role === "proponentUser")
      return (
        <Fragment>
          <Propose actionRoute="/proponente/projetos/novo" />
          <div className="footer-margin-logged" />
        </Fragment>
      );
    else if (personalData.role === "supporterUser")
      return (
        <Fragment>
          <Support actionRoute="/apoiador/projetos" />
          <div className="footer-margin-logged" />
        </Fragment>
      );
    else return <div className="footer-margin-logged" />;
  }
}

class WhyVbio extends Component {
  render() {
    return (
      <div className="container howto-container">
        <div className="row">
          <div
            className="col-sm-12 howto-title green-title"
            style={{ fontWeight: "bold", marginBottom: "0" }}
          >
            {dict.t("Por que a VBIO?")}
          </div>

          <div className="col-sm-10 about-text col-sm-offset-1 about-item">
            <div className="">
              <p>{dict.t("about-1-1")}</p>
              <p>{dict.t("about-1-2")}</p>
            </div>
          </div>

          <div className="col-sm-12 about-item">
            <div className="howto-title green-title" style={{ fontWeight: "bold" }}>
              <strong>{dict.t("about-2")}</strong>
            </div>
          </div>

          <div className="col-sm-10 col-sm-offset-1 about-item">
            <div className="about-text">
              <strong style={{ fontSize: "15px" }}>{dict.t("about-3")}</strong>
            </div>
          </div>

          <div className="col-sm-10 col-sm-offset-1">
            <div className="">{this.renderAreasOfInterest(this.props.areasOfInterest)}</div>
          </div>

          <div className="col-sm-10 col-sm-offset-1 about-item">
            <div className="about-text">{dict.t("about-4")}</div>
          </div>

          <div className="col-sm-10 col-sm-offset-1 about-item">
            <div className="about-text">
              <strong style={{ fontSize: "15px" }}>{dict.t("about-5")}</strong>
            </div>
          </div>

          <div className="col-sm-10 col-sm-offset-1">
            <div className="">
              <div className="about-icon about-ods-icon">
                <img
                  src="https://vbio.blob.core.windows.net/public/assets/img/about/ods.png"
                  alt={dict.t("Objetivos de Desenvolvimento Sustentável")}
                  title={dict.t("Objetivos de Desenvolvimento Sustentável")}
                  style={{ width: "200px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-10 col-sm-offset-1 about-item">
            <div className="about-text">{dict.t("about-6")}</div>
          </div>

          <div className="col-sm-10 col-sm-offset-1 about-item">
            <div className="about-text">
              <strong style={{ fontSize: "15px" }}>{dict.t("about-7")}</strong>
            </div>
          </div>

          <div className="col-sm-10 col-sm-offset-1">
            <div className="">
              <div className="about-icon about-aoi-icon">
                <img
                  src="https://vbio.blob.core.windows.net/public/assets/img/about/benefits.png"
                  alt={dict.t("Repartição de Benefícios")}
                  title={dict.t("Repartição de Benefícios")}
                  style={{ width: "60px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-10 col-sm-offset-1 about-item">
            <div className="about-text">{dict.t("about-8")}</div>
          </div>
        </div>
      </div>
    );
  }

  renderAreasOfInterest(items) {
    if (!items || !items.length) return null;

    return items.map((item, index) => {
      item[language] = languageTools.merge(item.PT, item[language]);

      return (
        <div className="about-icon about-aoi-icon" key={index}>
          <img src={item.image} alt={item[language].name} title={item[language].name} />
        </div>
      );
    });
  }
}

class Differentials extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "#F7F8F9", marginBottom: "-5px" }}>
        <div className="container differential-container">
          <div className="howto-title green-title about-subtitle">{dict.t("Diferenciais")}</div>

          <div className="row" style={{ marginTop: "15px" }}>
            <div className="col-sm-offset-1 col-sm-10">{this.items}</div>
          </div>
        </div>
      </div>
    );
  }

  get items() {
    const list = [
      {
        title: "Plataforma",
        body: [
          {
            title: "Experiência de usuário",
            body: "about-diff-1-1",
          },

          {
            title: "Curadoria",
            body: "about-diff-1-2",
          },

          {
            title: "Segurança",
            body: "about-diff-1-3",
          },
        ],
      },

      {
        title: "Monitoramento dos Projetos",
        body: "about-diff-2",
      },

      {
        title: "Compliance",
        body: "about-diff-3",
      },
    ];

    return list.map((e, k) => {
      let Body;

      function getBodyItems(items) {
        if (!items || !items.length) return null;

        return items.map((item, index) => {
          return (
            <li key={index} style={{ marginBottom: "10px" }}>
              <strong>{`${dict.t(item.title)}: `}</strong>
              {dict.t(item.body)}
            </li>
          );
        });
      }

      if (typeof e.body === "string")
        Body = <div className="about-accordion-body">{dict.t(e.body)}</div>;
      else Body = <ul className="about-accordion-body">{getBodyItems(e.body)}</ul>;

      return (
        <div key={k} className="differential-box-items">
          <Accordion title={dict.t(e.title)} contentComponent={Body} />
        </div>
      );
    });
  }
}


