
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista "zebrada"
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";

import ListHeader from "./Header";
import ButtonAttach from "../../Commons/ButtonAttach";

import multiLang from "../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class List extends Component {
  static defaultProps = {
    hideAddButton: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      expand: [],
    };

    this.allowSort = false;
  }

  componentWillMount() {
    const items = this.props.items;
    const expand = new Array(items.length).fill(false);

    this.setState({ items, expand });
  }

  componentWillReceiveProps(nextProps) {
    const items = nextProps.items;
    const expand = new Array(items.length).fill(false);

    this.setState({ items, expand });
  }

  render() {
    const SortableList = SortableContainer(props => {
      return <div className="row list-items-container">{this.getListItems(props.items)}</div>;
    });

    return (
      <Fragment>
        {this.props.title ? (
          <ListHeader
            title={this.props.title}
            addRoute={this.props.addRoute}
            editRoute={this.props.editRoute}
          />
        ) : (
          ""
        )}

        <SortableList
          items={this.state.items}
          shouldCancelStart={this.handleWillSort}
          onSortEnd={this.handleSortEnd}
        />
      </Fragment>
    );
  }

  getListItems(items) {
    if (!items) return "";

    return items.map((item, index) => this.composeItem(item, index));
  }

  composeItem(item, index) {
    const ItemHeader = this.props.itemHeaderComponent;
    const ItemBody = this.props.itemBodyComponent;
    const ItemExpanded = this.props.itemExpandedComponent;
    const ExtraRow = this.props.extraRowComponent;

    const SortableItem = SortableElement(props => {
      const itemClass = index % 2 === 0 ? "list-zebra-color-even" : "list-zebra-color-odd";

      const expand = this.state.expand[index];

      const headerStyle = this.props.headerKey ? { width: "77%" } : {};

      return (
        <div key={index} className={`${itemClass}`}>
          <div className="list-zebra-item-container" style={this.props.style}>
            {this.props.buttonsAlternatePos ? (
              <div className="list-zebra-item-buttons-container1" style={this.props.buttonsStyle}>
                <div className="list-zebra-item-buttons-container2">
                  {this.composeButtons(this.props.buttons, item)}
                </div>
              </div>
            ) : null}

            {ItemHeader ? (
              <ItemHeader item={item} />
            ) : (
              <div className="list-zebra-item-header-text" style={headerStyle}>
                {this.props.headerKeyInArray
                  ? item[this.props.headerKey][this.props.headerKeyInArray]
                  : item[this.props.headerKey]}
              </div>
            )}

            <div
              className="list-zebra-item-body-container1"
              style={this.props.bodyStyle}
            >
              <div className="list-zebra-item-body-container2">
                {ItemBody ? (
                  <ItemBody {...this.props.itemBodyProps} item={item} router={this.props.router} />
                ) : (
                  item[this.props.bodyKey]
                )}
              </div>
            </div>

            {!this.props.buttonsAlternatePos ? (
              <div className="list-zebra-item-buttons-container1" style={this.props.buttonsStyle}>
                <div className="list-zebra-item-buttons-container2">
                  {this.composeButtons(this.props.buttons, item)}
                </div>
              </div>
            ) : null}

            {ExtraRow ? (
              <div style={{ width: "100%", marginTop: "20px" }}>
                <ExtraRow {...this.props.extraRowProps} item={item} router={this.props.router} />
              </div>
            ) : null}
          </div>

          {expand && ItemExpanded ? <ItemExpanded item={item} /> : null}
        </div>
      );
    });

    return <SortableItem item={item} index={index} key={index} />;
  }

  composeButtons(buttons, item) {
    if (!buttons) return "";

    return buttons.map((button, index) => {
      let icon;
      let label;
      let alt = "";
      let route = button.route;
      let onMouseEnter;
      let onMouseLeave;
      let style = index === 0 ? { marginLeft: 0 } : { marginLeft: "20px" };

      switch (button.type) {
        case "move":
          icon = "https://vbio.blob.core.windows.net/public/assets/img/move-icon.png";
          alt = dict.t("Mover");
          onMouseEnter = this.handleMoveMouseEnter;
          onMouseLeave = this.handleMoveMouseLeave;
          style.cursor = "move";
          break;

        case "edit":
          icon = "https://vbio.blob.core.windows.net/public/assets/img/edit-icon.png";
          alt = dict.t("Editar");
          route = !button.removeIdFromRoute ? route + "/" + item._id : route;
          break;

        case "attach":
          route = route ? route + "/" + item._id : undefined;
          return this.renderAttachButton(
            item,
            index,
            route,
            button.onFileUpload,
            button.onRecordUpdate,
            button.handleUploadStart
          );

        case "delete":
          icon = "https://vbio.blob.core.windows.net/public/assets/img/delete-icon.png";
          alt = dict.t("Deletar");
          break;

        case "text":
          label = button.getLabel(item);
          break;

        case "textLink":
          label = button.getLabel(item);
          break;

        case "expand":
          style.height = "20px";
          return this.renderExpandButton(item, index, style);

        default:
          break;
      }

      // renderiza botão genérico
      return (
        <Link key={index} to={route}>
          {icon ? (
            <img
              className="list-zebra-item-button"
              src={icon}
              alt={alt}
              title={alt}
              style={style}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={() => this.handleButtonClick(button, item)}
            />
          ) : (
            ""
          )}
          {label ? (
            <div
              className="list-zebra-item-text-bold"
              style={style}
              onClick={() => this.handleButtonClick(button, item)}
            >
              {label}
            </div>
          ) : (
            ""
          )}
        </Link>
      );
    });
  }

  renderAttachButton(item, index, route, uploadHandler, updateHandler, handleUploadStart) {
    return (
      <ButtonAttach
        key={index}
        url={route}
        label={item.name}
        onFileUpload={url => {
          if (uploadHandler) uploadHandler(item, url);
        }}
        onRecordUpdate={updateHandler}
        onUploadStart={handleUploadStart}
      />
    );
  }

  renderExpandButton(item, index, style) {
    const itemIndex = this.state.items.indexOf(item);
    const icon = this.state.expand[itemIndex]
      ? "https://vbio.blob.core.windows.net/public/assets/img/accordion/button-up.png"
      : "https://vbio.blob.core.windows.net/public/assets/img/accordion/button-down.png";

    return (
      <img
        key={index}
        className="list-zebra-item-button"
        src={icon}
        alt={dict.t("Expandir")}
        style={style}
        onClick={() => this.handleExpandClick(itemIndex)}
      />
    );
  }

  handleButtonClick = (button, item) => {
    if (button.onClick) button.onClick(item);
  };

  handleMoveMouseEnter = () => {
    this.allowSort = true;
  };

  handleMoveMouseLeave = () => {
    this.allowSort = false;
  };

  handleWillSort = () => {
    return !this.allowSort;
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    let items = this.state.items;

    items = arrayMove(items, oldIndex, newIndex);
    items.forEach((item, index) => (item.order = index));

    this.setState({ items });

    if (this.props.onSort) this.props.onSort(items);
  };

  handleExpandClick = index => {
    const expand = this.state.expand;
    expand[index] = !expand[index];

    this.setState({ expand });
  };
}


