
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Sub Menu
//


import React, { Component } from "react";
import { Link } from "react-router";

import "./style.css";



export default class SubMenu extends Component {
  render() {
    if (!this.props.show) return "";

    let subMenuStyle = { top: this.props.config.pos.y };
    let detailStyle;

    if (this.props.config.side === "left") {
      subMenuStyle.right = `calc(${this.props.config.parentWidth}px - ${this.props.config.pos.x}px - 15px)`;
      detailStyle = { right: "4px" };
    } else {
      subMenuStyle.left = `calc(${this.props.config.pos.x}px - 15px)`;
      detailStyle = { left: "4px" };
    }

    return (
      <div className="menu-submenu-content" style={subMenuStyle}>
        <div className="menu-submenu-detail" style={detailStyle}></div>
        {this.composeItems(this.props.items)}
      </div>
    );
  }

  composeItems(items) {
    return items.map((item, index) => {
      return (
        <Link key={index} to={item.route} onClick={this.handleItemClick}>
          <div className="menu-submenu-item" label={item.label}>
            {item.label}
          </div>
        </Link>
      );
    });
  }

  handleItemClick = () => {
    if (this.props.onItemClick) {
      this.props.onItemClick();
    }
  };
}


