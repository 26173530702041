
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Corpo do item de lista do tipo banner
//


import React from "react";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default function(props) {
  props.item[language] = languageTools.merge(props.item.PT, props.item[language]);

  const style = props.smallScreen ? { marginLeft: "0" } : {};

  return (
    <div className="banner-list-body-text" style={style}>
      <span style={{ fontWeight: "bold" }}>{dict.t("Título")}: </span>
      {props.item[language].title}
      <br />
      <span style={{ fontWeight: "bold" }}>{dict.t("Texto")}: </span>
      {props.item[language].text}
      <br />
      <span style={{ fontWeight: "bold" }}>{dict.t("Botão")}: </span>
      {props.item[language].buttonLabel}
      <br />
      <span style={{ fontWeight: "bold" }}>{dict.t("Link do botão")}: </span>
      {props.item.buttonRoute}
    </div>
  );
}


