
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Página de login
//


import React, { Component } from "react";
import { Link } from "react-router";

import Form from "./Form";

import multiLang from "../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class Login extends Component {
  render() {
    return (
      <div className="container login-container">
        <div className="row">
          <Form router={this.props.router} />
        </div>

        <div className="row">
          <div className="col-sm-6">
            <Link to="/apoiador/novo" style={{ textDecoration: "none" }}>
              <button className="button register-button supporter-background" style={{}}>
                {dict.t("Cadastro apoiador")}
              </button>
            </Link>
          </div>
          <div className="col-sm-6">
            <Link to="/proponente/novo" style={{ textDecoration: "none" }}>
              <button className="button register-button proponent-background" style={{}}>
                {dict.t("Cadastro proponente")}
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}


