
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Regis Ebeling
//
// Descrição: Corpo do item de lista do tipo categoria
//


import React from "react";
import moment from "moment";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default function(props) {
  props.item[language] = languageTools.merge(props.item.PT, props.item[language]);

  const { trade, company } = props.item;

  const name = trade.trim() === "" ? company : trade;

  return (
    <div className="row list-zebra-item-header-text" style={{ width: "71vw" }}>
      <div className="col-md-4">{name.trim()}</div>
      <div className="col-md-4">
        {`${dict.t("Cadastro")}: ${moment(props.item.createdAt).format("DD/MM/YYYY")}`}
      </div>
      <div className="col-md-4">
        {`${dict.t("Edição")}: ${moment(props.item.updateAt).format("DD/MM/YYYY")}`}
      </div>
    </div>
  );
}


