
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Blog - lista de artigo
//


import React, { Component } from "react";
import { Link } from "react-router";

import moment from "moment";

import Loader from "../Loader";

import BackButton from "../Commons/Back";

import Authentication from "../../lib/Authentication";
import Request from "../../lib/BackendRequest";

import SearchBar from "./SearchBar";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const numOfPosts = 6;



export default class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: null,
      tags: null,
      fetching: false,
    };

    this.page = 1;
    this.selectedTags = [];
  }

  componentWillMount() {
    this.selectTag(this.props.router.params.tag);

    this.fetchPosts();
    this.fetchTags();
  }

  componentWillReceiveProps(newProps) {
    this.selectTag(newProps.router.params.tag);
  }

  selectTag(tag) {
    if (tag) this.selectedTags = [tag];
  }

  render() {
    if (!this.state.posts || !this.state.tags) return <Loader />;

    const footerMargin = Authentication.isAuthenticated()
      ? "footer-margin-logged"
      : "footer-margin";

    const buttonLabel = this.state.fetching ? dict.t("Aguarde") : dict.t("Mais artigos");

    return (
      <section className={`container ${footerMargin}`}>
        <div className="back-button-generic">
          <BackButton router={this.props.router} />
        </div>

        <header className="page-title" style={{ marginTop: "20px", marginBottom: "20px" }}>
          Blog
        </header>

        <section className="row">
          <section className="col-md-8">
            {this.renderPosts(this.state.posts)}

            <div className="blog-list-button-container">
              <button className="button blog-list-button" onClick={this.handleButtonClick}>
                {buttonLabel}
              </button>
            </div>
          </section>

          <aside className="col-md-4" style={{ marginBottom: "40px" }}>
            <SearchBar style={{ marginTop: "-7px" }} onSearch={this.handleSearch} />

            {this.renderTags(this.state.tags)}
          </aside>
        </section>
      </section>
    );
  }

  fetchPosts() {
    const query = Request.objToQueryParams(this.selectedTags);
    const url = `/blog/posts/limited/${numOfPosts * this.page}/${this.search || "null"}/?${query}`;

    Request.get(url, res => {
      const posts = res;
      this.setState({ posts, fetching: false });
    });
  }

  fetchTags() {
    const url = "/blog/tags";

    Request.get(url, res => {
      this.setState({ tags: res });
    });
  }

  renderPosts(posts) {
    if (!posts) return null;

    return posts.map((post, index) => {
      const image = post.image ? post.image : "https://vbio.blob.core.windows.net/public/assets/img/image-placeholder.png";
      const imageStyle = { backgroundImage: `url('${image}')` };

      const url = post.link ? `/blog/post/${post.link}` : `/blog/post/${post._id}`;

      return (
        <Link key={index} to={url}>
          <article
            className="blog-list-article-card"
            style={imageStyle}
            title={post[language].title}
          >
            <div className="blog-list-article-title-container">
              <div className="blog-list-title">{post[language].title}</div>

              <div className="blog-list-description">{post[language].summary}</div>

              <div className="blog-list-date">{moment(post.createdAt).format("DD/MM/YYYY")}</div>
            </div>
          </article>
        </Link>
      );
    });
  }

  renderTags(tags) {
    let textTags = tags[language]
      .map(tag => tag.description)
      .map(tag => (tag ? tag.trim() : ""))
      .filter(tag => tag.length);

    return (
      <section className="blog-list-tags-container">
        <div className="blog-list-tags-title">Tags</div>

        <div style={{ width: "calc(100% + 30px)" }}>{this.renderTagCards(textTags)}</div>
      </section>
    );
  }

  renderTagCards(tags) {
    if (!tags) return null;

    return tags.map((tag, index) => {
      const selected = this.selectedTags.indexOf(tag) >= 0;

      let css = "blog-list-tag-card";
      if (selected) css += " blog-list-tag-card-selected";

      return (
        <div key={index} className={css} onClick={() => this.handleTagClick(tag)}>
          {tag}
        </div>
      );
    });
  }

  handleSearch = value => {
    let { search } = value;

    search = search.toLowerCase().trim();

    if (search.length >= 3) {
      this.search = search;
      this.setState({ fetching: true }, () => this.fetchPosts());
    } else if (search.length === 0) {
      this.search = null;
      this.setState({ fetching: true }, () => this.fetchPosts());
    }
  };

  handleButtonClick = () => {
    this.page++;
    this.setState({ fetching: true }, () => this.fetchPosts());
  };

  handleTagClick = tag => {
    const index = this.selectedTags.indexOf(tag);

    if (index < 0) this.selectedTags.push(tag);
    else this.selectedTags.splice(index, 1);

    this.setState({ fetching: true }, () => this.fetchPosts());
  };
}


