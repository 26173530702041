
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Items do menu
//


import multiLang from "../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



const home = {
  label: "Home",
  type: "text",
  route: "/",
};

const blog = {
  label: "Blog",
  type: "text",
  route: "/blog",
};

const sobre = {
  label: dict.t("Sobre"),
  type: "text",
  route: "/sobre",
};



export default {
  open: [
    home,
    blog,
    sobre,

    {
      label: dict.t("Como Apoiar"),
      type: "text",
      color: "#2E91D5",
      colorHover: "#51A9E5",
      route: "/como-apoiar",
    },

    {
      label: dict.t("Como Propor"),
      type: "text",
      color: "#EFA500",
      colorHover: "#FFBA1F",
      route: "/como-propor",
    },

    {
      label: "Login",
      type: "button",
      route: "/login",
    },
  ],

  master: [
    {
      label: "Dashboards",
      type: "text",
      route: "/master/dashboard",
    },

    {
      name: "projects",
      label: dict.t("Projetos"),
      type: "text",
      refRoute: "/master/projetos",
      subItems: [
        { label: dict.t("Pendências"), route: "/master/projetos/lista/pendencias" },
        { label: dict.t("Todos"), route: "/master/projetos/lista/todos" },
        { label: dict.t("Vitrine"), route: "/master/projetos" },
      ],
    },

    {
      name: "supports",
      label: dict.t("Apoios"),
      type: "text",
      refRoute: "/master/apoios",
      subItems: [
        { label: dict.t("Pendências"), route: "/master/apoios/lista/pendencias" },
        { label: dict.t("Todos"), route: "/master/apoios/lista/todos" },
      ],
    },

    {
      label: dict.t("Apoiadores"),
      type: "text",
      route: "/master/apoiadores/lista",
    },

    {
      name: "proponents",
      label: dict.t("Proponentes"),
      type: "text",
      refRoute: "/master/proponentes",
      subItems: [
        { label: dict.t("Pendências"), route: "/master/proponentes/lista/pendencias" },
        { label: dict.t("Todos"), route: "/master/proponentes/lista/todos" },
      ],
    },

    {
      label: "Blog",
      type: "text",
      subMenuSide: "left",
      refRoute: "/master/blog",
      subItems: [
        { label: dict.t("Artigos"), route: "/master/blog/artigos" },
        { label: dict.t("Categorias"), route: "/master/blog/categorias" },
        { label: dict.t("Autores"), route: "/master/blog/autores" },
      ],
    },

    {
      label: dict.t("Configurações"),
      type: "image",
      image: "https://vbio.blob.core.windows.net/public/assets/img/header/gear.png",
      imageSelected: "https://vbio.blob.core.windows.net/public/assets/img/header/gear-selected.png",
      refRoute: "/master/configuracao",
      subMenuSide: "left",
    },
  ],

  supporterUser: [
    home,
    blog,
    sobre,

    {
      label: "Dashboard",
      type: "text",
      route: "/apoiador/dashboard",
    },

    {
      label: dict.t("Projetos Apoiados"), // Meus projetos
      type: "text",
      route: "/apoiador/meus-projetos",
    },

    {
      label: dict.t("Apoiar"),
      type: "button",
      btnColor: "blue",
      route: "/apoiador/projetos",
    },

    {
      label: "Perfil",
      type: "image",
      image: "https://vbio.blob.core.windows.net/public/assets/img/header/profile.png",
      imageSelected: "https://vbio.blob.core.windows.net/public/assets/img/header/profile-selected.png",
      refRoute: "/apoiador/perfil",
      subMenuSide: "left",
      subItems: [
        { label: dict.t("Usuário"), route: "/apoiador/perfil" },
        { label: dict.t("Alterar senha"), route: "/meu/altera-senha" },
        { label: dict.t("Sair"), route: "/logout" },
      ],
    },
  ],

  proponentUserPending: [
    home,
    blog,
    sobre,

    {
      label: dict.t("Habilitação"),
      type: "text",
      route: "/proponente/habilitacao",
    },

    {
      label: "Perfil",
      type: "image",
      image: "https://vbio.blob.core.windows.net/public/assets/img/header/profile.png",
      imageSelected: "https://vbio.blob.core.windows.net/public/assets/img/header/profile-selected.png",
      refRoute: "/proponente/perfil",
      subMenuSide: "left",
      subItems: [
        { label: dict.t("Usuário"), route: "/proponente/perfil" },
        { label: dict.t("Alterar senha"), route: "/meu/altera-senha" },
        { label: dict.t("Sair"), route: "/logout" },
      ],
    },
  ],

  proponentUser: [
    home,
    blog,
    sobre,

    {
      label: dict.t("Habilitação"),
      type: "text",
      route: "/proponente/habilitacao",
    },

    {
      label: "Dashboard",
      type: "text",
      route: "/proponente/dashboard",
    },

    {
      label: dict.t("Meus Projetos"),
      type: "text",
      route: "/proponente/meus-projetos",
    },

    {
      label: dict.t("Propor"),
      type: "button",
      btnColor: "yellow",
      route: "/proponente/projetos/novo",
    },

    {
      label: "Perfil",
      type: "image",
      image: "https://vbio.blob.core.windows.net/public/assets/img/header/profile.png",
      imageSelected: "https://vbio.blob.core.windows.net/public/assets/img/header/profile-selected.png",
      refRoute: "/proponente/perfil",
      subMenuSide: "left",
      subItems: [
        { label: dict.t("Usuário"), route: "/proponente/perfil" },
        { label: dict.t("Alterar senha"), route: "/meu/altera-senha" },
        { label: dict.t("Sair"), route: "/logout" },
      ],
    },
  ],
};


