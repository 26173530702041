
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Blog
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import moment from "moment";

import Carousel from "../Commons/Carousel";
import ImageFilter from "../ImageFilter";

import ScreenSize from "../Commons/ScreenSize";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



const blogImageFilter = "#666666";

const smallScreenWidth = 1200;



export default class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowSize: {},
      smallScreen: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowSize: { width: window.innerWidth, height: window.innerHeight } });
  };

  render() {
    const arrowMargin =
      this.state.windowSize.width >= 1600 ? "-10%" : this.state.smallScreen ? "-7%" : "-4%";

    const slidesToShow = this.state.smallScreen || this.props.items.length === 1 ? 1 : 2;

    return (
      <div className="blog-container">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="card blog-card">
          <div className="container">
            <div className="blog-title">Blog</div>

            <div className="blog-carousel-container">
              <Carousel
                prevArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/previous-arrow-green.png"
                nextArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/next-arrow-green.png"
                arrowMargin={arrowMargin}
                arrowTop="43%"
                slidesToShow={slidesToShow}
                items={this.composeCarouselItems(this.props.items)}
              />
            </div>

            <div className="blog-button-container">
              <Link to="/blog">
                <button className="button blog-button">{dict.t("Mais Artigos")}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  composeCarouselItems(items) {
    if (!items) return "";

    return items.map((item, index) => {
      return (
        <Fragment key={index}>
          <div className="blog-carousel-content-container">
            <Fragment>
              {this.composeArticle(item)}
            </Fragment>
          </div>
        </Fragment>
      );
    });
  }

  composeArticle(item, style) {
    item[language] = Object.assign(item[language], item.PT);

    const url = `/blog/post/${item.link}`;

    return (
      <Link to={url}>
        <div className="blog-article" style={style}>
          <img
            className="blog-article-image"
            src={item.image}
            alt={item[language].title}
            title={item[language].title}
          />
          <ImageFilter
            className="blog-article-image"
            filter={blogImageFilter}
            title={item[language].title}
          />

          <div className="blog-carousel-content-title">{item[language].title}</div>

          <div className="blog-carousel-content-text">{item[language].summary}</div>

          <div className="blog-carousel-content-date">
            <span className="blog-carousel-content-date">
              {moment(item.createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
      </Link>
    );
  }

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}


