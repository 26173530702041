
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de documentos - proponente
//


import React, { Component, Fragment } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";

import WaitUploadModal from "../../../Commons/WaitUploadModal";
import BackButton from "../../../Commons/Back";

import Authentication from "../../../../lib/Authentication";
import Alert from "../../../../lib/Alert";

import List from "../../List/Zebra";

import downloadFile from "../../../../lib/downloadFile";

import ItemBody from "./ItemBody";
import ItemDocumentStatus from "./ItemBodyDocumentStatus";
import ItemExpanded from "./ItemExpanded";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class Documents extends Component {
  handleFileUpload = (item, url) => {
    this.updateDocumentState(item._id, url);
  };

  buttons = [
    {
      type: "attach",
      handleUploadStart: () => this.setState({ uploading: true }),
      onFileUpload: this.handleFileUpload,
    },

    {
      type: "expand",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      proponent: null,
      uploading: false,
    };
  }

  componentWillMount() {
    this.fetchProponent();
    this.fetchManual();
  }

  render() {
    let { proponent, proponentManual } = this.state;

    if (!proponent || !proponentManual) return <Loader />;

    proponentManual[language] = languageTools.merge(proponentManual.PT, proponentManual[language]);

    return (
      <Fragment>
        <div className="container footer-margin-logged">
          <div className="back-button-generic" style={{ marginLeft: "-15px" }}>
            <BackButton router={this.props.router} />
          </div>

          <div className="row page-title" style={{ marginTop: "20px", marginBottom: "15px" }}>
            {dict.t("Habilitação")}
          </div>

          {proponentManual[language] ? (
            <div className="row new-project-manual-container" style={{ marginBottom: "15px" }}>
              <a
                onClick={() => downloadFile(this.state.proponentManual[language].url)}
                style={{ cursor: "pointer" }}
              >
                <span className="new-project-text-link">
                  {dict.t("Manual do Proponente")}
                  &nbsp;&nbsp;
                  <img
                    src="https://vbio.blob.core.windows.net/public/assets/img/download-icon.png"
                    alt={dict.t("Manual do proponente")}
                    title={dict.t("Manual do proponente")}
                  />
                </span>
              </a>
            </div>
          ) : null}

          <List
            items={proponent.documents}
            itemHeaderComponent={ItemDocumentStatus}
            itemBodyComponent={ItemBody}
            itemExpandedComponent={ItemExpanded}
            buttons={this.buttons}
            style={{ height: "80px" }}
          />

          <div style={{ marginBottom: "100px" }} />
        </div>

        <WaitUploadModal
          open={this.state.uploading}
          message={dict.t("Enviando arquivo, aguarde...")}
        />
      </Fragment>
    );
  }

  sortDocuments(documents) {
    if (!documents) return [];

    documents = documents.filter(doc => doc);

    return documents.sort((a, b) => {
      a = a.name.split(".")[0];
      b = b.name.split(".")[0];

      return a - b;
    });
  }

  fetchProponent() {
    const url = `/proponent/${Authentication.getUser().personal.companyId}`;

    Request.get(url, values => {
      values.documents = this.sortDocuments(values.documents);

      this.setState({ proponent: values });
    });
  }

  fetchManual() {
    const url = "/proponent/documents/manual";

    Request.get(url, values => {
      this.setState({ proponentManual: values[0] });
    });
  }

  updateDocumentState(docId, docUrl) {
    const { companyId } = Authentication.getUser().personal;

    this.setState({ proponent: null, proponentManual: null });

    Request.put(`/proponent/${companyId}/update-document/${docId}`, { url: docUrl }, res => {
      if (res.success) {
        Alert.success(dict.t("Arquivo enviado com sucesso"));

        this.setState({ uploading: false }, () => {
          this.fetchProponent();
          this.fetchManual();
        });
      }
    });
  }
}


