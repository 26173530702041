
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Ícone de informações
//


import React, { Component } from "react";
import ReactDOM from "react-dom";
import multiLang from "../../lib/multilang/multiLang";
import "./style.css";



const dict = new multiLang(multiLang.getLanguage());

const tooltipOffset = 10;



export default class InfoIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      position: {},
    };
  }

  componentDidUpdate() {
    const element = ReactDOM.findDOMNode(this.refs["infoicon-tooltip"]);

    if (element) {
      const bounds = element.getBoundingClientRect();

      const overflowY = Math.ceil(bounds.right - window.innerWidth);

      if (overflowY > 0) {
        let { position } = this.state;

        position.left -= overflowY;

        this.setState({ position });
      }

      const overflowX = Math.ceil(bounds.bottom - window.innerHeight);

      if (overflowX > 0) {
        let { position } = this.state;

        position.top -= overflowX;

        this.setState({ position });
      }
    }
  }

  render() {
    const { show, position } = this.state;

    return (
      <div className="infoicon-container" ref="infoicon-container">
        <img
          src="https://vbio.blob.core.windows.net/public/assets/img/info-icon.png"
          alt="info"
          onClick={this.handleClick}
          onMouseLeave={this.handleMouseLeave}
        />

        {show ? (
          <div
            className="infoicon-tooltip"
            ref="infoicon-tooltip"
            style={{ top: position.top, left: position.left }}
          >
            {dict.t(this.props.info)}
          </div>
        ) : null}
      </div>
    );
  }

  handleClick = e => {
    this.setState({
      show: true,
      position: { top: e.clientY + tooltipOffset, left: e.clientX + tooltipOffset },
    });
  };

  handleMouseLeave = () => {
    this.setState({ show: false });
  };
}


