
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de apoiador
//


import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

import VF from "../../constants/ValidationFields";
import {
  normalizerCNPJ,
  normalizerNumber,
  normalizerPhone,
  normalizerCEP,
} from "../../lib/Normalizer";

import Input from "../../lib/Forms/Input";

import PublicRequest from "../../lib/Request";

import config from "../../config";

import multiLang from "../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



class SupporteRegisterForm extends Component {
  componentWillMount() {
    const registerFormStorage = localStorage.getItem("registerFormCompany");

    if (registerFormStorage && registerFormStorage.length) {
      this.props.initialize(JSON.parse(registerFormStorage));
    }
  }

  render() {
    return (
      <div className="col-md-12 form-body" style={{ padding: "0 15px" }}>
        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="company"
                label={dict.t("Razão Social")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-6">
              <Field
                name="trade"
                label={dict.t("Nome Fantasia")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              {language === "PT" ? (
                <Field
                  name="cnpj"
                  label={dict.t("CNPJ")}
                  validate={[VF.Required, VF.CNPJ]}
                  normalize={normalizerCNPJ}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              ) : (
                <Field
                  name="cnpj"
                  label={dict.t("CNPJ")}
                  validate={[VF.Required]}
                  normalize={normalizerNumber}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              )}
            </div>

            <div className="col-md-6">
              <Field
                name="phone"
                label={dict.t("Telefone")}
                validate={[VF.Required]}
                normalize={normalizerPhone}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Field
                name="sector"
                label={dict.t("Atividade")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <Field
                name="cep"
                label={dict.t("CEP")}
                validate={[VF.Required]}
                normalize={normalizerCEP}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-3">
              <Field
                name="neighborhood"
                label={dict.t("Distrito")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-3">
              <Field
                name="city"
                label={dict.t("Cidade")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-3">
              <Field
                name="state"
                label={dict.t("Estado")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Field
                name="streetName"
                label={dict.t("Endereço")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-3">
              <Field
                name="addressNumber"
                label={dict.t("Número")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-3">
              <Field
                name="complement"
                label={dict.t("Complemento")}
                /* validate={[VF.Required]} */
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  handleCepBlur = ev => {
    this.handleFieldBlur();

    const cep = ev.target.value.replace("-", "");

    if (cep.length === 8) {
      PublicRequest.get(config.api.cep(cep), data => {
        if (!data.erro) {
          this.props.change("streetName", data.logradouro);
          this.props.change("neighborhood", data.bairro);
          this.props.change("city", data.localidade);
          this.props.change("state", data.uf);

          this.handleFieldBlur();
        }
      });
    }
  };
}



function saveForm(data) {
  localStorage.setItem("registerFormCompany", JSON.stringify(data));
}



export default reduxForm({
  form: "supporterRegisterForm",
  onChange: saveForm,
})(SupporteRegisterForm);


