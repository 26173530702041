
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Página de Documentos
//


import React, { Component } from "react";
import urlencode from "urlencode";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import List from "../../List/Zebra";

import masterAccess from "../../../../lib/masterAccess";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class Authors extends Component {
  handleDeleteButtonClick = item => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("excluir")} ${dict.t(
      "este documento"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/configs/documents/proponent-docs/${item._id}`;

        Request.delete(url, item, msg => {
          this.fetchProponentDocuments();

          // Forçar renderização após exclusão
          this.setState({ readyPropDoc: false });
          Alert.success(dict.t("Documento deletado com sucesso"));
        });
      }
    });
  };

  handleButtonUpdateRecord = () => {
    this.setState({ readyProjDoc: false, readyPropManual: false });
    this.fetchProponentManual();
    this.fetchProjectDocuments();
  };

  buttons = [
    {
      type: "edit",
      route: "/master/configuracao/documentos/proponente/editar",
    },

    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      readyTerms: false,
      terms: [],
      proponentDocs: [],
      readyPropDoc: false,
      proponentManual: [],
      readyPropManual: false,
      projectDocs: [],
      readyProjDoc: false,
    };
  }

  componentWillMount() {
    this.fetchTerms();
    this.fetchProponentDocuments();
    this.fetchProponentManual();
    this.fetchProjectDocuments();
  }

  render() {
    let {
      readyTerms,
      readyPropDoc,
      readyPropManual,
      readyProjDoc,
      projectDocs,
      proponentManual,
      terms,
      proponentDocs,
    } = this.state;

    if (!readyTerms || !readyPropDoc || !readyPropManual || !readyProjDoc) return <Loader />;

    let canEdit = false;

    if (masterAccess("settings")) canEdit = true;

    projectDocs = projectDocs.map(doc => {
      doc[language] = languageTools.merge(doc.PT, doc[language]);
      return doc;
    });

    proponentManual = proponentManual.map(doc => {
      doc[language] = languageTools.merge(doc.PT, doc[language]);
      return doc;
    });

    return (
      <div className="container footer-margin-logged">
        <List
          title={dict.t("Modelo de Documento do Projeto")}
          headerKey="name"
          items={this.composeItemsName(projectDocs)}
          buttons={
            canEdit
              ? [
                  {
                    type: "attach",
                    route: "/master/configs/documents/project-docs",
                    onRecordUpdate: this.handleButtonUpdateRecord,
                  },
                ]
              : null
          }
          style={{ height: "60px" }}
        />

        <List
          title={dict.t("Manual do Proponente")}
          headerKey="name"
          items={this.composeItemsName(proponentManual)}
          buttons={
            canEdit
              ? [
                  {
                    type: "attach",
                    route: "/master/configs/documents/proponent-docs/manual",
                    onRecordUpdate: this.handleButtonUpdateRecord,
                  },
                ]
              : null
          }
          style={{ height: "60px" }}
        />

        <List
          title={dict.t("Termos e Políticas")}
          headerKey="shortName"
          items={terms}
          buttons={
            canEdit
              ? [{ type: "edit", route: "/master/configuracao/documentos/termo-de-uso/editar" }]
              : null
          }
          style={{ height: "60px" }}
        />

        <List
          title={dict.t("Documentos do Proponente")}
          addRoute={canEdit ? "/master/configuracao/documentos/proponente/novo" : null}
          headerKey="name"
          items={proponentDocs}
          buttons={canEdit ? this.buttons : null}
          style={{ height: "60px" }}
        />
        <div style={{ marginBottom: "30px" }} />
      </div>
    );
  }

  composeItemsName(items) {
    return items.map(item => {
      const url = this.getFileNameFromUrl(item[language].url, true);

      item.name = url && url.length ? `${item.name} - ${url}` : `${item.name}`;
      return item;
    });
  }

  getFileNameFromUrl(url, removeS3Hash) {
    let filename = "";

    if (typeof url !== "string") {
      if (url) filename = url.name;
    } else {
      filename = url.substring(url.lastIndexOf("/") + 1);
    }

    if (removeS3Hash) filename = filename.substring(72);

    return urlencode.decode(filename);
  }

  fetchProjectDocuments() {
    Request.get("/master/configs/documents/project-docs", values => {
      if (!values || values.length === 0)
        values = [{ name: dict.t("Nenhum documento enviado"), attach: "" }];

      this.setState({ readyProjDoc: true, projectDocs: values });
    });
  }

  sortDocuments(documents) {
    if (!documents) return [];

    return documents.sort((a, b) => {
      a = a.name.split(".")[0];
      b = b.name.split(".")[0];

      return a - b;
    });
  }

  fetchProponentDocuments() {
    Request.get("/master/configs/documents/proponent-docs", values => {
      if (!values) values = [];

      values = this.sortDocuments(values);

      this.setState({ readyPropDoc: true, proponentDocs: values });
    });
  }

  fetchProponentManual() {
    Request.get("/master/configs/documents/proponent-docs/manual", values => {
      if (!values || values.length === 0)
        values = [{ name: dict.t("Nenhum documento enviado"), attach: "" }];

      this.setState({ readyPropManual: true, proponentManual: values });
    });
  }

  fetchTerms() {
    Request.get("/master/configs/documents/term", values => {
      if (!values) values = [];

      values = values.map(term => {
        return {
          ...term,
          shortName: dict.t(term.shortName),
        };
      });

      this.setState({ readyTerms: true, terms: values });
    });
  }
}


