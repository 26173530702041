
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Perfil de apoiador
//


import React, { Component } from "react";

import Authentication from "../../../../lib/Authentication";

import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";
import Loader from "../../../Loader/index.js";

import ListHeader from "../../List/Header";
import List from "../../List/Zebra";

import SupporterData from "../../../Commons/SupporterData";
import BackButton from "../../../Commons/Back";

import UserItemBody from "./UserItemBody";

import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class Profile extends Component {
  handleDeleteButtonClick = item => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("excluir")} ${dict.t(
      "este usuário"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/supporter/users/${item._id}`;

        Request.delete(url, item, msg => {
          this.fetchSupporter();

          // Forçar renderização após exclusão
          this.setState({ usersReady: false });
          Alert.success(dict.t("Usuário deletado com sucesso"));
        });
      }
    });
  };

  buttons = [
    {
      type: "edit",
      route: "/apoiador/usuario/editar",
    },

    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      supporterReady: false,
      usersReady: false,
      supporter: {},
      users: [],
    };
  }

  componentWillMount() {
    this.fetchSupporter();
  }

  render() {
    if (!this.state.supporterReady || !this.state.usersReady) return <Loader />;

    return (
      <div className="container footer-margin-logged">
        <div className="back-button-generic" style={{ marginLeft: "-15px" }}>
          <BackButton router={this.props.router} />
        </div>

        <ListHeader
          title={dict.t("Apoiador")}
          editRoute={`/apoiador/editar/${this.state.supporter._id}`}
          ref="supporter-form"
          onSubmit={this.handleSupporterSubmit}
        />

        <SupporterData supporter={this.state.supporter} />

        <div style={{ marginBottom: "40px" }}>
          <List
            title={dict.t("Usuários")}
            addRoute={`/apoiador/usuario/novo/${this.state.supporter._id}`}
            items={this.state.users}
            itemBodyComponent={UserItemBody}
            buttons={this.buttons}
            style={{ height: "60px" }}
          />
        </div>
      </div>
    );
  }

  fetchSupporter() {
    const url = `/supporter/users/${Authentication.getUser().personal.id}`;

    Request.get(url, values => {
      this.setState({ supporterReady: true, supporter: values.supporter });
      this.fetchUsers();
    });
  }

  fetchUsers() {
    const url = `/supporter/${this.state.supporter._id}`;

    Request.get(url, values => {
      this.setState({ usersReady: true, users: values.users });
    });
  }

  handleSupporterSubmit = values => {
    values.role = "supporter";

    this.supporter = values;

    this.composeRecord();
  };
}


