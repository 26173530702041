
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Apoiador - página interna - master
//


import React, { Component } from "react";
import { Link } from "react-router";

import Loader from "../../../Loader";

import Alert from "../../../../lib/Alert";
import Request from "../../../../lib/BackendRequest";

import Back from "../../../Commons/Back";

import SupporterData from "../../../Commons/SupporterData";

import List from "../../List/Zebra";

import ItemBody from "./ItemBody";
import SupportItemBody from "./SupportItemBody";

import multiLang from "../../../../lib/multilang/multiLang";
import masterAccess from "../../../../lib/masterAccess";



const dict = new multiLang(multiLang.getLanguage());



export default class SupporterIntern extends Component {
  handleDeleteButtonClick = item => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("excluir")} ${dict.t(
      "este usuário"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/users/supporter-user/${item._id}`;

        Request.delete(url, item, res => {
          if (res.success) {
            // Forçar renderização após exclusão
            this.setState({ supporter: null });
            this.fetchSupporter();

            Alert.success(dict.t("Usuário deletado com sucesso"));
          }
        });
      }
    });
  };

  buttons = [
    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },
  ];

  supportButtons = [
    {
      type: "edit",
      route: "/master/apoios",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      supporter: null,
      supports: null,
    };
  }

  componentWillMount() {
    this.fetchSupporter();
  }

  render() {
    if (!this.state.supporter || !this.state.supports) return <Loader />;

    const canEdit = masterAccess("supportApprove");

    return (
      <div
        className="container page-margin-top footer-margin-logged"
        style={{ paddingBottom: "30px" }}
      >
        <Back router={this.props.router} />

        <div className="" style={{ marginTop: "30px", marginBottom: "10px" }}>
          <div className="page-title">{dict.t("Apoiador")}</div>

          {canEdit ? (
            <div className="" style={{ float: "right", marginBottom: "10px" }}>
              <Link to={`/master/apoiadores/editar/${this.props.router.params.id}`}>
                <img
                  src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon-2.png"
                  alt="Editar"
                  title="Editar"
                  style={{ height: "30px" }}
                />
              </Link>

              <img
                src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon-2.png"
                alt={dict.t("Deletar")}
                title={dict.t("Deletar")}
                style={{ height: "30px", marginLeft: "15px", cursor: "pointer" }}
                onClick={this.handleDelete}
              />
            </div>
          ) : null}
        </div>

        <div className="col-md-12">
          <SupporterData supporter={this.state.supporter} />
        </div>

        <div className="col-md-12">
          <List
            title={dict.t("Usuários")}
            items={this.state.supporter.users}
            itemBodyComponent={ItemBody}
            addRoute={
              canEdit ? `/master/apoiadores/usuario/novo/${this.state.supporter._id}` : null
            }
            buttons={canEdit ? this.buttons : null}
            style={{ height: "60px" }}
          />
        </div>

        {this.state.supports && this.state.supports.length ? (
          <div className="col-md-12">
            <List
              title={dict.t("Apoios")}
              items={this.state.supports}
              itemBodyComponent={SupportItemBody}
              buttons={this.supportButtons}
              style={{ height: "60px" }}
            />
          </div>
        ) : null}
      </div>
    );
  }

  fetchSupporter() {
    const url = `/master/users/supporter/${this.props.router.params.id}`;

    Request.get(url, values => {
      this.setState({ supporter: values }, () => this.fetchSupports());
    });
  }

  fetchSupports() {
    const url = "/master/supports";

    Request.get(url, values => {
      values = values.filter(
        support => support.project && support.supporter._id === this.props.router.params.id
      );

      this.setState({ supports: values, filteredSupports: values });
    });
  }

  handleDelete = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este apoiador"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/users/supporter/${this.state.supporter._id}`;

        Request.delete(url, {}, res => {
          if (res.success) {
            Alert.success(
              `${dict.t("Apoiador")} ${dict.t("deletado")} ${dict.t("com sucesso")}`,
              () => {
                this.props.router.goBack();
              }
            );
          }
        });
      }
    });
  };
}


