export default [
  {
    label: "Básico",
    name: "basic",
    items: [
      { label: "Nome", key: "name" },
      { label: "Apelido", key: "shortName" },
      { label: "Cidade", key: "city" },
      { label: "Estado", key: "state", type: "valueNotZero" },
      { label: "Bioma", key: "biomes", type: "biome" },
    ],
  },

  {
    label: "Classificação",
    name: "classification",
    items: [
      { label: "Adequação legal", key: "legalFitness", type: "legalFitness" },
      { label: "Tema", key: "areasOfInterest", type: "areasOfinterest" },
      { label: "Subtema", key: "ods", type: "ods" },
    ],
  },

  {
    label: "Detalhes",
    name: "details",
    items: [
      { label: "Resumo", key: "summary" },
      { label: "Descrição", key: "description", type: "arrayNotEmpty" },
      { label: "Foto da capa", key: "images", type: "arrayNotEmpty" },
      { label: "Documento", key: "document", type: "fileUrl" },
      { label: "Valor das cotas", key: null, type: "shareValue" },
      { label: "Total de cotas", key: "numOfShares" },
      { label: "Valor total do projeto", key: "totalValue" },
      { label: "Valor mínimo do projeto", key: "minimumValue" },
      { label: "Indicadores de Desempenho", key: "expectedIndicators", type: "arrayNotEmpty" },
      { label: "Em operação", key: "inOperation", type: "yesNoBoolean" },
      { label: "Início da captação", key: "startDate" },
      { label: "Término da captação", key: "endDate" },
    ],
  },

  {
    label: "Gratificações",
    name: "gratifications",
    items: [
    ],
  },
];
