
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Home - área aberta
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import Loader from "../Loader";
import Request from "../../lib/BackendRequest";

import ImageFilter from "../ImageFilter";
import Carousel from "../Commons/Carousel";
import StatCard from "./StatCard";

import ProjectsShowcase from "../ProjectsShowcase";
import WhyVbio from "./WhyVbio";
import HowTo from "./HowTo";
import Blog from "./Blog";

import Authentication from "../../lib/Authentication";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();



const carouselImageFilter =
  "linear-gradient(360deg, #666666 0%, #666666 0.01%, rgba(79, 79, 79, 0.2) 100%";



export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bannersReady: false,
      statsReady: false,
      projectsReady: false,
      blogPosts: null,
      projectsTab: "all",
    };
  }

  componentWillMount() {
    this.fetchBanners();
    this.fetchStats();
    this.fetchProjects();
    this.fetchBlogPosts();
  }

  render() {
    if (
      !this.state.bannersReady ||
      !this.state.statsReady ||
      !this.state.projectsReady ||
      !this.state.blogPosts
    )
      return <Loader />;

    const footerMargin = Authentication.isAuthenticated()
      ? "footer-margin-logged"
      : "footer-margin";

    return (
      <div className={footerMargin}>
        <div className="home-carousel-container">
          <Carousel
            className="home-carousel"
            prevArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/previous-arrow.png"
            nextArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/next-arrow.png"
            items={this.composeMainCarouselItems(this.state.banners)}
          />
        </div>

        <div className="stats-container">
          <div className="stats">{this.getStatCards()}</div>
        </div>

        <div className="container">
          <ProjectsShowcase
            projects={this.state.projects}
          />
        </div>

        <WhyVbio />

        <HowTo />

        <Blog items={this.state.blogPosts} />
      </div>
    );
  }

  composeMainCarouselItems(items) {
    if (!items.length) {
      return (
        <Fragment>
          <div style={{ position: "relative", height: "100%", textAlign: "center" }}>
            <div className="home-carousel-image-container"></div>
          </div>
        </Fragment>
      );
    }

    return items.map((item, index) => {
      if (!item[language]) item[language] = item.PT;

      return (
        <Fragment key={index}>
          <div style={{ position: "relative", height: "100%", textAlign: "center" }}>
            <div className="home-carousel-image-container">
              <img className="home-carousel-image" src={item.image} alt="" />
              <ImageFilter className="home-carousel-image" filter={carouselImageFilter} />
            </div>

            <div className="home-carousel-content">
              <div className="home-carousel-content-title">{item[language].title}</div>

              <div>
                <div className="home-carousel-content-text">{item[language].text}</div>

                <Link to={item.buttonRoute}>
                  <button className="button home-carousel-content-button">
                    {item[language].buttonLabel}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });
  }

  getStatCards() {
    return this.state.stats.map((item, index) => {
      if (!item[language]) item[language] = item.PT;

      return <StatCard key={index} value={item[language].value} text={item[language].label} />;
    });
  }

  fetchBanners() {
    Request.get("/home/banners", values => {
      values = values.filter(banner => banner.published && banner.image && banner.image !== "");
      this.setState({ bannersReady: true, banners: values });
    });
  }

  fetchStats() {
    Request.get("/home/stats", values => {
      this.setState({ statsReady: true, stats: values });
    });
  }

  fetchProjects() {
    Request.get("/home/projects", values => {
      this.setState({ projectsReady: true, projects: values });
    });
  }

  fetchBlogPosts() {
    Request.get("/blog/posts", values => {
      this.setState({ blogPosts: values });
    });
  }
}


