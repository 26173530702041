export default [
  {
    name: "amazonia",
    label: "Amazônia",
  },

  {
    name: "caatinga",
    label: "Caatinga",
  },

  {
    name: "cerrado",
    label: "Cerrado",
  },

  {
    name: "mataAtlantica",
    label: "Mata Atlântica",
  },

  {
    name: "pampa",
    label: "Pampa",
  },

  {
    name: "pantanal",
    label: "Pantanal",
  },

  {
    name: "marinho",
    label: "Ecossistemas Marinhos",
  },
];
