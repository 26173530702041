
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Filtro para imagens
//


import React, { Component, Fragment } from "react";



export default class ImageFilter extends Component {
  static defaultProps = {
    enableOnHover: false,
    disableOnHover: false,
  };

  constructor(props) {
    super(props);

    const enable = !this.props.enableOnHover;

    this.state = { enable };
  }

  render() {
    const filterStyle = {
      background: this.state.enable ? this.props.filter : "",
      mixBlendMode: "multiply",
    };

    const componentStyle = { display: this.state.enable ? "block" : "none" };

    const InnerComponent = this.props.innerComponent;

    return (
      <Fragment>
        <div
          className={this.props.className}
          style={filterStyle}
          title={this.props.title}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        ></div>

        {InnerComponent ? (
          <div
            className={this.props.className}
            style={componentStyle}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <InnerComponent />
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }

  handleMouseEnter = () => {
    if (this.props.enableOnHover) this.setState({ enable: true });

    if (this.props.disableOnHover) this.setState({ enable: false });
  };

  handleMouseLeave = () => {
    if (this.props.enableOnHover) this.setState({ enable: false });

    if (this.props.disableOnHover) this.setState({ enable: true });
  };
}


