
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de proponente - usuário
//


import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

import VF from "../../constants/ValidationFields";

import Input from "../../lib/Forms/Input";

import multiLang from "../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



class ProponentUserForm extends Component {
  componentWillMount() {
    const registerFormStorage = localStorage.getItem("registerFormUser");

    if (registerFormStorage && registerFormStorage.length) {
      this.props.initialize(JSON.parse(registerFormStorage));
    }
  }

  render() {
    return (
      <div className="col-md-12 form-body" style={{ padding: "0 15px" }}>
        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="name"
                label={dict.t("Nome")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-6">
              <Field
                name="email"
                label={dict.t("E-mail")}
                validate={[VF.Required, VF.Email]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Field
                name="companyRole"
                label={dict.t("Cargo")}
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>

            <div className="col-md-6">
              <Field
                name="password"
                label={dict.t("Senha")}
                type="password"
                validate={[VF.Required]}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}



function saveForm(data) {
  localStorage.setItem("registerFormUser", JSON.stringify(data));
}



export default reduxForm({
  form: "proponentUserRegisterForm",
  onChange: saveForm,
})(ProponentUserForm);


