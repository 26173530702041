/**
 * 8 num
 * 00000-000
 * @param {*} value
 * @param {*} previousValue
 */
const normalizerCEP = (value, previousValue) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length <= 5) return onlyNums.slice(0, 5);

  return onlyNums.slice(0, 5) + "-" + onlyNums.slice(5, 8);
};

export default normalizerCEP;
