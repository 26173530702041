/**
 * R$000.000.000.000,00
 * @param {*} value
 * @param {*} previousValue
 */
const normalizerCurrency = (value, previousValue) => {
  if (!value) return value;

  let onlyNums = value.replace(/[^\d]/g, "");
  onlyNums = onlyNums.replace(/^0+/, "");

  const length = onlyNums.length;

  if (length === 0) return ""; //'R$0,00';

  if (length <= 1) return "R$0,0" + onlyNums;

  if (length <= 2) return "R$0," + onlyNums;

  if (length <= 5) return "R$" + onlyNums.slice(0, length - 2) + "," + onlyNums.slice(length - 2);

  if (length <= 8)
    return (
      "R$" +
      onlyNums.slice(0, length - 5) +
      "." +
      onlyNums.slice(length - 5, length - 2) +
      "," +
      onlyNums.slice(length - 2)
    );

  if (length <= 11)
    return (
      "R$" +
      onlyNums.slice(0, length - 8) +
      "." +
      onlyNums.slice(length - 8, length - 5) +
      "." +
      onlyNums.slice(length - 5, length - 2) +
      "," +
      onlyNums.slice(length - 2)
    );

  if (length <= 14)
    return (
      "R$" +
      onlyNums.slice(0, length - 11) +
      "." +
      onlyNums.slice(length - 11, length - 8) +
      "." +
      onlyNums.slice(length - 8, length - 5) +
      "." +
      onlyNums.slice(length - 5, length - 2) +
      "," +
      onlyNums.slice(length - 2)
    );

  return (
    "R$" +
    onlyNums.slice(0, length - 11 - 1) +
    "." +
    onlyNums.slice(length - 11 - 1, length - 8 - 1) +
    "." +
    onlyNums.slice(length - 8 - 1, length - 5 - 1) +
    "." +
    onlyNums.slice(length - 5 - 1, length - 2 - 1) +
    "," +
    onlyNums.slice(length - 2 - 1, length - 1)
  );
};

export default normalizerCurrency;
