
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Componente Range Slider customizado
//


import React, { Component } from "react";
import { Range } from "rc-slider";

import "rc-slider/assets/index.css";
import "./style.css";

const railStyle = {
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "3px",
  height: "5px",
};

const trackStyle = {
  backgroundColor: "#00522C",
  height: "5px",
};

const baseHandleStyle = {
  backgroundColor: "#FBFBFB",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "3px",
  height: "25px",
  width: "25px",
  marginTop: "-11px",
};

const lowerHandleStyle = {
  ...baseHandleStyle,
  marginLeft: "-1px",
};

const upperHandleStyle = {
  ...baseHandleStyle,
  marginLeft: "-24px",
};



export default class RangeSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : [this.props.min, this.props.max],
    };
  }

  render() {
    return (
      <div className="range-container">
        <div className="range-label-container">
          <div className="range-label" style={{ float: "left" }}>
            {this.formatLowerLabel(this.state.value[0])}
          </div>
          <div className="range-label" style={{ float: "right" }}>
            {this.formatUpperLabel(this.state.value[1])}
          </div>
        </div>

        <Range
          allowCross={false}
          min={this.props.min}
          max={this.props.max}
          value={this.state.value}
          railStyle={railStyle}
          trackStyle={[trackStyle]}
          handleStyle={[lowerHandleStyle, upperHandleStyle]}
          onChange={this.handleChange}
        />
      </div>
    );
  }

  formatLowerLabel(text) {
    if (this.props.formatLowerlabelFunc) return this.props.formatLowerlabelFunc(text);

    return text;
  }

  formatUpperLabel(text) {
    if (this.props.formatUpperlabelFunc) return this.props.formatUpperlabelFunc(text);

    return text;
  }

  handleChange = value => {
    this.setState({ value });

    if (this.props.onChange) this.props.onChange(value);
  };
}


