import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import Modal from "react-responsive-modal";

import ScreenSize from "../../../Commons/ScreenSize";

import scrollToComponent from "react-scroll-to-component";
import ReactHtmlParser from "react-html-parser";

import Alert from "../../../../lib/Alert";
import Authentication from "../../../../lib/Authentication";
import Request from "../../../../lib/BackendRequest";
import Loader from "../../../Loader";
import multiLang from "../../../../lib/multilang/multiLang";
import { formatCurrency } from "../../../../lib/FormatString";

import BadgeStack from "../../../Commons/BadgeStack";

import languageTools from "../../../../lib/languageTools";

import Carousel from "../../../Commons/Carousel";
import SupportModule from "./_Support";
import Toolbar from "./Toolbar";

import downloadFile from "../../../../lib/downloadFile";

import ProgressBar from "../../../ProgressBar";

import axios from "axios";

import List from "../../List/Zebra";

import biomesArray from "../../../../constants/biomes";
import odsArray from "../../../../constants/ods";
import legalFitnessArray from "../../../../constants/legalFitness";

import backendUserRoutes from "../../../../constants/backendUserRoutes";
import { forceDownload } from "../../../../lib/fileDownload";
import config from "../../../../config";

import "../style.css";
import masterAccess from "../../../../lib/masterAccess";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 992;



export default class ProjectIntern extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: null,
      supporters: null,
      showHeader: false,
      showModal: false,
      modalInputText: "",
      badgesWidth: {
        legalBadges: 0,
        areaBadges: 0,
        odsBadges: 0,
      },
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = event => {
    if (!this.refs) return;

    const element = ReactDOM.findDOMNode(this.refs["project-intern-support-button"]);

    if (element) {
      const bounds = element.getBoundingClientRect();
      let showHeader = false;

      if (bounds.y <= 0) showHeader = true;

      if (showHeader !== this.state.showHeader) this.setState({ showHeader });
    }
  };

  componentWillMount() {
    this.fetchProject();
  }

  render() {
    if (!this.state.project || !this.state.supporters) return <Loader />;

    const { project } = this.state;

    return (
      <Fragment>
        <div className="footer-margin-logged" style={{ paddingBottom: "10px" }}>
          <ScreenSize onScreenResize={this.handleScreenResize} />

          {Authentication.getUser().personal.role === "supporterUser" &&
          this.state.showHeader &&
          !this.state.smallScreen
            ? this.renderHeader(project)
            : null}

          {this.renderToolbar(Authentication.getUser().personal.role)}

          <Titles project={project} />

          <div className="container">
            <div className="project-intern-carousel-container">
              <Carousel
                className="project-intern-carousel"
                prevArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/previous-arrow.png"
                nextArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/next-arrow.png"
                items={this.composeCarouselItems(project.images)}
              />

              <div className="" style={{ position: "relative" }}>
                <div className="project-intern-carousel-toolbar-container">
                  <div className="project-intern-badges-container">
                    {this.getProjectBadges(project)}
                  </div>

                  {Authentication.getUser().personal.role === "supporterUser" ? (
                    <div className="project-intern-button-container">
                      <button
                        ref="project-intern-support-button"
                        className="button project-intern-button"
                        onClick={this.handlePreSupportClick}
                      >
                        {dict.t("Apoiar")}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <Goals project={project} screenWidth={this.state.screenWidth} />

          {Authentication.getUser().personal.role === "master" ? (
            <div className="container">
              <button
                className="button"
                style={{ width: "250px", marginTop: "20px" }}
                onClick={this.handleImageDownload}
              >
                {this.state.downloadingImages
                  ? dict.t("Aguarde") + "..."
                  : dict.t("Baixar imagens do projeto")}
              </button>
            </div>
          ) : null}

          {project[language].summary ? (
            <div className="container">
              <div className="project-intern-summary-title">{dict.t("Resumo")}</div>

              <div className="project-intern-description" style={{ marginTop: "0" }}>
                {project[language].summary}
              </div>
            </div>
          ) : null}

          <Operation project={project} screenWidth={this.state.screenWidth} />

          <div className="container project-intern-description">
            <div className="row">
              <div className="col-sm-offset-2 col-sm-8">
                {ReactHtmlParser(project[language].description)}
              </div>
            </div>
          </div>

          <Values project={project} css="project-intern-values-green" />

          <Gratifications project={project} />

          {project[language].expectedIndicators &&
          project[language].expectedIndicators.length > 0 ? (
            <div className="container">
              <div className="col-sm-12">
                <List
                  title={dict.t("Indicadores de Desempenho")}
                  headerKey="value"
                  items={project[language].expectedIndicators.map(item => ({ value: item }))}
                  style={{ height: "60px" }}
                  shadow={true}
                />
              </div>
            </div>
          ) : null}

          {this.state.supporters && this.state.supporters.length > 0 ? (
            <div className="container">
              <div className="col-sm-12">
                <List
                  title={dict.t("Apoiadores")}
                  headerKey="trade"
                  items={this.state.supporters}
                  style={{ height: "60px" }}
                  shadow={true}
                />
              </div>
            </div>
          ) : null}

          <Values project={project} css="project-intern-values-gray" />

          {Authentication.getUser().personal.role === "supporterUser" ? (
            <section
              ref={section => {
                this.submitForm = section;
              }}
            >
              <SupportModule
                ref="support-submit-form"
                project={project}
                params={this.props.params}
              />
            </section>
          ) : null}

          {this.renderToolbar(Authentication.getUser().personal.role)}
        </div>

        <Modal open={this.state.showModal} onClose={this.handleModalClose} center>
          <div className="proponent-documents-list-modal-container">
            <div className="page-title proponent-documents-list-modal-title">
              Mensagem para proponente
            </div>

            <div className="proponent-documents-list-modal-textarea-container">
              <textarea
                className="form-control form-input proponent-documents-list-modal-textarea"
                name="message"
                placeholder="Mensagem"
                rows="4"
                style={{ resize: "none" }}
                onChange={this.handleModalInputChange}
              />
            </div>

            <button
              className="button proponent-documents-list-modal-button"
              type="button"
              style={{ float: "right" }}
              onClick={this.handleModalSubmit}
            >
              Enviar
            </button>
          </div>
        </Modal>
      </Fragment>
    );
  }

  fetchProject() {
    const url = `/${backendUserRoutes(Authentication.getUser().personal.role)}projects/${
      this.props.params.id
    }`;

    Request.get(url, value => {
      value[language] = languageTools.merge(value.PT, value[language]);

      this.setState({ project: value });

      //set title into the window
      document.title = `VBIO.eco - ${value[language].shortName}`;

      this.fetchSupports();
    });
  }

  fetchSupports() {
    let url;

    const role = Authentication.getUser().personal.role;

    url = `/${backendUserRoutes(role)}supports/supporters/${this.state.project._id}`;

    Request.get(url, values => {
      // remove projetos duplicados
      const supporters = values.filter((item, index, array) => {
        return array.map(mapItem => mapItem._id).indexOf(item._id) === index;
      });

      this.setState({ supporters });
    });
  }

  renderHeader(project) {
    return (
      <div id="project-intern-header">
        <div className="container">
          <div className="row">
            <div className="col-md-6" id="project-intern-header-fixed-value" style={{ padding: 0 }}>
              {formatCurrency(project.supportedValue)} {dict.t("de")}{" "}
              {formatCurrency(project.totalValue)}
            </div>

            <div className="col-md-6 text-right" style={{ padding: 0 }}>
              <button
                className="button"
                id="project-intern-header-fixed-support"
                onClick={this.handlePreSupportClick}
              >
                {dict.t("Apoiar")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderToolbar(role) {
    return (
      <div style={{ marginTop: "30px" }}>
        <Toolbar
          backButtonOnly={role !== "master" || !masterAccess("projectApprove")}
          router={this.props.router}
          editRoute={`/master/projetos/editar/${this.props.router.params.id}`}
          status={this.state.project.status}
          inactivateDate={this.state.project.inactivateDate}
          alertDate={this.state.project.alertDate}
          updatedAt={this.state.project.updatedAt}
          onAlert={this.handleAlert}
          onApprove={this.handleApprove}
          onDeny={this.handleDeny}
          onInactivate={this.handleInactivate}
          onReactivate={this.handleReactivate}
          onDelete={this.handleDelete}
          onEditEnable={this.handleEditEnable}
        />
      </div>
    );
  }

  composeCarouselItems(items) {
    if (!items) return "";

    return items.map((item, index) => {
      return (
        <div key={index} style={{ position: "relative", height: "100%", textAlign: "center" }}>
          <img className="project-intern-carousel-image" src={item} alt="" />
        </div>
      );
    });
  }

  getProjectBadges(project) {
    let legalBadges = [];
    let areaBadges = [];
    let odsBadges = [];

    project.legalFitness.forEach(legalFitness => {
      legalBadges.push({
        icon: `/assets/img/badges/legal-fitness/${legalFitness}.png`,
        label: this.getLegalFitnessLabel(legalFitness),
      });
    });

    this.sortODSs(project.ODS).forEach(ODS => {
      odsBadges.push({
        icon: `/assets/img/badges/ods/${language}/${ODS}.png`,
        label: this.getODSLabel(ODS),
      });
    });

    project.areasOfInterest.forEach(area => {
      areaBadges.push({ icon: area.image, label: area[language].name });
    });

    const style = { margin: "0 20px", display: "inline-block", verticalAlign: "middle" };

    const { screenWidth } = this.state;

    let legalBadgeSize = 60;
    let badgeSize = 55;

    if (screenWidth < 768) {
      legalBadgeSize = 50;
      badgeSize = 45;
    }

    return (
      <Fragment>
        <BadgeStack
          items={legalBadges}
          onResize={width => this.handleBageResize("legalBadges", width)}
          badgeWidth={legalBadgeSize}
          style={style}
          group={this.state.groupBadges}
        />

        <BadgeStack
          items={areaBadges}
          onResize={width => this.handleBageResize("areaBadges", width)}
          badgeWidth={badgeSize}
          style={style}
          group={this.state.groupBadges}
        />

        <BadgeStack
          items={odsBadges}
          onResize={width => this.handleBageResize("odsBadges", width)}
          badgeWidth={badgeSize}
          style={style}
          group={this.state.groupBadges}
        />
      </Fragment>
    );
  }

  getLegalFitnessLabel(legalFitness) {
    return dict.t(legalFitnessArray.find(item => item.name === legalFitness).label);
  }

  getODSLabel(ods) {
    return dict.t(odsArray.find(item => item.name === ods).label);
  }

  sortODSs(items) {
    return items.sort((a, b) => {
      a = a.match(/ods-[\d]*/g)[0];
      a = a.replace(/[^\d]/g, "");

      b = b.match(/ods-[\d]*/g)[0];
      b = b.replace(/[^\d]/g, "");

      return b - a;
    });
  }

  shortDateToUTC(date) {
    const dateArray = date.split("/");

    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
  }

  updateProject(data, message) {
    const url = `/master/projects/${data._id}`;

    Request.post(url, data, msg => {
      // Forçar renderização após exclusão
      this.setState({ project: null });
      this.fetchProject();

      Alert.success(message);
    });
  }

  adjustBadges() {
    const { badgesWidth, screenWidth } = this.state;

    const totalWidth =
      badgesWidth["legalBadges"] + badgesWidth["areaBadges"] + badgesWidth["odsBadges"];

    const group = totalWidth >= screenWidth * 0.6;

    this.setState({ groupBadges: group });
  }

  handleScreenResize = screen => {
    const { width } = screen;

    this.setState({ smallScreen: width <= smallScreenWidth, screenWidth: width }, () =>
      this.adjustBadges()
    );
  };

  handleBageResize = (id, width) => {
    let { badgesWidth } = this.state;

    badgesWidth[id] = width;

    this.setState({ badgesWidth }, () => this.adjustBadges());
  };

  handlePreSupportClick = () => {
    scrollToComponent(this.submitForm);
  };

  handleAlert = () => {
    this.setState({ showModal: true });
  };

  handleApprove = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("aprovar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { project } = this.state;

        const endDate = this.shortDateToUTC(project.endDate);
        const expired = Date.now() > endDate;

        project.status = expired ? "finalized" : "raisingFunds";

        project.approveDate = Date.now();

        const message = `${dict.t("Projeto")} ${dict.t("aprovado")} ${dict.t("com sucesso")}`;
        this.updateProject(project, message);
      }
    });
  };

  handleDeny = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("negar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { project } = this.state;

        const endDate = this.shortDateToUTC(project.endDate);
        const expired = Date.now() > endDate;

        project.status = expired ? "finalized" : "refused";

        project.refuseDate = Date.now();

        const message = `${dict.t("Projeto")} ${dict.t("negado")} ${dict.t("com sucesso")}`;
        this.updateProject(project, message);
      }
    });
  };

  handleInactivate = () => {
    const { supporters } = this.state;

    if (supporters && supporters.length > 0) {
      Alert.error(dict.t("Não é possível inativar um projeto que já possui apoios"));
      return;
    }

    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("inativar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { project } = this.state;

        project.status = "inactive";
        project.inactivateDate = Date.now();

        const message = `${dict.t("Projeto")} ${dict.t("inativado")} ${dict.t("com sucesso")}`;
        this.updateProject(project, message);
      }
    });
  };

  handleReactivate = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("reativar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { project } = this.state;

        project.status = "pending";
        const message = `${dict.t("Projeto")} ${dict.t("reativado")} ${dict.t("com sucesso")}`;
        this.updateProject(project, message);
      }
    });
  };

  handleDelete = () => {
    const { supporters } = this.state;

    if (supporters && supporters.length > 0) {
      Alert.error(dict.t("Não é possível deletar um projeto que já possui apoios"));
      return;
    }

    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const { project } = this.state;
        const url = `/master/projects/${project._id}`;

        Request.delete(url, {}, res => {
          if (res.success) {
            Alert.success(
              `${dict.t("Projeto")} ${dict.t("deletado")} ${dict.t("com sucesso")}`,
              () => {
                this.props.router.goBack();
              }
            );
          }
        });
      }
    });
  };

  handleEditEnable = () => {
    let msg = dict.t("Gostaria de habilitar a edição deste projeto para o proponente?") + "\n";
    msg += dict.t('Caso confirme, este projeto retornará ao status de "Não submetido".');

    Alert.confirm(msg, yes => {
      if (yes) {
        let { project } = this.state;

        project.status = "notSubmited";

        const message = dict.t("Edição habilitada com sucesso");
        this.updateProject(project, message);
      }
    });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleModalInputChange = ev => {
    this.setState({ modalInputText: ev.target.value });
  };

  handleModalSubmit = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("enviar")} ${dict.t(
      "um alerta para este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        this.setState({ showModal: false });

        const url = `/master/projects/notify/${this.state.project._id}`;

        Request.post(url, { message: this.state.modalInputText }, res => {
          console.log(res);
          if (res.success) {
            // Forçar renderização após exclusão
            this.setState({ project: null });
            this.fetchProject();

            const message = `${dict.t("Alerta")} ${dict.t("enviado")} ${dict.t("com sucesso")}`;
            Alert.success(message);
          }
        });
      }
    });
  };

  handleImageDownload = () => {
    this.setState({ downloadingImages: true });

    axios({
      url: `${config.backend_url}/file/downloadzip/imagens.zip`,
      method: "POST",
      responseType: "blob", // important
      data: this.state.project.images,
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "imagens.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      this.setState({ downloadingImages: false });
    });
  };
}

class Gratifications extends Component {
  render() {
    if (!this.props.project.gratifications || this.props.project.gratifications.length === 0)
      return null;

    return (
      <div id="project-intern-gratifications" style={{ marginTop: "30px" }}>
        <div className="container">
          <h2 className="page-title" style={{ fontSize: "24px" }}>
            {dict.t("Gratificações")}
          </h2>

          {this.gratifications}
        </div>
      </div>
    );
  }

  get gratifications() {
    let rows = [];
    let grats = this.props.project.gratifications || [];

    for (let index = 0; index <= grats.length; index += 2)
      rows.push(
        <div className="row">
          {this.renderGratification(grats[index])}
          {this.renderGratification(grats[index + 1])}
        </div>
      );

    return rows;
  }

  renderGratification = (g, index) => {
    if (!g) return null;

    return (
      <div key={index} className="col-md-6">
        <div className="gratification-card">
          <div className="row">
            <div className="col-md-4 gratification-title">
              {`${dict.t("De")} ${g.numOfSharesStart} ${dict.t("a")} ${g.numOfSharesEnd} ${dict.t(
                "cota(s)"
              )}`}
            </div>
            <div className="col-md-8 gratification-desc">{this.getDescription(g)}</div>
          </div>
        </div>
      </div>
    );
  };

  getDescription = g => {
    return g[`description_${language}`] || g.description;
  };
}

class Values extends Component {
  render() {
    const { css } = this.props;

    return (
      <div className="container">
        <div className={`row project-intern-values ${css} `}>
          <div className="row clear">
            <div className="col-sm-4">
              <div className="project-intern-values-title">{dict.t("valor do projeto")}</div>
              <div className="project-intern-values-value">{this.totalValue}</div>
            </div>

            <div className="col-sm-4">
              <div className="project-intern-values-title">{dict.t("valor da cota")}</div>
              <div className="project-intern-values-value">{this.shareValueFormatted}</div>
            </div>

            <div className="col-sm-4">
              <div className="project-intern-values-title">{dict.t("cotas apoiadas")}</div>
              <div className="project-intern-values-value">{this.shareSupported}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  get totalValue() {
    return formatCurrency(this.props.project.totalValue);
  }

  get shareValueFormatted() {
    return formatCurrency(this.shareValue);
  }

  get shareSupported() {
    const { project } = this.props;

    const shareValue = this.shareValue;

    const shareSupported = this.props.project.supportedValue / shareValue;

    return `${shareSupported.toFixed(2).replace(".", ",")}/${project.numOfShares}`;
  }

  get shareValue() {
    return this.props.project.totalValue / this.props.project.numOfShares;
  }
}

class Operation extends Component {
  render() {
    const { project } = this.props;

    let style = {};

    const { screenWidth } = this.props;

    if (screenWidth < 768) {
      style = { fontSize: "20px", marginBottom: "20px", textAlign: "center" };
    }

    return (
      <div className="container">
        <div className="my-projects-intern-operation">
          <div className="row clear">
            <div className="col-sm-4" style={style}>
              {project.inOperation ? dict.t("Projeto em operação") : dict.t("Projeto")}
            </div>

            {this.download}
          </div>
        </div>
      </div>
    );
  }

  handleDownloadClick = () => {
    if (!this.props.project.document) return;

    const role = Authentication.getUser().personal.role;

    if (role === "supporterUser") {
      const p = this.props.project;
      const url = `/${backendUserRoutes(Authentication.getUser().personal.role)}document/${p._id}`;
      Request.get(url, () => {
        forceDownload(p.document);
      });
    } else downloadFile(this.props.project.document);
  };

  handleDownloadClick2 = () => {
    if (!this.props.project.document2) return;

    const role = Authentication.getUser().personal.role;

    if (role === "supporterUser") {
      const p = this.props.project;
      const url = `/${backendUserRoutes(Authentication.getUser().personal.role)}document/${p._id}`;
      Request.get(url, () => {
        forceDownload(p.document2);
      });
    } else downloadFile(this.props.project.document2);
  };

  get download() {
    if (!this.props.project.document) return null;

    let className = "col-sm-4 text-right";
    let style = {};

    const { screenWidth } = this.props;

    if (screenWidth < 768) {
      className = "col-sm-4";
      style = { fontSize: "20px", textAlign: "center" };
    }

    return (
      <Fragment>
        <div className="col-sm-4">
          <div
            style={{ color: "#00522C", textDecoration: "none" }}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            {`${dict.t("Formulário do Projeto")}`}
            <img
              className="my-projects-intern-operation-download"
              src="https://vbio.blob.core.windows.net/public/assets/img/download.png"
              alt="Download"
              title="Download"
              onClick={() => this.handleDownloadClick()}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        <div className={className}>
          <div style={{ color: "#00522C", textDecoration: "none" }} style={style}>
            {`${dict.t("Orçamento e Cronograma")}`}
            <img
              className="my-projects-intern-operation-download"
              src="https://vbio.blob.core.windows.net/public/assets/img/download.png"
              alt="Download"
              title="Download"
              onClick={() => this.handleDownloadClick2()}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

class Titles extends Component {
  render() {
    return (
      <div id="project-intern-titles" className="container">
        <h2>{this.props.project[language].shortName}</h2>
        <h4>{this.props.project[language].name}</h4>
      </div>
    );
  }
}

class Goals extends Component {
  render() {
    let { project, screenWidth } = this.props;

    let labelFontSize = "17px";

    const configs = {
      containerMarginTop: screenWidth > 480 ? "60px" : "130px",
      iconHeight: "30px",
      labelFontSize,
      labelMargin: "10px",
      locationMarginBottom: "10px",
      progressHeight: "15px",
      progressMarkerHeight: "31px",
      progressMarkerWidth: "2px",
      progressMarkerFontSize: "14px",
      progressMarginTop: "50px",
      textFontSize: "12px",
    };

    const supportedPercent = 100 * (project.supportedValue / project.totalValue);
    const targetPercent = 100 * (project.minimumValue / project.totalValue);

    let valueStyle = { float: "left", fontSize: configs.textFontSize };
    let dateStyle = { float: "right", fontSize: configs.textFontSize };

    if (screenWidth <= 650) {
      valueStyle.width = "100%";
      dateStyle.float = "none";
    }

    return (
      <div id="project-intern-goals" style={{ marginTop: configs.containerMarginTop }}>
        <div className="container">
          <div className="vitrine-main-project-details-container col-sm-12">
            <div className="row">
              <div
                className="vitrine-main-project-location"
                style={{ marginBottom: configs.locationMarginBottom }}
              >
                <img
                  className="vitrine-main-project-details-icon"
                  src="https://vbio.blob.core.windows.net/public/assets/img/location-icon.png"
                  alt={project.state}
                  title={project.state}
                  style={{ height: configs.iconHeight }}
                />
                <div
                  className="vitrine-main-project-name vitrine-main-project-details-text"
                  style={{ fontSize: configs.labelFontSize, marginLeft: configs.labelMargin }}
                >
                  {`${project.city} - ${project.state}`}
                </div>
              </div>

              <div className="vitrine-main-project-details-biomes-container">
                {this.composeBiomes(
                  project.biomes,
                  configs.iconHeight,
                  configs.labelFontSize,
                  configs.labelMargin
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: configs.progressMarginTop }}>
              <ProgressBar
                height={configs.progressHeight}
                markerHeight={configs.progressMarkerHeight}
                markerWidth={configs.progressMarkerWidth}
                markerFontSize={configs.progressMarkerFontSize}
                value={supportedPercent}
                markerLabel={dict.t("meta mínima")}
                markerPos={targetPercent.toFixed(0)}
              />
            </div>

            <div className="row" style={{ marginTop: "16px" }}>
              <Fragment>
                <div className="vitrine-main-project-details-small-text" style={valueStyle}>
                  {`${formatCurrency(project.supportedValue)} ${dict.t("de")} ${formatCurrency(
                    project.totalValue
                  )}`}
                </div>

                <div className="vitrine-main-project-details-small-text" style={dateStyle}>
                  {`${dict.t("prazo")}: ${project.endDate}`}
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }

  composeBiomes(biomes, iconHeight, labelFontSize, labelMargin) {
    if (!biomes || !biomes.length) return null;

    return biomes.map((biome, index) => {
      const label = biomesArray.find(item => item.name === biome).label;

      return (
        <div className="vitrine-main-project-biome" key={biome}>
          <img
            className="vitrine-main-project-details-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/biome-icon.png"
            alt={label}
            title={label}
            style={{ height: iconHeight }}
          />
          <div
            className="vitrine-main-project-name vitrine-main-project-details-text"
            style={{ fontSize: labelFontSize, display: "inline-block" }}
          >
            {label}
          </div>
        </div>
      );
    });
  }
}
