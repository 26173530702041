
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cadastro de projeto
//


import React, { Component, Fragment } from "react";

import Loader from "../../Loader";

import WaitUploadModal from "../../Commons/WaitUploadModal";

import TabProgress from "../../Commons/TabProgress";

import ContentBasic from "./ContentBasic";
import ContentClassification from "./ContentClassification";
import ContentDetails from "./ContentDetails";
import ContentGratification from "./ContentGratification";
import ContentSubmission from "./ContentSubmission";

import ButtonBar from "./ButtonBar";

import Authentication from "../../../lib/Authentication";
import Request from "../../../lib/BackendRequest";
import Alert from "../../../lib/Alert";

import backendUserRoutes from "../../../constants/backendUserRoutes";

import fileUpload from "../../../lib/fileUpload";

import { formatCurrency, formatOnlyNumbers } from "../../../lib/FormatString";

import projectFields from "../../../constants/projectFields";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



const progressItems = [
  { label: dict.t("Básico"), value: "basic" },
  { label: dict.t("Classificação"), value: "classification" },
  { label: dict.t("Detalhes"), value: "details" },
  { label: dict.t("Gratificações"), value: "gratifications" },
  { label: dict.t("Submissão"), value: "submission" },
];

const formRefs = [
  "new-project-basic-form",
  "new-project-classification-form",
  "new-project-details-form",
  "new-project-gratification",
];

const newProject = {
  PT: { expectedIndicators: [] },
  EN: { expectedIndicators: [] },
  biomes: [],
  legalFitness: [],
  areasOfInterest: [],
  ODS: [],
  images: [],
  gratifications: [],
  status: "notSubmited",
  readProponentManual: false,

  // informações de andamento do formulário
  tab: 0,
  tabMax: 0,
};



export default class NewProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectId: null,
      ready: false,
      tab: 0,
      status: new Array(progressItems.length).fill(""),
      submitStatus: false,
      uploadingPhoto: false,
      uploadingDoc: false,
    };

    this.tab = 0;
    this.tabMax = 0;
  }

  componentWillMount() {
    const id = this.props.router.params.id || localStorage.getItem("newProjectId");
    const role = Authentication.getUser().personal.role;
    const url = `/${backendUserRoutes(role)}projects/exists/${id}`;

    if (id) {
      Request.get(url, values => {
        if (id && values.exists) {
          // edit mode
          this.setState({ mode: "edit" });
          this.fetchProject(id);
        } // create mode
        else {
          localStorage.removeItem("newProjectId");
          this.setState({ mode: "create" });
          this.createLocalProject(id);
        }
      });
    } else {
      localStorage.removeItem("newProjectId");
      this.setState({ mode: "create" });
      this.createLocalProject(id);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("newProjectId");
  }

  render() {
    if (!this.state.ready) return <Loader />;

    return (
      <Fragment>
        <div className="container footer-margin-logged">
          <div className="page-title new-project-title">{dict.t("Novo Projeto")}</div>

          <TabProgress
            items={progressItems}
            status={this.state.status}
            selectedItem={this.state.tab}
            onClick={this.handleTabClick}
          />

          {this.getContent()}

          <ButtonBar
            first={this.state.tab === 0}
            last={this.state.tab === progressItems.length - 1}
            onPrevClick={this.handlePrevClick}
            onNextClick={this.handleNextClick}
            onSaveClick={this.handleSaveClick}
            onSubmitClick={this.handleSubmitClick}
          />
        </div>

        <WaitUploadModal
          open={this.state.uploadingPhoto || this.state.uploadingDoc}
          message={dict.t("Enviando arquivos, aguarde...")}
        />
      </Fragment>
    );
  }

  getContent() {
    const { basic, classification, details, gratifications } = this.state.formData || {};

    switch (this.state.tab) {
      case 0:
        return (
          <ContentBasic
            defaultValues={basic ? basic : {}}
            ref={formRefs[0]}
            onSubmit={this.handleBasicFormSubmit}
          />
        );
      case 1:
        return (
          <ContentClassification
            defaultValues={classification ? classification : {}}
            ref={formRefs[1]}
            onSubmit={this.handleClassificationFormSubmit}
          />
        );
      case 2:
        return (
          <ContentDetails
            defaultValues={details ? details : {}}
            ref={formRefs[2]}
            onSubmit={this.handleDetailsFormSubmit}
          />
        );
      case 3:
        return (
          <ContentGratification
            defaultValues={gratifications ? gratifications : {}}
            ref={formRefs[3]}
            onSubmit={this.handleGratificationFormSubmit}
          />
        );
      case 4:
        return (
          <ContentSubmission
            status={this.state.status}
            values={this.state.formData}
            onStatusChange={this.handleSubmitStatusChange}
          />
        );
      default:
        return "";
    }
  }

  createLocalProject(id) {
    const project = id ? { ...newProject, id } : newProject;

    const formData = this.formatFormData(project);
    this.setState({ formData, ready: true, projectId: id });
  }

  fetchProject(id) {
    let url;
    const personalUserData = Authentication.getUser().personal;

    if (personalUserData.role === "proponentUser") url = `/proponent/projects/${id}`;
    //url = `/proponent/projects/temp/${personalUserData.companyId}`;
    else if (personalUserData.role === "master") url = `/master/projects/${id}`;

    Request.get(url, values => {
      const formData = this.formatFormData(values);

      this.setState({
        formData,
        ready: true,
        projectId: id,

        tab: values.tab || 0,
      });

      this.tabMax = values.tabMax || 0;
      this.checkRequierements();
    });
  }

  formatFormData(data) {
    let formData = {};

    // básico
    formData.basic = {};

    formData.basic.proponentManual = { value: data.readProponentManual };
    formData.basic.name = data[language].name;
    formData.basic.shortName = data[language].shortName;
    formData.basic.city = data.city;
    formData.basic.state = data.state;

    formData.basic.biomes = {};
    data.biomes.forEach(
      item => (formData.basic.biomes[`${item}`] = { name: `biomes.${item}`, value: true })
    );

    //classificação
    formData.classification = {};

    formData.classification.legalFitness = {};
    data.legalFitness.forEach(
      item =>
        (formData.classification.legalFitness[item] = { name: `legalFitness.${item}`, value: true })
    );

    formData.classification.areasOfInterest = {};
    data.areasOfInterest.forEach(
      item =>
        (formData.classification.areasOfInterest[item._id] = {
          name: `areasOfInterest.areaOfInterest${item._id}`,
          value: true,
        })
    );

    formData.classification.ods = {};
    data.ODS.forEach(
      item => (formData.classification.ods[item] = { name: `ods.ods-${item}`, value: true })
    );

    // detalhes
    formData.details = {};

    formData.details.summary = data[language].summary;
    formData.details.description = data[language].description;

    formData.details.expectedIndicators = [];
    data[language].expectedIndicators.forEach(item =>
      formData.details.expectedIndicators.push(item)
    );

    formData.details.document = data.document;
    formData.details.document2 = data.document2;

    formData.details.images = [];
    data.images.forEach(item => formData.details.images.push(item));

    formData.details.totalValue = formatCurrency(data.totalValue);
    formData.details.numOfShares = data.numOfShares;
    formData.details.minimumValue = formatCurrency(data.minimumValue);
    formData.details.inOperation = data.inOperation;
    formData.details.startDate = data.startDate;
    formData.details.endDate = data.endDate;

    // gratificações
    formData.gratifications = {};

    formData.gratifications.gratifications = [];
    data.gratifications.forEach(item => formData.gratifications.gratifications.push(item));

    return formData;
  }

  formatBackendData(data) {
    let project = {};
    project[language] = {};

    // básico
    if (data.basic) {
      project.readProponentManual = data.basic.proponentManual.value;
      project[language].name = data.basic.name;
      project[language].shortName = data.basic.shortName;
      project.city = data.basic.city;
      project.state = data.basic.state;
      project.biomes = [];
      for (let biome in data.basic.biomes) {
        if (data.basic.biomes[biome].value) project.biomes.push(biome);
      }
    }

    // classificação
    if (data.classification) {
      project.legalFitness = [];
      for (let item in data.classification.legalFitness) {
        if (data.classification.legalFitness[item].value) project.legalFitness.push(item);
      }

      project.areasOfInterest = [];
      for (let item in data.classification.areasOfInterest) {
        if (data.classification.areasOfInterest[item].value) project.areasOfInterest.push(item);
      }

      project.ODS = [];
      for (let item in data.classification.ods) {
        if (data.classification.ods[item].value) project.ODS.push(item);
      }
    }

    // detalhes
    if (data.details) {
      project[language].summary = data.details.summary;
      project[language].description = data.details.description;

      project[language].expectedIndicators = [];
      for (let item in data.details.expectedIndicators) {
        project[language].expectedIndicators.push(data.details.expectedIndicators[item]);
      }

      project.document = data.details.document;
      project.document2 = data.details.document2;

      project.images = [];
      for (let item in data.details.images) {
        const image = data.details.images[item];
        if (image) project.images.push(data.details.images[item]);
      }

      project.totalValue = formatOnlyNumbers(data.details.totalValue) / 100;
      project.numOfShares = data.details.numOfShares;
      project.minimumValue = formatOnlyNumbers(data.details.minimumValue) / 100;
      project.inOperation = data.details.inOperation;
      project.startDate = data.details.startDate;
      project.endDate = data.details.endDate;
    }

    // gratificações
    if (data.gratifications) {
      project.gratifications = [];
      for (let item in data.gratifications.gratifications) {
        project.gratifications.push(data.gratifications.gratifications[item]);
      }
    }

    return project;
  }

  submitCurrentForm() {
    if (!formRefs[this.state.tab]) return;
    this.refs[formRefs[this.state.tab]].submit();
  }

  updateFormData(form, values) {
    let formData = this.state.formData;
    formData[form] = values;
    this.setState({ formData });

    this.save();
    this.checkRequierements();
  }

  async save() {
    let { formData } = this.state;

    const images = await this.savePhotos();

    if (images) formData.details.images = images;

    const doc = await this.saveDoc(this.state.formData.details.document);

    if (doc) formData.details.document = doc;

    const doc2 = await this.saveDoc(this.state.formData.details.document2);

    if (doc2) formData.details.document2 = doc2;

    this.setState({ formData });

    this.updateRecord();
  }

  savePhotos() {
    return new Promise(resolve => {
      let images = this.state.formData.details.images
        .map((file, index) => {
          return { file, index };
        })
        .filter(image => typeof image.file === "object" && image.file);

      if (images.length > 0) {
        this.setState({ uploadingPhoto: true });

        fileUpload(images.map(items => items.file)).then(res => {
          const urls = res.map(item => item.url);

          let { images: newImages } = this.state.formData.details;

          urls.forEach((url, index) => {
            const destIndex = images[index].index;
            newImages[destIndex] = url;
          });

          this.setState({ uploadingPhoto: false });

          resolve(newImages);
        });
      } else resolve();
    });
  }

  saveDoc(document) {
    return new Promise(resolve => {
      if (document && typeof document === "object" && document.length > 0) {
        this.setState({ uploadingDoc: true });

        fileUpload(document).then(res => {
          const url = res[0].url;

          this.setState({ uploadingDoc: false });

          resolve(url);
        });
      } else resolve();
    });
  }

  updateRecord(submit = false) {
    let url;
    let msg;

    const personalUserData = Authentication.getUser().personal;
    const project = this.formatBackendData(this.state.formData);

    if (personalUserData.role === "proponentUser") {
      const proponent = personalUserData.companyId;

      project.proponent = proponent;
      project.proponentUser = personalUserData.id;

      if (submit) project.status = "pending";

      const id = this.props.router.params.id || localStorage.getItem("newProjectId");

      if (id) url = `/proponent/projects/${this.state.projectId}`;
      else url = "/proponent/projects";

      msg = dict.t("Muito obrigado!") + "\n";
      msg +=
        dict.t("Seu projeto será analisado por nossa equipe em até 03 (três) dias úteis.") + "\n";
      msg += dict.t(
        "Entraremos em contato em breve. Caso queira entrar falar conosco, ligue para +55 (51) 4042.0039 ou envie um e-mail para projetos@vbio.eco."
      );
    } else if (personalUserData.role === "master") {
      url = `/master/projects/${this.props.router.params.id}`;

      if (submit) project.status = "pending";

      msg = dict.t("Projeto submetido com sucesso");
    }

    project.tab = this.tab;
    project.tabMax = this.tabMax;

    Request.post(url, project, res => {
      if (res.success) {
        if (submit) {
          localStorage.setItem("newProjectId", "");
          Alert.success(msg, () => this.props.router.goBack());
        } else {
          if (!this.state.projectId) {
            this.setState({ projectId: res.project._id });

            localStorage.setItem("newProjectId", res.project._id);
          }
        }
      }
    });
  }

  checkRequierements() {
    let statusGroup = [];

    progressItems.forEach((group, index) => {
      if (group.value === "submission") return;

      if (index <= this.tabMax) {
        let status = "ok";

        projectFields[group.value].some(field => {
          let value = this.checkFieldlValue(this.state.formData[group.value][field.name]);

          // verifica se o campo não é requerido
          if (field.required === false) {
          } else {
            // varifica se campo possui valor requerido
            if (field.requiredValue) {
              if (value !== field.requiredValue) {
                status = "error";
                return true;
              }
            }

            // verifica se campo possui qualquer valor
            if (!value) {
              if (field.required) {
                status = "error";
                return true;
              }

              status = "warning";
            }
          }

          return false;
        });

        statusGroup.push(status);
      } else statusGroup.push("");
    });

    if (this.tabMax >= 4) {
      let mainStatus = "ok";
      statusGroup.forEach(status => {
        if (status === "warning" && mainStatus === "ok") mainStatus = "warning";
        if (status === "error") mainStatus = "error";
      });

      statusGroup.push(mainStatus);
    }

    this.setState({ status: statusGroup });
  }

  checkFieldlValue(field) {
    if (!field) return false;

    switch (typeof field) {
      case "object":
        if (field.value) {
          return field.value;
        } else {
          for (let item in field) {
            if (field[item]) {
              // campos com valor na propriedade "value"
              if (field[item].value) return true;

              // campos com valor em forma de string ou número
              if (typeof field[item] === "string" || typeof field[item] === "number")
                return field[item];

              // campos com objetos
              if (typeof field[item] === "object") {
                for (let subItem in field[item]) {
                  if (!field[item][subItem]) return false;
                }

                return true;
              }
            }
          }

          return false;
        }

      default:
        return field;
    }
  }

  removeFalseItemsFromArray(array) {
    for (let item in array) {
      if (!array[item].value) delete array[item];
    }

    return array;
  }

  handleTabClick = index => {
    let { tab } = this.state;
    tab = index;
    this.tabMax = tab > this.tabMax ? tab : this.tabMax;
    this.tab = tab;

    this.submitCurrentForm();
    this.setState({ tab });
  };

  handlePrevClick = () => {
    let { tab } = this.state;
    tab -= 1;
    this.tab = tab;

    this.submitCurrentForm();
    this.setState({ tab });

    window.scrollTo(0, 0);
  };

  handleNextClick = () => {
    let { tab } = this.state;
    tab += 1;
    this.tabMax = tab > this.tabMax ? tab : this.tabMax;
    this.tab = tab;

    this.submitCurrentForm();
    this.setState({ tab });

    window.scrollTo(0, 0);
  };

  handleSaveClick = () => {
    this.submitCurrentForm();
  };

  handleSubmitClick = () => {
    if (this.state.submitStatus) {
      Alert.confirm(
        dict.t("Seu projeto está prestes a ser submetido. Você está certo disso?"),
        yes => {
          if (yes) this.updateRecord(true);
        }
      );
    } else {
      Alert.error(dict.t("Campos obrigatórios não preenchidos. Por favor, revise seu projeto."));
    }
  };

  handleSubmitStatusChange = value => {
    this.setState({ submitStatus: value });
  };

  handleBasicFormSubmit = values => {
    values.biomes = this.removeFalseItemsFromArray(values.biomes);

    this.updateFormData("basic", values);
  };

  handleClassificationFormSubmit = values => {
    values.legalFitness = this.removeFalseItemsFromArray(values.legalFitness);
    values.areasOfInterest = this.removeFalseItemsFromArray(values.areasOfInterest);
    values.ods = this.removeFalseItemsFromArray(values.ods);

    this.updateFormData("classification", values);
  };

  handleDetailsFormSubmit = values => {
    console.log(values);

    if (!values.document || values.document.length === 0)
      values.document = this.state.formData.details.document;

    if (!values.document2 || values.document2.length === 0)
      values.document2 = this.state.formData.details.document2;

    values.images = values.images.map(image => {
      if (!image || image.length === 0) {
        return undefined;
      }

      return image;
    });

    this.updateFormData("details", values);
  };

  handleGratificationFormSubmit = values => {
    this.updateFormData("gratifications", values);
  };
}


