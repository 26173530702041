import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
  form: formReducer,
});

const reduxStore = configureStore({
  reducer: rootReducer,
});

export default reduxStore;
