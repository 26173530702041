
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Tooltip dos badges
//


import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./style.css";



export default class Tooltip extends Component {
  componentWillReceiveProps(newProps) {
    this.setState({ position: newProps.position });
  }

  componentDidUpdate() {
    const element = ReactDOM.findDOMNode(this.refs["badgestack-tooltip-container"]);

    if (element) {
      const bounds = element.getBoundingClientRect();

      if (!bounds.width && !bounds.height) return;

      const overflowX = Math.ceil(bounds.right - window.innerWidth);

      if (overflowX > 0) {
        let { position } = this.state;

        position.left -= overflowX;

        this.setState({ position });
      }

      const overflowY = Math.ceil(bounds.bottom - window.innerHeight);

      if (overflowY > 0) {
        let { position } = this.state;

        position.top -= overflowY;

        this.setState({ position });
      }
    }
  }

  render() {
    if (!this.state) return null;

    const { position } = this.state;
    const { show, items } = this.props;

    let style = show ? { display: "block" } : { display: "none" };
    style.top = `${position.top}px`;
    style.left = `${position.left}px`;

    return (
      <div
        className="badgestack-tooltip-container"
        ref="badgestack-tooltip-container"
        style={style}
      >
        {this.renderItems(items)}
      </div>
    );
  }

  renderItems(items) {
    if (!items || items.length === 0) return null;

    return items.map((item, index) => {
      return (
        <div key={index} className="badgestack-tooltip-item">
          {item}
        </div>
      );
    });
  }
}


