
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Blog - lista de artigo
//


import React from "react";

import socialLinks from "../../constants/socialLinks";

import "./style.css";



export default function(props) {
  return (
    <div className="blog-post-social-bar">
      <a target="_blank" href={socialLinks.facebook}>
        <img
          className="blog-post-social-bar-item"
          src="https://vbio.blob.core.windows.net/public/assets/img/facebook-green.png"
          alt="Facebook"
          title="Facebook"
        />
      </a>

      <a target="_blank" href={socialLinks.linkedin}>
        <img
          className="blog-post-social-bar-item"
          src="https://vbio.blob.core.windows.net/public/assets/img/linkedin-green.png"
          alt="Linked-In"
          title="Linked-In"
        />
      </a>

      <a target="_blank" href={socialLinks.twitter}>
        <img
          className="blog-post-social-bar-item"
          src="https://vbio.blob.core.windows.net/public/assets/img/twitter-green.png"
          alt="Twitter"
          title="Twitter"
        />
      </a>

      <a target="_blank" href={socialLinks.instagram}>
        <img
          className="blog-post-social-bar-item"
          src="https://vbio.blob.core.windows.net/public/assets/img/instagram-green.png"
          alt="Instagram"
          title="Instagram"
        />
      </a>

      <a target="_blank" href={socialLinks.youtube}>
        <img
          className="blog-post-social-bar-item"
          src="https://vbio.blob.core.windows.net/public/assets/img/youtube-green.png"
          alt="Youtube"
          title="Youtube"
        />
      </a>
    </div>
  );
}


