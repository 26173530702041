
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Fortmatador de moeda
//


const format = value => {
  if (!value) return value;

  value = value.toFixed(0);

  let onlyNums = value.replace(/[^\d]/g, "");
  onlyNums = onlyNums.replace(/^0+/, "");

  const length = onlyNums.length;

  if (length === 0) return ""; //'R$0,00';

  if (length <= 3) return "R$" + onlyNums;

  if (length <= 6) return "R$" + onlyNums.slice(0, length - 3) + "." + onlyNums.slice(length - 3);

  if (length <= 9)
    return (
      "R$" +
      onlyNums.slice(0, length - 6) +
      "." +
      onlyNums.slice(length - 6, length - 3) +
      "." +
      onlyNums.slice(length - 3)
    );

  if (length <= 12)
    return (
      "R$" +
      onlyNums.slice(0, length - 9) +
      "." +
      onlyNums.slice(length - 9, length - 6) +
      "." +
      onlyNums.slice(length - 6, length - 3) +
      "." +
      onlyNums.slice(length - 3)
    );

  return (
    "R$" +
    onlyNums.slice(0, length - 9) +
    "." +
    onlyNums.slice(length - 9, length - 6) +
    "." +
    onlyNums.slice(length - 6, length - 3) +
    "." +
    onlyNums.slice(length - 3)
  );
};



export default format;


