/*
 * Library that helps with Server Requests (AJAX and XHR)
 */

import Alert from "./Alert";
import Authentication from "./Authentication";

export default {
  /*
   * Default JSON Headers
   */
  JSONHeaders: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  /*
   * Constants regarding the statuses returned by web server
   */
  WebStatus: {
    Success: 200,
    Unauthorized: 401,
    NotFound: 404,
    ServerError: 500,
  },

  /*
   *
   */
  ValidationErrors: {
    Unauthorized: "fetch-val-un",
    NotFound: "Route not found.",
  },

  /*
   * Perform a 'GET' call operation sending the 'data' to the following 'url' in a SYNC way
   * @param url string
   * @param callback function
   * @return void
   */
  get(url, callback = {}, headers = this.JSONHeaders) {
    this.sync(url, "GET", undefined, callback, headers);
  },

  /*
   * Perform a 'GET' call operation sending the 'data' to the following 'url' in ASYNC way
   * @param url string
   * @param callback function
   * @return void
   */
  asyncGet(url, callback = {}, headers = this.JSONHeaders) {
    this.async(url, "GET", undefined, callback, headers);
  },

  /*
   * Perform a 'POST' call operation sending the 'data' to the following 'url' in a SYNC way
   * @param url string
   * @param dataObject object
   * @param callback function
   * @return void
   */
  post(url, dataObject = undefined, callback = {}, headers = this.JSONHeaders) {
    this.sync(url, "POST", dataObject, callback, headers);
  },

  /*
   * Perform a 'POST' call operation sending the 'data' to the following 'url' in ASYNC way
   * @param url string
   * @param dataObject object
   * @param callback function
   * @return void
   */
  asyncPost(url, dataObject = undefined, callback = {}, headers = this.JSONHeaders) {
    this.async(url, "POST", dataObject, callback, headers);
  },

  /*
   * Perform a 'methodType' call operation sending the 'data' to the following 'url' in a SYNC way
   * @param url string
   * @param methodType POST|GET|OPTIONS|DELETE
   * @param dataObject object
   * @param callback function
   * @return void
   */
  sync(url, methodType, dataObject = undefined, callback = {}, headers = this.JSONHeaders) {
    this.call(url, methodType, dataObject, callback, headers);
  },

  /*
   * Perform a 'methodType' call operation sending the 'data' to the following 'url' in ASYNC way
   * @param url string
   * @param methodType POST|GET|OPTIONS|DELETE
   * @param dataObject object
   * @param callback function
   * @return void
   */
  async(url, methodType, dataObject = undefined, callback = {}, headers = this.JSONHeaders) {
    this.call(url, methodType, dataObject, callback, headers);
  },

  /*
   * Perform a 'methodType' call operation sending the 'data' to the following 'url'
   * @param url string
   * @param methodType POST|GET|OPTIONS|DELETE
   * @param dataObject object
   * @param callback function
   * @return void
   */
  call(url, methodType, dataObject = undefined, callback = {}, headers) {
    let callBody = dataObject != undefined ? JSON.stringify(dataObject) : undefined;

    fetch(url, {
      body: callBody,
      headers: headers,
      method: methodType,
    })
      .then(res => {
        // Status=401
        if (res.status === this.WebStatus.Unauthorized) throw this.ValidationErrors.Unauthorized;

        // Status=404
        if (res.status === this.WebStatus.NotFound) throw this.ValidationErrors.NotFound;

        // Status=500
        if (res.status === this.WebStatus.ServerError) throw res.json();

        // Otherwise, just return back to the pipe
        return res;
      })
      .then(res => res.json())
      .then(data => callback(data))
      .catch(res => {
        Promise.resolve(res).then(obj => {
          //Here we must handle the errors differently
          console.log(obj);

          //Is it an unauthorized event?
          if (obj === this.ValidationErrors.Unauthorized) Authentication.tokenExpiredAction();
          //otherwise, it's error 500, and we will show the error to user
          else Alert.error(obj.message, () => {}, { title: "Erro ao processar requisição" });
        });
      });
  },
};
