
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cadastro de projeto
//


import React, { Component } from "react";

import ScreenSize from "../ScreenSize";

import "./style.css";



const smallScreenWidth = 992;



export default class TabProgress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      smallScreen: false,
    };
  }

  render() {
    return (
      <div className="tabprogress-container">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        {this.composeItems(this.props.items, this.props.selectedItem)}
      </div>
    );
  }

  composeItems(items, selectedItem) {
    if (!items) return "";

    let itemWidth = 100 / items.length;
    itemWidth += "%";

    return items.map((item, index) => {
      let itemClass = "tabprogress-item-default";
      let itemIcon;

      switch (this.props.status[index]) {
        case "ok":
          itemClass = "tabprogress-item-ok";
          itemIcon = "https://vbio.blob.core.windows.net/public/assets/img/tab-progress/ok-icon.png";
          break;

        case "warning":
          itemClass = "tabprogress-item-warning";
          itemIcon = "https://vbio.blob.core.windows.net/public/assets/img/tab-progress/warning-icon.png";
          break;

        case "error":
          itemClass = "tabprogress-item-error";
          itemIcon = "https://vbio.blob.core.windows.net/public/assets/img/tab-progress/error-icon.png";
          break;

        default:
          break;
      }

      if (selectedItem === index) {
        itemClass = "tabprogress-item-selected";
        itemIcon = null;
      }

      let style;

      if (this.state.smallScreen) {
        style = { width: "100%" };
      } else {
        let itemWidth = 100 / items.length;
        itemWidth += "%";
        style = { width: itemWidth };
      }

      return (
        <div
          key={index}
          className={`tabprogress-item-base ${itemClass}`}
          style={style}
          onClick={() => this.props.onClick(index)}
        >
          {itemIcon ? (
            <img className="tabprogress-item-icon" src={itemIcon} alt={item.label} />
          ) : (
            ""
          )}
          {item.label}
        </div>
      );
    });
  }

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}


