import React, { Component } from "react";

export default class Input extends Component {
  constructor(props) {
    super(props);

    this.state = { checked: this.props.input.value.value || this.props.input.value || false };
  }

  render() {
    let css = this.props.cssClass + (this.state.checked ? ` ${this.props.cssClass}-checked` : "");
    css += this.hasError() ? ` ${this.props.cssClassError}` : "";

    let style = { height: this.props.height };
    if (this.state.checked) style.background = this.props.checkedColor;
    style = Object.assign(style, this.props.componentStyle);

    return (
      <div className="form-group" style={{ marginBottom: "0" }}>
        <div className={css} style={style}>
          <input type="checkbox" checked={this.state.checked} onChange={this.handleChange} />

          {this.props.icon ? (
            <img src={this.props.icon} alt={this.props.label} style={this.props.iconStyle} />
          ) : (
            ""
          )}

          <div className="form-input-label" style={this.props.labelStyle}>
            {this.props.label}
          </div>
        </div>
      </div>
    );
  }

  handleChange = e => {
    const checked = e.target.checked;

    this.setState({ checked });

    if (this.props.input.onChange)
      this.props.input.onChange({ name: this.props.input.name, value: checked });
  };

  hasError() {
    return this.props.meta.touched && this.props.meta.error;
  }

  getErrorMessage() {
    let errorMessage = null;

    //validate if the current fields has any error
    if (this.hasError()) {
      let errorContent = this.props.meta.error;

      if (this.props.errorMessage !== undefined && this.props.errorMessage[errorContent])
        errorMessage = this.props.errorMessage[errorContent];
      else errorMessage = errorContent;
    }

    return errorMessage;
  }
}
