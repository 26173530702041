/*
 * Library that helps with Server Requests specifically to the BACKEND server (AJAX and XHR)
 */

import Authentication from "./Authentication";
import Request from "./Request";
import config from "../config";

/*
 * Perform a 'methodType' call operation sending the 'data' to the following BACKEND 'url' in a SYNC way
 * @param url string
 * @param methodType POST|GET|OPTIONS|DELETE
 * @param dataObject object
 * @param callback function
 * @return void
 */
function _handleRequest(
  url,
  methodType,
  dataObject = undefined,
  callback = {},
  headers = Request.JSONHeaders
) {
  headers = {
    ...headers,
    Authorization: `Bearer ${Authentication.getToken()}`,
  };

  Request.call(config.backend_url + url, methodType, dataObject, callback, headers);
}

export default {
  /*
   * Perform a 'GET' call operation sending the 'data' to the following BACKEND 'url' in a SYNC way
   * @param url string
   * @param callback function
   * @return void
   */
  get(url, callback = {}, headers = Request.JSONHeaders) {
    _handleRequest(url, "GET", undefined, callback);
  },

  /*
   * Perform a 'POST' call operation sending the 'data' to the following BACKEND 'url' in a SYNC way
   * @param url string
   * @param dataObject object
   * @param callback function
   * @return void
   */
  post(url, dataObject = undefined, callback = {}, headers = Request.JSONHeaders) {
    _handleRequest(url, "POST", dataObject, callback, headers);
  },

  /*
   * Perform a 'PUT' call operation sending the 'data' to the following BACKEND 'url' in a SYNC way
   * @param url string
   * @param dataObject object
   * @param callback function
   * @return void
   */
  put(url, dataObject = undefined, callback = {}, headers = Request.JSONHeaders) {
    _handleRequest(url, "PUT", dataObject, callback, headers);
  },

  /*
   * Perform a 'DELETE' call operation sending the 'data' to the following BACKEND 'url' in a SYNC way
   * @param url string
   * @param dataObject object
   * @param callback function
   * @return void
   */
  delete(url, dataObject = undefined, callback = {}, headers = Request.JSONHeaders) {
    _handleRequest(url, "DELETE", dataObject, callback, headers);
  },

  objToQueryParams(obj) {
    const keyValuePairs = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key))
        keyValuePairs.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    }

    return keyValuePairs.join("&");
  },
};
