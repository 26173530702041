
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Corpo da lista de documentos do proponente
//


import React, { Fragment } from "react";
import documentStatus from "../../../../constants/proponentDocumentStatus";

import multiLang from "../../../../lib/multilang/multiLang";
import masterAccess from "../../../../lib/masterAccess";



const dict = new multiLang(multiLang.getLanguage());



function renderChangeStatus(props) {
  return (
    <Fragment>
      {masterAccess("proponentApprove") ? (
        <Fragment>
          <div
            className="text-button"
            style={{ marginRight: "15px", color: "#24A862" }}
            onClick={() => props.onApprove(props.item)}
          >
            {dict.t("aprovar")}
          </div>

          <div
            className="text-button"
            style={{ color: "#B73520" }}
            onClick={() => props.onRefuse(props.item)}
          >
            {dict.t("negar")}
          </div>
        </Fragment>
      ) : (
        dict.t("Aprovação pendente")
      )}
    </Fragment>
  );
}



function renderJustification(props) {
  return (
    <Fragment>
      {masterAccess("proponentApprove") ? (
        <div
          className="proponent-documents-list-justitication-button"
          onClick={() => props.onSendJustification(props.item)}
        >
          {dict.t("enviar justificativa")}
        </div>
      ) : (
        dict.t("Negado")
      )}
    </Fragment>
  );
}



function renderStatus(props) {
  const { status } = props.item;

  switch (status) {
    case "received":
      return renderChangeStatus(props);

    case "refused":
      return renderJustification(props);

    default:
      return dict.t(documentStatus.find(item => item.name === status).label);
  }
}



export default function(props) {
  if (!props.item) return null;

  return (
    <div className="row list-zebra-item-header-text" style={{ width: "64vw" }}>
      <div className="col-md-7">{props.item.name}</div>

      <div className="col-md-4">
        <div className="list-body-actions-documents">{renderStatus(props)}</div>
      </div>
    </div>
  );
}


