
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Home
//


import React, { Component } from "react";

import Request from "../../lib/BackendRequest";

import Loader from "../Loader";
import ImageFilter from "../ImageFilter";
import StatCard from "./StatCard";

import ProjectsShowcase from "../ProjectsShowcase";
import WhyVbio from "./WhyVbio";
import Blog from "./Blog";

import Authentication from "../../lib/Authentication";

import userRoutes from "../../constants/userRoutes";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";
import "./supporter.css";

const language = multiLang.getLanguage();
const dict = new multiLang(language);

const bannerImageFilter =
  "linear-gradient(360deg, #353535 0%, rgba(79, 79, 79, 0.2) 100%, rgba(102, 102, 102, 0) 100%)";

export default class Supporter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statsReady: false,
      projectsReady: false,
      blogPosts: null,
      open: Authentication.getUser().personal.role === "supporterUser",
    };
  }

  componentWillMount() {
    this.fetchStats();
    this.fetchProjects();
    this.fetchBlogPosts();
  }

  render() {
    if (!this.state.statsReady || !this.state.projectsReady || !this.state.blogPosts)
      return <Loader />;

    const role = Authentication.getUser().personal.role;
    const extraButtonUrl = `/${userRoutes(role)}projetos`;

    return (
      <div className="footer-margin-logged">
        <div className="supporter-home-banner-container">
          <img
            className="supporter-home-banner"
            src="https://vbio.blob.core.windows.net/public/assets/img/home/supporter/banner.png"
            alt=""
          />
          <ImageFilter className="supporter-home-banner" filter={bannerImageFilter} />

          <div className="stats supporter-home-stats">{this.getStatCards()}</div>
        </div>

        <div className="container">
          <ProjectsShowcase
            detailed={true}
            projects={this.state.projects}
            extraButtons={[{ label: `${dict.t("Mais filtros")} +`, route: extraButtonUrl }]}
          />
        </div>

        <WhyVbio />

        <Blog items={this.state.blogPosts} />
      </div>
    );
  }

  getStatCards() {
    if (!this.state.stats) return "";

    return this.state.stats.map((item, index) => {
      if (!item[language]) item[language] = item.PT;

      return <StatCard key={index} value={item[language].value} text={item[language].label} />;
    });
  }

  fetchStats() {
    Request.get("/home/stats", values => {
      this.setState({ statsReady: true, stats: values });
    });
  }

  fetchProjects() {
    Request.get("/home/projects", values => {
      this.setState({ projectsReady: true, projects: values });
    });
  }

  fetchBlogPosts() {
    Request.get("/blog/posts", values => {
      this.setState({ blogPosts: values });
    });
  }
}