
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cadastro de projeto - classificação
//


import React, { Component, Fragment } from "react";
import { reduxForm, Field } from "redux-form";

import Authentication from "../../../lib/Authentication";

import Loader from "../../Loader";
import Request from "../../../lib/BackendRequest";

import CardCheckbox from "../../../lib/Forms/CardCheckbox";

import InfoIcon from "../../InfoIcon";
import legalFitness from "../../../constants/legalFitness";
import legalFitnessCheckboxStyle from "../../../constants/legalFitnessCheckboxStyle";

import ods from "../../../constants/ods";

import backendUserRoutes from "../../../constants/backendUserRoutes";

import languageTools from "../../../lib/languageTools";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



class ClassificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: {},
      areasOfInterest: null,
    };
  }

  componentWillMount() {
    this.props.initialize(this.props.defaultValues);
    this.fetchAreasOfInterest();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowSize: { width: window.innerWidth, height: window.innerHeight } });
  };

  render() {
    if (!this.state.areasOfInterest) return <Loader />;

    return (
      <Fragment>
        <div className="new-project-text">{dict.t("new-project-classification-description")}</div>

        <div className="form-body">
          <form>
            <div className="new-project-section-title">
              {dict.t("Adequação Legal")}
              <InfoIcon
                info={dict.t(
                  "Se aplicável, selecione por quais enquadramentos legais seu projeto está apto a receber recursos."
                )}
              />
            </div>

            <div className="row new-project-section-container">
              {this.getLegalFitnessFields(legalFitness)}
            </div>

            <div className="new-project-section-title">
              {dict.t("Áreas de Interesse")}
              <InfoIcon
                info={dict.t("Selecione quais destas áreas estão relacionadas ao seu projeto.")}
              />
            </div>

            <div className="row new-project-section-container">
              {this.getAreasOfInterestFields(this.state.areasOfInterest)}
            </div>

            <div className="new-project-section-title">
              {dict.t("ODS")}
              <InfoIcon
                info={dict.t(
                  "Selecione em quais Objetivos de Desenvolvimento Sustentável da ONU seu projeto se enquadra."
                )}
              />
            </div>

            <div className="row new-project-section-container">{this.getODSs(ods)}</div>
          </form>
        </div>
      </Fragment>
    );
  }

  getLegalFitnessFields(items) {
    if (!items) return "";

    return items.map((item, index) => {
      return (
        <div key={index} className="col-md-4 new-project-card-checkbox-container">
          <Field
            name={`legalFitness.${legalFitness[index].name}`}
            label={dict.t(item.label)}
            icon={item.icon}
            iconStyle={legalFitnessCheckboxStyle.iconStyle}
            labelStyle={legalFitnessCheckboxStyle.labelStyle}
            componentStyle={legalFitnessCheckboxStyle.componentStyle}
            checkedColor={"#EFA500"}
            height="47px"
            cssClass="form-input-card-checkbox"
            cssClassError="form-input-error"
            component={CardCheckbox}
          />
        </div>
      );
    });
  }

  getAreasOfInterestFields(items) {
    if (!items) return "";

    const colunms = this.getColunmsWidth(this.state.windowSize.width);

    return items.map((item, index) => {
      item[language] = languageTools.merge(item.PT, item[language]);

      return (
        <div key={index} className={`col-md-${colunms} new-project-card-checkbox-container`}>
          <Field
            name={`areasOfInterest.${item._id}`}
            label={item[language].name}
            icon={item.image}
            checkedColor={"#24A862"}
            height="50px"
            cssClass="form-input-card-checkbox"
            cssClassError="form-input-error"
            component={CardCheckbox}
          />
        </div>
      );
    });
  }

  getODSs(items) {
    if (!items) return "";

    const colunms = this.getColunmsWidth(this.state.windowSize.width);

    return items.map((item, index) => {
      return (
        <div key={index} className={`col-md-${colunms} new-project-card-checkbox-container`}>
          <Field
            name={`ods.ods-${index + 1}`}
            label={dict.t(item.label)}
            icon={item.icon[language]}
            checkedColor={item.color}
            height="50px"
            cssClass="form-input-card-checkbox"
            cssClassError="form-input-error"
            component={CardCheckbox}
          />
        </div>
      );
    });
  }

  getColunmsWidth(windowWidth) {
    if (windowWidth <= 1680) return 4;

    return 3;
  }

  fetchAreasOfInterest() {
    const role = Authentication.getUser().personal.role;

    const url = `/${backendUserRoutes(role)}projects/areas-of-interest`;

    Request.get(url, values => {
      this.setState({ areasOfInterest: values });
    });
  }
}



export default reduxForm({
  form: "newProjectClassificationForm",
})(ClassificationForm);


