
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de botões de gerenciamento de projeto - master
//


import React, { Fragment } from "react";
import { Link } from "react-router";

import Back from "../../../Commons/Back";

import Date from "../../../../lib/Date";

import multiLang from "../../../../lib/multilang/multiLang";
import masterAccess from "../../../../lib/masterAccess";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



function renderButtons(props) {
  return (
    <Fragment>
      {
        <div className="project-intern-toolbar-item">
          <Link to={props.editRoute}>
            <img
              src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon-2.png"
              alt={dict.t("Editar")}
              title={dict.t("Editar")}
            />
          </Link>

          <img
            src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon-2.png"
            alt={dict.t("Deletar")}
            title={dict.t("Deletar")}
            style={{ marginLeft: "15px" }}
            onClick={props.onDelete}
          />
        </div>
      }
    </Fragment>
  );
}



function renderPendingToolbar(props) {
  return (
    <Fragment>
      <div className="project-intern-toolbar-item">
        <button
          className="button project-intern-toolbar-button red-background"
          onClick={props.onDeny}
        >
          {dict.t("Negar")}
        </button>
      </div>

      <div className="project-intern-toolbar-item">
        <button className="button  project-intern-toolbar-button" onClick={props.onApprove}>
          {dict.t("Aprovar")}
        </button>
      </div>

      {renderButtons(props)}
    </Fragment>
  );
}



function renderInactivateToolbar(props) {
  return (
    <Fragment>
      <div className="project-intern-toolbar-item">
        <button
          className="button project-intern-toolbar-button gray-background"
          onClick={props.onInactivate}
          style={{ marginBottom: "20px" }}
        >
          {dict.t("Inativar")}
        </button>
      </div>

      {renderButtons(props)}
    </Fragment>
  );
}



function renderInactivatedToolbar(props) {
  return (
    <Fragment>
      <div className="project-intern-toolbar-item">
        <div className="project-intern-toolbar-text">
          {`${dict.t("Projeto")} `}
          <strong>{`${dict.t("inativado manualmente")} `}</strong>
          {`${dict.t("em")} ${Date.formatWithoutTime(props.inactivateDate)} ${dict.t("as")}`}
          {/* <br/> */}
          <div style={{ textAlign: "right" }}>{Date.formatTimeOnly(props.inactivateDate)}</div>
        </div>
      </div>

      {renderButtons(props)}
    </Fragment>
  );
}



export default function(props) {
  function selectToolbar(status) {
    switch (status) {
      case "pending":
        return renderPendingToolbar(props);
      case "refused":
        return renderInactivateToolbar(props);
      case "raisingFunds":
        return renderInactivateToolbar(props);
      case "inactive":
        return renderInactivatedToolbar(props);
      default:
        return renderButtons(props);
    }
  }

  return (
    <div className="container page-margin-top">
      <div className="project-intern-back-button-container">
        <Back router={props.router} />
      </div>

      <div
        className="row project-intern-toolbar-container align-right"
        style={{ marginRight: "0" }}
      >
        {masterAccess("proponentApprove") ? selectToolbar(props.status) : renderButtons(props)}
      </div>
    </div>
  );
}


