import React, { Component } from "react";

import multiLang from "../multilang/multiLang";

const dict = new multiLang(multiLang.getLanguage());

export default class Dropdown extends Component {
  render() {
    let css = this.props.cssClass;

    css += this.hasError() ? ` ${this.props.cssClassError}` : "";

    return (
      <div className="form-group">
        <div className={`form-input-label ${this.props.labelClass}`}>{this.props.label}</div>

        <div style={{ position: "relative" }}>
          <select className={"form-control " + css} {...this.props.input}>
            {this.options}
          </select>

          {this.hasError() ? <div className="form-input-error-detail" /> : null}
        </div>
      </div>
    );
  }

  get options() {
    if (!this.props.data) return [];
    if (!this.props.data.length) return [];

    let options = [];
    let data = this.props.data.map(e => e);

    //the first element is the placeholder
    let firstElement = {};

    firstElement[this.props.dataKey] = 0;
    firstElement[this.props.dataLabel] = this.props.placeholder || dict.t("selecione...");

    data.unshift(firstElement);

    if (data !== undefined) {
      data.forEach((option, index) => {
        options.push(
          <option
            key={option[this.props.dataKey]}
            value={option[this.props.dataKey]}
            hidden={index === 0 && !this.props.placeholderSelectable}
          >
            {option[this.props.dataLabel]}
          </option>
        );
      });
    }

    return options;
  }

  hasError() {
    return this.props.meta.touched && (this.props.meta.error || this.props.input.value === "0");
  }

  getErrorMessage() {
    let errorMessage = null;

    //validate if the current fields has any error
    if (this.hasError()) {
      let errorContent =
        this.props.meta.error || this.props.input.value === "0"
          ? dict.t("Campo obrigatório")
          : undefined;

      if (this.props.errorMessage !== undefined && this.props.errorMessage[errorContent])
        errorMessage = this.props.errorMessage[errorContent];
      else errorMessage = errorContent;
    }

    return errorMessage;
  }
}
