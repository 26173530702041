
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Página de projetos
//


import React, { Component } from "react";

import Request from "../../../lib/BackendRequest";

import Loader from "../../Loader";
import BackButton from "../../Commons/Back";
import SearchForm from "./SearchForm";

import ProjectsShowcase from "../../ProjectsShowcase";

import Authentication from "../../../lib/Authentication";
import backendUserRoutes from "../../../constants/backendUserRoutes";

import "./style.css";



export default class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      areasOfInterestReady: false,
      projectsReady: false,
      filter: {},
    };
  }

  componentWillMount() {
    this.fetchAreasOfInterest();
    this.fetchProjects();
  }

  render() {
    if (!this.state.projectsReady || !this.state.areasOfInterestReady) return <Loader />;
    const query = this.props.location.query;

    return (
      <div className="container vitrine-container footer-margin-logged">
        <div className="row">
          <div className="col-lg-4 col-md-6" style={{ marginTop: "37px" }}>
            <div className="projects-back-button-container">
              <BackButton router={this.props.router} />
            </div>

            <div className="card">
              <SearchForm
                projects={this.state.projects}
                areasOfInterest={this.state.areasOfInterest}
                onFilterChange={this.handleSearchFormChange}
                query={query}
              />
            </div>
          </div>

          <div className="col-lg-8 col-md-6">
            <ProjectsShowcase
              hideHeader={true}
              hideMainProject={true}
              hideSeeAllLink={true}
              showAll={true}
              numOfCols={2}
              detailed={true}
              projects={this.state.projects}
              filter={this.state.filter}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchAreasOfInterest() {
    const role = Authentication.getUser().personal.role;

    let url;

    if (role === "master") url = `/${backendUserRoutes(role)}projects/areas-of-interest`;
    else url = `/${backendUserRoutes(role)}areas-of-interest`;

    Request.get(url, values => {
      this.setState({ areasOfInterestReady: true, areasOfInterest: values });
    });
  }

  fetchProjects() {
    const role = Authentication.getUser().personal.role;
    const url = `/${backendUserRoutes(role)}projects`;

    Request.get(url, values => {
      this.setState({ projectsReady: true, projects: values });
    });
  }

  handleSearchFormChange = values => {
    this.setState({ filter: values });
  };
}


