
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Componente principal
//


import React, { Component, Fragment } from "react";

import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

import Header from "./Header";
import Footer from "./Footer";



export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
    };
  }

  componentWillReceiveProps(n, o) {
    this.updateTitle(n);
  }

  componentDidMount(n, o) {
    this.updateTitle(this.props);
  }

  updateTitle(props) {
    let title = props.children.props.route.title;

    if (title) {
      title = `${title} | VBIO App`;
      document.title = title;
      this.setState({ title });
    }
  }

  render() {
    return (
      <Fragment>
        <Header router={this.props.router} hideMenu={this.props.children.props.route.hideMenu} />

        {this.props.children}

        {!this.props.children.props.route.hideFooter ? <Footer /> : ""}

        <div>
          <ScrollUpButton
            AnimationDuration={1000}
            style={{
              backgroundColor: "rgba(86, 86, 86, 0.45)",
              fill: "#00522C",
            }}
          />
        </div>
      </Fragment>
    );
  }
}


