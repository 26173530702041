
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Marco, Régis, Diego
//
// Descrição: Formulário de busca de projetos padrão
//


import React, { Component } from "react";

import moment from "moment";

import DateRange from "../DateRange";
import Dropdown from "../Dropdown";

import SearchBar from "../SearchBar";

import biomesArray from "../../../constants/biomes";
import legalFitnessArray from "../../../constants/legalFitness";
import odsArray from "../../../constants/ods";
import statusProjectArray from "../../../constants/projectStatus";
import statusSupportArray from "../../../constants/supportStatus";

import multiLang from "../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class ProjectSearchForm extends Component {
  componentWillMount() {
    const { projects, status } = this.props;
    this.processProjects(projects, status);
  }

  render() {
    let { states, status, biomes, legalFitness, aoi, ods } = this.state;

    return (
      <div>
        {this.props.title ? (
          <div className="page-margin-top page-title" style={{ marginLeft: "-15px" }}>
            {this.props.title ? this.props.title : ""}
          </div>
        ) : null}

        <div className="container searchOptionsContainer">
          <div className="row">
            <div className="col-sm-4">
              <DateRange
                onRef={ref => (this.dateRange = ref)}
                css={{ inputClass: "dateRangeInput" }}
              />
            </div>
            <div className="col-sm-3">
              <Dropdown
                label={dict.t("Estado")}
                selectClass="searchOptionsSelect"
                options={states}
                onChange={ev => this.handleChangeFilter(ev, "state")}
              />
            </div>
            <div className="col-sm-3">
              <Dropdown
                label={dict.t("Bioma")}
                selectClass="searchOptionsSelect"
                options={biomes}
                onChange={ev => this.handleChangeFilter(ev, "biome")}
              />
            </div>
            <div className="col-sm-2">
              <Dropdown
                label="Status"
                selectClass="searchOptionsSelect"
                options={status}
                onChange={ev => this.handleChangeFilter(ev, "status")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 ">
              <Dropdown
                label={dict.t("Adequação legal")}
                selectClass="searchOptionsSelect"
                options={legalFitness}
                onChange={ev => this.handleChangeFilter(ev, "legalFitness")}
              />
            </div>
            <div className="col-sm-4">
              <Dropdown
                label={dict.t("Área de Interesse")}
                selectClass="searchOptionsSelect"
                options={aoi}
                onChange={ev => this.handleChangeFilter(ev, "aoi")}
              />
            </div>
            <div className="col-sm-4">
              <Dropdown
                label={dict.t("ODS")}
                selectClass="searchOptionsSelect"
                options={ods}
                onChange={ev => this.handleChangeFilter(ev, "ods")}
              />
            </div>
          </div>
        </div>

        <SearchBar
          button={{
            title: this.props.buttonLabel,
            onClick: this.handleSearchButtonClick,
          }}
        />
      </div>
    );
  }

  processProjects(projects, status) {
    if (status) {
      status = status.map(item => {
        return { status: item };
      });
    }

    this.setState({
      records: projects,
      recordsCache: projects.concat(),
      states: this.buildStates(projects),
      biomes: this.buildBiomes(projects),
      status: !status ? this.buildStatus(projects) : this.buildStatus(status),
      legalFitness: this.buildLegalFitness(projects),
      aoi: this.buildAOI(projects),
      ods: this.buildODS(projects),
      filters: {
        state: "all",
        biome: "all",
        status: "all",
        legalFitness: "all",
        aoi: "all",
        ods: "all",
      },
    });
  }

  buildStates = records => {
    const obj = {};
    const result = [{ title: dict.t("Todos"), value: "all" }];

    records.forEach(record => {
      if (record.state && !obj[record.state]) {
        result.push({ title: record.state, value: record.state });
        obj[record.state] = true;
      }
    });

    return result;
  };

  buildBiomes = records => {
    const obj = {};
    let result = [{ title: dict.t("Todos"), value: "all" }];

    records.forEach(record => {
      if (record.biomes) {
        record.biomes.forEach(biome => {
          if (!obj[biome]) {
            const biomeItem = biomesArray.find(item => item.name === biome);
            result.push({ title: biomeItem.label, value: biomeItem.name });
            obj[biome] = true;
          }
        });
      }
    });

    return result;
  };

  buildLegalFitness = records => {
    const obj = {};
    let result = [{ title: dict.t("Todas"), value: "all" }];

    records.forEach(record => {
      if (record.legalFitness) {
        record.legalFitness.forEach(option => {
          if (!obj[option]) {
            const legalFitnessItem = legalFitnessArray.find(item => item.name === option);
            result.push({ title: dict.t(legalFitnessItem.label), value: legalFitnessItem.name });
            obj[option] = true;
          }
        });
      }
    });

    return result;
  };

  buildAOI = records => {
    const obj = {};
    let result = [{ title: dict.t("Todas"), value: "all" }];

    records.forEach(record => {
      if (record.areasOfInterest) {
        record.areasOfInterest.forEach(option => {
          const current = option[dict.lang];

          if (current) {
            if (!obj[current.name]) {
              result.push({ title: current.name, value: current.name });
              obj[current.name] = true;
            }
          }
        });
      }
    });

    return result;
  };

  buildODS = records => {
    const obj = {};
    let result = [{ title: dict.t("Todas"), value: "all" }];

    records.forEach(record => {
      if (record.ODS) {
        record.ODS.forEach(option => {
          if (!obj[option]) {
            const odsItem = odsArray.find(item => item.name === option);
            result.push({ title: dict.t(odsItem.label), value: odsItem.name });
            obj[option] = true;
          }
        });
      }
    });

    return result;
  };

  buildStatus(records) {
    const obj = {};
    let result = [{ title: dict.t("Todos"), value: "all" }];

    const statusArray =
      this.props.statusType === "support" ? statusSupportArray : statusProjectArray;

    records.forEach(record => {
      if (record.status) {
        const option = record.status;

        if (!obj[option]) {
          const statusItem = statusArray.find(item => item.name === option);
          if (statusItem) {
            result.push({ title: dict.t(statusItem.label), value: statusItem.name });
            obj[option] = true;
          }
        }
      }
    });

    return result;
  }

  filterByState = (records, state) => {
    return records.filter(item => item.state === state);
  };

  filterByBiome = (records, biome) => {
    return records.filter(item => item.biomes.includes(biome));
  };

  fllterByDateRange = (records, iniDate, endDate) => {
    return records.filter(item => {
      const current = moment(item.createdAt);

      return current >= iniDate && current <= endDate;
    });
  };

  filterByStatus(records, status) {
    return records.filter(item => item.status.includes(status));
  }

  filterByName = (records, searchValue) => {
    let translated = dict.t(searchValue);

    if (!translated || typeof translated !== "string") return;

    translated = translated.toLowerCase().trim();

    return records.filter(item => {
      const current = item[dict.lang];

      if (!current) return false;

      let name = current.name ? current.name.toLowerCase().trim() : "";
      let shortName = current.shortName ? current.shortName.toLowerCase().trim() : "";

      return name.includes(translated) || shortName.includes(translated);
    });
  };

  filterByLegalFitness = (records, fitness) => {
    return records.filter(item => item.legalFitness.includes(fitness));
  };

  filterByAOI = (records, aoi) => {
    return records.filter(item => {
      return item.areasOfInterest.filter(e => e[dict.lang].name === aoi).length > 0;
    });
  };

  filterByODS = (records, ods) => {
    return records.filter(item => item.ODS.includes(ods));
  };

  handleChangeFilter = (ev, key) => {
    let { filters } = this.state;

    filters[key] = ev.target.value;

    this.setState({ filters });
  };

  handleSearchButtonClick = searchValue => {
    const { filters } = this.state;
    const { iniDate, endDate } = this.dateRange.state;

    let records = this.props.projects;

    if (this.props.status) {
      records.map((item, index) => {
        item.status = this.props.status[index];
        return item;
      });
    }

    if (searchValue !== "") records = this.filterByName(records, searchValue);

    if (iniDate && endDate) records = this.fllterByDateRange(records, iniDate, endDate);

    if (filters.status !== "all") records = this.filterByStatus(records, filters.status);

    if (filters.state !== "all") records = this.filterByState(records, filters.state);

    if (filters.biome !== "all") records = this.filterByBiome(records, filters.biome);

    if (filters.legalFitness !== "all")
      records = this.filterByLegalFitness(records, filters.legalFitness);

    if (filters.aoi !== "all") records = this.filterByAOI(records, filters.aoi);

    if (filters.ods !== "all") records = this.filterByODS(records, filters.ods);

    if (this.props.onFilter) this.props.onFilter(records);
  };
}


