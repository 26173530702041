/**
 * 11 num
 * (51) 99999-9999
 * @param {*} value
 * @param {*} previousValue
 */
const normalizerPhone = (value, previousValue) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, "");

  //first part of string
  let string = "(" + onlyNums.slice(0, 2) + ")";

  if (onlyNums.length <= 2) return string;

  //second part
  string += " " + onlyNums.slice(2, 7);

  if (onlyNums.length <= 7) return string;

  //third (and last) part
  return string + "-" + onlyNums.slice(7, 11);
};

export default normalizerPhone;
