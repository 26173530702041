
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Corpo do item de lista de usuários
//


import React from "react";

import "./style.css";



export default function(props) {
  return (
    <div className="row master-users-list-item" style={{ width: "100%" }}>
      <div className="col-sm-5 master-users-list-item-name">{props.item.name}</div>

      <div className="col-sm-offset-1 col-sm-6 master-users-list-item-email">
        {props.item.email}
      </div>
    </div>
  );
}


