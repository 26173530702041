
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de apoiadores - master
//


import React, { Component } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Dropdown from "../../../Commons/Dropdown";

import moment from "moment";
import Back from "../../../Commons/Back";
import Alert from "../../../../lib/Alert";

import SearchBar from "../../../Commons/SearchBar";
import List from "../../List/Zebra";

import ItemBody from "./ItemBody";

import multiLang from "../../../../lib/multilang/multiLang";
import masterAccess from "../../../../lib/masterAccess";



const dict = new multiLang(multiLang.getLanguage());



export default class SupporterList extends Component {
  handleDeleteButtonClick = item => {
    let supporter = item;

    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este apoiador"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/users/supporter/${supporter._id}`;

        Request.delete(url, {}, res => {
          if (res.success) {
            Alert.success(
              `${dict.t("Apoiador")} ${dict.t("deletado")} ${dict.t("com sucesso")}`,
              () => {
                this.setState({ supporters: null });
                this.fetchSupporters();
              }
            );
          }
        });
      }
    });
  };

  buttons = [
    {
      type: "edit",
      route: "/master/apoiadores",
    },
    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },
  ];

  buttonsNoDelete = [
    {
      type: "edit",
      route: "/master/apoiadores",
    },
  ];

  sortByOptions = [
    {
      title: dict.t("Todos"),
      value: "all",
    },
    {
      title: dict.t("Nome"),
      value: "name",
    },
    {
      title: dict.t("Data de cadastro"),
      value: "createdAt",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      supporters: null,
      filteredSupporters: [],
    };
  }

  handleSortBy = ev => {
    let { filteredSupporters } = this.state;

    switch (ev.target.value) {
      case "createdAt":
        filteredSupporters.sort((opt1, opt2) =>
          moment(opt1.createdAt).diff(moment(opt2.createdAt), "minutes")
        );
        this.setState({ filteredSupporters });
        break;

      case "name":
        filteredSupporters.sort((opt1, opt2) => {
          const name1 = opt1.trade.trim() === "" ? opt1.company : opt1.trade;
          const name2 = opt2.trade.trim() === "" ? opt2.company : opt2.trade;

          return (name1.trim() || "").localeCompare(name2.trim() || "");
        });

        this.setState({ filteredSupporters });
        break;

      default:
        break;
    }
  };

  componentWillMount() {
    this.fetchSupporters();
  }

  render() {
    if (!this.state.supporters) return <Loader />;

    const canDelete = masterAccess("supportApprove");

    return (
      <div className="container page-margin-top footer-margin-logged">
        <div className="page-title" style={{ marginBottom: "15px" }}>
          {dict.t("Apoiadores")}
        </div>

        <SearchBar
          button={{
            title: dict.t("Buscar apoiadores"),
            onClick: this.handleSearchButtonClick,
          }}
        />

        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-md-9 page-title" style={{ marginTop: "30px", marginBottom: "20px" }}>
            {dict.t("Apoiadores")}
          </div>

          <div className="col-md-3">
            <Dropdown
              label={dict.t("Ordenar por")}
              selectClass="searchOptionsSelect"
              options={this.sortByOptions}
              onChange={this.handleSortBy}
            />
          </div>
        </div>

        <div style={{ marginRight: "15px", marginLeft: "15px" }}>
          <List
            items={this.state.filteredSupporters}
            itemBodyComponent={ItemBody}
            buttons={canDelete ? this.buttons : this.buttonsNoDelete}
            style={{ height: "60px" }}
          />
        </div>

        <div style={{ marginTop: "30px" }}>
          <Back router={this.props.router} />
        </div>
      </div>
    );
  }

  fetchSupporters() {
    const url = "/master/users/role/supporter";

    Request.get(url, values => {
      this.setState({ supporters: values, filteredSupporters: values });
    });
  }

  filterByName = (records, searchValue) => {
    let translated = dict.t(searchValue);

    if (!translated || typeof translated !== "string") return;

    translated = translated.toLowerCase().trim();

    return records.filter(item => {
      let trade = item.trade.toLowerCase().trim();
      let company = item.company.toLowerCase().trim();

      return trade.includes(translated) || company.includes(translated);
    });
  };

  handleSearchButtonClick = search => {
    if (search !== "") {
      let { filteredSupporters } = this.state;
      filteredSupporters = this.filterByName(filteredSupporters, search);
      this.setState({ filteredSupporters });
    } else {
      this.setState({ filteredSupporters: this.state.supporters });
    }
  };
}


