
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Regis Ebeling
//
// Descrição: Corpo do item de lista do tipo categoria
//


import React from "react";
import moment from "moment";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default function(props) {
  return (
    <div className="row list-zebra-item-header-text" style={{ width: "100vw" }}>
      <div className="col-md-3">{props.item.projectName}</div>

      <div className="col-md-3">{props.item.proponent}</div>

      <div className="col-md-4">{`${dict.t("Apoiador")}: ${props.item.supporter}`}</div>

      <div className="col-md-2">{`${moment(props.item.createDate).format("DD/MM/YYYY")}`}</div>
    </div>
  );
}


