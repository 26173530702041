
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de áres de interesse - master
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import ListHeader from "./Header";



export default class List extends Component {
  render() {
    return (
      <Fragment>
        <ListHeader title={this.props.title} addRoute={this.props.addRoute} />
        <div className="row list-items-container">{this.getListItems(this.props.items)}</div>
      </Fragment>
    );
  }

  getListItems(items) {
    return items.map((item, index) => {
      const ItemTitle = this.props.itemTitleComponent;
      return (
        <div className="list-item-container" key={index}>
          <div className="card" style={{ height: "60px" }}>
            {item.image ? (
              <img className="list-item-icon" src={item.image} alt={item.name} title={item.name} />
            ) : (
              ""
            )}
            {ItemTitle ? (
              <ItemTitle item={item} />
            ) : (
              <div className="list-item-title">{item.name}</div>
            )}
            {this.props.canEdit ? (
              <div className="list-item-buttons-container">
                {
                  <Link to={`/master/configuracao/areas-de-interesse/editar/${item._id}`}>
                    <img
                      className="list-item-button"
                      src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon.png"
                      alt="Editar"
                      title="Editar"
                    />
                  </Link>
                }
                <img
                  className="list-item-button"
                  src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon.png"
                  alt="Deletar"
                  title="Deletar"
                  style={{ marginRight: "25px", marginLeft: "18px" }}
                  onClick={() => this.handleDeleteClick(item)}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  }

  handleDeleteClick = item => {
    if (this.props.onItemDeleteClick) this.props.onItemDeleteClick(item);
  };
}


