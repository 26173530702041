
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Componente de formulário para entrada de imagem
//


import React, { Component } from "react";

import multiLang from "../multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class ImageInput extends Component {
  constructor(props) {
    super(props);

    this.imageStyle = {};
    this.files = null;
  }

  render() {
    const {
      input: { value: omitValue, ...inputProps },
      meta: omitMeta,
      ...props
    } = this.props;

    if (typeof props.image === "string" && props.image !== "empty") {
      this.imageStyle = { backgroundImage: `url(${props.image})` };
    }

    if (props.image && props.image !== "empty")
      this.imageStyle = Object.assign({}, this.imageStyle, props.style);

    let partialProps = { ...props };
    delete partialProps.closeButton;

    return (
      <div className="form-group">
        <div className="form-input-label">{props.label}</div>

        <div className="form-input-image" style={this.imageStyle}>
          {this.props.closeButton ? (
            <div className="form-input-image-close" onClick={this.handleCloseClick}>
              <img
                src="https://vbio.blob.core.windows.net/public/assets/img/plus-icon.png"
                alt={dict.t("Remover imagem")}
                title={dict.t("Remover imagem")}
              />
            </div>
          ) : null}

          <input
            {...inputProps}
            {...partialProps}
            type="file"
            accept="image/*"
            onChange={this.handleChange}
            title={dict.t("Enviar imagem")}
          />

          {this.hasError() ? <div className="form-input-error-detail" /> : null}
        </div>
      </div>
    );
  }

  hasError() {
    return this.props.meta.touched && this.props.meta.error;
  }

  handleChange = e => {
    const {
      input: { value: omitValue, ...inputProps },
    } = this.props;

    const reader = new FileReader();

    reader.onload = e => {
      this.imageStyle = { backgroundImage: `url(${e.target.result})` };
      this.imageStyle = Object.assign({}, this.imageStyle, this.props.style);

      if (inputProps.onChange) inputProps.onChange({ name: inputProps.name, files: this.files });
    };

    this.files = e.target.files;
    reader.readAsDataURL(this.files[0]);
  };

  handleCloseClick = () => {
    const {
      input: { value: omitValue, ...inputProps },
    } = this.props;

    if (this.props.onClose) this.props.onClose({ name: inputProps.name });
  };
}


