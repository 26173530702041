
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Vitrine de projetos
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";
import html2plaintext from "html2plaintext";

import ScreenSize from "../Commons/ScreenSize";

import Request from "../../lib/BackendRequest";

import languageTools from "../../lib/languageTools";

import ImageFilter from "../ImageFilter";
import ProgressBar from "../ProgressBar";

import Loader from "../Loader";

import BadgeStack from "../Commons/BadgeStack";

import Authentication from "../../lib/Authentication";

import { formatCurrency } from "../../lib/FormatString";

import userRoutes from "../../constants/userRoutes";

import biomesArray from "../../constants/biomes";
import odsArray from "../../constants/ods";
import legalFitnessArray from "../../constants/legalFitness";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



const mainProjectImageFilter =
  "linear-gradient(-90deg, rgba(102, 102, 102, 0.8) 0%, rgba(102, 102, 102, 0) 100%)";

const projectImageFilter =
  "linear-gradient(-90deg, rgba(102, 102, 102, 0.8) 0%, rgba(102, 102, 102, 0) 100%)";

const smallScreenWidth = 992;



export default class ProjectsShowcase extends Component {
  static defaultProps = {
    numOfCols: 3,
  };

  constructor(props) {
    super(props);

    this.state = {
      windowSize: {},
      projectsTab: "all",
      mainProjectReady: false,
      mainProject: null,
      spotlightProjects: null,
    };
  }

  componentWillMount() {
    this.fetchConfigs();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowSize: { width: window.innerWidth, height: window.innerHeight } });
  };

  render() {
    if (!this.state.mainProjectReady || !this.state.spotlightProjects) return <Loader />;

    let { mainProject, projectsTab } = this.state;

    const projectTabClass = "vitrine-projects-tabs-item";
    let projectTabAllClass = projectTabClass;
    let projectTabNewClass = projectTabClass;

    projectTabAllClass += projectsTab === "all" ? " vitrine-projects-tabs-item-selected" : "";
    projectTabNewClass += projectsTab === "new" ? " vitrine-projects-tabs-item-selected" : "";

    const preFilteredProjects = this.preFilterprojects(this.props.projects, projectsTab);

    let url;

    if (Authentication.isAuthenticated()) {
      const role = Authentication.getUser().personal.role;
      url = `/${userRoutes(role)}projetos`;
    } else url = "/login";

    return (
      <div className="vitrine">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        {!this.props.hideHeader ? (
          <Fragment>
            <Link to={url} style={{ display: "inline-block" }}>
              <div className="vitrine-title">{dict.t("Vitrine")}</div>
            </Link>
            <div className="vitrine-description">{dict.t("vitrine-descricao")}</div>
          </Fragment>
        ) : (
          ""
        )}

        {!this.props.hideMainProject ? (
          this.state.smallScreen ? (
            <div style={{ marginBottom: "20px", marginLeft: "-15px", marginRight: "-15px" }}>
              {this.composeProject(
                this.props.projects.find(project => project._id === mainProject)
              )}
            </div>
          ) : (
            this.composeMainProject(
              this.props.projects.find(project => project._id === mainProject)
            )
          )
        ) : null}

        <div className="container vitrine-projects-container">
          <div className="vitrine-projects-tabs">
            <div className={projectTabAllClass} onClick={this.handleAllProjectsClick}>
              {dict.t("Todos")}
            </div>
            <div className={projectTabNewClass} onClick={this.handleNewProjectsClick}>
              {dict.t("Novos")}
            </div>

            {this.componseExtraButtons(this.props.extraButtons)}
          </div>

          <div className="row display-flex">
            {this.getProjectCards(this.filterProjects(preFilteredProjects, this.props.filter))}
          </div>
        </div>

        {!this.props.hideSeeAllLink ? (
          <Link to={url}>
            <div className="vitrine-projects-button">{dict.t("Ver Todos")} ></div>
          </Link>
        ) : null}
      </div>
    );
  }

  composeMainProject(project) {
    if (!project) return "";

    const container = this.props.detailed ? "card vitrine-main-project-container" : "";

    project[language] = languageTools.merge(project.PT, project[language]);

    const detailsConfig = {
      containerTop: "78%",
      containerMarginTop: "30px",
      iconHeight: "30px",
      sidePadding: "85px",
      labelFontSize: "17px",
      labelMargin: "12px",
      locationMarginBottom: "10px",
      progressHeight: "15px",
      progressMarkerHeight: "31px",
      progressMarkerWidth: "2px",
      progressMarkerFontSize: "14px",
      progressMarginTop: "50px",
      textFontSize: "12px",
    };

    const url = Authentication.isAuthenticated()
      ? `/${userRoutes(Authentication.getUser().personal.role)}projetos/${project._id}`
      : "/login";

    return (
      <Link to={url}>
        <div className={container} style={{ marginBottom: "30px" }}>
          <div className="card">
            <div className="vitrine-main-project-image-container">
              <img
                className="vitrine-main-project-image"
                src={project.images[0]}
                alt={project[language].shortName}
                title={project[language].shortName}
              />
              <ImageFilter
                className="vitrine-main-project-image"
                filter={mainProjectImageFilter}
                disableOnHover={true}
                title={project[language].shortName}
              />
            </div>

            {this.getMainLegalFitnessIcons(project)}

            <div className="container vitrine-main-project-badges-container">
              {this.getProjectBadges(project, true)}
            </div>

            <div className="vitrine-main-project-name-container">
              <div className="vitrine-main-project-shortname-container">
                <div className="vitrine-main-project-shortname">{project[language].shortName}</div>
              </div>

              <div className="vitrine-main-project-description-container">
                <div className="vitrine-main-project-description">
                  {html2plaintext(project[language].description, { decodeEntities: false })}
                </div>
              </div>
            </div>
          </div>

          {this.props.detailed ? this.composeDetails(project, detailsConfig) : ""}
        </div>
      </Link>
    );
  }

  preFilterprojects(projects, filter) {
    if (!projects) return null;

    let filtered = [];

    if (filter === "new") {
      filtered = projects.slice();
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      filtered = filtered.slice(0, 6);
    }

    if (filter === "all") {
      if (!this.state.spotlightProjects) return null;

      if (this.state.spotlightProjects === "all") return projects;

      this.state.spotlightProjects.forEach(projectId => {
        const project = projects.find(project => project._id === projectId);

        if (project) filtered.push(project);
      });
    }

    return filtered;
  }

  filterProjects(projects, filter) {
    if (!projects) return null;

    let filtered = projects.slice();

    if (!filter) return filtered;

    filtered = filtered.filter(project => {
      let pass = true;

      pass = pass && this.findValuesOnArray(filter.legalFitness, project.legalFitness);

      pass =
        pass &&
        this.findValuesOnArray(
          filter.areasOfInterest,
          project.areasOfInterest.map(item => item._id)
        );

      pass = pass && this.findValuesOnArray(filter.ODS, project.ODS);

      pass = pass && this.findValuesOnArray([filter.state], [project.state]);

      pass = pass && this.findValuesOnArray(filter.biomes, project.biomes);

      if (filter.contributionVolume) {
        pass =
          pass &&
          (project.totalValue >= filter.contributionVolume[0] &&
            project.totalValue <= filter.contributionVolume[1]);
      }

      pass =
        pass &&
        this.findValuesOnArray([filter.inOperation], [project.inOperation ? "true" : "false"]);

      return pass;
    });

    return filtered;
  }

  findValuesOnArray(values, array) {
    if (!values || !values.length > 0 || !values[0]) return true;

    return array.some(item => {
      return values.indexOf(item) !== -1;
    });
  }

  componseExtraButtons(buttons) {
    if (!buttons) return "";

    return buttons.map((button, index) => {
      return (
        <Link key={index} to={button.route}>
          <div className="vitrine-projects-tabs-item" style={{ width: "fit-content" }}>
            {button.label}
          </div>
        </Link>
      );
    });
  }

  getProjectCards(projects) {
    if (!projects || projects.length === 0) return <ProjectNotFound />;

    return projects.map((project, index) => {
      return this.composeProject(project, index);
    });
  }

  composeProject(project, index) {
    if (!project) return null;

    project[language] = languageTools.merge(project.PT, project[language]);

    const url = Authentication.isAuthenticated()
      ? `/${userRoutes(Authentication.getUser().personal.role)}projetos/${project._id}`
      : "/login";

    const container = this.props.detailed ? "card vitrine-project-container" : "";

    const detailedShortNameMargin = this.props.detailed ? { marginBottom: "21px" } : {};

    const detailsConfig = {
      containerTop: "85%",
      containerMarginTop: "20px",
      iconHeight: "26px",
      sidePadding: "33px",
      labelFontSize: "14px",
      labelMargin: "10px",
      locationMarginBottom: "8px",
      progressHeight: "15px",
      progressMarkerHeight: "26px",
      progressMarkerWidth: "1px",
      progressMarkerFontSize: "12px",
      progressMarginTop: "28px",
      textFontSize: "10px",
    };

    const colWidth = 12 / this.props.numOfCols;
    const colWidthMd = 12 / (this.props.numOfCols - 1);

    return (
      <div key={index} className={`col-lg-${colWidth} col-md-${colWidthMd} vitrine-card-container`}>
        <Link to={url} style={{ textDecoration: "none" }}>
          <div className={container}>
            <div className="card">
              <div className="vitrine-project-image-container">
                <img
                  className="vitrine-project-image"
                  src={project.images[0]}
                  alt={project[language].shortName}
                  title={project[language].shortName}
                />
                <ImageFilter
                  className="vitrine-project-image"
                  filter={projectImageFilter}
                  disableOnHover={true}
                  title={project[language].shortName}
                />
              </div>

              {this.getLegalFitnessIcons(project)}

              <div className="container vitrine-project-badges-container">
                {this.getProjectBadges(project)}
              </div>

              <div className="vitrine-project-shortname-container">
                <div className="vitrine-project-shortname" style={detailedShortNameMargin}>
                  {project[language].shortName}
                </div>
              </div>

              {this.props.detailed ? (
                <div className="vitrine-project-name-container">
                  <div className="vitrine-project-name">{project[language].name}</div>
                </div>
              ) : (
                ""
              )}
            </div>

            {this.props.detailed ? this.composeDetails(project, detailsConfig) : ""}
          </div>
        </Link>
      </div>
    );
  }

  getProjectBadges(project, main) {
    let areaBadges = [];
    let odsBadges = [];

    this.sortODSs(project.ODS).forEach(ODS => {
      odsBadges.push({
        icon: `/assets/img/badges/ods/${language}/${ODS}.png`,
        label: this.getODSLabel(ODS),
      });
    });

    project.areasOfInterest.forEach(area => {
      area[language] = languageTools.merge(area.PT, area[language]);
      areaBadges.push({ icon: area.image, label: area[language].name });
    });

    const style = main
      ? {}
      : { margin: "0 20px", display: "inline-block", verticalAlign: "middle" };

    return (
      <Fragment>
        <BadgeStack items={areaBadges} badgeWidth={45} style={style} group={true} />
        <BadgeStack items={odsBadges} badgeWidth={45} style={style} group={true} />
      </Fragment>
    );
  }

  getLegalFitnessLabel(legalFitness) {
    return dict.t(legalFitnessArray.find(item => item.name === legalFitness).label);
  }

  getODSLabel(ods) {
    return dict.t(odsArray.find(item => item.name === ods).label);
  }

  sortODSs(items) {
    return items.sort((a, b) => {
      a = a.match(/ods-[\d]*/g)[0];
      a = a.replace(/[^\d]/g, "");

      b = b.match(/ods-[\d]*/g)[0];
      b = b.replace(/[^\d]/g, "");

      return b - a;
    });
  }

  getMainLegalFitnessIcons(project) {
    let legalBadges = [];

    project.legalFitness.forEach(legalFitness => {
      legalBadges.push({
        icon: `/assets/img/badges/legal-fitness/${legalFitness}.png`,
        label: this.getLegalFitnessLabel(legalFitness),
      });
    });

    return (
      <div className="vitrine-main-project-icon">
        <BadgeStack items={legalBadges} badgeWidth={60} group={true} />
      </div>
    );
  }

  getLegalFitnessIcons(project) {
    let legalBadges = [];

    project.legalFitness.forEach(legalFitness => {
      legalBadges.push({
        icon: `/assets/img/badges/legal-fitness/${legalFitness}.png`,
        label: this.getLegalFitnessLabel(legalFitness),
      });
    });

    return (
      <div className="vitrine-project-icon">
        <BadgeStack items={legalBadges} badgeWidth={50} />
      </div>
    );
  }

  composeDetails(project, configs) {
    const supportedPercent = 100 * (project.supportedValue / project.totalValue);
    const targetPercent = 100 * (project.minimumValue / project.totalValue);

    return (
      <div
        className="vitrine-main-project-details-container"
        style={{
          marginTop: configs.containerMarginTop,
          top: configs.containerTop,
          padding: `0 ${configs.sidePadding}`,
        }}
      >
        <div className="row">
          <div
            className="vitrine-main-project-location"
            style={{ marginBottom: configs.locationMarginBottom }}
          >
            <img
              className="vitrine-main-project-details-icon"
              src="https://vbio.blob.core.windows.net/public/assets/img/location-icon.png"
              alt={project.state}
              title={project.state}
              style={{ height: configs.iconHeight }}
            />
            <div
              className="vitrine-main-project-name vitrine-main-project-details-text"
              style={{ fontSize: configs.labelFontSize, marginLeft: configs.labelMargin }}
            >
              {`${project.city} - ${project.state}`}
            </div>
          </div>

          <div className="vitrine-main-project-details-biomes-container">
            {this.composeBiomes(
              project.biomes,
              configs.iconHeight,
              configs.labelFontSize,
              configs.labelMargin
            )}
          </div>
        </div>

        <div className="row" style={{ marginTop: configs.progressMarginTop }}>
          <ProgressBar
            height={configs.progressHeight}
            markerHeight={configs.progressMarkerHeight}
            markerWidth={configs.progressMarkerWidth}
            markerFontSize={configs.progressMarkerFontSize}
            value={supportedPercent}
            markerLabel={dict.t("meta mínima")}
            markerPos={targetPercent.toFixed(0)}
          />
        </div>

        <div className="row" style={{ textAlign: "center", marginTop: "16px" }}>
          <Fragment>
            <div
              className="vitrine-main-project-details-small-text"
              style={{ fontSize: configs.textFontSize, float: "left" }}
            >
              {`${formatCurrency(project.supportedValue)} ${dict.t("de")} ${formatCurrency(
                project.totalValue
              )}`}
            </div>

            <div
              className="vitrine-main-project-details-small-text"
              style={{ fontSize: configs.textFontSize, float: "right" }}
            >
              {`${dict.t("prazo")}: ${project.endDate}`}
            </div>
          </Fragment>
        </div>
      </div>
    );
  }

  composeBiomes(biomes, iconHeight, labelFontSize, labelMargin) {
    if (!biomes || !biomes.length) return null;

    return biomes.map((biome, index) => {
      const label = biomesArray.find(item => item.name === biome).label;

      return (
        <div className="vitrine-main-project-biome" key={biome}>
          <img
            className="vitrine-main-project-details-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/biome-icon.png"
            alt={label}
            title={label}
            style={{ height: iconHeight }}
          />
          <div
            className="vitrine-main-project-name vitrine-main-project-details-text"
            style={{ fontSize: labelFontSize, display: "inline-block" }}
          >
            {label}
          </div>
        </div>
      );
    });
  }

  fetchConfigs() {
    Request.get("/home/projects/main-project", values => {
      let id;

      if (values.idProject) id = values.idProject._id;
      else id = null;

      this.setState({ mainProject: id, mainProjectReady: true });
    });

    if (!this.props.showAll) {
      Request.get("/home/projects/showcase-projects", values => {
        this.setState({ spotlightProjects: values.projects.map(project => project._id) });
      });
    } else {
      this.setState({ spotlightProjects: "all" });
    }
  }

  handleScreenResize = screen => {
    const { width } = screen;

    this.setState({ smallScreen: width <= smallScreenWidth });
  };

  handleAllProjectsClick = () => {
    this.setState({ projectsTab: "all" });
  };

  handleNewProjectsClick = () => {
    this.setState({ projectsTab: "new" });
  };
}



class ProjectNotFound extends Component {
  render() {
    return (
      <div className="projects-search-text-container text-center" style={{ width: "100%" }}>
        <img
          src="https://vbio.blob.core.windows.net/public/assets/img/warning-green.png"
          alt={dict.t("Nenhum projeto encontrado")}
          title={dict.t("Nenhum projeto encontrado")}
        />{" "}
        <br /> <br />
        {dict.t("Não achou o projeto que estava procurando?")}
        <br />
        <span style={{ fontWeight: "bold" }}>{`${dict.t(
          "Fale com a gente"
        )}: +55 (51) 4042.0039`}</span>
        <br />
        <span style={{ fontWeight: "bold" }}>{"vbio@vbio.eco"}</span>
      </div>
    );
  }
}


