
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Menu hamburger
//


import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { slide as BurgerMenu } from "react-burger-menu";

import MenuItems from "./MenuItems";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuShow: this.props.isOpen,
      subMenuShow: false,
      lastItem: null,
    };
  }

  componentWillMount() {
    this.setState({ menuShow: this.props.isOpen, subMenuShow: false });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ menuShow: newProps.isOpen, subMenuShow: false });
  }

  render() {
    return (
      <Fragment>
        <BurgerMenu right isOpen={this.state.menuShow} customBurgerIcon={false}>
          <div className="menu-burger-container">
            <img
              className="menu-burger-close-button"
              src="https://vbio.blob.core.windows.net/public/assets/img/plus-icon.png"
              alt={dict.t("Fechar")}
              onClick={this.handleCloseButtonClick}
            />

            <MenuItems
              items={this.props.items}
              router={this.props.router}
              subMenuOpenId={this.state.subMenuShow ? this.state.lastItem : null}
              itemClassName="menu-burger-item"
              subItemClassName="menu-burger-sub-item"
              onItemClick={this.handleItemClick}
            />
          </div>
        </BurgerMenu>
      </Fragment>
    );
  }

  closeMenu() {
    this.setState({ menuShow: false });

    if (this.props.onClose) this.props.onClose();
  }

  handleMouseMove = e => {
    const bounds = ReactDOM.findDOMNode(this.refs["menu"]).getBoundingClientRect();

    const x = e.pageX - bounds.left;
    const y = e.pageY - bounds.top;
    const width = bounds.width;

    this.setState({ mousePos: { x, y }, width: width });
  };

  handleItemClick = item => {
    if (!item.subItems) {
      this.setState({ subMenuShow: false });
      this.closeMenu();
      return;
    }

    let show;

    if (item.id === this.state.lastItem) {
      show = !this.state.subMenuShow;
    } else {
      show = true;
    }

    this.setState({
      menuShow: true,
      subMenuShow: show,
      lastItem: item.id,
    });
  };

  handleCloseButtonClick = () => {
    this.closeMenu();
  };
}


