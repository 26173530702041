import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from "react-ga";
import Router from "./Router";
import reduxStore from "./store";
import { Provider } from "react-redux";

// Inicializa o Analytics
ReactGA.initialize("UA-151348907-1");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <Router />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
