
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Corpo da lista de usuários apoiadores
//


import React from "react";



export default function(props) {
  return (
    <div className="row list-zebra-item-header-text" style={{ width: "70vw" }}>
      <div className="col-md-4 proponent-user-name">{props.item.name}</div>
      <div className="col-md-5 proponent-user-email">{props.item.email}</div>
      <div className="col-md-3  proponent-user-email">{props.item.companyRole}</div>
    </div>
  );
}


