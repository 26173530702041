
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de projetos - master
//


import React, { Component } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Dropdown from "../../../Commons/Dropdown";

import Alert from "../../../../lib/Alert";
import Authentication from "../../../../lib/Authentication";

import moment from "moment";
import Back from "../../../Commons/Back";

import List from "../../List/Zebra";
import ProjectSearchForm from "../../../Commons/ProjectSearchForm";

import ItemBody from "./ItemBody";

import reportTableConfig from "../../../../constants/reportTableConfig";
import generateReport from "../../../../lib/generateReport";

import projectStatus from "../../../../constants/projectStatus";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";
import masterAccess from "../../../../lib/masterAccess";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class PendingProjects extends Component {
  handleDeleteButtonClick = item => {
    let project = item;

    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/projects/${project._id}`;

        Request.delete(url, {}, res => {
          if (res.success) {
            Alert.success(
              `${dict.t("Projeto")} ${dict.t("deletado")} ${dict.t("com sucesso")}`,
              () => {
                this.setState({ projects: null });
                this.setListFilter(this.props.router.params.filter);
              }
            );
          }
        });
      }
    });
  };

  buttons = [
    {
      type: "edit",
      route: "/master/projetos",
    },
    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },
  ];

  buttonsNoDelete = [
    {
      type: "edit",
      route: "/master/projetos",
    },
  ];

  sortByOptions = [
    {
      title: dict.t("Todos"),
      value: "all",
    },
    {
      title: "Nome",
      value: "name",
    },
    {
      title: "Data de cadastro",
      value: "createdAt",
    },
    {
      title: "Data de edição",
      value: "updatedAt",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      filteredProjects: [],
      projects: null,
      showcaseProjects: null,
    };
  }

  handleSortBy = ev => {
    let { filteredProjects } = this.state;

    switch (ev.target.value) {
      case "createdAt":
        filteredProjects.sort((opt1, opt2) =>
          moment(opt1.createdAt).diff(moment(opt2.createdAt), "minutes")
        );
        this.setState({ filteredProjects });
        break;

      case "updatedAt":
        filteredProjects.sort((opt1, opt2) =>
          moment(opt1.updatedAt).diff(moment(opt2.updatedAt), "minutes")
        );
        this.setState({ filteredProjects });
        break;

      case "name":
        filteredProjects.sort((opt1, opt2) =>
          (opt1[dict.lang].shortName || "").localeCompare(opt2[dict.lang].shortName || "")
        );
        this.setState({ filteredProjects });
        break;

      default:
        break;
    }
  };

  componentWillMount() {
    this.setListFilter(this.props.router.params.filter);
    this.updateUserRecord();
  }

  componentWillReceiveProps(newProps) {
    this.setListFilter(newProps.router.params.filter);
    this.updateUserRecord();
  }

  updateUserRecord() {
    let date = new Date();
    date = date.toISOString();

    localStorage.setItem("userLastCheckProjects", date);

    const url = `/master/users/project/${Authentication.getUser().personal.id}`;

    Request.post(url, { role: "master", lastCheckProjects: date }, () => {});
  }

  setListFilter(param) {
    this.setState({ projects: null });

    let fetchUrl = "/master/projects";

    if (param === "pendencias") fetchUrl += "/pending";
    else if (param === "todos") fetchUrl += "/projects";
    else this.props.router.push("/");

    this.fetchProjects(fetchUrl);
  }

  render() {
    if (!this.state.projects) return <Loader />;

    const canDelete = masterAccess("projectApprove");

    return (
      <div className="container footer-margin-logged">
        <div className="page-title" style={{ marginTop: "30px" }}>
          {dict.t("Projetos")}
        </div>

        <ProjectSearchForm
          buttonLabel={dict.t("Buscar Projetos")}
          projects={this.state.projects}
          onFilter={this.handleProjectFilter}
        />

        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <Dropdown
              label={dict.t("Ordenar por")}
              selectClass="searchOptionsSelect"
              options={this.sortByOptions}
              onChange={this.handleSortBy}
            />
          </div>
        </div>

        <div style={{ marginRight: "15px", marginLeft: "15px" }}>
          <List
            items={this.state.filteredProjects}
            itemBodyComponent={ItemBody}
            buttons={canDelete ? this.buttons : this.buttonsNoDelete}
            style={{ height: "60px" }}
          />
        </div>

        <div>
          <div className="master-list-button-container">
            <button className="button supports-list-report-button" onClick={this.handleReportClick}>
              {dict.t("Baixar relatório de projetos")}
            </button>
          </div>

          <div className="master-list-back-container">
            <Back router={this.props.router} />
          </div>
        </div>
      </div>
    );
  }

  fetchProjects(url) {
    Request.get(url, values => {
      this.setState({ projects: values, filteredProjects: values });
    });
  }

  handleProjectFilter = filteredProjects => {
    this.setState({ filteredProjects });
  };

  handleReportClick = () => {
    let reportData = JSON.parse(JSON.stringify(this.state.projects));

    reportData = reportData.map(project => {
      project.name = project[language].name;
      project.proponent = project.proponent ? project.proponent.trade : "";
      project.status = projectStatus.find(item => item.name === project.status).label;
      return project;
    });

    generateReport(reportData, reportTableConfig["project"], "relatório-projetos.xlsx");
  };
}


