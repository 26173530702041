
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Menu de idioma
//


import React, { Component, Fragment } from "react";

import "./style.css";



export default class LanguageMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: this.props.items.filter(item => item.id === this.props.defaultItemId)[0],
      open: false,
    };
  }

  render() {
    let dropdownIcon;

    if (this.hover)
      dropdownIcon = this.state.open
        ? "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown-up-selected.png"
        : "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown-selected.png";
    else
      dropdownIcon = this.state.open
        ? "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown-up.png"
        : "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown.png";

    return (
      <div
        className="menu-item"
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.state.open ? (
          <div className="menu-language-item">{this.getItems()}</div>
        ) : (
          <img
            className="menu-language-item"
            src={this.state.selectedItem.icon}
            alt={this.state.selectedItem.label}
            title={this.state.selectedItem.label}
          />
        )}

        <img
          className="menu-item-dropdown-icon"
          src={dropdownIcon}
          alt=""
          style={{ marginLeft: "9px" }}
        />
      </div>
    );
  }

  getItems() {
    return this.props.items.map((item, index) => {
      return (
        <Fragment key={index}>
          <img
            className="menu-language-subitem"
            src={item.icon}
            alt={item.label}
            title={item.label}
            onClick={() => this.handleItemClick(item)}
          />
        </Fragment>
      );
    });
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleItemClick = item => {
    this.setState({ selectedItem: item });

    if (this.props.onSelect) this.props.onSelect(item);
  };

  handleMouseEnter = () => {
    this.hover = true;
  };

  handleMouseLeave = () => {
    this.hover = false;
  };
}


