
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Página Como Apoiar
//


import React, { Component } from "react";
import { Link } from "react-router";

import BackButton from "../Commons/Back";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class HowToSupport extends Component {
  render() {
    return (
      <div className="how-to-support-container">
        <div className="container back-button-generic">
          <BackButton router={this.props.router} />
        </div>

        <div className="howto-container">
          <SupportBody />
        </div>
      </div>
    );
  }
}

export class SupportBody extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="howto-title supporter">{dict.t("Como Apoiar")}</div>
            <div className="row howto-intern-item">
              <div className="col-md-11 howto-text col-md-offset-1 howto-text-wide">
                <strong>{dict.t("como-apoiar-subtitulo")}</strong>
              </div>
            </div>
            <div className="row howto-intern-item">
              <div className="col-md-11 col-md-offset-1">
                <div className="howto-index supporter">1</div>
                <div className="howto-text">{dict.t("como-apoiar-1")}</div>
              </div>
            </div>
            <div className="row howto-intern-item">
              <div className="col-md-11 col-md-offset-1">
                <div className="howto-index supporter">2</div>
                <div className="howto-text">{dict.t("como-apoiar-2")}</div>
              </div>
            </div>
            <div className="row howto-intern-item">
              <div className="col-md-11 col-md-offset-1">
                <div className="howto-index supporter">3</div>
                <div className="howto-text">{dict.t("como-apoiar-3")}</div>
              </div>
            </div>
            <Link to={this.props.actionRoute || "/login"}>
              <button className="button howto-button supporter-background">
                {dict.t("Apoiar")}
              </button>
            </Link>
            <br /> <br /> <br />
          </div>
        </div>
      </div>
    );
  }
}


