export default function(role) {
  switch (role) {
    case "master":
      return "master/";
    case "supporterUser":
      return "supporter/";
    case "proponentUser":
      return "proponent/";
    default:
      return "";
  }
}
