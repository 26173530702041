
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Header de lista
//


import React, { Component } from "react";
import { Link } from "react-router";

import "./style.css";



export default class Header extends Component {
  render() {
    const titleStyle =
      this.props.addRoute || this.props.editRoute ? { maxWidth: "calc(100% - 40px)" } : {};

    return (
      <div className="row list-header-container">
        <div className="page-title" style={titleStyle}>
          {this.props.title}
        </div>

        {this.props.addRoute ? (
          <Link to={this.props.addRoute}>
            <img
              className="list-add-button"
              src="https://vbio.blob.core.windows.net/public/assets/img/add-icon.png"
              alt="Adicionar"
              title="Adicionar"
            />
          </Link>
        ) : (
          ""
        )}

        {this.props.editRoute ? (
          <Link to={this.props.editRoute}>
            <img
              className="list-add-button"
              src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon-2.png"
              alt="Editar"
              title="Editar"
            />
          </Link>
        ) : (
          ""
        )}
      </div>
    );
  }
}


