
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Proponente - página interna - master
//


import React, { Component, Fragment } from "react";

import Modal from "react-responsive-modal";

import Loader from "../../../Loader";

import Alert from "../../../../lib/Alert";
import Request from "../../../../lib/BackendRequest";

import Toolbar from "./Toolbar";
import SupporterData from "../../../Commons/SupporterData";

import List from "../../List/Zebra";

import ItemBody from "./ItemBody";
import ItemDocumentStatus from "./ItemBodyDocumentStatus";
import ItemBodyDocument from "./ItemBodyDocument";
import ItemExpanded from "./ItemExpanded";

import ProjectItemBody from "./ProjectItemBody";

import multiLang from "../../../../lib/multilang/multiLang";
import Authentication from "../../../../lib/Authentication";
import masterAccess from "../../../../lib/masterAccess";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const updateDocuments = true;



export default class SupporterIntern extends Component {
  handleDeleteButtonClick = item => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("excluir")} ${dict.t(
      "este usuário"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/users/proponent-user/${item._id}`;

        Request.delete(url, item, res => {
          if (res.success) {
            // Forçar renderização após exclusão
            this.setState({ supporter: null });
            this.fetchProponent();

            Alert.success(dict.t("Usuário deletado com sucesso"));
          }
        });
      }
    });
  };

  handleProjectDeleteButtonClick = item => {
    let project = item;

    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/projects/proponent/${project._id}`;

        Request.delete(url, {}, res => {
          if (res.success) {
            Alert.success(
              `${dict.t("Projeto")} ${dict.t("deletado")} ${dict.t("com sucesso")}`,
              () => {
                this.setState({ projects: null });
                this.fetchProponent();
              }
            );
          }
        });
      }
    });
  };

  buttonsUsers = [
    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },
  ];

  buttonsDocuments = [
    {
      type: "expand",
    },
  ];

  buttonsProjects = [
    {
      type: "edit",
      route: "/master/projetos",
    },
    {
      type: "delete",
      onClick: this.handleProjectDeleteButtonClick,
    },
  ];

  buttonsProjectsNoDelete = [
    {
      type: "edit",
      route: "/master/projetos",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      supporter: null,
      projects: null,
      showModal: false,
      modalInputText: "",
    };
  }

  componentWillMount() {
    this.fetchProponent();
  }

  render() {
    if (!this.state.supporter || !this.state.projects) return <Loader />;

    const documentsBodyProps = {
      onApprove: this.handleDocumentApprove,
      onRefuse: this.handleDocumentRefuse,
      onSendJustification: this.handleDocumentJustification,
    };

    let userAddUlr = `/proponente/usuario/novo/${this.state.supporter._id}`;

    if (Authentication.getUser().personal.role === "master") userAddUlr = "/master" + userAddUlr;

    const canEdit = masterAccess("proponentApprove");

    return (
      <Fragment>
        {this.renderToolbar()}

        <div className="container footer-margin-logged" style={{ paddingBottom: "30px" }}>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-11 page-title">{dict.t("Editar Proponente")}</div>
          </div>

          <div className="col-md-12">
            <SupporterData supporter={this.state.supporter} />
          </div>

          <div className="col-md-12">
            <List
              title={dict.t("Usuários")}
              items={this.state.supporter.users}
              itemBodyComponent={ItemBody}
              addRoute={canEdit ? userAddUlr : null}
              buttons={canEdit ? this.buttonsUsers : null}
              style={{ height: "60px" }}
            />
          </div>

          <div className="col-md-12">
            <List
              title={dict.t("Documentos")}
              items={this.state.supporter.documents}
              router={this.props.router}
              itemBodyComponent={ItemBodyDocument}
              itemBodyProps={documentsBodyProps}
              itemHeaderComponent={ItemDocumentStatus}
              itemExpandedComponent={ItemExpanded}
              buttons={this.buttonsDocuments}
              style={{ height: "60px" }}
            />
          </div>

          {this.state.projects && this.state.projects.length ? (
            <div className="col-md-12">
              <List
                title={dict.t("Projetos")}
                items={this.state.projects}
                itemBodyComponent={ProjectItemBody}
                buttons={canEdit ? this.buttonsProjects : this.buttonsProjectsNoDelete}
                style={{ height: "60px" }}
              />
            </div>
          ) : null}
        </div>

        <Modal open={this.state.showModal} onClose={this.handleModalClose} center>
          <div className="proponent-documents-list-modal-container">
            <div className="page-title proponent-documents-list-modal-title">
              Informe a justificativa
            </div>

            <div className="proponent-documents-list-modal-textarea-container">
              <textarea
                className="form-control form-input proponent-documents-list-modal-textarea"
                name="justification"
                placeholder="Justificativa"
                rows="6"
                style={{ resize: "none" }}
                onChange={this.handleModalInputChange}
              />
            </div>

            <button
              className="button proponent-documents-list-modal-button"
              type="button"
              style={{ float: "right" }}
              onClick={this.handleModalSubmit}
            >
              Enviar
            </button>
          </div>
        </Modal>
      </Fragment>
    );
  }

  renderToolbar() {
    return (
      <Toolbar
        router={this.props.router}
        editRoute={`/master/proponentes/editar/${this.props.router.params.id}`}
        status={this.state.supporter.status}
        inactivateDate={this.state.supporter.inactivateDate}
        onApprove={this.handleProponentApprove}
        onDeny={this.handleProponentDenny}
        onInactivate={this.handleProponentInactivate}
        onDelete={this.handleProponentDelete}
      />
    );
  }

  sortDocuments(documents) {
    if (!documents) return [];

    return documents.sort((a, b) => {
      a = a.name.split(".")[0];
      b = b.name.split(".")[0];

      return a - b;
    });
  }

  fetchProponent() {
    const url = `/master/users/supporter/${this.props.router.params.id}`;

    Request.get(url, values => {
      values.documents = values.documents.filter(doc => doc);

      values.documents = this.sortDocuments(values.documents);

      this.setState({ supporter: values }, () => this.fetchProjects());
    });
  }

  fetchProjects() {
    const url = "/master/projects";

    Request.get(url, values => {
      const proponent = this.props.router.params.id;

      values = values.filter(project => project.proponent && project.proponent._id === proponent);

      this.setState({ projects: values });
    });
  }

  updateDocument(id, data, msg) {
    const url = `/master/proponents/change-status/${this.props.router.params.id}/${id}`;

    Request.post(url, data, res => {
      if (res.success) {
        this.setState({ supporter: null });
        this.fetchProponent();

        Alert.success(msg);
      }
    });
  }

  updateProponent(data, message, back = false) {
    const url = `/master/proponents/${data._id}`;

    Request.post(url, data, msg => {
      // Forçar renderização após exclusão
      this.setState({ supporter: null });

      if (data.deleted === false) this.fetchProponent();

      Alert.success(message);

      if (back) this.props.router.goBack();
    });
  }

  handleProponentApprove = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("aprovar")} ${dict.t(
      "este proponente"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { supporter } = this.state;

        supporter.status = "approved";

        const message = `${dict.t("Proponente")} ${dict.t("aprovado")} ${dict.t("com sucesso")}`;
        this.updateProponent(supporter, message);
      }
    });
  };

  handleProponentDenny = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("rejeitar")} ${dict.t(
      "este proponente"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { supporter } = this.state;

        supporter.status = "refused";

        const message = `${dict.t("Proponente")} ${dict.t("rejeitado")} ${dict.t("com sucesso")}`;
        this.updateProponent(supporter, message);
      }
    });
  };

  handleProponentInactivate = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("inativar")} ${dict.t(
      "este proponente"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { supporter } = this.state;

        supporter.status = "inactive";

        const message = `${dict.t("Proponente")} ${dict.t("inativado")} ${dict.t("com sucesso")}`;
        this.updateProponent(supporter, message);
      }
    });
  };

  handleProponentDelete = () => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este proponente"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        let { supporter } = this.state;

        supporter.deleted = "true";

        const message = `${dict.t("Proponente")} ${dict.t("deletado")} ${dict.t("com sucesso")}`;
        this.updateProponent(supporter, message, true);
      }
    });
  };

  handleDocumentApprove = item => {
    const message = `Tem certeza que deseja aprovar o documento ${item.name}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        this.updateDocument(
          item._id,
          { status: "approved" },
          `Documento ${item.name} aprovado com sucesso`
        );
      }
    });
  };

  handleDocumentRefuse = item => {
    const message = `Tem certeza que deseja negar o documento ${item.name}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        this.updateDocument(
          item._id,
          { status: "refused" },
          `Documento ${item.name} negado com sucesso`
        );
      }
    });
  };

  handleDocumentJustification = item => {
    this.setState({ showModal: true, selectedItem: item });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleModalInputChange = ev => {
    this.setState({ modalInputText: ev.target.value });
  };

  handleModalSubmit = () => {
    this.setState({ showModal: false });

    const message = `Tem certeza que deseja enviar um ajustificativa para o documento ${this.state.selectedItem.name}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/proponents/add-justification/${this.props.router.params.id}/${this.state.selectedItem._id}`;

        const data = { value: this.state.modalInputText, status: "refused" };

        Request.post(url, data, res => {
          if (res.success) {
            this.setState({ supporter: null });
            this.fetchProponent();

            Alert.success("Justificativa enviada com sucesso");
          }
        });
      }
    });
  };

  handleUpdateClick = () => {
    if (Authentication.getUser().personal.role === "master" && updateDocuments) {
      const message = `Tem certeza que deseja atualizar os documentos?`;

      Alert.confirm(message, yes => {
        if (yes) this.updateDocuments();
      });
    }
  };

  updateDocuments() {
    const url = `/master/proponents/update-documents/${this.state.supporter._id}`;

    console.log("Update documents");

    Request.post(url, {}, res => {
      if (res.success) {
        console.log(res);
      }
    });
  }
}


