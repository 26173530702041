
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Formulário de criação e edição para cadastro de documentos de proponente
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import FileInput from "../../../../lib/Forms/FileInput";
import Submit from "../../../..//lib/Forms/Submit";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import fileUpload from "../../../../lib/fileUpload";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();



class FormDocumentProponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      record: null,
      attachChange: false,
      uploading: false,
    };
  }

  componentWillMount() {
    this.formValues = {};

    if (this.props.params.id) {
      // edit mode
      this.fetchRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", record: {} });
    }
  }

  render() {
    const { record } = this.state;

    if (!record) return <Loader />;

    return (
      <div
        className="container form-container page-margin-top footer-margin-logged"
        style={{ paddingBottom: "30px" }}
      >
        <div className="page-title">
          {this.state.mode === "create" ? "Novo Documento" : "Editar Documento"}
        </div>

        <div className="col-md-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row" style={{ marginBottom: "30px" }}>
              <div className="col-md-11">
                <div>
                  <Field
                    name="name"
                    label="Nome"
                    validate={[VF.Required]}
                    cssClass="form-input"
                    cssClassError="form-input-error"
                    component={Input}
                  />
                </div>
              </div>

              <div className="col-md-1">
                <Field
                  name="attach"
                  label="Anexo"
                  attach={this.state.record.attach}
                  component={FileInput}
                  onChange={this.handleAttachChange}
                  style={{ backgroundSize: "cover" }}
                />
              </div>
            </div>

            <Submit backRoute="/master/configuracao/documentos" sending={this.state.uploading} />
          </form>
        </div>
      </div>
    );
  }

  fetchRecord(id) {
    const url = `/master/configs/documents/proponent-docs/${id}`;

    Request.get(url, values => {
      if (!values[language]) values[language] = values.PT;

      if (!values[language]) values[language] = { url: values.attach };

      values.attach = values[language].url;

      this.setState({ record: values, ready: true });
      this.props.initialize(this.state.record);
    });
  }

  submitData(values) {
    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  }

  createRecord(values) {
    const url = "/master/configs/documents/proponent-docs";

    if (values.attach && values.attach.length === 0) delete values.attach;

    Request.post(url, values, msg => {
      Alert.success("Cadastro efetuado com sucesso", () => {
        this.props.router.push("/master/configuracao/documentos");
      });
    });
  }

  updateRecord(values) {
    const url = `/master/configs/documents/proponent-docs/${this.state.record._id}`;

    Request.post(url, values, msg => {
      Alert.success("Edição efetuada com sucesso", () => {
        this.props.router.push("/master/configuracao/documentos");
      });
    });
  }

  handleAttachChange = attach => {
    const record = this.state.record;
    record.attach = attach;
    this.setState({ record: record, attachChange: true });
  };

  handleSubmit = values => {
    values.role = "document";

    if (this.state.attachChange) {
      this.formValues = values;
      this.setState({ uploading: true });

      fileUpload(values.attach).then(res => {
        if (!this.formValues[language]) this.formValues[language] = {};

        this.formValues[language].url = res[0].url;

        this.setState({ uploading: false });

        this.submitData(this.formValues);
      });
    } else {
      this.submitData(values);
    }
  };
}



export default reduxForm({
  form: "bannerForm",
})(FormDocumentProponent);


