
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Página Como Propôr
//


import React, { Component } from "react";
import { Link } from "react-router";

import Authentication from "../../lib/Authentication";
import BackButton from "../Commons/Back";
import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class HowToPropose extends Component {
  render() {
    return (
      <div className="how-to-support-container">
        <div className="container back-button-generic">
          <BackButton router={this.props.router} />
        </div>

        <div className="howto-container">
          <ProposeBody />
        </div>
      </div>
    );
  }
}

export class ProposeBody extends Component {
  render() {
    let button = null

    if (!this.props.actionRoute) {
      button = (
            <Link to="/login">
              <button className="button howto-button proponent-background">
                {dict.t("Propor")}
              </button>
            </Link>
      )
    } else {
      let user = Authentication.getUser();
      if (user.personal.companyStatus === "approved") {
        button = (
            <Link to={this.props.actionRoute}>
              <button className="button howto-button proponent-background">
                {dict.t("Propor")}
              </button>
            </Link>
        )
      } else {
        let style = {"background-color":"#aaaaaa"};
        button = (
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 col-md-offset-4">
                  <span>{dict.t("como-propor-habilitar")}</span>
                </div>
                <div className="col-md-2">
                  <Link to={this.props.actionRoute}>
                    <button
                      className="button howto-button proponent-background"
                      disabled="disabled" style={style}>
                      {dict.t("Propor")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      }
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="howto-title proponent">{dict.t("Como Propor")}</div>
            <div className="row howto-intern-item">
              <div className="col-md-11 howto-text col-md-offset-1 howto-text-wide">
                <strong>{dict.t("como-propor-subtitulo")}</strong>
              </div>
            </div>
            <div className="row howto-intern-item">
              <div className="col-md-11 col-md-offset-1">
                <div className="howto-index proponent">1</div>
                <div className="howto-text">{dict.t("como-propor-1")}</div>
              </div>
            </div>
            <div className="row howto-intern-item">
              <div className="col-md-11 col-md-offset-1">
                <div className="howto-index proponent">2</div>
                <div className="howto-text">{dict.t("como-propor-2")}</div>
              </div>
            </div>
            <div className="row howto-intern-item">
              <div className="col-md-11 col-md-offset-1">
                <div className="howto-index proponent">3</div>
                <div className="howto-text">{dict.t("como-propor-3")}</div>
              </div>
            </div>
            {button}
            <br /> <br /> <br />
          </div>
        </div>
      </div>
    );
  }
}


