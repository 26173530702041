
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Corrosel Home
//


import React, { Component } from "react";

import Slider from "react-slick";
import Arrow from "./Arrow";



export default class Carousel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.props.slidesToShow || 1,
      slidesToScroll: 1,
      className: this.props.className,
      autoplay: true,
      autoplaySpeed: 5000,

      dotsClass: "custom-dots",

      prevArrow: (
        <Arrow
          image={this.props.prevArrowImage}
          margin={this.props.arrowMargin}
          top={this.props.arrowTop}
          size={this.props.arrowSize}
          side="left"
        />
      ),
      nextArrow: (
        <Arrow
          image={this.props.nextArrowImage}
          margin={this.props.arrowMargin}
          top={this.props.arrowTop}
          size={this.props.arrowSize}
          side="right"
        />
      ),
    };

    return <Slider {...settings}>{this.props.items}</Slider>;
  }
}


