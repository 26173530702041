import config from "../../config";

import dictionaryJSON from "./dict.json";

/**
 * Dicitionary (simple) for multilanguage applications
 */
class dict {
  constructor(lang) {
    this.d = dictionaryJSON;
    this.lang = lang;
  }

  static getLanguage() {
    return localStorage.userLanguage || config.defaultLanguage;
  }

  t(phrase) {
    try {
      if (this.d[phrase][this.lang]) return this.d[phrase][this.lang];
      return phrase;
    } catch (e) {
      return phrase;
    }
  }

  mt(array) {
    let answer = "";
    try {
      for (let i in array) {
        if (this.d[array[i]][this.lang]) answer += this.d[array[i]][this.lang] + " ";
        else throw array[i];
      }
      return answer.trim();
    } catch (e) {
      answer = "";
      for (let i in array) {
        answer += array[i] + " ";
      }
      return answer.trim();
    }
  }
}

export default dict;
