
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Regis Ebeling
//
// Descrição: Corpo do item de lista de post do blog
//


import React from "react";

import moment from "moment";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default function(props) {
  props.item[language] = languageTools.merge(props.item.PT, props.item[language]);

  const author = props.item.author && props.item.author[0] ? props.item.author[0].name : "";
  const publish = props.item.publishDate
    ? `${dict.t("Publicação")}: ${moment(props.item.publishDate).format("DD/MM/YYYY")}`
    : dict.t("Não publicado");

  return (
    <div className="row list-zebra-item-header-text" style={{ width: "71vw" }}>
      <div className="col-md-4">
        {props.item[language].title ? props.item[language].title : props.item["PT"].title}
      </div>

      <div className="col-md-4">{author}</div>

      <div className="col-md-4">{publish}</div>
    </div>
  );
}


