
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Formulário de edição de supporters
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import { normalizerCNPJ, normalizerPhone, normalizerCEP } from "../../../../lib/Normalizer";
import Submit from "../../../..//lib/Forms/Submit";

import ScreenSize from "../../../Commons/ScreenSize";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";
import PublicRequest from "../../../../lib/Request";

import Authentication from "../../../../lib/Authentication";

import backendUserRoutes from "../../../../constants/backendUserRoutes";

import config from "../../../../config";

import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());

const smallScreenWidth = 768;



class SupporterForm extends Component {
  constructor(props) {
    super(props);

    this.state = { record: null };
  }

  componentWillMount() {
    this.fecthRecord(this.props.params.id);
  }

  render() {
    if (!this.state.record) return <Loader />;

    let backStyle;
    let marginBottom = "50px";

    if (this.state.smallScreen) {
      backStyle = { bottom: "-45px" };
      marginBottom = "80px";
    }

    return (
      <div className="container form-container page-margin-top footer-margin-logged">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">{dict.t("Editar Apoiador")}</div>

        <div className="col-md-12 form-body" style={{ marginBottom }}>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="company"
                  label={dict.t("Razão Social")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="trade"
                  label={dict.t("Nome Fantasia")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Field
                  name="cnpj"
                  label={dict.t("CNPJ")}
                  validate={[VF.Required]}
                  normalize={normalizerCNPJ}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="phone"
                  label={dict.t("Telefone")}
                  validate={[VF.Required]}
                  normalize={normalizerPhone}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Field
                  name="sector"
                  label={dict.t("Setor")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <Field
                  name="cep"
                  label={dict.t("CEP")}
                  validate={[VF.Required]}
                  normalize={normalizerCEP}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                  onBlur={this.handleCepBlur}
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="neighborhood"
                  label={dict.t("Bairro")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="city"
                  label={dict.t("Cidade")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="state"
                  label={dict.t("Estado")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Field
                  name="streetName"
                  label={dict.t("Endereço")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="addressNumber"
                  label={dict.t("Número")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="complement"
                  label={dict.t("Complemento")}
                  //validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <div>
              <Submit
                router={this.props.router}
                buttonLabel={dict.t("Salvar")}
                backStyle={backStyle}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  fecthRecord(id) {
    const role = Authentication.getUser().personal.role;
    const url = `/${backendUserRoutes(role)}users/${id}`;

    Request.get(url, values => {
      this.setState({ record: values });
      this.props.initialize(this.state.record);
    });
  }

  updateRecord(values) {
    const id = values._id;
    const role = Authentication.getUser().personal.role;
    const url = `/${backendUserRoutes(role)}users/supporter/${id}`;

    values.supporter = id;

    Request.post(url, values, res => {
      if (res.success) {
        Alert.success(dict.t("Edição efetuada com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  handleSubmit = values => {
    this.updateRecord(values);
  };

  handleCepBlur = ev => {
    const cep = ev.target.value.replace("-", "");

    if (cep.length === 8) {
      PublicRequest.get(config.api.cep(cep), data => {
        if (!data.erro) {
          this.props.change("streetName", data.logradouro);
          this.props.change("neighborhood", data.bairro);
          this.props.change("city", data.localidade);
          this.props.change("state", data.uf);
        }
      });
    }
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "supporterForm",
})(SupporterForm);


