
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de progresso
//


import React, { Component } from "react";

import "./style.css";



export default class ProgressBar extends Component {
  static defaultProps = {
    height: "35px",
    markerHeight: "51px",
    markerWidth: "2px",
    markerFontSize: "14px",
  };

  render() {
    const markerTop = `${-(
      this.props.markerHeight.replace("px", "") - this.props.height.replace("px", "")
    ) / 2}px`;
    const labelTop = `${-22 + parseInt(markerTop.replace("px", ""), 10)}px`;

    const labelSide = this.props.markerPos > 50 ? "translate(-100%, 0)" : "";

    return (
      <div className="progress-bar-container1">
        <div className="progress-bar-container2" style={{ height: this.props.height }}>
          <div
            className="progress-bar"
            style={{ height: this.props.height, width: `${this.props.value}%` }}
          ></div>
        </div>

        <div
          className="progress-bar-marker"
          style={{
            top: markerTop,
            width: this.props.markerWidth,
            height: this.props.markerHeight,
            left: `${this.props.markerPos}%`,
          }}
        >
          <div
            className="progress-bar-marker-label"
            style={{ top: labelTop, fontSize: this.props.markerFontSize, transform: labelSide }}
          >
            {this.props.markerLabel}
          </div>
        </div>
      </div>
    );
  }
}


