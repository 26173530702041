
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Imagem de status de um documento do proponente
//


import React from "react";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default function(props) {
  const style = !props.item.image ? { overflow: "visible" } : {};

  return (
    <div className="status-list-item-image-container" style={style}>
      <div className="status-list-item-image">
        {props.item.status === "approved" ? (
          <img
            className="status-list-image-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/ok-icon.png"
            alt={dict.t("Documento aprovado")}
            title={dict.t("Documento aprovado")}
          />
        ) : props.item.status === "refused" ? (
          <img
            className="status-list-image-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/error-icon.png"
            alt={dict.t("Documento recusado")}
            title={dict.t("Documento recusado")}
          />
        ) : (
          <img
            className="status-list-image-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/warning-icon.png"
            alt={dict.t("Documento pendente")}
            title={dict.t("Documento pendente")}
          />
        )}
      </div>
    </div>
  );
}


