
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Componente de formulário para entrada de texto formatado
//


import React, { Component } from "react";

import ReactQuill from "react-quill";

import InfoIcon from "../../components/InfoIcon";

import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ size: ["small", "normal", "large", "huge"] }],
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ align: ["", "center", "right", "justify"] }],
    ["image", "video", "link"],
  ],
};

const formats = [
  "size",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "image",
  "video",
  "link",
];



export default class TextArea extends Component {
  render() {
    return (
      <div className="form-group">
        <div className="form-input-label">
          {this.props.label}

          {this.renderInfo}
        </div>

        <div className="form-input-text">
          <ReactQuill
            value={this.props.input.value}
            modules={modules}
            formats={formats}
            onChange={this.props.input.onChange}
          />
        </div>
      </div>
    );
  }

  get renderInfo() {
    if (!this.props.info) return null;

    return <InfoIcon info={this.props.info} />;
  }

  get renderErrorMessage() {
    if (!this.hasError()) return null;

    return <label className="react-input-error">{this.getErrorMessage()}</label>;
  }

  hasError() {
    return this.props.meta.touched && this.props.meta.error;
  }

  getErrorMessage() {
    let errorMessage = null;

    //validate if the current fields has any error
    if (this.hasError()) {
      let errorContent = this.props.meta.error;

      if (this.props.errorMessage !== undefined && this.props.errorMessage[errorContent])
        errorMessage = this.props.errorMessage[errorContent];
      else errorMessage = errorContent;
    }

    return errorMessage;
  }
}


