import React, { Component } from "react";

import InfoIcon from "../../components/InfoIcon";

export default class Input extends Component {
  render() {
    let type = this.props.type ? this.props.type : "text";

    let css = this.props.cssClass;
    css += this.hasError() ? ` ${this.props.cssClassError}` : "";

    let placeholder = this.props.placeholder ? this.props.placeholder : this.props.label;
    placeholder = this.hasError() ? "*" + this.getErrorMessage() : this.props.placeholder;

    return (
      <div className="form-group" style={this.props.containerStyle}>
        <div className="form-input-label">
          {this.props.label}

          {this.renderInfo}
        </div>

        <div className="form-input-text">
          <input
            {...this.props.input}
            type={type}
            placeholder={placeholder}
            autoComplete={this.props.autoComplete}
            className={"form-control " + css}
          />

          {this.hasError() ? <div className="form-input-error-detail" /> : null}
        </div>
      </div>
    );
  }

  get renderInfo() {
    if (!this.props.info) return null;

    return <InfoIcon info={this.props.info} />;
  }

  hasError() {
    return this.props.meta.touched && this.props.meta.error;
  }

  getErrorMessage() {
    let errorMessage = null;

    //validate if the current fields has any error
    if (this.hasError()) {
      let errorContent = this.props.meta.error;

      if (this.props.errorMessage !== undefined && this.props.errorMessage[errorContent])
        errorMessage = this.props.errorMessage[errorContent];
      else errorMessage = errorContent;
    }

    return errorMessage;
  }
}
