
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Corpo do item de lista de usuários
//


import React from "react";

import "./style.css";



export default function(props) {
  return (
    <div className="supporter-user-list-item-container row">
      <div className="col-md-4">{props.item.name}</div>

      <div className="col-md-5">{props.item.email}</div>

      <div className="col-md-3">{props.item.companyRole}</div>
    </div>
  );
}


