
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de busca de artigos
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import Search from "../../lib/Forms/Search";

import "./style.css";



class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: {},
    };
  }

  render() {
    return (
      <div className="form-container" style={this.props.style}>
        <form>
          <Field
            name="search"
            cssClass="form-input"
            cssClassError="form-input-error"
            component={Search}
            onChange={this.handleSearchChange}
          />
        </form>
      </div>
    );
  }

  handleSearchChange = e => {
    let search = this.state.search;
    search.search = e.target.value;
    this.setState({ search });

    if (this.props.onSearch) this.props.onSearch(this.state.search);
  };
}



export default reduxForm({
  form: "postSearchBarForm",
})(SearchBar);


