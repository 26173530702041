
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cartão de estatísticas
//


import React, { Component } from "react";



export default class StatCard extends Component {
  render() {
    return (
      <div className="col-xs-6 col-sm-3 stat-card-containter">
        <div className="stat-card">
          <div className="stat-card-value">{this.props.value}</div>

          <div className="stat-card-text">{this.props.text}</div>
        </div>
      </div>
    );
  }
}


