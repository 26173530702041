export default {
  basic: [
    { name: "proponentManual", requiredValue: true },
    { name: "name", required: true },
    { name: "shortName", required: true },
    { name: "city", required: true },
    { name: "state", required: true },
    { name: "biomes", required: true },
  ],

  classification: [
    { name: "legalFitness", required: false },
    { name: "areasOfInterest" },
    { name: "ods" },
  ],

  details: [
    { name: "summary" },
    { name: "description" },
    { name: "expectedIndicators" },
    { name: "images", required: true },
    { name: "document", required: true },
    { name: "totalValue", required: true },
    { name: "numOfShares", required: true },
    { name: "minimumValue" },
    { name: "inOperation" },
    { name: "startDate" },
    { name: "endDate" },
  ],

  gratifications: [{ name: "gratifications" }],
};
