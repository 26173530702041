
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de áres de interesse - master
//


import React, { Fragment } from "react";

import Back from "../../../Commons/Back";

import multiLang from "../../../../lib/multilang/multiLang";
import masterAccess from "../../../../lib/masterAccess";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



function renderPendingToolbar(props) {
  return (
    <Fragment>
      <button
        className="button project-intern-toolbar-item project-intern-toolbar-button red-background"
        onClick={props.onDeny}
        style={{ marginBottom: "20px" }}
      >
        {dict.t("Negar")}
      </button>

      <button
        className="button project-intern-toolbar-item project-intern-toolbar-button"
        onClick={props.onApprove}
      >
        {dict.t("Aprovar")}
      </button>
    </Fragment>
  );
}



function renderDeleteToolbar(props) {
  return (
    <div className="project-intern-toolbar-item" onClick={props.onDelete}>
      <img
        src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon.png"
        alt={dict.t("Deletar")}
        title={dict.t("Deletar")}
        style={{ height: "25px" }}
      />
    </div>
  );
}



export default function(props) {
  function selectToolbar(status) {
    if (!masterAccess("supportApprove")) return null;

    switch (status) {
      case "pending":
        return renderPendingToolbar(props);
      case "refused":
        return renderDeleteToolbar(props);
      case "approved":
        return renderDeleteToolbar(props);
      default:
        return null;
    }
  }

  return (
    <div className="container page-margin-top">
      <div className="project-intern-back-button-container" style={{ marginLeft: "-15px" }}>
        <Back router={props.router} />
      </div>

      <div className="project-intern-toolbar-container" style={{ marginRight: "-15px" }}>
        {selectToolbar(props.status)}
      </div>
    </div>
  );
}


