
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Form de busca
//


import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";

import ScreenSize from "../../Commons/ScreenSize";

import CardCheckbox from "../../../lib/Forms/CardCheckbox";
import Checkbox from "../../../lib/Forms/Checkbox";
import Dropdown from "../../../lib/Forms/Dropdown";
import Range from "../../Commons/Range";

import Accordion from "../../Commons/Accordion";

import legalFitness from "../../../constants/legalFitness";
import legalFitnessCheckboxStyle from "../../../constants/legalFitnessCheckboxStyle";
import ODS from "../../../constants/ods";
import biomes from "../../../constants/biomes";

import stateList from "../../../constants/stateList";

import { formatCurrencyNoCents } from "../../../lib/FormatString";

import languageTools from "../../../lib/languageTools";

import multiLang from "../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const inOperationValues = [
  { label: dict.t("Sim"), value: true },
  { label: dict.t("Não"), value: false },
];



class SearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      search: {},
    };
  }

  componentWillMount() {
    let formData = JSON.parse(localStorage.getItem("search"));

    if (!formData) {
      formData = {};
    }
    const query = this.props.query;
    if (query.promo && query.promo === "amazonia") {
      formData.amazonia = true;
    }

    this.setState({ formData });
    this.props.initialize(formData);
    this.generateSearchData(formData);
  }

  getProjectsValues() {
    const values = this.props.projects.map(project => project.totalValue);

    if (!values.length) values.push(0);

    return values;
  }

  render() {
    const values = this.getProjectsValues();

    const maxValue = Math.ceil(Math.max(...values));
    const minValue = Math.floor(Math.min(...values));

    const defaultContributionVolume = this.state.formData
      ? this.state.formData.contributionVolume
      : undefined;

    return (
      <div className="projects-search-container">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <form>
          <div className="projects-accordion-container">
            <Accordion
              title={dict.t("Adequação Legal")}
              contentComponent={this.getLegalFitnessFields(legalFitness)}
            />
          </div>

          <div className="projects-accordion-container">
            <Accordion
              title={dict.t("Áreas de Interesse")}
              contentComponent={this.getAreasOfInterestFields(this.props.areasOfInterest)}
            />
          </div>

          <div className="projects-accordion-container">
            <Accordion title={dict.t("ODS")} contentComponent={this.getODSs(ODS)} />
          </div>

          <Field
            name="state"
            label={dict.t("Estado")}
            data={stateList}
            dataKey="id"
            dataLabel="shortName"
            placeholder={dict.t("Todos")}
            placeholderSelectable={true}
            labelClass="projects-search-section-label"
            cssClass="form-input"
            cssClassError="form-input-error"
            component={Dropdown}
            onChange={this.handleStateChange}
          />

          <div className="projects-search-section-label">{dict.t("Bioma")}</div>

          <div className="row">{this.getBiomeFields(biomes)}</div>

          <div className="projects-search-section-label">{dict.t("Volume do Aporte")}</div>

          <div className="">
            <Range
              defaultValue={defaultContributionVolume}
              /* defaultValue={ [minValue, maxValue] } */
              min={minValue}
              max={maxValue}
              formatLowerlabelFunc={text => `${dict.t("De")}: ${formatCurrencyNoCents(text)}`}
              formatUpperlabelFunc={text => `${dict.t("Até")}: ${formatCurrencyNoCents(text)}`}
              onChange={this.handleContributionVolume}
            />
          </div>

          <Field
            name="inOperation"
            label={dict.t("Projeto em Operação?")}
            data={inOperationValues}
            dataKey="value"
            dataLabel="label"
            placeholder={dict.t("Não definido")}
            placeholderSelectable={true}
            labelClass="projects-search-section-label"
            cssClass="form-input"
            cssClassError="form-input-error"
            component={Dropdown}
            onChange={this.handleStatusChange}
          />

          <div className="projects-search-text-container">
            {dict.t("Não achou o projeto que estava procurando?")}
            <br />
            <span style={{ fontWeight: "bold" }}>
              {`${dict.t("Fale com a gente")}: +55 (51) 4042.0039`}{" "}
            </span>
            <br />
            <span style={{ fontWeight: "bold" }}>{"vbio@vbio.eco"}</span>
          </div>
        </form>
      </div>
    );
  }

  getLegalFitnessFields(items) {
    if (!items) return "";

    return items.map((item, index) => {
      const last = index === items.length - 1;

      const style = last ? { marginBottom: "12px" } : {};

      return (
        <div key={index} className="projects-search-field" style={style}>
          <Field
            name={item.name}
            label={dict.t(item.label)}
            icon={item.icon}
            iconStyle={legalFitnessCheckboxStyle.iconStyle}
            labelStyle={legalFitnessCheckboxStyle.labelStyle}
            componentStyle={legalFitnessCheckboxStyle.componentStyle}
            checkedColor={"#EFA500"}
            height="47px"
            cssClass="form-input-card-checkbox"
            cssClassError="form-input-error"
            component={CardCheckbox}
            onChange={this.handleLegalFitnessChange}
          />
        </div>
      );
    });
  }

  getAreasOfInterestFields(items) {
    if (!items) return "";

    return items.map((item, index) => {
      const last = index === items.length - 1;

      const style = last ? { marginBottom: "0" } : {};

      item[language] = languageTools.merge(item.PT, item[language]);

      return (
        <div key={index} className="projects-search-field" style={style}>
          <Field
            name={item._id}
            label={item[language].name}
            icon={item.image}
            checkedColor={"#24A862"}
            height="50px"
            cssClass="form-input-card-checkbox"
            cssClassError="form-input-error"
            component={CardCheckbox}
            onChange={this.handleAreasOfInterestChange}
          />
        </div>
      );
    });
  }

  getODSs(items) {
    if (!items) return "";

    return items.map((item, index) => {
      const last = index === items.length - 1;

      const style = last ? { marginBottom: "0" } : {};

      return (
        <div key={index} className="projects-search-field" style={style}>
          <Field
            name={item.name}
            label={dict.t(item.label)}
            icon={item.icon[language]}
            checkedColor={item.color}
            height="50px"
            cssClass="form-input-card-checkbox"
            cssClassError="form-input-error"
            component={CardCheckbox}
            onChange={this.handleODSChange}
          />
        </div>
      );
    });
  }

  getBiomeFields(items) {
    if (!items) return "";

    return items.map((item, index) => {
      return (
        <div key={index} className="col-lg-12" style={{ marginTop: "8px" }}>
          <Field
            name={item.name}
            label={dict.t(item.label)}
            cssClass="form-input-checkbox"
            cssClassError="form-input-error"
            component={Checkbox}
            onChange={this.handleBiomeChange}
          />
        </div>
      );
    });
  }

  generateSearchData(formData) {
    let search = {};

    for (let property in formData) {
      if (legalFitness.map(item => item.name).indexOf(property) !== -1 && formData[property]) {
        if (!search.legalFitness) search.legalFitness = [];
        search.legalFitness.push(property);
      }

      if (
        this.props.areasOfInterest.map(item => item._id).indexOf(property) !== -1 &&
        formData[property]
      ) {
        if (!search.areasOfInterest) search.areasOfInterest = [];
        search.areasOfInterest.push(property);
      }

      if (ODS.map(item => item.name).indexOf(property) !== -1 && formData[property]) {
        if (!search.ODS) search.ODS = [];
        search.ODS.push(property);
      }

      if (biomes.map(item => item.name).indexOf(property) !== -1 && formData[property]) {
        if (!search.biomes) search.biomes = [];
        search.biomes.push(property);
      }

      if (property === "state") {
        if (formData[property] !== "0") search.state = formData[property];
      }

      if (property === "contributionVolume") {
        search.contributionVolume = formData[property];
      }

      if (property === "inOperation") {
        if (formData[property] !== "0") search.inOperation = formData[property];
      }
    }

    this.setState({ search });

    if (this.props.onFilterChange) this.props.onFilterChange(search);
  }

  setSearchItem(value, field, add = true, array = true) {
    let items = this.state.search[field] || (array ? [] : undefined);

    if (add) {
      if (array) items.push(value);
      else items = value;
    } else {
      if (array) {
        const index = items.indexOf(value);
        items.splice(index, 1);
      } else items = undefined;
    }

    const search = this.state.search;

    if (!items) delete search[field];
    else search[field] = items;

    this.setState({ search });

    if (this.props.onFilterChange) this.props.onFilterChange(search);
  }

  saveFormItem(name, value) {
    let formData = this.state.formData || {};
    formData[name] = value;
    this.setState({ formData });

    localStorage.setItem("search", JSON.stringify(formData));
  }

  handleScreenResize = screen => {
    const { width } = screen;

    this.setState({ screenWidth: width });
  };

  handleLegalFitnessChange = e => {
    this.setSearchItem(e.name, "legalFitness", e.value);
    this.saveFormItem(e.name, e.value);
  };

  handleAreasOfInterestChange = e => {
    this.setSearchItem(e.name, "areasOfInterest", e.value);
    this.saveFormItem(e.name, e.value);
  };

  handleODSChange = e => {
    this.setSearchItem(e.name, "ODS", e.value);
    this.saveFormItem(e.name, e.value);
  };

  handleStateChange = e => {
    if (e.target.value === "0") {
      this.setSearchItem(e.target.value, e.target.name, false, false);
    } else {
      this.setSearchItem(e.target.value, e.target.name, true, false);
    }

    this.saveFormItem(e.target.name, e.target.value);
  };

  handleBiomeChange = e => {
    this.setSearchItem(e.name, "biomes", e.value);
    this.saveFormItem(e.name, e.value);
  };

  handleContributionVolume = value => {
    this.setSearchItem(value, "contributionVolume", true, false);
    this.saveFormItem("contributionVolume", value);
  };

  handleStatusChange = e => {
    if (e.target.value === "0") {
      this.setSearchItem(e.target.value, e.target.name, false, false);
    } else {
      this.setSearchItem(e.target.value, e.target.name, true, false);
    }
    this.saveFormItem("inOperation", e.target.value);
  };
}



export default reduxForm({
  form: "projectSearchForm",
})(SearchForm);


