import React, { Fragment } from "react";
import { IndexRoute, Router, Route, browserHistory } from "react-router";

import ReactGA from "react-ga";

import Authentication from "./lib/Authentication";

import userRoutes from "./constants/userRoutes";

import App from "./components/App";

import Home from "./components/Home";

import Blog from "./components/Blog";
import BlogPost from "./components/Blog/Post";

import About from "./components/About";
import HowToSupport from "./components/HowTo/Support.js";
import HowToPropose from "./components/HowTo/Propose.js";
import Login from "./components/Login";
import ForgotPassword from "./components/Login/Forgot";
import ChangePassword from "./components/Login/Change";
import TermOfUse from "./components/TermOfUse";

import Dashboard from "./components/RestrictedArea/Dashboard";

import HomeBanners from "./components/RestrictedArea/Master/HomeBanners";
import HomeProjects from "./components/RestrictedArea/Master/HomeProjects";
import HomeProjectsForm from "./components/RestrictedArea/Master/HomeProjects/Form";

import BannerForm from "./components/RestrictedArea/Master/HomeBanners/BannerForm";
import StatsForm from "./components/RestrictedArea/Master/HomeBanners/StatsForm";

import AreasOfInterest from "./components/RestrictedArea/Master/AreasOfInterest";
import AreaOfInterestForm from "./components/RestrictedArea/Master/AreasOfInterest/Form";

import Authors from "./components/RestrictedArea/Master/Authors";
import AuthorForm from "./components/RestrictedArea/Master/Authors/Form";

import Categories from "./components/RestrictedArea/Master/Categories";
import CategoryForm from "./components/RestrictedArea/Master/Categories/Form";

import BlogPosts from "./components/RestrictedArea/Master/BlogPost";
import BlogNewPost from "./components/RestrictedArea/Master/BlogPost/PostForm";

import SupporterRegister from "./components/Supporter";

import SupporterProfile from "./components/RestrictedArea/Supporter/Profile";
import SupporterUserForm from "./components/RestrictedArea/Supporter/Profile/UserForm";
import SupporterEditForm from "./components/RestrictedArea/Supporter/Profile/SupporterEditForm";
import SupporterUserPasswordForm from "./components/RestrictedArea/Supporter/Profile/UserPasswordForm";

import ProponentRegister from "./components/Proponent";

import NewProject from "./components/RestrictedArea/NewProject";
import Projects from "./components/RestrictedArea/Projects";
import ProjectsIntern from "./components/RestrictedArea/Projects/Intern";
import SupportedProject from "./components/RestrictedArea/MyProjects/Intern";
import ProjectsListFilter from "./components/RestrictedArea/Projects/ListFilter";
import MyProjects from "./components/RestrictedArea/MyProjects";

import ProponentsListFilter from "./components/RestrictedArea/Proponent/ListFilter";
import ProponentIntern from "./components/RestrictedArea/Proponent/Intern";
import ProponentEditForm from "./components/RestrictedArea/Proponent/Profile/ProponentEditForm";

import ProponentProfile from "./components/RestrictedArea/Proponent/Profile";

import ProponentUserForm from "./components/RestrictedArea/Proponent/Profile/UserForm";
import ProponentUserPasswordForm from "./components/RestrictedArea/Proponent/Profile/UserPasswordForm";

import ProponentDocuments from "./components/RestrictedArea/Proponent/Documents";

import SupportsListFilter from "./components/RestrictedArea/Supports/ListFilter";
import SupportsIntern from "./components/RestrictedArea/Supports/Intern";

import SupporterListFilter from "./components/RestrictedArea/Supporter/ListFilter";
import SupporterIntern from "./components/RestrictedArea/Supporter/Intern";

import Documents from "./components/RestrictedArea/Master/Documents";
import DocumentsForm from "./components/RestrictedArea/Master/Documents/FormDocumentProponent";
import TermsForm from "./components/RestrictedArea/Master/Documents/FormTermOfUse";

import Users from "./components/RestrictedArea/Master/Users";
import UsersForm from "./components/RestrictedArea/Master/Users/Form";
import UsersPasswordForm from "./components/RestrictedArea/Master/Users/PasswordForm";

import Profiles from "./components/RestrictedArea/Master/Profiles";
import ProfilesForm from "./components/RestrictedArea/Master/Profiles/Form";

import MyPasswordForm from "./components/RestrictedArea/Profile/UserPasswordForm";




export default function AppRouter() {
  return (
    <Router onUpdate={onRouterUpdate} history={browserHistory}>
      <Route path="/" component={App}>
        <IndexRoute title="Home" component={Home} />

        <Route path="/blog" title="Blog" component={Blog} />
        <Route path="/blog/:tag" title="Blog" component={Blog} />
        <Route path="/blog/post/:id" title="Blog" component={BlogPost} />

        <Route path="/sobre" title="Sobre a VBIO" component={About} />

        <Route
          path="/login"
          title="Login"
          component={Login}
          hideFooter={true}
          hideMenu={true}
          onEnter={validateAlreadyLogged}
        />
        <Route
          path="/login/esqueci-minha-senha"
          title="Esqueci minha senha"
          component={ForgotPassword}
          hideFooter={true}
          hideMenu={true}
        />
        <Route
          path="/login/redefinir-senha/:id"
          title="Esqueci minha senha"
          component={ChangePassword}
          hideFooter={true}
          hideMenu={true}
          onEnter={validateAlreadyLogged}
        />
        <Route path="/logout" title="Logout" onEnter={logout} />

        {/* apoiador - área aberta */}
        <Route
          path="/apoiador/novo"
          title="Apoiador - Cadastro"
          component={SupporterRegister}
          hideFooter={true}
          hideMenu={true}
        />
        <Route path="/como-apoiar" title="Como Apoiar" component={HowToSupport} />
        <Route
          path="/termo-de-uso/:userType"
          title="Termo de Uso"
          component={TermOfUse}
          hideFooter={true}
          hideMenu={true}
        />

        {/* proponente - área aberta */}
        <Route
          path="/proponente/novo"
          title="proponente - Cadastro"
          component={ProponentRegister}
          hideFooter={true}
          hideMenu={true}
        />
        <Route path="/como-propor" title="Como Propôr" component={HowToPropose} />

        {/* master - área restrita */}
        <Route
          path="/master/dashboard"
          title="Dashboard"
          component={Dashboard}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/configuracao/home-banners"
          title="Banners da Home"
          component={HomeBanners}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/home-banners/banner/novo"
          title="Novo Banner"
          component={BannerForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/home-banners/banner/editar/:id"
          title="Editar Banner"
          component={BannerForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/home-banners/estatisticas/editar"
          title="Editar Estatísticas"
          component={StatsForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/configuracao/home-projetos"
          title="Projetos da Home"
          component={HomeProjects}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/home-projetos/destaque"
          title="Escolher projeto em destaque da Home"
          component={HomeProjectsForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/home-projetos/vitrine/:id"
          title="Escolher projetos da vitrine da Home"
          component={HomeProjectsForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/configuracao/areas-de-interesse"
          title="Áreas de Interesse"
          component={AreasOfInterest}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/areas-de-interesse/novo"
          title="Nova Área de Interesse"
          component={AreaOfInterestForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/areas-de-interesse/editar/:id"
          title="Editar Área de Interesse"
          component={AreaOfInterestForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/blog/autores"
          title="Autores"
          component={Authors}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/blog/autores/novo"
          title="Novo Autor"
          component={AuthorForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/blog/autores/editar/:id"
          title="Editar Autor"
          component={AuthorForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/blog/artigos"
          title="Posts"
          component={BlogPosts}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/blog/artigos/novo"
          title="Novo Artigo"
          component={BlogNewPost}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/blog/artigos/editar/:id"
          title="Editar Artigo"
          component={BlogNewPost}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/blog/categorias"
          title="Categorias"
          component={Categories}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/blog/categorias/novo"
          title="Nova Categoria"
          component={CategoryForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/blog/categorias/editar/:id"
          title="Editar Categoria"
          component={CategoryForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/projetos/novo"
          title="Novo Projeto"
          component={NewProject}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/projetos/lista/:filter"
          title="Projetos"
          component={ProjectsListFilter}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/projetos/lista"
          component={RedirectToHome}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/projetos/:id"
          title=""
          component={ProjectsIntern}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/projetos/editar/:id"
          title=""
          component={NewProject}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/projetos"
          title="Projetos"
          component={Projects}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/apoios/lista/:filter"
          title="Apoios"
          component={SupportsListFilter}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/apoios/lista"
          component={RedirectToHome}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/apoios/:id"
          title="Apoio"
          component={SupportsIntern}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/apoiadores/lista"
          title="Apoiadores"
          component={SupporterListFilter}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/apoiadores/:id"
          title="Apoiadores"
          component={SupporterIntern}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/apoiadores/editar/:id"
          title="Apoiador - Editar"
          component={SupporterEditForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/apoiadores/usuario/novo/:supporterId"
          title="Apoiador - Novo usuário"
          component={SupporterUserForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/proponentes/lista/:filter"
          title="Proponentes"
          component={ProponentsListFilter}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/proponentes/lista"
          component={RedirectToHome}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/proponentes/editar/:id"
          title="Proponente - Editar"
          component={ProponentEditForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/proponentes/:id"
          title="Proponentes"
          component={ProponentIntern}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/proponente/usuario/novo/:proponentId"
          title="Proponente - Novo usuário"
          component={ProponentUserForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/master/configuracao/documentos"
          title="Documentos"
          component={Documents}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/documentos/proponente/editar/:id"
          title="Editar Documentos"
          component={DocumentsForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/documentos/proponente/novo"
          title="Novo Documentos"
          component={DocumentsForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/documentos/termo-de-uso/editar/:id"
          title="Editar Termo"
          component={TermsForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/usuarios"
          title="Usuários"
          component={Users}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/usuarios/novo"
          title="Novo Usuário"
          component={UsersForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/usuarios/editar/:id"
          title="Editar Usuário"
          component={UsersForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/usuarios/alterar-senha/:id"
          title="Alterar Senha"
          component={UsersPasswordForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/perfis"
          title="Perfis"
          component={Profiles}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/perfis/novo"
          title="Novo Perfil"
          component={ProfilesForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/master/configuracao/perfis/editar/:id"
          title="Editar Perfil"
          component={ProfilesForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        {/* apoiador - área restrita */}
        <Route
          path="/apoiador/dashboard"
          title="Dashboard"
          component={Dashboard}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/perfil"
          title="Apoiador - Perfil"
          component={SupporterProfile}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/usuario/novo/:supporterId"
          title="Apoiador - Novo usuário"
          component={SupporterUserForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/usuario/editar/altera-senha/:id"
          title="Apoiador - Editar senha do usuário"
          component={SupporterUserPasswordForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/usuario/editar/:id"
          title="Apoiador - Editar usuário"
          component={SupporterUserForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/projetos/:id"
          title="Projeto"
          component={ProjectsIntern}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/projetos/apoio/:id"
          title="Projeto"
          component={SupportedProject}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/editar/:id"
          title="Apoiador - Editar"
          component={SupporterEditForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/projetos"
          title="Projetos"
          component={Projects}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/apoiador/meus-projetos"
          title="Meus Projetos"
          component={MyProjects}
          onEnter={validateRestrictedAreaRoleBased}
        />

        {/* proponente - área restrita */}
        <Route
          path="/proponente/dashboard"
          title="Dashboard"
          component={Dashboard}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/projetos/novo"
          title="Novo Projeto"
          component={NewProject}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/meus-projetos"
          title="Meus Projetos"
          component={MyProjects}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/projetos/editar/:id"
          title=""
          component={NewProject}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/projetos/apoio/:id"
          title="Projeto"
          component={SupportedProject}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/projetos/:id"
          title="Projeto"
          component={ProjectsIntern}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/proponente/projetos"
          title="Projetos"
          component={Projects}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/perfil"
          title="Perfil do Proponente"
          component={ProponentProfile}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/editar/:id"
          title="Proponentes - Editar"
          component={ProponentEditForm}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/proponente/usuario/novo/:proponentId"
          title="Proponente - Novo usuário"
          component={ProponentUserForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/usuario/editar/altera-senha/:id"
          title="Proponente - Editar senha do usuário"
          component={ProponentUserPasswordForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/usuario/editar/:id"
          title="Proponente - Editar usuário"
          component={ProponentUserForm}
          onEnter={validateRestrictedAreaRoleBased}
        />
        <Route
          path="/proponente/habilitacao"
          title="Habilitação"
          component={ProponentDocuments}
          onEnter={validateRestrictedAreaRoleBased}
        />

        <Route
          path="/meu/altera-senha"
          title="Alterar minha senha"
          component={MyPasswordForm}
          onEnter={validateRestrictedArea}
        />

        {/* redirecionamento para home */}
        <Route path="*" title="VBIO - Home" component={RedirectToHome} />
      </Route>
    </Router>
  );
}



function onRouterUpdate() {
  //scroll to the top
  window.scrollTo(0, 0);

  //set page view to GA
  ReactGA.pageview(window.location.pathname);
}

/* Route validators based on login status */
function validateRestrictedArea(nextState, replace) {
  if (nextState !== undefined)
    if (!Authentication.isAuthenticated())
      replace({
        pathname: "/login",
        state: { nextPathname: nextState.location.pathname },
      });
}

/* Route validators based on login status */
function validateRestrictedAreaRoleBased(nextState, replace) {
  if (nextState !== undefined) {
    if (!Authentication.isAuthenticated()) {
      replace({
        pathname: "/login",
        state: { nextPathname: nextState.location.pathname },
      });
    }

    const pathUserRole = nextState.location.pathname.split("/")[1];

    const personal = Authentication.getUser().personal;

    if (personal) {
      const userRoute = userRoutes(personal.role).replace("/", "");

      // user acessando área não autorizada
      if (pathUserRole !== userRoute) {
        replace({ pathname: "/" });
      }
    }
  }
}



function validateAlreadyLogged(nextState, replace) {
  if (nextState !== undefined) {
    if (Authentication.isAuthenticated()) {
      replace({
        pathname: "/",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  }
}



function logout(nextState, replace) {
  if (nextState !== undefined) {
    Authentication.logout();

    replace({
      pathname: "/",
      state: { nextPathname: nextState.location.pathname },
    });
  }
}



function RedirectToHome(props) {
  return <Fragment>{props.router.push("/")}</Fragment>;
}


