
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Links para redes sociais
//


export default {
  facebook: "https://www.facebook.com/vbioeco/",
  linkedin: "https://www.linkedin.com/company/vbioeco/",
  twitter: "https://twitter.com/vbioeco",
  instagram: "https://www.instagram.com/vbio.eco/",
  youtube: "https://www.youtube.com/channel/UC9UnFUZSBFFtKp0u_mBCmEw",
};


