
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário parcial de estatística
//


import React, { Component, Fragment } from "react";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class StatPartialForm extends Component {
  render() {
    return (
      <Fragment>
        <div className="page-title" style={{ marginBottom: "45px" }}>
          {dict.t("Estatística")}
        </div>

        <div className="row">
          <div className="col-md-5">
            <Field
              name={`${this.props.index}.[${language}].value`}
              label={dict.t("Numeral")}
              validate={[VF.Required]}
              cssClass="form-input"
              cssClassError="form-input-error"
              component={Input}
            />
          </div>

          <div className="col-md-7">
            <Field
              name={`${this.props.index}.[${language}].label`}
              label={dict.t("Descritivo")}
              validate={[VF.Required]}
              cssClass="form-input"
              cssClassError="form-input-error"
              component={Input}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}


