import React, { Component } from "react";

import InfoIcon from "../../components/InfoIcon";

export default class RadioButton extends Component {
  constructor(props) {
    super(props);

    this.state = { checked: false };
  }

  render() {
    let css = this.props.cssClass;
    css += this.hasError() ? ` ${this.props.cssClassError}` : "";

    const checked = this.props.id === this.props.input.value;

    const checkedIcon = checked
      ? { backgroundImage: "url(/assets/img/checked-icon.png)" }
      : { backgroundImage: "none" };

    const labelStyle = Object.assign(
      { verticalAlign: "middle", marginLeft: "20px" },
      this.props.labelStyle || {}
    );

    return (
      <div className="form-group">
        <div className={css} style={checkedIcon}>
          <input {...this.props.input} type="radio" onChange={this.handleChange} />
        </div>

        <span className="form-input-label" style={labelStyle}>
          {this.props.label}
          {this.renderInfo}
        </span>
      </div>
    );
  }

  handleChange = e => {
    e.target.value = this.props.id;

    if (this.props.input.onChange) this.props.input.onChange(e);
  };

  get renderInfo() {
    if (!this.props.info) return null;

    return <InfoIcon info={this.props.info} />;
  }

  hasError() {
    return this.props.meta.touched && this.props.meta.error;
  }

  getErrorMessage() {
    let errorMessage = null;

    //validate if the current fields has any error
    if (this.hasError()) {
      let errorContent = this.props.meta.error;

      if (this.props.errorMessage !== undefined && this.props.errorMessage[errorContent])
        errorMessage = this.props.errorMessage[errorContent];
      else errorMessage = errorContent;
    }

    return errorMessage;
  }
}
