
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Componente accordion
//


import React, { Component } from "react";

import "./style.css";



export default class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  render() {
    const buttonIcon = this.state.open
      ? "https://vbio.blob.core.windows.net/public/assets/img/accordion/button-up.png"
      : "https://vbio.blob.core.windows.net/public/assets/img/accordion/button-down.png";

    const contentStyle = this.state.open ? { display: "block" } : {};

    return (
      <div className="accordion-container">
        <div className="accordion-title">{this.props.title}</div>

        <img
          className="accordion-button"
          src={buttonIcon}
          alt=""
          onClick={this.handleButtonClick}
        />

        <div className="accordion-content-container" style={contentStyle}>
          {this.props.contentComponent}
        </div>
      </div>
    );
  }

  handleButtonClick = () => {
    this.setState({ open: !this.state.open });
  };
}


