
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cadastro de projeto - Submissão
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";
import urlencode from "urlencode";

import Authentication from "../../../lib/Authentication";

import Loader from "../../Loader";
import Request from "../../../lib/BackendRequest";

import Checkbox from "../../Commons/Checkbox";

import biomes from "../../../constants/biomes";
import legalFitness from "../../../constants/legalFitness";
import ods from "../../../constants/ods";

import multiLang from "../../../lib/multilang/multiLang";

import statusItems from "../../../constants/newProjectStatusItems";

import { formatOnlyNumbers, formatCurrency } from "../../../lib/FormatString";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class ContentSubisson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      areasOfInterestReady: false,
      areasOfInterest: [],
    };
  }

  componentWillMount() {
    this.fetchAreasOfInterest();

    this.checkStatus(false, this.props.status);
  }

  render() {
    if (!this.state.areasOfInterestReady) return <Loader />;

    return (
      <Fragment>
        <div className="new-project-text">
          <p>{dict.t("new-project-submission-description-1")}</p>
          <ul>
            <li>{dict.t("new-project-submission-description-2")}</li>
            <li>{dict.t("new-project-submission-description-3")}</li>
            <li>{dict.t("new-project-submission-description-4")}</li>
          </ul>
        </div>

        {this.composeStatusItems(statusItems)}

        <div className="row" style={{ marginTop: "70px", marginBottom: "70px" }}>
          <div className="col-md-12 termsofuse-container">
            <Checkbox onChange={this.handleCheckboxChange} />

            <span className="new-submission-terms-text" style={{ marginLeft: "16px" }}>
              {dict.t("Li e aceito os")}{" "}
            </span>

            <Link to="/termo-de-uso/projeto">
              <span className="text-link">{dict.t("Termos de uso")}</span>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }

  composeStatusItems(staticItems) {
    if (!staticItems) return "";

    return staticItems.map((item, index) => {
      let icon = "";
      let title;

      switch (this.props.status[index]) {
        case "ok":
          icon = "https://vbio.blob.core.windows.net/public/assets/img/ok-icon.png";
          title = "Todos os campos preenchidos";
          break;
        case "warning":
          icon = "https://vbio.blob.core.windows.net/public/assets/img/warning-icon.png";
          title = "Campos obrigatórios preenchidos";
          break;
        case "error":
          icon = "https://vbio.blob.core.windows.net/public/assets/img/error-icon.png";
          title = "Campos obrigatórios não preenchidos";
          break;
        default:
          icon = "https://vbio.blob.core.windows.net/public/assets/img/warning-icon.png";
          title = "Campos obrigatórios preenchidos";
          break;
      }

      return (
        <div key={index} className="row" style={{ marginBottom: "77px" }}>
          <div className="col-md-3">
            <img
              className="new-project-submission-icon"
              src={icon}
              alt={dict.t(title)}
              title={dict.t(title)}
            />

            <div className="new-project-submission-label">{dict.t(item.label)}</div>
          </div>

          <div className="col-md-9 new-project-submission-list">
            {item.name === "gratifications"
              ? this.composeGratificationsListItems(this.props.values[item.name])
              : this.composeListItems(item.items, this.props.values[item.name])}
          </div>
        </div>
      );
    });
  }

  composeListItems(items, values) {
    if (!items) return "";

    return items.map((item, index) => {
      let value = "";
      let separator = "";

      switch (item.type) {
        case "yesNoBoolean":
          if (values[item.key]) value = dict.t("Sim");
          else value = dict.t("Não");
          break;

        case "array":
          separator = ", ";
          for (let field in values[item.key]) {
            value += `${field}${separator}`;
          }
          value = value.slice(0, value.length - separator.length);
          break;

        case "arrayNotEmpty":
          if ((values[item.key] || []).length > 0) value = "Ok";
          break;

        case "valueNotZero":
          if (values[item.key] !== "0") value = values[item.key];
          break;

        case "biome":
          value = this.getItemsLabel(values[item.key], biomes);
          break;

        case "legalFitness":
          value = this.getItemsLabel(values[item.key], legalFitness);
          break;

        case "ods":
          value = this.getItemsLabel(values[item.key], ods, " - ");
          break;

        case "areasOfinterest":
          separator = " - ";
          for (let field in values[item.key])
            value += `${this.state.areasOfInterest
              .filter(item => item._id === field)
              .map(item => item[language].name)}${separator}`;

          value = value.slice(0, value.length - separator.length);
          break;

        case "fileUrl":
          if (values[item.key]) value = this.getFileNameFromUrl(values[item.key]);
          break;

        case "shareValue":
          value = formatCurrency(
            formatOnlyNumbers(values["totalValue"]) / 100 / values["numOfShares"]
          );
          break;

        default:
          value = values[item.key];
      }

      if (value === "" || !value) value = dict.t("Não definido");

      return (
        <div key={index} className="new-project-submission-list-item">
          {`${dict.t(item.label)}: ${value}`}
        </div>
      );
    });
  }

  composeGratificationsListItems(values) {
    return values.gratifications.map((item, index) => {
      let description = "";

      if (item.numOfSharesStart !== undefined && item.numOfSharesEnd !== undefined)
        description = `${dict.t("De")} ${item.numOfSharesStart} ${dict.t("à")} ${
          item.numOfSharesEnd
        } ${dict.t("cotas")} - ${item.description}`;
      else description = dict.t("Não definido");

      return (
        <div key={index} className="new-project-submission-list-item">
          {`${dict.t("Gratificação")} ${index + 1}: ${description}`}
        </div>
      );
    });
  }

  getItemsLabel(items, ref, separator = ", ") {
    let value = "";

    for (let field in items) {
      value += `${ref.find(item => item.name === field).label}${separator}`;
    }

    value = value.slice(0, value.length - separator.length);

    return value;
  }

  fetchAreasOfInterest() {
    const role = Authentication.getUser().personal.role === "master" ? "master" : "proponent";

    Request.get(`/${role}/projects/areas-of-interest`, values => {
      this.setState({ areasOfInterestReady: true, areasOfInterest: values });
    });
  }

  getFileNameFromUrl(url) {
    return urlencode.decode(url.substring(url.lastIndexOf("/") + 1));
  }

  checkStatus(termsOfUse, formStatus) {
    return formStatus.filter(item => item === "error").length === 0 && termsOfUse;
  }

  handleCheckboxChange = value => {
    if (this.props.onStatusChange)
      this.props.onStatusChange(this.checkStatus(value, this.props.status));
  };
}


