
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Campo com estilo card
//


import React from "react";

import "./style.css";



export default function(props) {
  return (
    <div className="card-field-container">
      <div className="card-field-label">{props.label + ": "}</div>
      <div className="card-field-value">{props.value}</div>
    </div>
  );
}


