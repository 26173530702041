
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Regis Ebeling
//
// Descrição: Corpo do item de lista de apoio
//


import React from "react";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();



export default function(props) {
  return (
    <div>
      {props.item.button === "attach" ? (
        <div className="supports-intern-attache-button-container">
          <div className="button-file">
            <input type="file" onChange={props.onUploadClick} title="Enviar recibo" />
          </div>
        </div>
      ) : (
        props.item.value
      )}
    </div>
  );
}


