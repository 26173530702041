import React, { Component } from "react";

export default class Input extends Component {
  constructor(props) {
    super(props);

    let checked = false;
    if (this.props.input.value.value) checked = true;
    if (typeof this.props.input.value !== "object" && this.props.input.value) checked = true;

    this.state = { checked };
  }

  render() {
    let css = this.props.cssClass;
    css += this.hasError() ? ` ${this.props.cssClassError}` : "";

    const checkedIcon = this.state.checked
      ? { backgroundImage: "url(/assets/img/checked-icon.png)" }
      : { backgroundImage: "none" };

    return (
      <div className="form-group" style={this.props.style}>
        <div className={css} style={checkedIcon}>
          <input type="checkbox" checked={this.state.checked} onChange={this.handleChange} />
        </div>

        <span className="form-input-label" style={{ verticalAlign: "middle", marginLeft: "20px" }}>
          {this.props.label}
        </span>
      </div>
    );
  }

  handleChange = e => {
    const checked = e.target.checked;

    this.setState({ checked });

    if (this.props.input.onChange)
      this.props.input.onChange({ name: this.props.input.name, value: checked });
  };

  hasError() {
    return this.props.meta.touched && this.props.meta.error;
  }

  getErrorMessage() {
    let errorMessage = null;

    //validate if the current fields has any error
    if (this.hasError()) {
      let errorContent = this.props.meta.error;

      if (this.props.errorMessage !== undefined && this.props.errorMessage[errorContent])
        errorMessage = this.props.errorMessage[errorContent];
      else errorMessage = errorContent;
    }

    return errorMessage;
  }
}
