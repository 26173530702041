import React, { Component } from "react";

export default class Search extends Component {
  render() {
    let type = this.props.type ? this.props.type : "text";

    let css = this.props.cssClass;
    css += this.hasError() ? ` ${this.props.cssClassError}` : "";

    let placeholder = this.props.placeholder ? this.props.placeholder : this.props.label;
    placeholder = this.hasError() ? this.props.placeholder + "*" : this.props.placeholder;

    return (
      <div className="form-group" style={this.props.containerStyle}>
        <div className="form-input-label">{this.props.label}</div>

        <div className="form-input-text">
          <input
            {...this.props.input}
            type={type}
            placeholder={placeholder}
            autoComplete={this.props.autoComplete}
            className={"form-control " + css}
          />

          <img
            className="form-input-search-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/search-icon.png"
            alt=""
            onClick={this.handleButtonClick}
          />

        </div>
      </div>
    );
  }

  handleButtonClick = () => {
    if (this.props.onButtonClick) this.props.onButtonClick();
  };

  hasError() {
    return this.props.meta.touched && this.props.meta.error;
  }

  getErrorMessage() {
    let errorMessage = null;

    //validate if the current fields has any error
    if (this.hasError()) {
      let errorContent = this.props.meta.error;

      if (this.props.errorMessage !== undefined && this.props.errorMessage[errorContent])
        errorMessage = this.props.errorMessage[errorContent];
      else errorMessage = errorContent;
    }

    return errorMessage;
  }
}
