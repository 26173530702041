
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Imagem do item de lista do tipo banner
//


import React from "react";
import "./style.css";



export default function(props) {
  const style = !props.item.image ? { overflow: "visible" } : {};

  return (
    <div className="banner-list-item-image-container" style={style}>
      {props.item.image ? (
        <img className="banner-list-item-image" src={props.item.image} alt={props.item.name} />
      ) : (
        <div className="card banner-list-image-placeholder">
          <img
            className="banner-list-image-placeholder-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/image-icon.png"
            alt=""
          />
        </div>
      )}
    </div>
  );
}


