
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Componente de formulário para entrada de arquivo - versão "wide"
//


import React, { Component } from "react";
import urlencode from "urlencode";

import downloadFile from "../downloadFile";

import multiLang from "../multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class FileInputWide extends Component {
  constructor(props) {
    super(props);
    this.files = null;
    this.state = { filename: null };
  }

  render() {
    const {
      input: { value: omitValue, ...inputProps },
      meta: omitMeta,
      ...props
    } = this.props;

    const filename = this.getFileNameFromUrl(props.url, props.removeS3Hash) || this.state.filename;

    return (
      <div className="form-group">
        <div className="form-input-label">{props.label}</div>

        <div className="form-input-file-wide" style={props.style}>
          <div style={{ overflow: "auto" }}>
            <div className="form-input-file-wide-filename">{filename}</div>

            <div className="form-input-file-wide-attach-icon">
              <input
                {...inputProps}
                {...props}
                type="file"
                onChange={this.handleChange}
                title={dict.t("Enviar arquivo")}
              />
              <img
                src="https://vbio.blob.core.windows.net/public/assets/img/attach-icon.png"
                alt={dict.t("Enviar arquivo")}
                title={dict.t("Enviar arquivo")}
              />
            </div>

            {this.props.template ? (
              <div
                className="form-input-file-wide-download-icon"
                onClick={this.handleDonwloadClick}
              >
                <img
                  src="https://vbio.blob.core.windows.net/public/assets/img/download-icon.png"
                  alt={dict.t("Baixar modelo de arquivo")}
                  title={dict.t("Baixar modelo de arquivo")}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  getFileNameFromUrl(url, removeS3Hash) {
    let filename = "";

    if (typeof url !== "string") {
      if (url) filename = url.name;
    } else {
      filename = url.substring(url.lastIndexOf("/") + 1);
    }

    if (removeS3Hash) filename = filename.substring(72);

    return urlencode.decode(filename);
  }

  handleChange = e => {
    const {
      input: { value: omitValue, ...inputProps },
    } = this.props;

    this.files = e.target.files;

    this.setState({ filename: this.files[0].name });

    if (inputProps.onChange) inputProps.onChange(this.files);
  };

  handleDonwloadClick = () => {
    downloadFile(this.props.template);
  };
}


