
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Como apoiar - como propor
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import ScreenSize from "../Commons/ScreenSize";

import multiLang from "../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const textData = [
  ["Como apoiar um projeto", "Como propor um projeto"],

  ["como-apoiar-1", "como-propor-1"],

  ["como-apoiar-2", "como-propor-2"],

  ["como-apoiar-3", "como-propor-3"],

  ["Apoiar", "Propor"],
];



export default class HowTo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      smallScreen: false,
    };
  }

  render() {
    const { smallScreen } = this.state;

    if (smallScreen) {
      const dataSupporter = textData.map(item => [item[0]]);
      const dataProponent = textData.map(item => [item[1]]);

      return (
        <div className="container howto-container">
          <ScreenSize onScreenResize={this.handleScreenResize} />

          {this.composePage(dataSupporter, "supporter")}
          {this.composePage(dataProponent, "proponent")}
        </div>
      );
    } else {
      return (
        <div className="container howto-container">
          <ScreenSize onScreenResize={this.handleScreenResize} />

          {this.composePage(textData)}
        </div>
      );
    }
  }

  composePage(data, style) {
    return data.map((row, index) => {
      const first = index === 0;
      const last = index === data.length - 1;

      if (first) return this.composeTitleRow(row, index, style);
      else if (last) return this.composeButtonRow(row, index, style);
      else return this.composeRow(row, index, style);
    });
  }

  composeTitleRow(data, index, style) {
    return (
      <div key={index} className="row">
        <div className="col-md-6">
          <div className={`howto-title ${style ? style : "supporter"}`}>{dict.t(data[0])}</div>
        </div>

        {data[1] ? (
          <div className="col-md-6">
            <div className="howto-title proponent">{dict.t(data[1])}</div>
          </div>
        ) : null}
      </div>
    );
  }

  composeRow(data, index, style) {
    const textCols = data[1] ? 4 : 11;

    const cols = data[1] ? 6 : 12;

    return (
      <div key={index} className="row howto-item">
        <div className={`col-xs-${cols}`}>
          <div className={`howto-index ${style ? style : "supporter"}`}>{index}</div>
          <div className={`howto-text`}>{dict.t(data[0])}</div>
        </div>

        {data[1] ? (
          <div className={`col-xs-${cols}`}>
            <Fragment>
              <div className="howto-index proponent">{index}</div>
              <div className="howto-text">{dict.t(data[1])}</div>
            </Fragment>
          </div>
        ) : null}
      </div>
    );
  }

  composeButtonRow(data, index, style) {
    return (
      <div key={index} className="row howto-item">
        <div className="col-md-6">
          <Link to="/login">
            <button className={`button howto-button ${style ? style : "supporter"}-background`}>
              {dict.t(data[0])}
            </button>
          </Link>
        </div>

        {data[1] ? (
          <div className="col-md-5 col-md-offset-1">
            <Link to="/login">
              <button className="button howto-button proponent-background">
                {dict.t(data[1])}
              </button>
            </Link>
          </div>
        ) : (
          <div style={{ marginBottom: "50px" }} />
        )}
      </div>
    );
  }

  handleScreenResize = screen => {
    const { width } = screen;

    this.setState({ smallScreen: width <= 992 });
  };
}


