
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Itens do menu
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import LanguageMenu from "./LanguageMenu";

import languageItems from "../../constants/languageItems";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class Menu extends Component {
  render() {
    return (
      <Fragment>
        {this.getMenuItems(this.props.items)}

        {this.props.itemClassName ? (
          <div className={this.props.itemClassName}>{this.renderLanguageMenu()}</div>
        ) : (
          this.renderLanguageMenu()
        )}
      </Fragment>
    );
  }

  getMenuItems(items) {
    if (!items) return "";

    return items.map((item, index) => {
      let selected = false;

      if ((item.route || item.refRoute) === "/") {
        selected = this.props.router.location.pathname === (item.route || item.refRoute);
      } else {
        selected = this.props.router.location.pathname.indexOf(item.route || item.refRoute) === 0;
      }

      item.id = index;

      return this.composeItemUI(item, index, selected);
    });
  }

  composeItemUI(rawItem, index, selected) {
    const notificationStyle = rawItem.notification ? {} : { opacity: 0 };

    return (
      <Fragment key={index}>
        <Link to={rawItem.route}>
          <div className={this.props.itemClassName} style={{ display: "inline-block" }}>
            <div className="menu-item-notification" style={notificationStyle} />
            {rawItem.type === "image"
              ? this.composeImageItem(rawItem, selected, index)
              : this.composeTextItem(rawItem, selected, index)}
          </div>
        </Link>

        {!this.props.hideSubItems && this.props.subMenuOpenId === rawItem.id && rawItem.subItems
          ? this.composeSubItems(rawItem.subItems)
          : null}
      </Fragment>
    );
  }

  composeTextItem(item, selected, index) {
    let className = "";

    switch (item.type) {
      case "text-bold":
        className = "menu-item-bold";
        break;

      case "button":
        className = `button menu-item-button menu-item-button-${item.btnColor}`;
        break;

      default:
        break;
    }

    if (selected && item.type !== "button") className += " menu-item-selected";

    let style = {};
    let styleAfter = {};

    if (item.color) {
      style = this.hover === index ? { color: item.colorHover } : { color: item.color };
      styleAfter =
        this.hover === index
          ? { backgroundColor: item.colorHover }
          : { backgroundColor: item.color };
    }

    let icon;

    if (selected || this.hover === index)
      icon =
        this.props.subMenuOpenId === item.id
          ? "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown-up-selected.png"
          : "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown-selected.png";
    else
      icon =
        this.props.subMenuOpenId === item.id
          ? "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown-up.png"
          : "https://vbio.blob.core.windows.net/public/assets/img/header/dropdown.png";

    return (
      <div
        className={`menu-item ${className}`}
        style={style}
        onClick={() => this.handleItemClick(item)}
        onMouseEnter={() => this.handleMouseEnter(index)}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        {item.label}
        {item.subItems ? (
          <Fragment>
            <img className="menu-item-dropdown-icon" src={icon} alt={dict.t(item.label)} />
          </Fragment>
        ) : (
          ""
        )}
        {selected && item.type !== "button" ? (
          <div className="menu-item-selected-after" style={styleAfter} />
        ) : null}
      </div>
    );
  }

  composeImageItem(item, selected, index) {
    const src = selected || this.hover === index ? item.imageSelected : item.image;

    return (
      <div
        className="menu-item"
        onClick={() => this.handleItemClick(item)}
        onMouseEnter={() => this.handleMouseEnter(index)}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        <img
          src={src}
          style={{ width: "42px", marginTop: "-2px" }}
          alt={dict.t(item.label)}
          title={dict.t(item.label)}
        />
      </div>
    );
  }

  composeSubItems(subItems) {
    return subItems.map((subItem, index) => {
      return (
        <Link to={subItem.route} key={index}>
          <div
            className={this.props.subItemClassName}
            onClick={() => this.handleItemClick(subItem)}
          >
            {subItem.label}
          </div>
        </Link>
      );
    });
  }

  renderLanguageMenu() {
    return (
      <LanguageMenu
        items={languageItems}
        defaultItemId={multiLang.getLanguage()}
        onSelect={this.handleLanguageSelect}
      />
    );
  }

  handleItemClick = item => {
    if (this.props.onItemClick) this.props.onItemClick(item);
  };

  handleLanguageSelect = item => {
    localStorage.userLanguage = item.id;
    window.location.reload();
  };

  handleMouseEnter = index => {
    this.hover = index;
  };

  handleMouseLeave = () => {
    this.hover = null;
  };
}


