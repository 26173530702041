export default {
  componentStyle: {
    overflow: "visible",
    marginTop: "12px",
    marginLeft: "42px",
    width: "calc(100% - 42px)",
  },

  iconStyle: {
    height: "65px",
    marginTop: "-10px",
    marginLeft: "-43px",
  },

  labelStyle: {
    marginTop: "-10px",
  },
};
