
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Blog - lista de artigo
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import moment from "moment";
import ReactHtmlParser from "react-html-parser";

import Loader from "../Loader";
import Back from "../Commons/Back";

import SocialBar from "./SocialBar";

import Authentication from "../../lib/Authentication";
import Request from "../../lib/BackendRequest";

import ScreenSize from "../Commons/ScreenSize";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 992;



export default class Post extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: null,
      morePosts: null,
      smallScreen: false,
    };
  }

  componentWillMount() {
    this.fetchPost(this.props.router.params.id);
  }

  componentWillReceiveProps() {
    this.setState({ post: null, morePosts: null }, () =>
      this.fetchPost(this.props.router.params.id)
    );
  }

  render() {
    if (!this.state.post) return <Loader />;

    const { post } = this.state;

    if (!post[language]) post[language] = Object.assign(post[language], post.PT);

    const footerMargin = Authentication.isAuthenticated()
      ? "footer-margin-logged"
      : "footer-margin";

    const bannerImage = post.image;

    return (
      <Fragment>
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="container page-margin-top">
          <div className="project-intern-back-button-container">
            <Back router={this.props.router} />
          </div>

          <div className="blog-post-title">{post[language].title}</div>

          <div className="blog-post-summary">{post[language].summary}</div>

          {this.renderInfoBar(post)}
        </div>

        <div className="container">
          <img
            className="blog-post-banner"
            src={bannerImage}
            title={post[language].title}
            alt={post[language].title}
          />
        </div>

        <div className={`container ${footerMargin}`} style={{ paddingBottom: "40px" }}>
          <div className="blog-post-body row">
            <div className="col-sm-offset-2 col-sm-8">
              {ReactHtmlParser(post[language].fullText)}
              {this.renderTags(post[language].tags)}
            </div>
          </div>

          <div className="blog-post-social-bar-container">
            <SocialBar />
          </div>

          {this.renderMorePosts(this.state.morePosts)}
        </div>
      </Fragment>
    );
  }

  fetchPost(id) {
    const url = `/blog/post/${id}`;

    Request.get(url, res => {
      this.fetchMorePosts(res[language].tags);
      this.setState({ post: res });
    });
  }

  fetchMorePosts(tags) {
    const textTags = tags.map(tag => tag.description);

    const query = Request.objToQueryParams(textTags);
    const url = `/blog/posts/more/?${query}`;

    Request.get(url, res => {
      const morePosts = res.filter(post => post.link !== this.state.post.link).slice(0, 3);
      this.setState({ morePosts });
    });
  }

  renderInfoBar(post) {
    const { smallScreen } = this.state;
    const { author, publishDate } = post;
    const { name } = author[0];

    const formatedDate = moment(publishDate).format("DD/MM/YYYY");

    return (
      <div className="blog-post-infobar-container">
        <div className="blog-post-infobar-item">
          <img src="https://vbio.blob.core.windows.net/public/assets/img/article-icon.png" alt={dict.t("Artigo")} title={dict.t("Artigo")} />
          <div className="blog-post-infobar-text">{dict.t("Artigo")}</div>
        </div>

        {!smallScreen ? <div className="blog-post-infobar-separator" /> : null}

        <div className="blog-post-infobar-item">
          <img src="https://vbio.blob.core.windows.net/public/assets/img/user-icon.png" alt={name} title={name} />
          <div className="blog-post-infobar-text">{name}</div>
        </div>

        {!smallScreen ? <div className="blog-post-infobar-separator" /> : null}

        <div className="blog-post-infobar-item">
          <img src="https://vbio.blob.core.windows.net/public/assets/img/calendar-icon.png" alt={formatedDate} title={formatedDate} />
          <div className="blog-post-infobar-text">{formatedDate}</div>
        </div>
      </div>
    );
  }

  renderTags(tags) {
    const textTags = tags.map(tag => tag.description.trim()).filter(tag => tag.length);

    return (
      <section className="blog-list-tags-container">
        <div style={{ width: "calc(100% + 30px)" }}>{this.renderTagCards(textTags)}</div>
      </section>
    );
  }

  renderTagCards(tags) {
    if (!tags) return null;

    return tags.map((tag, index) => {
      return (
        <div
          key={index}
          className="blog-post-tag-card"
          style={{ cursor: "pointer" }}
          onClick={() => this.handleTagClick(tag)}
        >
          {tag}
        </div>
      );
    });
  }

  renderMorePosts(posts) {
    if (!posts) return null;

    return (
      <div className="blog-post-more-articles-container">
        <div className="blog-post-more-articles-title">{dict.t("Mais artigos como esse...")}</div>

        <div className="row">{this.renderMoreArticlesCards(posts)}</div>

        <button className="button blog-post-more-articles-button" onClick={this.handleButtonClick}>
          {dict.t("Mais artigos")}
        </button>
      </div>
    );
  }

  renderMoreArticlesCards(posts) {
    return posts.map((post, index) => {
      const image = post.image ? post.image : "https://vbio.blob.core.windows.net/public/assets/img/image-placeholder.png";
      const imageStyle = { backgroundImage: `url('${image}')` };

      const url = post.link ? `/blog/post/${post.link}` : `/blog/post/${post._id}`;

      return (
        <div key={index} className="col-sm-4">
          <Link to={url}>
            <div className="blog-post-article-card" style={imageStyle} title={post[language].title}>
              <div className="blog-list-article-title-container">
                <div className="blog-post-article-card-title">{post[language].title}</div>

                <div className="blog-post-article-card-description">{post[language].summary}</div>

                <div className="blog-post-article-card-date">
                  {moment(post.createdAt).format("DD/MM/YYYY")}
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    });
  }

  handleButtonClick = () => {
    this.props.router.push("/blog");
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };

  handleTagClick = tag => {
    this.props.router.push(`/blog/${tag}`);
  };
}


