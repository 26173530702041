
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Dashboard
//


import React, { Component } from "react";
import moment from "moment";

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineMarkSeries,
  DiscreteColorLegend,
  VerticalBarSeries,
  RadarChart,
  RadialChart,
  HorizontalBarSeries,
} from "react-vis";

import Loader from "../../Loader";
import ScreenSize from "../../Commons/ScreenSize";
import BackButton from "../../Commons/Back";

import Authentication from "../../../lib/Authentication";
import Request from "../../../lib/BackendRequest";

import backendUserRoutes from "../../../constants/backendUserRoutes";

import monthArray from "../../../constants/months";
import biomeArray from "../../../constants/biomes";

import reportTableConfig from "../../../constants/reportTableConfig";
import generateReport from "../../../lib/generateReport";

import { formatCurrency } from "../../../lib/FormatString";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 768;



export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supports: null,
      projectStatus: null,
    };
  }

  componentWillMount() {
    this.role = Authentication.getUser().personal.role;

    if (this.role === "master" || this.role === "supporterUser") this.fetchSupports();

    if (this.role === "master" || this.role === "proponentUser") this.fetchProjectStatus();
  }

  render() {
    const {
      supports,
      projectStatus,
      distributionData,
      evolutionData,
      biomeData,
      statusData,
      proponentRankingData,
      supporterRankingData,
    } = this.state;

    if (!supports || !projectStatus || !statusData) return <Loader />;

    const footerMargin = Authentication.isAuthenticated()
      ? "footer-margin-logged"
      : "footer-margin";

    return (
      <section className={`container ${footerMargin}`}>
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="back-button-generic" style={{ marginBottom: "0" }}>
          <BackButton router={this.props.router} />
        </div>

        {this.role === "master" ? this.renderStatCards() : null}

        {this.renderEvolutionChart(evolutionData)}

        {this.role === "master" ? this.renderDistributionChart(distributionData) : null}

        <div className="row">
          <div className="col-sm-6" ref={this.handleBiomeChartContainerRef}>
            {this.renderBiomeChart(biomeData)}
          </div>

          <div className="col-sm-6">{this.renderStatusChart(statusData)}</div>
        </div>

        {this.role === "master"
          ? this.renderRankingCharts(proponentRankingData, supporterRankingData)
          : null}

        <div className="row">
          <div className=" col-xs-12 col-sm-offset-8 col-sm-4">
            <button className="button supports-list-report-button" onClick={this.handleReportClick}>
              {dict.t("Baixar relatório completo")}
            </button>
          </div>
        </div>
      </section>
    );
  }

  renderStatCards() {
    const { supports, projectStatus } = this.state;

    return (
      <div className="row" style={{ marginTop: "30px", marginBottom: "-15px" }}>
        <div className="col-md-3">
          <div className="dashboard-stat-card">
            <div className="dashboard-stat-card-value">
              {formatCurrency(this.getFindingRisingProjectsValue(projectStatus, "raisingFunds"))}
            </div>

            <div className="dashboard-stat-card-label">{dict.t("Total em captação")}</div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="dashboard-stat-card">
            <div className="dashboard-stat-card-value">
              {formatCurrency(
                this.getFindingRisingProjectsValue(projectStatus, "raisingFunds", 12)
              )}
            </div>

            <div className="dashboard-stat-card-label">
              {dict.t("Total captado nos últimos 12 meses")}
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="dashboard-stat-card">
            <div className="dashboard-stat-card-value">
              {this.getNumOfProjectsByStatus(projectStatus, "raisingFunds")}
            </div>

            <div className="dashboard-stat-card-label">{dict.t("Projetos em captação")}</div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="dashboard-stat-card">
            <div className="dashboard-stat-card-value">
              {this.getNumOfProjectsByStatus(projectStatus, "pending")}
            </div>

            <div className="dashboard-stat-card-label">{dict.t("Projetos em análise")}</div>
          </div>
        </div>
      </div>
    );
  }

  renderEvolutionChart(data) {
    const markSize = 3;

    const valueColor = "#B2D58F";
    const shareColor = "#D05A47";

    const legend = [
      { title: dict.t("Valor apoiado"), color: valueColor, strokeWidth: 10 },
      { title: dict.t("Quantidade de cotas"), color: shareColor, strokeWidth: 10 },
    ];

    const valueMax = this.getMax([data[0]]);
    const shareMax = this.getMax([data[1]]);

    const scaleFactor = valueMax / shareMax;

    const dataShare = this.scaleData(JSON.parse(JSON.stringify(data[1])), scaleFactor);

    const { screenWidth } = this.state;

    let height = 420;

    let labelXAngle = 0;
    let marginBottom = 30;
    let marginLeft = 80;
    let labelFontSize = 11;

    if (screenWidth < 768) {
      height = 360;
    }

    if (screenWidth < 1300) {
      labelXAngle = -90;
      marginBottom = 60;
      marginLeft = 55;
      labelFontSize = 9;
    }

    return (
      <div className="dashboard-chart-container">
        <div className="dashboard-chart-title">{dict.t("Valor apoiado e quantidade de cotas")}</div>

        <FlexibleWidthXYPlot
          height={height}
          xType="ordinal"
          margin={{ left: marginLeft, right: 40, bottom: marginBottom }}
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis tickLabelAngle={labelXAngle} style={{ text: { fontSize: labelFontSize } }} />

          <YAxis
            title={dict.t("Valor arrecadado")}
            position="middle"
            style={{ text: { fontSize: labelFontSize } }}
          />
          <VerticalBarSeries data={data[0]} color={valueColor} />

          <YAxis
            title={dict.t("Quantidade de cotas")}
            position="middle"
            orientation="right"
            tickFormat={tick => (tick / scaleFactor).toFixed(2)}
            style={{ text: { fontSize: labelFontSize } }}
          />
          <LineMarkSeries data={dataShare} size={markSize} color={shareColor} />
        </FlexibleWidthXYPlot>

        <div className="dashboard-chart-legend">
          <DiscreteColorLegend orientation="horizontal" items={legend} />
        </div>
      </div>
    );
  }

  renderDistributionChart(data) {
    const markSize = 3;
    const openProjectsColor = "#B2D58F";
    const pendingProjectsColor = "#F2CE81";
    const approvedProjectsColor = "#5BC4CD";
    const legend = [
      { title: dict.t("Projetos em captação"), color: openProjectsColor, strokeWidth: 10 },
      { title: dict.t("Projetos em análise"), color: pendingProjectsColor, strokeWidth: 10 },
      { title: dict.t("Projetos aprovados"), color: approvedProjectsColor, strokeWidth: 10 },
    ];

    const { screenWidth } = this.state;

    let height = 420;

    let labelXAngle = 0;
    let marginBottom = 30;
    let labelFontSize = 11;

    if (screenWidth < 768) {
      height = 360;
    }

    if (screenWidth < 1300) {
      labelXAngle = -90;
      marginBottom = 60;
      labelFontSize = 9;
    }

    return (
      <div className="dashboard-chart-container">
        <div className="dashboard-chart-title">
          {dict.t("Projetos em aberto, em análise e aprovados")}
        </div>

        <FlexibleWidthXYPlot height={height} xType="ordinal" margin={{ bottom: marginBottom }}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis tickLabelAngle={labelXAngle} />
          <YAxis style={{ text: { fontSize: labelFontSize } }} />
          <LineMarkSeries
            data={data[0]}
            curve="curveMonotoneX"
            size={markSize}
            color={openProjectsColor}
          />
          <LineMarkSeries
            data={data[1]}
            curve="curveMonotoneX"
            size={markSize}
            color={pendingProjectsColor}
          />
          <LineMarkSeries
            data={data[2]}
            curve="curveMonotoneX"
            size={markSize}
            color={approvedProjectsColor}
          />
        </FlexibleWidthXYPlot>

        <div className="dashboard-chart-legend">
          <DiscreteColorLegend orientation="horizontal" items={legend} />
        </div>
      </div>
    );
  }

  renderBiomeChart(data) {
    if (!this.state.biomeChartWidth) return null;

    const size = this.state.biomeChartWidth;

    const maxNumOfTicks = 5;

    let dataMax = 0;

    for (let key in data) {
      if (data[key] > dataMax) dataMax = data[key];
    }

    const ticks = this.generateTicks(maxNumOfTicks, dataMax);

    const gridData = this.generateBiomeGridData(data, ticks);

    const domains = biomeArray.map((item, index) => {
      const tickFormat = t => Math.round(t);

      return {
        name: dict.t(item.label),
        domain: [0, dataMax],
        getValue: d => d[item.label],
        tickFormat: item.label === "Amazônia" ? tickFormat : t => "",
      };
    });

    const { screenWidth } = this.state;

    let margin = 80;
    let labelFontSize = 12;

    if (screenWidth < 450) {
      margin = 40;
      labelFontSize = 9;
    } else if (screenWidth < 1300) {
      margin = 60;
      labelFontSize = 11;
    }

    return (
      <div className="dashboard-chart-container">
        <div className="dashboard-chart-title">{dict.t("Quantidade de projetos e biomas")}</div>

        <RadarChart
          domains={domains}
          data={gridData}
          width={size}
          height={size}
          margin={{ left: margin, right: margin, top: margin, bottom: margin }}
          style={{
            labels: {
              textAnchor: "middle",
              fontSize: labelFontSize,
            },
            axes: {
              line: {
                fillOpacity: 0.8,
                strokeWidth: 0.5,
                strokeOpacity: 0.8,
              },
              ticks: {
                fillOpacity: 0,
                strokeOpacity: 0,
              },
            },
          }}
          colorRange={["transparent"]}
          hideInnerMostValues={false}
          renderAxesOverPolygons={true}
        ></RadarChart>
      </div>
    );
  }

  renderStatusChart(data) {
    if (!this.state.biomeChartWidth) return null;

    const approvedColor = "#B2D58F";
    const refusedColor = "#F2CE81";
    const pendingColor = "#5BC4CD";

    const legend = [
      { title: dict.t("Aprovado"), color: approvedColor, strokeWidth: 10 },
      { title: dict.t("Pendente"), color: pendingColor, strokeWidth: 10 },
      { title: dict.t("Recusado"), color: refusedColor, strokeWidth: 10 },
    ];

    const size = this.state.biomeChartWidth * 0.8;
    const margin = 80;

    let title;

    switch (this.role) {
      case "master":
        title = "Status dos projetos";
        break;
      case "supporterUser":
        title = "Status dos apoios";
        break;
      case "proponentUser":
        title = "Status dos apoios";
        break;
      default:
        title = "";
    }

    const { screenWidth } = this.state;

    let containerMarginTop = "60px";

    if (screenWidth < 768) {
      containerMarginTop = "40px";
    }

    return (
      <div className="dashboard-chart-container">
        <div className="dashboard-chart-title">{dict.t(title)}</div>

        <div style={{ marginTop: containerMarginTop }}>
          <RadialChart
            data={data}
            width={size}
            height={size}
            colorType={"literal"}
            margin={{ left: margin, right: margin, top: margin, bottom: margin }}
          ></RadialChart>
        </div>

        <div className="dashboard-chart-legend">
          <DiscreteColorLegend orientation="horizontal" items={legend} />
        </div>
      </div>
    );
  }

  renderRankingCharts(proponentData, supporterData) {
    const colors = ["#B2D58F", "#D05A47", "#F2CE81", "#5EC4DC", "#24A862"];

    const proponentLegend = [
      { title: proponentData[1].name, color: colors[0], strokeWidth: 10 },
      { title: proponentData[2].name, color: colors[1], strokeWidth: 10 },
      { title: proponentData[3].name, color: colors[2], strokeWidth: 10 },
      { title: proponentData[4].name, color: colors[3], strokeWidth: 10 },
      { title: proponentData[5].name, color: colors[4], strokeWidth: 10 },
    ];

    const supporterLegend = [
      { title: supporterData[1].name, color: colors[0], strokeWidth: 10 },
      { title: supporterData[2].name, color: colors[1], strokeWidth: 10 },
      { title: supporterData[3].name, color: colors[2], strokeWidth: 10 },
      { title: supporterData[4].name, color: colors[3], strokeWidth: 10 },
      { title: supporterData[5].name, color: colors[4], strokeWidth: 10 },
    ];

    return (
      <div className="dashboard-chart-container">
        <div className="dashboard-chart-title">
          {dict.t("Ranking de apoiadores e proponentes com maior quantidade de projetos")}
        </div>

        {this.renderRankingChart("Apoiadores", supporterData, supporterLegend)}

        {this.renderRankingChart("Proponentes", proponentData, proponentLegend)}
      </div>
    );
  }

  renderRankingChart(title, data, legend) {
    const dataMax = data.reduce((max, item) => (item.x > max ? item.x : max), 0);

    const ticks = this.generateTicks(dataMax <= 10 ? dataMax : 10, dataMax);

    return (
      <div>
        <div className="dashboard-ranking-vert-label">{dict.t(title)}</div>

        <div className="dashboard-ranking-chart-container">
          <FlexibleWidthXYPlot height={240}>
            <VerticalGridLines tickValues={[0, ...ticks]} />
            <HorizontalGridLines />
            <XAxis tickValues={[0, ...ticks]} tickFormat={v => v.toFixed(0)} />
            <HorizontalBarSeries colorType={"literal"} data={data} barWidth={0.6} />
          </FlexibleWidthXYPlot>
        </div>

        <div className="dashboard-chart-legend-inline">
          <DiscreteColorLegend orientation="vertical" items={legend} />
        </div>
      </div>
    );
  }

  generateBiomeGridData(data, ticks) {
    function biomeValues(n) {
      let values = {};

      biomeArray.forEach(biome => (values[biome.label] = n));

      return values;
    }

    let gridData = ticks.map((tick, index) => {
      return {
        name: `grid${tick}`,
        stroke: "#cccccc",
        fill: index % 2 === 0 ? "#f8f8f8" : "white",
        ...biomeValues(tick),
      };
    });

    gridData = [
      {
        ...data,
        name: "data",
        tickFormat: t => "",
        fill: "rgba(114,172,240,0.5)",
        stroke: "rgba(114,172,240,0.2)",
      },
      ...gridData,
    ];

    return gridData.reverse();
  }

  fetchSupports() {
    let url = `/${backendUserRoutes(this.role)}supports/private`;

    if (this.role === "supporterUser") url += `/${Authentication.getUser().personal.companyId}`;

    Request.get(url, res => {
      if (!Authentication.isAuthenticated()) return;

      if (!res) res = [];

      if (this.role === "proponentUser") {
        res = res.filter(support => {
          if (!this.state.projectStatus) return [];

          return this.state.projectStatus.some(project => {
            return support.project && support.project._id === project._id;
          });
        });
      }

      this.setState({
        supports: res,
        evolutionData: this.buildEvolutionData(res),
        supporterRankingData: this.buildSupporterRankingData(res),
      });

      if (this.role === "supporterUser" || this.role === "proponentUser") {
        this.setState({ statusData: this.buildSupportsStatusData(res) });
      }

      if (this.role === "supporterUser") {
        this.fetchProjectStatus();
      }
    });
  }

  fetchProjectStatus() {
    const url = `/${backendUserRoutes(this.role)}projects/status`;

    Request.get(url, res => {
      if (!Authentication.isAuthenticated()) return;

      if (!res) res = [];

      if (this.role === "supporterUser") {
        res = res.filter(project => {
          return this.filterApprovedSupports(this.state.supports).some(support => {
            return support.project && support.project === project._id;
          });
        });
      } else if (this.role === "proponentUser") {
        res = res.filter(project => {
          return project.proponent._id === Authentication.getUser().personal.companyId;
        });

        this.fetchSupports();
      }

      this.setState({
        projectStatus: res,
        distributionData: this.buildDistributionData(res),
        biomeData: this.buildBiomeData(res),
        proponentRankingData: this.buildProponentRankingData(res),
      });

      if (this.role === "master") this.setState({ statusData: this.buildProjectStatusData(res) });
    });
  }

  scaleData(data, factor) {
    return data.map(item => {
      item.y *= factor;
      return item;
    });
  }

  getMax(data) {
    return data
      .map(set => set.map(value => value.y).reduce((max, value) => Math.max(max, value), 0))
      .reduce((max, value) => Math.max(max, value), 0);
  }

  generateTicks(num, max) {
    const step = Math.round(max / num);

    const ticks = [];
    let tick = 0;
    while (tick <= max - step && max > 0 && step > 0) ticks.push((tick += step));

    return ticks;
  }

  getLast12Months() {
    const refDate = moment();
    const currentYear = moment().year();

    let monthShift = refDate.month() - 11;
    monthShift = monthShift <= 0 ? monthShift + 12 : monthShift;

    let months = monthArray[language].map(month => ({ m: month, y: currentYear - 1 }));

    for (let i = 0; i < monthShift; i++) {
      const temp = months.shift();
      temp.y++;
      months.push(temp);
    }

    return months;
  }

  buildEvolutionData(supports) {
    const last12Months = this.getLast12Months();

    let data = new Array(2);

    supports = this.filterApprovedSupports(supports);

    data[0] = this.getSupportValueData(last12Months, supports);
    data[1] = this.getShareData(last12Months, supports);

    return data;
  }

  getSupportValueData(x, suppports) {
    let data = x.map(month => ({ x: `${month.m} ${month.y}`, y: 0 }));

    x.forEach((xItem, index) => {
      let value = 0;

      suppports.forEach(support => {
        const currentMonth = monthArray[language].indexOf(xItem.m);
        const currentYear = xItem.y;

        const supportMonth = moment(support.createdAt).month();
        const supportYear = moment(support.createdAt).year();

        const supported = supportYear === currentYear && supportMonth === currentMonth;

        if (supported) value += support.value;
      });

      data[index].y = value;
    });

    return data;
  }

  getShareData(x, suppports) {
    let data = x.map(month => ({ x: `${month.m} ${month.y}`, y: 0 }));

    x.forEach((xItem, index) => {
      let shares = 0;

      suppports.forEach(support => {
        const currentMonth = monthArray[language].indexOf(xItem.m);
        const currentYear = xItem.y;

        const supportMonth = moment(support.createdAt).month();
        const supportYear = moment(support.createdAt).year();

        const supported = supportYear === currentYear && supportMonth === currentMonth;

        if (supported) shares = support.numOfShares ? shares + support.numOfShares : 0;
      });

      data[index].y = shares;
    });

    return data;
  }

  buildDistributionData(projects) {
    const last12Months = this.getLast12Months();

    let data = new Array(3);

    data[0] = this.getRaisingProjectsData(last12Months, projects);
    data[1] = this.getPendingProjectsData(last12Months, projects);
    data[2] = this.getProjectApproveData(last12Months, projects);

    return data;
  }

  getRaisingProjectsData(x, projects) {
    let data = x.map(month => ({ x: `${month.m} ${month.y}`, y: 0 }));

    x.forEach((xItem, index) => {
      projects.forEach(project => {
        if (project.approveDate) {
          const approveMonth = moment(project.approveDate).month();
          const approveYear = moment(project.approveDate).year();

          const endMonth = moment(project.endDate, "DD/MM/YYYY").month();
          const endYear = moment(project.endDate, "DD/MM/YYYY").year();

          const currentMonth = monthArray[language].indexOf(xItem.m);
          const currentYear = xItem.y;

          const raising = 12 * (currentYear - approveYear) + currentMonth - approveMonth >= 0;
          const finalized = 12 * (currentYear - endYear) + currentMonth - endMonth >= 0;

          if (!finalized && raising) data[index].y++;
        }
      });
    });

    return data;
  }

  getPendingProjectsData(x, projects) {
    let data = x.map(month => ({ x: `${month.m} ${month.y}`, y: 0 }));

    x.forEach((xItem, index) => {
      projects.forEach(project => {
        const createMonth = moment(project.createdAt).month();
        const createYear = moment(project.createdAt).year();

        const currentMonth = monthArray[language].indexOf(xItem.m);
        const currentYear = xItem.y;

        const evaluateMonth = moment(project.approveDate || project.refuseDate).month();
        const evaluateYear = moment(project.approveDate || project.refuseDate).year();

        const pending = 12 * (currentYear - createYear) + currentMonth - createMonth >= 0;
        const evaluate =
          12 * (currentYear - evaluateYear) + currentMonth - evaluateMonth >= 0 &&
          (project.approveDate || project.refuseDate);

        if (!evaluate && pending) data[index].y++;
      });
    });

    return data;
  }

  getProjectApproveData(x, projects) {
    let data = x.map(month => ({ x: `${month.m} ${month.y}`, y: 0 }));

    x.forEach((xItem, index) => {
      projects.forEach(project => {
        const currentMonth = monthArray[language].indexOf(xItem.m);
        const currentYear = xItem.y;

        const approveMonth = moment(project.approveDate).month();
        const approveYear = moment(project.approveDate).year();

        const approved =
          approveYear === currentYear && approveMonth === currentMonth && project.approveDate;

        if (approved) data[index].y++;
      });
    });

    return data;
  }

  filterApprovedSupports(supports) {
    return supports.filter(support => support.status === "approved");
  }

  getSupportedValue(supports, period) {
    let totalValue;
    const startDate = moment().subtract(period, "months");

    supports = this.filterApprovedSupports(supports);

    totalValue = supports.reduce((total, item) => {
      if (period && moment(item.createdAt) < startDate) return total;

      return item.status === "approved" ? total + item.value : total;
    }, 0);

    return totalValue;
  }

  getFindingRisingProjectsValue(projects, status, period) {
    const startDate = moment().subtract(period, "months");

    return projects.reduce((count, project) => {
      if (project.status === status) {
        if (period && moment(project.createdAt) < startDate) return count + project.totalValue;
        else return count + project.totalValue;
      }

      return count;
    }, 0);
  }

  getNumOfProjectsByStatus(projects, status) {
    return projects.reduce((count, project) => {
      if (project.status === status) return count + 1;

      return count;
    }, 0);
  }

  buildBiomeData(projects) {
    let data = new Array(biomeArray.length).fill({});

    data = data.map((biome, index) => {
      biome[biomeArray[index].label] = this.getNumOfBiomes(projects, biomeArray[index].name);
      return biome;
    });

    return data[0];
  }

  getNumOfBiomes(projects, biome) {
    let count = 0;

    projects.forEach(project => {
      if (project.biomes.indexOf(biome) !== -1) count++;
    });

    if (count === 0) count = 0.4;

    return count;
  }

  setBiomeChartSize(width) {
    this.setState({ biomeChartWidth: width });
  }

  buildProjectStatusData(projects) {
    const approvedColor = "#B2D58F";
    const refusedColor = "#F2CE81";
    const pendingColor = "#5BC4CD";

    let approvedCount = 0;
    let refusedCount = 0;
    let pendingCount = 0;

    projects.forEach(project => {
      switch (project.status) {
        case "raisingFunds":
          approvedCount++;
          break;
        case "refused":
          refusedCount++;
          break;
        case "pending":
          pendingCount++;
          break;
        default:
          break;
      }
    });

    return [
      { angle: approvedCount, color: approvedColor },
      { angle: refusedCount, color: refusedColor },
      { angle: pendingCount, color: pendingColor },
    ];
  }

  buildSupportsStatusData(supports) {
    {
      const approvedColor = "#B2D58F";
      const refusedColor = "#F2CE81";
      const pendingColor = "#5BC4CD";

      let approvedCount = 0;
      let refusedCount = 0;
      let pendingCount = 0;

      supports.forEach(project => {
        switch (project.status) {
          case "approved":
            approvedCount++;
            break;
          case "refused":
            refusedCount++;
            break;
          case "pending":
            pendingCount++;
            break;
          default:
            break;
        }
      });

      return [
        { angle: approvedCount, color: approvedColor },
        { angle: refusedCount, color: refusedColor },
        { angle: pendingCount, color: pendingColor },
      ];
    }
  }

  buildProponentRankingData(projects) {
    const colors = ["#B2D58F", "#D05A47", "#F2CE81", "#5EC4DC", "#24A862"];

    let proponents = [];

    projects.forEach(project => {
      const proponent = project.proponent;

      if (proponent) {
        const index = proponents.map(item => item.id).indexOf(proponent._id);

        if (index === -1) {
          proponents.push({ id: proponent._id, name: proponent.trade || proponent.company, x: 1 });
        } else {
          proponents[index].x++;
        }
      }
    });

    proponents.sort((a, b) => b.x - a.x);
    proponents = proponents
      .slice(0, 5)
      .map((item, index) => ({ ...item, y: 5 - index, color: colors[index] }));

    proponents = this.addCompanyPadding(proponents, 5);

    proponents = [
      { x: 0, y: proponents.length + 1, opacity: 0 },
      ...proponents,
      { x: 0, y: 0, opacity: 0 },
    ];

    return proponents;
  }

  buildSupporterRankingData(supports) {
    const colors = ["#B2D58F", "#D05A47", "#F2CE81", "#5EC4DC", "#24A862"];

    let supporters = [];

    supports = this.filterApprovedSupports(supports);

    supports.forEach(support => {
      const supporter = support.supporter;
      const index = supporters.map(item => item.id).indexOf(supporter._id);

      if (index === -1) {
        supporters.push({ id: supporter._id, name: supporter.trade || supporter.company, x: 1 });
      } else {
        supporters[index].x++;
      }
    });

    supporters.sort((a, b) => b.x - a.x);
    supporters = supporters
      .slice(0, 5)
      .map((item, index) => ({ ...item, y: 5 - index, color: colors[index] }));

    supporters = this.addCompanyPadding(supporters, 5);

    supporters = [
      { x: 0, y: supporters.length + 1, opacity: 0 },
      ...supporters,
      { x: 0, y: 0, opacity: 0 },
    ];

    return supporters;
  }

  addCompanyPadding(data, n) {
    for (let i = data.length; i < n; i++) {
      data.push({ name: "---", x: 0, y: n - i });
    }

    return data;
  }

  handleBiomeChartContainerRef = element => {
    this.biomeChartElement = element;

    if (element) this.setBiomeChartSize(element.getBoundingClientRect().width * 0.8);
  };

  handleScreenResize = screen => {
    const { screenWidth } = this.state;

    if (screenWidth !== screen.width) this.setState({ screenWidth: screen.width });

    const element = this.biomeChartElement;

    if (element) this.setBiomeChartSize(element.getBoundingClientRect().width * 0.8);
  };

  generateEvolutionReport(evolutionData) {
    let evolutionDataReport = [];

    for (let key in evolutionData[0]) {
      evolutionDataReport.push({
        date: evolutionData[0][key].x,
        value: evolutionData[0][key].y,
        numOfShares: evolutionData[1][key].y,
      });
    }

    return evolutionDataReport;
  }

  generateDistributionReport(distributionData) {
    let distributionDataReport = [];

    for (let key in distributionData[0]) {
      distributionDataReport.push({
        date: distributionData[0][key].x,
        raising: distributionData[0][key].y,
        pending: distributionData[1][key].y,
        approved: distributionData[2][key].y,
        total: distributionData[0][key].y + distributionData[1][key].y + distributionData[2][key].y,
      });
    }

    return distributionDataReport;
  }

  generateBiomeReport(biomeData) {
    let biomeDataReport = [];

    for (let key in biomeData) {
      biomeDataReport.push({
        biome: key,
        projects: biomeData[key] < 0.5 ? 0 : biomeData[key],
      });
    }

    return biomeDataReport;
  }

  generateStatusReport(statusData) {
    let statusDataReport = [];
    let totalAngle = 0;

    const statusValues = [dict.t("Aprovado"), dict.t("Recusado"), dict.t("Pendente")];

    for (let key in statusData) totalAngle += statusData[key].angle;

    for (let key in statusData) {
      statusDataReport.push({
        status: statusValues[key],
        value: `${((100 * statusData[key].angle) / totalAngle).toFixed(2)}%`,
      });
    }

    return statusDataReport;
  }

  generateProponentRankingReport(proponentRankingData) {
    let proponentRankingDataReport = [];

    for (let i = 1; i < 6; i++) {
      proponentRankingDataReport.push({
        name: proponentRankingData[i].name,
        projects: proponentRankingData[i].x,
      });
    }

    return proponentRankingDataReport;
  }

  generateProponentRankingReport(proponentRankingData) {
    let proponentRankingDataReport = [];

    for (let i = 1; i < 6; i++) {
      proponentRankingDataReport.push({
        name: proponentRankingData[i].name,
        projects: proponentRankingData[i].x,
      });
    }

    return proponentRankingDataReport;
  }

  generateSupporterRankingReport(supporterRankingData) {
    let supporterRankingDataReport = [];

    for (let i = 1; i < 6; i++) {
      supporterRankingDataReport.push({
        name: supporterRankingData[i].name,
        projects: supporterRankingData[i].x,
      });
    }

    return supporterRankingDataReport;
  }

  handleReportClick = () => {
    const {
      evolutionData,
      distributionData,
      biomeData,
      statusData,
      proponentRankingData,
      supporterRankingData,
    } = this.state;

    const role = Authentication.getUser().personal.role;

    const reportData = [];

    reportData.push(this.generateEvolutionReport(evolutionData));
    if (role === "master") reportData.push(this.generateDistributionReport(distributionData));
    reportData.push(this.generateBiomeReport(biomeData));
    reportData.push(this.generateStatusReport(statusData));
    if (role === "master") {
      reportData.push(this.generateProponentRankingReport(proponentRankingData));
      reportData.push(this.generateSupporterRankingReport(supporterRankingData));
    }

    const tableConfigs = [];

    tableConfigs.push(reportTableConfig["evolutionData"]);
    if (role === "master") tableConfigs.push(reportTableConfig["distributionData"]);
    tableConfigs.push(reportTableConfig["biomeData"]);
    tableConfigs.push(reportTableConfig["statusData"]);
    if (role === "master") {
      tableConfigs.push(reportTableConfig["rankingData"]);
      tableConfigs.push(reportTableConfig["rankingData"]);
    }

    const sheetNames = [];

    sheetNames.push("Evolução");
    if (role === "master") sheetNames.push("Distribuição");
    sheetNames.push("Biomas");
    sheetNames.push("Status");
    if (role === "master") {
      sheetNames.push("Ranking - proponentes");
      sheetNames.push("Ranking - apoiadores");
    }

    generateReport(reportData, tableConfigs, "relatório-completo.xlsx", true, sheetNames);
  };
}


