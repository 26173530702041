
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Item "Continue >>" para o lado direito
//


import React, { Component } from "react";
import { Link } from "react-router";

import multiLang from "../../lib/multilang/multiLang";
const dict = new multiLang(multiLang.getLanguage());



export default class List extends Component {
  static defaultProps = {
    language: "PT",
    sending: false,
  };

  render() {
    return (
      <div className="row form-submit" style={this.props.style}>
        <Link to={this.props.forwardRoute}>
          <div className="form-forward-button">{`${dict.t(this.props.forwardLabel)} >`}</div>
        </Link>
      </div>
    );
  }
}


