import React, { Component } from "react";

import Dropdown from "../Dropdown";
import "./style.css";

export default class SearchBar extends Component {
  state = {
    value: "",
  };

  handleInputChange = e => {
    this.setState({ value: e.target.value });
  };

  render() {
    const { button } = this.props;
    const { value } = this.state;

    return (
      <div className="container searchContainer">
        {!button.states ? (
          <div className="row">
            <div className="col-sm-9">
              <input
                type="text"
                value={value}
                onChange={this.handleInputChange}
                className={"form-control form-input searchBar"}
              />
            </div>
            <div className="col-sm-3">
              <button
                type="button"
                className={"button searchBarButton"}
                onClick={() => {
                  button.onClick(value);
                }}
              >
                {button.title}
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-2">
              <Dropdown
                label="Estado"
                selectClass="searchOptionsSelect"
                options={button.states}
                onChange={ev => this.handleChangeFilter(ev, "state")}
              />
            </div>
            <div className="col-sm-7" style={{ paddingLeft: "0px" }}>
              <input
                type="text"
                value={value}
                onChange={this.handleInputChange}
                className={"form-input searchBar"}
              />
            </div>
            <div className="col-sm-3" style={{ paddingRight: "0px" }}>
              <button
                type="button"
                className={"button searchBarButton"}
                onClick={() => {
                  button.onClick(value);
                }}
              >
                {button.title}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
