
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de botões de gerenciamento de projeto - master
//


import React, { Component, Fragment } from "react";

import Alert from "../../../../lib/Alert";
import Request from "../../../../lib/BackendRequest";

import Loader from "../../../Loader";

import ScreenSize from "../../../Commons/ScreenSize";

import Toolbar from "./Toolbar";

import List from "../../List/Zebra";
import ItemBody from "./ItemBody";

import SupporterData from "../../../Commons/SupporterData";

import Date from "../../../../lib/Date";
import { formatCurrency } from "../../../../lib/FormatString";
import supportStatus from "../../../../constants/supportStatus";

import fileUpload from "../../../../lib/fileUpload";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class SupportIntern extends Component {
  constructor(props) {
    super(props);

    this.state = {
      support: null,
    };
  }

  componentWillMount() {
    this.fetchSupport();
  }

  render() {
    const { support } = this.state;

    if (!support) return <Loader />;

    return (
      <div className="footer-margin-logged">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="">
          <Toolbar
            router={this.props.router}
            status={support.status}
            onApprove={this.handleApprove}
            onDeny={this.handleDeny}
            onDelete={this.handleDelete}
          />
        </div>

        <div className="container">
          <div className="row page-title" style={{ marginTop: "0px" }}>
            {support.project[language].shortName}
          </div>

          {this.state.screenWidth >= 768 ? (
            <Fragment>
              {this.renderSupportListHeader()}
              {this.renderSupportData(support)}
            </Fragment>
          ) : (
            this.renderMobileList(support)
          )}

          <div className="row page-title" style={{ marginTop: "15px", marginBottom: "0px" }}>
            {dict.t("Apoiador")}
          </div>

          <div style={{ marginBottom: "30px" }}>
            <SupporterData supporter={support.supporter} />
          </div>
        </div>
      </div>
    );
  }

  fetchSupport() {
    const url = `/master/supports/${this.props.params.id}`;

    Request.get(url, values => {
      this.setState({ support: values });
    });
  }

  renderSupportListHeader() {
    return (
      <div style={{ marginTop: "0px" }}>
        <div
          className="row my-projects-intern-support-list-header"
          style={{ marginLeft: "-15px", marginRight: "-15px" }}
        >
          <div className="col-sm-2">{dict.t("Código")}</div>

          <div className="col-sm-1">{dict.t("Cotas")}</div>

          <div className="col-sm-2">{dict.t("Valor apoio")}</div>

          <div className="col-sm-2">{dict.t("Valor Vbio")}</div>

          <div className="col-sm-2">{dict.t("Valor total")}</div>

          <div className="col-sm-1">{dict.t("Data")}</div>

          <div className="col-sm-1">{dict.t("Status")}</div>

          <div className="col-sm-1">{dict.t("Recibo")}</div>
        </div>
      </div>
    );
  }

  renderSupportData(support) {
    return (
      <div className="row supports-intern-data-container">
        <div className="my-projects-intern-support-list-item-body">
          <div className="col-sm-2">{support.token}</div>

          <div className="col-sm-1">{support.numOfShares}</div>

          <div className="col-sm-2">{formatCurrency(support.value)}</div>

          <div className="col-sm-2">{formatCurrency(support.value * support.supportFee)}</div>

          <div className="col-sm-2">{formatCurrency(support.value * (support.supportFee + 1))}</div>

          <div className="col-sm-1">{Date.formatWithoutTime(support.createdAt)}</div>

          <div className="col-sm-1">
            {dict.t(supportStatus.find(status => status.name === support.status).label)}
          </div>

          <div className="col-sm-1">
            <div className="supports-intern-attache-button-container">
              <div className="button-file">
                <input type="file" onChange={this.handleSupportUploadClick} title="Enviar recibo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobileList(support) {
    const items = [];

    items.push({ label: dict.t("Código"), value: support.token });
    items.push({ label: dict.t("Cotas"), value: support.numOfShares });
    items.push({ label: dict.t("Total"), value: formatCurrency(support.value) });
    items.push({ label: dict.t("Data"), value: Date.formatWithoutTime(support.createdAt) });
    items.push({
      label: dict.t("Status"),
      value: dict.t(supportStatus.find(status => status.name === support.status).label),
    });
    items.push({ label: dict.t("Recibo"), value: "recibo", button: "attach" });

    return (
      <div style={{ marginTop: "50px" }}>
        <List
          items={items}
          headerKey={"label"}
          bodyStyle={{ float: "right" }}
          itemBodyComponent={ItemBody}
          itemBodyProps={{ onUploadClick: this.handleSupportUploadClick }}
          buttons={this.buttons}
          style={{ height: "65px" }}
        />
      </div>
    );
  }

  updateSupport(data, message, reload) {
    const url = `/master/supports/${this.props.params.id}`;

    Request.post(url, data, res => {
      if (res.success) Alert.success(message);

      if (reload) {
        this.setState({ support: null });
        this.fetchSupport();
      }
    });
  }

  handleApprove = () => {
    Alert.confirm(`${dict.t("Tem certeza que deseja aprovar o apoio")}?`, yes => {
      if (yes) {
        const support = { status: "approved" };

        this.updateSupport(support, `${dict.t("Apoio aprovado com sucesso")}`, true);
      }
    });
  };

  handleDeny = () => {
    Alert.confirm(`${dict.t("Tem certeza que deseja negar o apoio")}?`, yes => {
      if (yes) {
        const support = { status: "refused" };

        this.updateSupport(support, `${dict.t("Apoio negado com sucesso")}`, true);
      }
    });
  };

  handleDelete = () => {
    Alert.confirm(`${dict.t("Tem certeza que deseja deletar o apoio")}?`, yes => {
      if (yes) {
        const support = { deleted: true };

        const url = `/master/supports/${this.props.params.id}`;

        Request.delete(url, support, res => {
          if (res.success) Alert.success(`${dict.t("Apoido deletado com sucesso")}`);

          this.props.router.goBack();
        });
      }
    });
  };

  handleSupportUploadClick = e => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    fileUpload(files).then(res => {
      const url = res[0].url;

      const support = { receipt: url };

      this.updateSupport(support, `${dict.t("Recibo enviado com sucesso")}`);
    });
  };

  handleScreenResize = screen => {
    const { width } = screen;

    this.setState({ screenWidth: width });
  };
}


