
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de projetos - proponente
//


import React, { Component } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Dropdown from "../../../Commons/Dropdown";

import Authentication from "../../../../lib/Authentication";

import Back from "../../../Commons/Back";

import moment from "moment";

import List from "../../List/Zebra";
import ProponentSearchForm from "../../../Commons/ProponentSearchForm";

import Alert from "../../../../lib/Alert";

import ItemBody from "./ItemBody";

import reportTableConfig from "../../../../constants/reportTableConfig";
import generateReport from "../../../../lib/generateReport";

import proponentStatus from "../../../../constants/proponentStatus";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";
import masterAccess from "../../../../lib/masterAccess";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class PendingProjects extends Component {
  handleDeleteButtonClick = item => {
    let proponent = item;

    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este proponente"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/users/proponent/${proponent._id}`;

        Request.delete(url, {}, res => {
          if (res.success) {
            Alert.success(
              `${dict.t("Proponente")} ${dict.t("deletado")} ${dict.t("com sucesso")}`,
              () => {
                this.setState({ supporters: null });
                this.setListFilter(this.props.router.params.filter);
                this.updateUserRecord();
              }
            );
          }
        });
      }
    });
  };

  buttons = [
    {
      type: "edit",
      route: "/master/proponentes",
    },
    {
      type: "delete",
      onClick: this.handleDeleteButtonClick,
    },
  ];

  buttonsNoDelete = [
    {
      type: "edit",
      route: "/master/proponentes",
    },
  ];

  sortByOptions = [
    {
      title: dict.t("Todos"),
      value: "all",
    },
    {
      title: dict.t("Nome"),
      value: "name",
    },
    {
      title: dict.t("Data de cadastro"),
      value: "createdAt",
    },
    {
      title: dict.t("Data de edição"),
      value: "updatedAt",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      filteredProponents: [],
      proponents: null,
    };
  }

  handleSortBy = ev => {
    let { filteredProponents } = this.state;

    switch (ev.target.value) {
      case "createdAt":
        filteredProponents.sort((opt1, opt2) =>
          moment(opt1.createdAt).diff(moment(opt2.createdAt), "minutes")
        );
        this.setState({ filteredProponents });
        break;

      case "updatedAt":
        filteredProponents.sort((opt1, opt2) =>
          moment(opt1.updatedAt).diff(moment(opt2.updatedAt), "minutes")
        );
        this.setState({ filteredProponents });
        break;

      case "name":
        filteredProponents.sort((opt1, opt2) => {
          const name1 = opt1.trade.trim() === "" ? opt1.company : opt1.trade;
          const name2 = opt2.trade.trim() === "" ? opt2.company : opt2.trade;

          return (name1.trim() || "").localeCompare(name2.trim() || "");
        });

        this.setState({ filteredProponents });
        break;

      default:
        break;
    }
  };

  componentWillMount() {
    this.setListFilter(this.props.router.params.filter);
    this.updateUserRecord();
  }

  componentWillReceiveProps(newProps) {
    this.setListFilter(newProps.router.params.filter);
    this.updateUserRecord();
  }

  updateUserRecord() {
    let date = new Date();
    date = date.toISOString();

    localStorage.setItem("userLastCheckProponents", date);

    const url = `/master/users/proponent/${Authentication.getUser().personal.id}`;

    Request.post(url, { role: "master", lastCheckProponents: date }, () => {});
  }

  setListFilter(param) {
    this.setState({ proponents: null });

    let fetchUrl = "/master/proponents";

    if (param === "pendencias") fetchUrl += "/pending";
    else if (param === "todos") fetchUrl += "";
    else this.props.router.push("/");

    this.fetchProponents(fetchUrl);
  }

  render() {
    if (!this.state.proponents) return <Loader />;

    const canDelete = masterAccess("proponentApprove");

    return (
      <div className="container page-margin-top footer-margin-logged">
        <div className="page-title">{dict.t("Proponentes")}</div>

        <ProponentSearchForm
          buttonLabel={dict.t("Buscar Proponentes")}
          proponents={this.state.proponents}
          onFilter={this.handleProponentFilter}
        />

        <div className="row" style={{ marginTop: "15px" }}>
          <div className="col-md-9 page-title" style={{ marginTop: "30px", marginBottom: "20px" }}>
            {this.props.params.filter === "todos"
              ? dict.t("Proponentes")
              : dict.t("Proponentes pendentes")}
          </div>
          <div className="col-md-3">
            <Dropdown
              label={dict.t("Ordenar por")}
              selectClass="searchOptionsSelect"
              options={this.sortByOptions}
              onChange={this.handleSortBy}
            />
          </div>
        </div>

        <div style={{ marginRight: "15px", marginLeft: "15px" }}>
          <List
            items={this.state.filteredProponents}
            itemBodyComponent={ItemBody}
            buttons={canDelete ? this.buttons : this.buttonsNoDelete}
            style={{ height: "60px" }}
          />
        </div>

        <div>
          <div className="master-list-button-container">
            <button className="button supports-list-report-button" onClick={this.handleReportClick}>
              {dict.t("Baixar relatório de proponentes")}
            </button>
          </div>

          <div className="master-list-back-container">
            <Back router={this.props.router} />
          </div>
        </div>
      </div>
    );
  }

  handleProponentFilter = filteredProponents => {
    this.setState({ filteredProponents });
  };

  fetchProponents(url) {
    Request.get(url, values => {
      this.setState({ proponents: values, filteredProponents: values });
    });
  }

  filterByName = (records, searchValue) => {
    let translated = dict.t(searchValue);

    if (!translated || typeof translated !== "string") return;

    translated = translated.toLowerCase().trim();

    return records.filter(item => {
      let trade = item.trade.toLowerCase().trim();
      let company = item.company.toLowerCase().trim();

      return trade.includes(translated) || company.includes(translated);
    });
  };

  handleSearchButtonClick = search => {
    if (search !== "") {
      let { filteredSupporters } = this.state;
      filteredSupporters = this.filterByName(filteredSupporters, search);
      this.setState({ filteredSupporters });
    } else {
      this.setState({ filteredSupporters: this.state.supporters });
    }
  };

  handleReportClick = () => {
    let reportData = JSON.parse(JSON.stringify(this.state.proponents));

    reportData = reportData.map(proponent => {
      proponent.status = proponentStatus.find(item => item.name === proponent.status).label;
      return proponent;
    });

    generateReport(reportData, reportTableConfig["proponent"], "relatório-proponentes.xlsx");
  };
}


