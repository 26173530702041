
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cadastro de projeto - básico
//


import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import Input from "../../../lib/Forms/Input";
import Dropdown from "../../../lib/Forms/Dropdown";
import Checkbox from "../../../lib/Forms/Checkbox";

import Loader from "../../Loader";

import Authentication from "../../../lib/Authentication";
import Request from "../../../lib/BackendRequest";

import biomes from "../../../constants/biomes";

import languageTools from "../../../lib/languageTools";

import downloadFile from "../../../lib/downloadFile";

import multiLang from "../../../lib/multilang/multiLang";

import stateList from "../../../constants/stateList";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



class BasicForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proponentManualReady: false,
    };
  }

  componentWillMount() {
    this.props.initialize(this.props.defaultValues);
    this.fetchManual();
  }

  render() {
    let { proponentManual, proponentManualReady } = this.state;

    if (!proponentManualReady) return <Loader />;

    proponentManual[language] = languageTools.merge(proponentManual.PT, proponentManual[language]);

    return (
      <Fragment>
        <div className="new-project-text">
          {dict.t("Preencha aqui os dados de cadastro de seu projeto.")}
        </div>

        <div className="form-body">
          <form>
            <div className="new-project-manual-container">
              <div style={{ display: "inline-block" }}>
                <Field
                  name="proponentManual"
                  style={{ marginBottom: "10px" }}
                  cssClass="form-input-checkbox"
                  cssClassError="form-input-error"
                  component={Checkbox}
                />
              </div>

              <span className="new-project-text">{dict.t("Declaro que li o")} </span>

              <a
                onClick={() => downloadFile(this.state.proponentManual[language].url)}
                style={{ cursor: "pointer" }}
              >
                <span className="new-project-text-link">{dict.t("Manual do Proponente")}</span>
              </a>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Field
                  name="name"
                  label={dict.t("Nome do Projeto")}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  info={dict.t("Nome técnico de seu projeto")}
                  component={Input}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Field
                  name="shortName"
                  label={dict.t("Apelido do Projeto")}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  info={dict.t(
                    "Nome resumido ou comercial de seu projeto, para facilitar sua identificação em nossa plataforma"
                  )}
                  component={Input}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Field
                  name="city"
                  label={dict.t("Cidade")}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="state"
                  label={dict.t("Estado")}
                  placeholder={dict.t("Selecione...")}
                  data={stateList}
                  dataKey="id"
                  dataLabel="shortName"
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Dropdown}
                />
              </div>
            </div>

            <div className="new-project-section-title">{dict.t("Bioma")}</div>

            <div className="row">{this.getBiomeFields(biomes)}</div>
          </form>
        </div>
      </Fragment>
    );
  }

  getBiomeFields(items) {
    return items.map((item, index) => {
      return (
        <div key={index} className="col-md-3">
          <Field
            name={`biomes.${item.name}`}
            label={dict.t(item.label)}
            style={{ marginBottom: "30px" }}
            cssClass="form-input-checkbox"
            cssClassError="form-input-error"
            component={Checkbox}
          />
        </div>
      );
    });
  }

  fetchManual() {
    let url;
    const role = Authentication.getUser().personal.role;

    if (role === "proponentUser") {
      url = "/proponent/documents/manual";
    }

    if (role === "master") {
      url = "/master/configs/documents/proponent-docs/manual";
    }

    Request.get(url, values => {
      this.setState({ proponentManual: values[0], proponentManualReady: true });
    });
  }
}



export default reduxForm({
  form: "newProjectBasicForm",
})(BasicForm);


