
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição:	Animação de load
//


import React, { Component } from "react";

import LoaderSpinner from "react-loader-spinner";

import "./style.css";



export default class Loader extends Component {
  render() {
    return (
      <div className="container loader-container">
        <div className="loader">
          <LoaderSpinner type="Oval" color="#24A862" height="70" width="70" />
        </div>
      </div>
    );
  }
}


