
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de botões de gerenciamento de projeto - master
//


import React, { Fragment } from "react";
import { Link } from "react-router";

import Back from "../../../Commons/Back";

import Date from "../../../../lib/Date";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



function renderButtons(props) {
  return (
    <div className="project-intern-toolbar-item">
      <Link to={props.editRoute}>
        <img src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon-2.png" alt={dict.t("Editar")} title={dict.t("Editar")} />
      </Link>

      <img
        src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon-2.png"
        alt={dict.t("Deletar")}
        title={dict.t("Deletar")}
        style={{ marginLeft: "15px" }}
        onClick={props.onDelete}
      />
    </div>
  );
}



function renderPendingToolbar(props) {
  return (
    <Fragment>
      <div className="project-intern-toolbar-item">
        <button
          className="button project-intern-toolbar-button gray-background"
          onClick={props.onEditEnable}
        >
          {dict.t("Habilitar edição")}
        </button>
      </div>

      <div className="project-intern-toolbar-item" style={{ marginBottom: "0" }}>
        <button
          className="button project-intern-toolbar-button yellow-background"
          onClick={props.onAlert}
        >
          {dict.t("Gerar alerta")}
        </button>

        <div className="project-intern-toolbar-alert-label">
          {props.alertDate
            ? `${dict.t("Último alerta em")} ${Date.formatWithoutTime(props.alertDate)}`
            : null}
        </div>
      </div>

      <div className="project-intern-toolbar-item">
        <button
          className="button project-intern-toolbar-button red-background"
          onClick={props.onDeny}
        >
          {dict.t("Negar")}
        </button>
      </div>

      <div className="project-intern-toolbar-item">
        <button className="button project-intern-toolbar-button" onClick={props.onApprove}>
          {dict.t("Aprovar")}
        </button>
      </div>

      {renderButtons(props)}
    </Fragment>
  );
}



function renderInactivateToolbar(props) {
  return (
    <Fragment>
      <div className="project-intern-toolbar-item">
        <button
          className="button project-intern-toolbar-button gray-background"
          onClick={props.onInactivate}
        >
          {dict.t("Inativar")}
        </button>
      </div>

      {renderButtons(props)}
    </Fragment>
  );
}



function renderInactivatedToolbar(props) {
  return (
    <Fragment>
      <div className="project-intern-toolbar-item">
        <div className="project-intern-toolbar-text">
          {`${dict.t("Projeto")} `}
          <strong>{`${dict.t("inativado manualmente")} `}</strong>
          {`${dict.t("em")} ${Date.formatWithoutTime(props.inactivateDate)} ${dict.t("as")}`}
          <div style={{ textAlign: "right" }}>{Date.formatTimeOnly(props.inactivateDate)}</div>
        </div>
      </div>

      <div className="project-intern-toolbar-item">
        <button
          className="button project-intern-toolbar-button gray-background"
          onClick={props.onReactivate}
          style={{ marginBottom: "0" }}
        >
          {dict.t("Reativar")}
        </button>
      </div>

      {renderButtons(props)}
    </Fragment>
  );
}



function renderDefaultToolbar(props) {
  return <Fragment>{renderButtons(props)}</Fragment>;
}



export default function(props) {
  function selectToolbar(status) {
    switch (status) {
      case "pending":
        return renderPendingToolbar(props);
      case "refused":
        return renderInactivateToolbar(props);
      case "raisingFunds":
        return renderInactivateToolbar(props);
      case "inactive":
        return renderInactivatedToolbar(props);
      default:
        return renderDefaultToolbar(props);
    }
  }

  return (
    <div className="container page-margin-top">
      <div className="project-intern-back-button-container">
        <Back router={props.router} />
      </div>

      {props.backButtonOnly ? null : (
        <div style={{ marginBottom: "30px", float: "right" }}>
          <div className="row project-intern-toolbar-container align-right">
            <div className="col-sm-12">{selectToolbar(props.status)}</div>
          </div>

          <div className="row">
            <div className="col-sm-offset-10 col-sm-2 align-right">
              <div className="project-inter-toolbar-edit-date project-intern-toolbar-text">
                {`${dict.t("Última edição")}: ${Date.formatWithoutTime(props.updatedAt)}`}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}


