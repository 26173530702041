
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Lista de áres de interesse - master
//


import React, { Component } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import masterAccess from "../../../../lib/masterAccess";

import List from "../../List/Card";

import ItemAreaBody from "./ItemAreaBody";

import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class AreasOfInterest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      records: [],
    };
  }

  componentWillMount() {
    this.fetchRecords();
  }

  render() {
    if (!this.state.ready) return <Loader />;

    let addRoute;
    let canEdit = false;

    if (masterAccess("settings")) {
      addRoute = "/master/configuracao/areas-de-interesse/novo";
      canEdit = true;
    }

    return (
      <div className="container footer-margin-logged">
        <div style={{ marginBottom: "30px" }}>
          <List
            title={dict.t("Áreas de Interesse")}
            addRoute={addRoute}
            items={this.state.records}
            itemTitleComponent={ItemAreaBody}
            canEdit={canEdit}
            onItemDeleteClick={this.handleItemDeleteClick}
            style={{ height: "60px" }}
          />
        </div>
      </div>
    );
  }

  fetchRecords() {
    Request.get("/master/projects/areas-of-interest", values => {
      this.setState({ ready: true, records: values });
    });
  }

  handleItemDeleteClick = item => {
    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("excluir")} ${dict.t(
      "esta área de interesse"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/master/projects/areas-of-interest/${item._id}`;

        Request.delete(url, item, msg => {
          this.fetchRecords();

          // Forçar renderização após exclusão
          this.setState({ ready: false });
          Alert.success(dict.t("Área de interesse deletada com sucesso"));
        });
      }
    });
  };
}


