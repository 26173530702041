
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Componente de formulário para entrada de arquivo
//


import React, { Component } from "react";

import Alert from "../../../lib/Alert";
import Request from "../../../lib/BackendRequest";

import fileUpload from "../../../lib/fileUpload";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang("PT");



export default class ButtonAttach extends Component {
  render() {
    return (
      <div className="button-file" title={dict.t("Enviar arquivo")}>
        <input type="file" onChange={this.handleSubmit} />
      </div>
    );
  }

  handleSubmit = ev => {
    if (this.props.onUploadStart) this.props.onUploadStart();

    this.formValues = ev;

    fileUpload(ev.target.files).then(res => {
      const url = res[0].url;

      if (!this.formValues[language]) this.formValues[language] = {};

      this.formValues[language].url = url;

      if (this.props.onFileUpload) this.props.onFileUpload(url);

      if (this.props.url) this.updateRecord(this.formValues);
    });
  };

  updateRecord(values) {
    Request.post(this.props.url, values, msg => {
      //Alert.success(`Arquivo anexo em ${this.props.label} com sucesso`);
      Alert.success(dict.t("Arquivo enviado com sucesso"));

      if (this.props.onRecordUpdate) this.props.onRecordUpdate();
    });
  }
}


