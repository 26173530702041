
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Corpo da lista de apoiadores
//


import React from "react";
import moment from "moment";
import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default function(props) {
  const { trade, company } = props.item;

  const name = trade.trim() === "" ? company : trade;

  return (
    <div className="row list-zebra-item-header-text" style={{ width: "71vw" }}>
      <div className="col-md-9">{name.trim()}</div>
      <div className="col-md-3">
        {`${dict.t("Cadastro")}: ${moment(props.item.createdAt).format("DD/MM/YYYY")}`}
      </div>
    </div>
  );
}


