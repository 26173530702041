
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Formulário de criação e edição para cadastro de termos de uso
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";

import TextFormatted from "../../../../lib/Forms/TextFormatted";
import Submit from "../../../..//lib/Forms/Submit";

import ScreenSize from "../../../Commons/ScreenSize";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 768;



class FormDocumentTerm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      record: {},
      uploading: false,
    };
  }

  componentWillMount() {
    this.formValues = {};

    this.fecthRecord(this.props.params.id);
  }

  getNumRows() {
    return this.state.record[language].text.length / 150;
  }

  render() {
    const { record, ready } = this.state;

    if (!ready) return <Loader />;

    //record[language] = languageTools.merge(record[language], record.PT);
    record[language] = languageTools.merge(record.PT, record[language]);

    let backStyle;
    let paddingBottom = "50px";

    if (this.state.smallScreen) {
      backStyle = { bottom: "-50px" };
      paddingBottom = "80px";
    }

    return (
      <div
        className="container form-container page-margin-top footer-margin-logged"
        style={{ paddingBottom }}
      >
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">{dict.t(this.state.record.shortName)}</div>

        <div className="col-md-12 form-body" style={{ marginTop: "15px" }}>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row" style={{ marginBottom: "16px" }}>
              <div className="col-md-12">
                <div>
                  <Field
                    name={`[${language}].text`}
                    rows={this.getNumRows()}
                    validate={[VF.Required]}
                    cssClass="form-input"
                    cssClassError="form-input-error"
                    component={TextFormatted}
                  />
                </div>
              </div>
            </div>

            <Submit
              backRoute="/master/configuracao/documentos"
              sending={this.state.uploading}
              backStyle={backStyle}
            />
          </form>
        </div>
      </div>
    );
  }

  fecthRecord(id) {
    const url = `/master/configs/documents/term/${id}`;

    Request.get(url, values => {
      this.setState({ record: values, ready: true });
      this.props.initialize(this.state.record);
    });
  }

  updateRecord(values) {
    const url = `/master/configs/documents/term/${this.state.record._id}`;

    Request.post(url, values, msg => {
      Alert.success(dict.t("Edição efetuada com sucesso"), () => {
        this.props.router.push("/master/configuracao/documentos");
      });
    });
  }

  handleSubmit = values => {
    this.updateRecord(values);
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "termForm",
})(FormDocumentTerm);


