
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Gerador de relatórios xlsx
//


import { utils, writeFile } from "xlsx";



export default function generateReport(data, tableConfig, fileName, multiSheet = false, sheetNames) {
  const wb = utils.book_new();

  if (!multiSheet) {
    data = [data];
    tableConfig = [tableConfig];
  }

  data.forEach((sheet, index) => {
    let table = [];
    let header = [];

    sheet.forEach(dataItem => {
      let row = [];

      tableConfig[index].forEach(configItem => {
        if (table.length === 0) header.push(configItem.label);

        const value = dataItem[configItem.key];

        row.push(value);
      });

      table.push(row);
    });

    table = [header, ...table];

    const ws = utils.aoa_to_sheet(table);

    const wsName = sheetNames && sheetNames[index] ? sheetNames[index] : `Planilha${index + 1}`;

    utils.book_append_sheet(wb, ws, wsName);
  });

  writeFile(wb, fileName);
}


