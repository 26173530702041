
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Meus projetos
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import ScreenSize from "../../Commons/ScreenSize";

import Loader from "../../Loader";

import SearchBar from "./SearchBar";

import BadgeStack from "../../Commons/BadgeStack";
import BackButton from "../../Commons/Back";

import Authentication from "../../../lib/Authentication";
import Request from "../../../lib/BackendRequest";
import Alert from "../../../lib/Alert";

import languageTools from "../../../lib/languageTools";

import ImageFilter from "../../ImageFilter";
import ProgressBar from "../../ProgressBar";

import userRoutes from "../../../constants/userRoutes";

import odsArray from "../../../constants/ods";
import legalFitnessArray from "../../../constants/legalFitness";

import projectStatus from "../../../constants/projectStatus";
import supportStatus from "../../../constants/supportStatus";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const projectImageFilter =
  "linear-gradient(-90deg, rgba(102, 102, 102, 0.8) 0%, rgba(102, 102, 102, 0) 100%)";

const smallScreenWidth = 992;



export default class MyProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: null,
      status: projectStatus,
      searchFilter: {},
    };
  }

  componentWillMount() {
    const { companyId } = Authentication.getUser().personal;

    if (Authentication.getUser().personal.role === "supporterUser") {
      this.fetchSupports(companyId);
    }

    if (Authentication.getUser().personal.role === "proponentUser") {
      this.fetchProponentProjects(companyId);
    }
  }

  render() {
    if (!this.state.projects) return <Loader />;

    let status;
    const role = Authentication.getUser().personal.role;

    if (role === "supporterUser") status = this.buildSupportStatus();
    else if (role === "proponentUser") status = this.buildProjectStatus();

    return (
      <div className="container footer-margin-logged">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="back-button-generic" style={{ marginBottom: 0 }}>
          <BackButton router={this.props.router} />
        </div>

        {this.composeHeader()}

        <SearchBar status={status} onSearch={this.handleSearch} />

        <div className="my-projects-list-container">
          {this.composeProjectList(this.filterProjects(this.state.projects))}
        </div>
      </div>
    );
  }

  buildProjectStatus() {
    let status = [{ name: "all", label: "Todos" }];

    this.state.projects.forEach(project => {
      projectStatus.some(item => {
        if (item.name === project.status) {
          if (status.indexOf(item) === -1) status.push(item);

          return true;
        }

        return false;
      });
    });

    return status;
  }

  buildSupportStatus() {
    let status = [{ name: "all", label: "Todos" }];

    this.state.supports.forEach(support => {
      supportStatus.some(item => {
        if (item.name === support.status) {
          if (status.indexOf(item) === -1) status.push(item);

          return true;
        }

        return false;
      });
    });

    return status;
  }

  composeHeader() {
    let pageLabel = "";
    let buttonLabel = "";
    let buttonLink = "";
    let buttonColorClass = "";

    switch (Authentication.getUser().personal.role) {
      case "supporterUser":
        pageLabel = dict.t("Projetos apoiados");
        buttonLabel = dict.t("Apoiar");
        buttonLink = "/apoiador/projetos";
        buttonColorClass = "button-blue";
        break;

      case "proponentUser":
        pageLabel = dict.t("Projetos propostos");
        buttonLabel = dict.t("Propor");
        buttonLink = "/proponente/projetos/novo";
        buttonColorClass = "button-orange";
        break;

      default:
        break;
    }

    return (
      <div className="row">
        <div className="col-md-10 col-sm-9 my-projects-page-title-label">{pageLabel}</div>
        <div className="col-md-2 col-sm-3">
          <Link to={buttonLink} style={{ textDecoration: "none" }}>
            <button className={`button my-projects-button ${buttonColorClass}`}>
              {buttonLabel}
            </button>
          </Link>
        </div>
      </div>
    );
  }

  composeProjectList(projects) {
    if (!projects) return "";

    return projects.map((project, index) => {
      return (
        <div key={index}>
          {this.state.smallScreen
            ? this.composeAlternateProjectCard(project)
            : this.composeProjectCard(project)}
        </div>
      );
    });
  }

  composeProjectCard(project) {
    if (!project) return null;

    project[language] = languageTools.merge(project.PT, project[language]);

    const role = Authentication.getUser().personal.role;

    const url = Authentication.isAuthenticated()
      ? `/${userRoutes(role)}projetos/apoio/${project._id}`
      : "";

    const supportedPercent = 100 * (project.supportedValue / project.totalValue);
    const targetPercent = 100 * (project.minimumValue / project.totalValue);

    let status;

    if (project.status === "pending" && project.alert) {
      status = "Alerta gerado";
    } else {
      status = projectStatus.find(item => item.name === project.status).label;
    }

    const hasImage = project.images[0];
    const image = hasImage ? project.images[0] : "https://vbio.blob.core.windows.net/public/assets/img/image-placeholder.png";

    const toolbar = role === "proponentUser" && project.status === "notSubmited";

    const shortNameStyle = toolbar ? { width: "calc(100% - 70px)" } : {};

    return (
      <Link to={url}>
        <div className="card my-projects-list-container" style={{ marginBottom: "15px" }}>
          <div className="card" style={{ backgroundColor: "#F2F3F3" }}>
            <div className="my-projects-project-image-container">
              <img
                className="my-projects-project-image"
                src={image}
                alt={project[language].shortName}
                title={project[language].shortName}
              />
              {hasImage ? (
                <ImageFilter
                  className="my-projects-project-image"
                  filter={projectImageFilter}
                  disableOnHover={true}
                  title={project[language].shortName}
                />
              ) : null}
            </div>

            {this.getLegalFitnessIcons(project)}

            <div className="container my-projects-project-badges-container">
              {this.getProjectBadges(project)}
            </div>

            <div className="my-projects-project-content-container">
              <div style={{ overflow: "auto" }}>
                <div className="my-projects-project-shortname-container" style={shortNameStyle}>
                  <div className="my-projects-project-shortname">{project[language].shortName}</div>
                </div>

                {toolbar ? (
                  <div className="my-projects-project-toolbar">
                    <img
                      className="my-projects-project-toolbar-item"
                      src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon.png"
                      alt={dict.t("Editar projeto")}
                      title={dict.t("Editar projeto")}
                      onClick={e => this.handleEditClick(e, project._id)}
                    />

                    <img
                      className="my-projects-project-toolbar-item"
                      src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon.png"
                      alt={dict.t("Deletar projeto")}
                      title={dict.t("Deletar projeto")}
                      onClick={e => this.handleDeleteClick(e, project._id)}
                    />
                  </div>
                ) : null}
              </div>

              <div className="my-projects-project-bar-container">
                <ProgressBar
                  height="15px"
                  markerHeight="31px"
                  value={supportedPercent}
                  markerLabel={dict.t("meta mínima")}
                  markerPos={targetPercent.toFixed(0)}
                  markerFontSize={"14px"}
                />
              </div>

              <div className="my-projects-project-status">{`Status: ${dict.t(status)}`}</div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  composeAlternateProjectCard(project) {
    if (!project) return null;

    project[language] = languageTools.merge(project.PT, project[language]);

    const role = Authentication.getUser().personal.role;

    const url = Authentication.isAuthenticated()
      ? `/${userRoutes(role)}projetos/apoio/${project._id}`
      : "";

    const supportedPercent = 100 * (project.supportedValue / project.totalValue);
    const targetPercent = 100 * (project.minimumValue / project.totalValue);

    let status;

    if (project.status === "pending" && project.alert) {
      status = "Alerta gerado";
    } else {
      status = projectStatus.find(item => item.name === project.status).label;
    }

    const hasImage = project.images[0];
    const image = hasImage ? project.images[0] : "https://vbio.blob.core.windows.net/public/assets/img/image-placeholder.png";

    const toolbar = role === "proponentUser" && project.status === "notSubmited";

    const shortNameStyle = toolbar ? { width: "calc(100% - 170px)", float: "left" } : {};

    return (
      <div className="row">
        <div className="col-sm-12 vitrine-card-container">
          <Link to={url} style={{ textDecoration: "none" }}>
            <div className="card vitrine-project-container">
              <div className="card">
                <div className="vitrine-project-image-container">
                  <img className="vitrine-project-image" src={image} alt={project[language].name} />
                  {hasImage ? (
                    <ImageFilter
                      className="vitrine-project-image"
                      filter={projectImageFilter}
                      disableOnHover={true}
                    />
                  ) : null}
                </div>

                {this.getLegalFitnessIcons(project)}

                <div className="container vitrine-project-badges-container">
                  {this.getProjectBadges(project, true)}
                </div>

                <div style={{ overflow: "auto" }}>
                  <div className="vitrine-project-shortname-container" style={shortNameStyle}>
                    <div className="vitrine-project-shortname" style={{ marginBottom: "21px" }}>
                      {project[language].shortName}
                    </div>
                  </div>

                  {toolbar ? (
                    <div
                      className="my-projects-project-toolbar"
                      style={{ marginTop: "50px", marginRight: "30px" }}
                    >
                      <img
                        className="my-projects-project-toolbar-item"
                        src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon.png"
                        alt={dict.t("Editar projeto")}
                        title={dict.t("Editar projeto")}
                        onClick={e => this.handleEditClick(e, project._id)}
                      />

                      <img
                        className="my-projects-project-toolbar-item"
                        src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon.png"
                        alt={dict.t("Deletar projeto")}
                        title={dict.t("Deletar projeto")}
                        onClick={e => this.handleDeleteClick(e, project._id)}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="vitrine-project-name-container">
                  <div className="vitrine-project-name">{project[language].name}</div>
                </div>
              </div>

              <div style={{ margin: "55px 30px 20px 30px" }}>
                <div className="my-projects-project-bar-container">
                  <ProgressBar
                    height="15px"
                    markerHeight="31px"
                    value={supportedPercent}
                    markerLabel={dict.t("meta mínima")}
                    markerPos={targetPercent.toFixed(0)}
                    markerFontSize={"14px"}
                  />
                </div>

                <div className="my-projects-project-status">{`Status: ${status}`}</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  getLegalFitnessIcons(project) {
    let legalBadges = [];

    project.legalFitness.forEach(legalFitness => {
      legalBadges.push({
        icon: `/assets/img/badges/legal-fitness/${legalFitness}.png`,
        label: this.getLegalFitnessLabel(legalFitness),
      });
    });

    return (
      <div className="my-projects-project-icon">
        <BadgeStack items={legalBadges} badgeWidth={50} />
      </div>
    );
  }

  getProjectBadges(project, alternate, group = true) {
    let areaBadges = [];
    let odsBadges = [];

    this.sortODSs(project.ODS).forEach(ODS => {
      odsBadges.push({
        icon: `/assets/img/badges/ods/${language}/${ODS}.png`,
        label: this.getODSLabel(ODS),
      });
    });

    project.areasOfInterest.forEach(area => {
      areaBadges.push({ icon: area.image, label: area[language].name });
    });

    const style = alternate
      ? { margin: "0 20px", display: "inline-block", verticalAlign: "middle" }
      : {};

    return (
      <Fragment>
        <BadgeStack items={areaBadges} badgeWidth={45} style={style} group={group} />
        <BadgeStack items={odsBadges} badgeWidth={45} style={style} group={group} />
      </Fragment>
    );
  }

  getLegalFitnessLabel(legalFitness) {
    return dict.t(legalFitnessArray.find(item => item.name === legalFitness).label);
  }

  getODSLabel(ods) {
    return dict.t(odsArray.find(item => item.name === ods).label);
  }

  sortODSs(items) {
    return items.sort((a, b) => {
      a = a.match(/ods-[\d]*/g)[0];
      a = a.replace(/[^\d]/g, "");

      b = b.match(/ods-[\d]*/g)[0];
      b = b.replace(/[^\d]/g, "");

      return b - a;
    });
  }

  shortDateToUTC(date) {
    if (!date) return null;

    const dateArray = date.split("/");

    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
  }

  fetchSupports(supporterId) {
    const url = `/supporter/supports/${supporterId}`;

    Request.get(url, values => {
      values = values.filter(support => support.project);

      let projects = values.map(support => support.project);

      // remove projetos duplicados
      projects = projects.filter((item, index, array) => {
        if (!item) return false;
        return array.map(mapItem => (mapItem ? mapItem._id : null)).indexOf(item._id) === index;
      });

      projects = this.checkProjectStatus(projects);

      this.setState({ projects, supports: values });
    });
  }

  fetchProponentProjects(proponentId) {
    const url = `/proponent/projects/proposed/${proponentId}`;

    Request.get(url, values => {
      const projects = this.checkProjectStatus(values);
      this.setState({ projects });
    });
  }

  checkProjectStatus(projects) {
    return projects.map(project => {
      const endDate = this.shortDateToUTC(project.endDate);
      let status = project.status;

      if (Date.now() > endDate && status !== "notSubmited") status = "finalized";
      return { ...project, status };
    });
  }

  filterProjects(projects) {
    const { status, startDate, endDate, search } = this.state.searchFilter;

    const statusFilter = status;
    const startDateFilter = startDate && startDate.length >= 10 ? startDate : null;
    const endDateFilter = endDate && endDate.length >= 10 ? endDate : null;
    const searchFilter = search ? search.toLowerCase() : null;

    const role = Authentication.getUser().personal.role;

    return projects.filter(project => {
      if (role === "supporterUser") {
        const projectSupports = this.state.supports.filter(
          support => support.project._id === project._id
        );

        let supports = projectSupports.filter(support => support.status === statusFilter);
        if (statusFilter && statusFilter !== "all" && supports.length === 0) return false;

        if (startDateFilter) {
          supports = projectSupports.filter(
            support => Date.parse(support.createdAt) >= this.shortDateToUTC(startDateFilter)
          );
          if (supports.length === 0) return false;
        }

        if (endDateFilter) {
          supports = projectSupports.filter(
            support => Date.parse(support.createdAt) <= this.shortDateToUTC(endDateFilter)
          );
          if (supports.length === 0) return false;
        }
      } else if (role === "proponentUser") {
        if (statusFilter && statusFilter !== "all" && project.status !== statusFilter) return false;

        if ((startDateFilter || endDateFilter) && !project.endDate) return false;

        if (
          startDateFilter &&
          this.shortDateToUTC(project.endDate) < this.shortDateToUTC(startDateFilter)
        )
          return false;

        if (
          endDateFilter &&
          this.shortDateToUTC(project.endDate) > this.shortDateToUTC(endDateFilter)
        )
          return false;
      }

      if (
        searchFilter &&
        ((project[language].name || "").toLowerCase().search(searchFilter) === -1 &&
          (project[language].shortName || "").toLowerCase().search(searchFilter) === -1 &&
          (project[language].summary || "").toLowerCase().search(searchFilter) === -1 &&
          (project.city || "").toLowerCase().search(searchFilter) === -1 &&
          (project.state || "").toLowerCase().search(searchFilter) === -1)
      )
        return false;

      return true;
    });
  }

  handleScreenResize = screen => {
    const { width } = screen;

    this.setState({ smallScreen: width <= smallScreenWidth });
  };

  handleSearch = values => {
    this.setState({ searchFilter: values });
  };

  handleEditClick = (e, id) => {
    e.preventDefault();
    this.props.router.push(`/proponente/projetos/editar/${id}`);
  };

  handleDeleteClick = (e, id) => {
    e.preventDefault();

    const message = `${dict.t("Tem certeza que deseja")} ${dict.t("deletar")} ${dict.t(
      "este projeto"
    )}?`;

    Alert.confirm(message, yes => {
      if (yes) {
        const url = `/proponent/projects/${id}`;

        const localId = localStorage.getItem("newProjectId");

        if (id === localId) localStorage.removeItem("newProjectId");

        Request.delete(url, {}, res => {
          if (res.success) {
            Alert.success(
              `${dict.t("Projeto")} ${dict.t("deletado")} ${dict.t("com sucesso")}`,
              () => {
                const { companyId } = Authentication.getUser().personal;
                this.setState({ projects: null });
                this.fetchProponentProjects(companyId);
              }
            );
          }
        });
      }
    });
  };
}


