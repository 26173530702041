
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro usuários apoiadores
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import Submit from "../../../../lib/Forms/Submit";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";
import Forward from "../../../Commons/Forward";

import ScreenSize from "../../../Commons/ScreenSize";

import Authentication from "../../../../lib/Authentication";

import backendUserRoutes from "../../../../constants/backendUserRoutes";

import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());

const smallScreenWidth = 768;



class UserForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ready: false, smallScreen: false };
  }

  componentWillMount() {
    if (this.props.params.id) {
      // edit mode
      this.fecthRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", ready: true });
    }
  }

  render() {
    if (!this.state.ready) return <Loader />;

    let forgotStyle = { marginBottom: "30px" };
    let backStyle;
    let marginBottom = "50px";

    if (this.state.smallScreen) {
      //forgotStyle = { marginBottom: '15px' };
      backStyle = { bottom: "-45px" };
      marginBottom = "80px";
    }

    return (
      <div className="container form-container page-margin-top footer-margin-logged">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="page-title">
          {this.state.mode === "create" ? dict.t("Novo Usuário") : dict.t("Editar Usuário")}
        </div>

        <div className="col-md-12 form-body" style={{ marginBottom }}>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="name"
                  label={dict.t("Nome")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="email"
                  label={dict.t("E-mail")}
                  validate={[VF.Required, VF.Email]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            {this.state.mode === "create" ? (
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="password"
                    label={dict.t("Senha")}
                    type="password"
                    validate={[VF.Required]}
                    cssClass="form-input"
                    cssClassError="form-input-error"
                    component={Input}
                  />
                </div>

                <div className="col-md-6">
                  <Field
                    name="companyRole"
                    label={dict.t("Cargo")}
                    validate={[VF.Required]}
                    cssClass="form-input"
                    cssClassError="form-input-error"
                    component={Input}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="companyRole"
                      label={dict.t("Cargo")}
                      validate={[VF.Required]}
                      cssClass="form-input"
                      cssClassError="form-input-error"
                      component={Input}
                    />
                  </div>
                </div>
                <Forward
                  style={forgotStyle}
                  forwardLabel="Alterar Senha"
                  forwardRoute={`/apoiador/usuario/editar/altera-senha/${this.props.params.id}`}
                />
              </div>
            )}

            <div>
              <Submit router={this.props.router} backStyle={backStyle} />
            </div>
          </form>
        </div>
      </div>
    );
  }

  fecthRecord(id) {
    const role = Authentication.getUser().personal.role;
    const url = `/${backendUserRoutes(role)}users/${id}`;

    Request.get(url, values => {
      this.setState({ record: values, ready: true });
      this.props.initialize(this.state.record);
    });
  }

  createRecord(values) {
    const role = Authentication.getUser().personal.role;
    let url;

    if (role === "master") url = `/${backendUserRoutes(role)}users/supporter-user`;

    if (role === "supporterUser") url = `/${backendUserRoutes(role)}users`;

    values.role = "supporterUser";
    values.supporter = this.props.router.params.supporterId;

    Request.post(url, values, res => {
      if (res.success) {
        Alert.success(dict.t("Cadastro efetuado com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  updateRecord(values) {
    const role = Authentication.getUser().personal.role;
    let url;

    if (role === "master") url = "/master/users/supporter/${this.state.record._id}";
    else url = "/${backendUserRoutes(role)}/users/${this.state.record._id}";

    values.supporter = values.supporter._id;

    Request.post(url, values, res => {
      if (res.success) {
        Alert.success(dict.t("Edição efetuada com sucesso"), () => {
          this.props.router.goBack();
        });
      }
    });
  }

  handleSubmit = values => {
    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "supporterUserForm",
})(UserForm);


