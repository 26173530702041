/**
 * Authentication logic lies in here
 */

export default {
  getUser() {
    if (!this.isAuthenticated()) return {};

    return {
      jwt: localStorage.userJWT,
      personal: {
        id: localStorage.userId,
        name: localStorage.userName,
        email: localStorage.userEmail,
        role: localStorage.userRole,
        companyId: localStorage.userCompanyId,
        companyStatus: localStorage.userCompanyStatus,

        lastCheckProjects: localStorage.userLastCheckProjects,
        lastCheckSupports: localStorage.userLastCheckSupports,
        lastCheckProponents: localStorage.userLastCheckProponents,

        access: localStorage.userAccess ? JSON.parse(localStorage.userAccess) : [],
      },
    };
  },

  /**
   * Retrieve JWT from local storage
   * @return string JWT
   */
  getToken() {
    return localStorage.userJWT;
  },

  /**
   * Set new JWT token into local storage
   * @return void
   */
  setToken(newToken) {
    localStorage.userJWT = newToken;
  },

  /**
   * Retrieve user name info from local storage
   * @return string
   */
  getUserName() {
    return localStorage.userName;
  },

  /**
   * Clean authentication data inside Local Storage
   * @return void
   */
  logout() {
    localStorage.userId = "";
    localStorage.userJWT = "";
    localStorage.userName = "";
    localStorage.userEmail = "";
    localStorage.userRole = "";
    localStorage.userCompanyId = "";
    localStorage.userCompanyStatus = "";

    localStorage.userLastCheckProjects = "";
    localStorage.userLastCheckSupports = "";
    localStorage.userLastCheckProponents = "";

    localStorage.userAccess = "";
    //localStorage.userProfile = "";

    localStorage.newProjectId = "";
  },

  /**
   * Returns true in case the current session has not expired yet
   * @return boolean
   */
  isAuthenticated() {
    return localStorage.userJWT !== "" && localStorage.userJWT !== undefined;
  },

  /**
   * Store session data inside Local Storage
   * @param jwt string
   * @param user object
   * @return void
   */
  login(jwt, user) {
    localStorage.userJWT = jwt;

    localStorage.userId = user.id;
    localStorage.userName = user.name;
    localStorage.userEmail = user.email;
    localStorage.userRole = user.role;
    localStorage.userCompanyId = user.companyId;
    localStorage.userCompanyStatus = user.companyStatus;

    localStorage.userLastCheckProjects = user.lastCheckProjects;
    localStorage.userLastCheckSupports = user.lastCheckSupports;
    localStorage.userLastCheckProponents = user.lastCheckProponents;

    localStorage.userAccess =
      user.profile && user.profile.access ? JSON.stringify(user.profile.access) : [];
  },
};
