export default [
  {
    name: "ods-1",
    label: "Erradicação a pobreza",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-1.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-1.png" },
    color: "#E5243B",
  },

  {
    name: "ods-2",
    label: "Fome zero e agricultura sustentável",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-2.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-2.png" },
    color: "#DDA53A",
  },

  {
    name: "ods-3",
    label: "Saúde e bem-estar",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-3.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-3.png" },
    color: "#4C9F38",
  },

  {
    name: "ods-4",
    label: "Educação de qualidade",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-4.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-4.png" },
    color: "#C6192D",
  },

  {
    name: "ods-5",
    label: "Igualdade de gênero",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-5.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-5.png" },
    color: "#FF3921",
  },

  {
    name: "ods-6",
    label: "Água limpa e saneamento",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-6.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-6.png" },
    color: "#22BDE2",
  },

  {
    name: "ods-7",
    label: "Energia acessível e limpa",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-7.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-7.png" },
    color: "#FCC30A",
  },

  {
    name: "ods-8",
    label: "Emprego digno e crescimento econômico",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-8.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-8.png" },
    color: "#A11942",
  },

  {
    name: "ods-9",
    label: "Indústria, inovação e infraestrutura",
    icon: { PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-9.png", EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-9.png" },
    color: "#FD6924",
  },

  {
    name: "ods-10",
    label: "Redução das desigualdades",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-10.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-10.png",
    },
    color: "#DD1367",
  },

  {
    name: "ods-11",
    label: "Cidades e comunidades sustentáveis",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-11.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-11.png",
    },
    color: "#F99B22",
  },

  {
    name: "ods-12",
    label: "Consumo e produção responsáveis",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-12.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-12.png",
    },
    color: "#BF8B2E",
  },

  {
    name: "ods-13",
    label: "Combate às alterações climáticas",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-13.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-13.png",
    },
    color: "#3E7E44",
  },

  {
    name: "ods-14",
    label: "Vida debaixo d’agua",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-14.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-14.png",
    },
    color: "#0397D9",
  },

  {
    name: "ods-15",
    label: "Vida sobre a terra",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-15.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-15.png",
    },
    color: "#55C02B",
  },

  {
    name: "ods-16",
    label: "Paz, justiça e instituições fortes",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-16.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-16.png",
    },
    color: "#02689D",
  },

  {
    name: "ods-17",
    label: "Parcerias em prol das metas",
    icon: {
      PT: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/PT/ods-17.png",
      EN: "https://vbio.blob.core.windows.net/public/assets/img/badges/ods/EN/ods-17.png",
    },
    color: "#1A476A",
  },
];
