
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Fortmatador de moeda
//


const format = value => {
  if (!value) return value;

  value = value.toFixed(2);

  let onlyNums = value.replace(/[^\d]/g, "");
  onlyNums = onlyNums.replace(/^0+/, "");

  const length = onlyNums.length;

  if (length === 0) return ""; //'R$0,00';

  if (length <= 1) return "R$0,0" + onlyNums;

  if (length <= 2) return "R$0," + onlyNums;

  if (length <= 5) return "R$" + onlyNums.slice(0, length - 2) + "," + onlyNums.slice(length - 2);

  if (length <= 8)
    return (
      "R$" +
      onlyNums.slice(0, length - 5) +
      "." +
      onlyNums.slice(length - 5, length - 2) +
      "," +
      onlyNums.slice(length - 2)
    );

  if (length <= 11)
    return (
      "R$" +
      onlyNums.slice(0, length - 8) +
      "." +
      onlyNums.slice(length - 8, length - 5) +
      "." +
      onlyNums.slice(length - 5, length - 2) +
      "," +
      onlyNums.slice(length - 2)
    );

  if (length <= 14)
    return (
      "R$" +
      onlyNums.slice(0, length - 11) +
      "." +
      onlyNums.slice(length - 11, length - 8) +
      "." +
      onlyNums.slice(length - 8, length - 5) +
      "." +
      onlyNums.slice(length - 5, length - 2) +
      "," +
      onlyNums.slice(length - 2)
    );

  return (
    "R$" +
    onlyNums.slice(0, length - 11 - 1) +
    "." +
    onlyNums.slice(length - 11 - 1, length - 8 - 1) +
    "." +
    onlyNums.slice(length - 8 - 1, length - 5 - 1) +
    "." +
    onlyNums.slice(length - 5 - 1, length - 2 - 1) +
    "," +
    onlyNums.slice(length - 2 - 1, length - 1)
  );
};



export default format;


