
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Regis Ebeling
//
// Descrição: Corpo do item de lista de projetos
//


import React, { Fragment } from "react";
import moment from "moment";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();



export default function(props) {
  props.item[language] = languageTools.merge(props.item.PT, props.item[language]);

  let proponentName = props.item.proponent
    ? props.item.proponent.trade || props.item.proponent.company || ""
    : "";

  return (
    <Fragment>
      <div className="supporter-user-list-item-container row">
        <div className="col-md-2">{props.item[language].shortName}</div>
        <div className="col-md-4">{proponentName}</div>
        <div className="col-md-3">{moment(props.item.createdAt).format("DD/MM/YYYY hh:mm:ss")}</div>
        <div className="col-md-3">{moment(props.item.updatedAt).format("DD/MM/YYYY hh:mm:ss")}</div>
      </div>
    </Fragment>
  );
}


