
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de cadastro de categoria
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../../constants/ValidationFields";
import Input from "../../../../lib/Forms/Input";
import Submit from "../../../..//lib/Forms/Submit";

import masterAccess from "../../../../lib/masterAccess";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import config from "../../../../config";

import multiLang from "../../../../lib/multilang/multiLang";



const dict = new multiLang(config.language);
const language = multiLang.getLanguage();


class Form extends Component {
  constructor(props) {
    super(props);

    this.state = { ready: false };
  }

  componentWillMount() {
    if (this.props.params.id) {
      // edit mode
      this.fecthRecord(this.props.params.id);
      this.setState({ mode: "edit" });
    } // create mode
    else {
      this.setState({ mode: "create", ready: true });
    }
  }

  render() {
    if (!this.state.ready) return <Loader />;

    const canEdit = masterAccess("blogPost") >= 0;

    return (
      <div className="container form-container footer-margin-logged">
        <div className="page-title">
          {this.state.mode === "create" ? dict.t("Nova Categoria") : dict.t("Editar Categoria")}
        </div>

        <div className="col-md-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div className="row" style={{ marginBottom: "150px" }}>
              <div className="col-md-12">
                <Field
                  name={`${language}.name`}
                  label={dict.t("Nome")}
                  validate={[VF.Required]}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>

            <Submit backRoute="/master/blog/categorias" backOnly={!canEdit} />
          </form>
        </div>
      </div>
    );
  }

  fecthRecord(id) {
    const url = `/master/blog/categories/${id}`;

    Request.get(url, values => {
      this.setState({ record: values, ready: true });
      this.props.initialize(this.state.record);
    });
  }

  createRecord(values) {
    const url = "/master/blog/categories";

    Request.post(url, values, msg => {
      Alert.success(dict.t("Cadastro efetuado com sucesso"), () => {
        this.props.router.push("/master/blog/categorias");
      });
    });
  }

  updateRecord(values) {
    const url = `/master/blog/categories/${this.state.record._id}`;

    Request.post(url, values, msg => {
      Alert.success(dict.t("Edição efetuada com sucesso"), () => {
        this.props.router.push("/master/blog/categorias");
      });
    });
  }

  handleSubmit = values => {
    if (this.state.mode === "create") {
      this.createRecord(values);
    } else if (this.state.mode === "edit") {
      this.updateRecord(values);
    }
  };
}



export default reduxForm({
  form: "categoryForm",
})(Form);


