
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cadastro de projeto - detalhes
//


import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import { normalizerCurrency, normalizerDate, normalizerNumber } from "../../../lib/Normalizer";

import { formatCurrency, formatOnlyNumbers } from "../../../lib/FormatString";

import Authentication from "../../../lib/Authentication";

import Loader from "../../Loader";
import Request from "../../../lib/BackendRequest";

import TextArea from "../../../lib/Forms/TextArea";
import TextFormatted from "../../../lib/Forms/TextFormatted";
import Input from "../../../lib/Forms/Input";
import ImageInput from "../../../lib/Forms/ImageInput";
import FileInputWide from "../../../lib/Forms/FileInputWide";
import Dropdown from "../../../lib/Forms/Dropdown";

import InfoIcon from "../../InfoIcon";

import languageTools from "../../../lib/languageTools";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



const inOperationValues = [
  { label: dict.t("Sim"), value: true },
  { label: dict.t("Não"), value: false },
];



class DetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentTemplates: [null, null],
      expectedIndicators: [""],
      images: ["empty"],
      totalValue: this.props.defaultValues.totalValue,
      numOfShares: this.props.defaultValues.numOfShares,
    };
  }

  componentWillMount() {
    this.initialize(this.props);
  }

  initialize(props) {
    this.setState({
      expectedIndicators: props.defaultValues.expectedIndicators || [""],
      images: props.defaultValues.images || [undefined],
      document: props.defaultValues.document,
      document2: props.defaultValues.document2,
    });

    this.props.initialize(props.defaultValues);

    this.fetchDocumentTemplate();
  }

  render() {
    const { documentTemplates } = this.state;

    let shareValue;

    if (this.state.totalValue && this.state.numOfShares) {
      shareValue = formatOnlyNumbers(this.state.totalValue) / 100 / this.state.numOfShares;
    }
    return (
      <Fragment>
        <div className="new-project-text">{dict.t("new-project-details-description")}</div>

        <div className="form-body">
          <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <Field
                  name="summary"
                  label={dict.t("Resumo do Projeto")}
                  rows={3}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  info={dict.t("Resuma o propósito do seu projeto em uma curta frase.")}
                  component={TextArea}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Field
                  name="description"
                  label={dict.t("Descrição Sumária")}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={TextFormatted}
                  info={dict.t(
                    "Com texto, fotos e vídeos, conte sobre seu projeto e por que ele deve ser apoiado."
                  )}
                  onChange={this.handleDescriptionChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-input-label">
                  {dict.t("Indicadores de Desempenho")}

                  <InfoIcon
                    info={dict.t("Pontue quais indicadores serão monitorados em seu projeto.")}
                  />
                </div>
              </div>
            </div>

            {this.getExpectedIndicatorsFields()}

            <div className="row">
              <div className="col-sm-4 col-sm-offset-8">
                <button
                  className="button new-project-base-button new-project-add-indicator-button"
                  style={{ float: "right", marginBottom: "30px" }}
                  onClick={this.addNewIndicator}
                >
                  <img
                    className="button-icon new-project-add-indicator-icon"
                    src="https://vbio.blob.core.windows.net/public/assets/img/plus-icon.png"
                    alt=""
                  />
                  {dict.t("Adicionar indicador esperado")}
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Field
                  name="document"
                  label={`${dict.t("Formulário do Projeto")}`}
                  url={this.state.document}
                  template={documentTemplates[0]}
                  component={FileInputWide}
                  removeS3Hash={true}
                  onChange={this.handleDocumentChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Field
                  name="document2"
                  label={`${dict.t("Orçamento e Cronograma")}`}
                  url={this.state.document2}
                  template={documentTemplates[1]}
                  component={FileInputWide}
                  removeS3Hash={true}
                  onChange={this.handleDocumentChange2}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-input-label">
                  {`${dict.t("Fotos da Capa")} - 1140x560px`}

                  <InfoIcon
                    info={dict.t(
                      "Adicione as fotos que ficarão em destaque na capa de seu projeto."
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {this.getImageFields()}

              <div className="col-md-1">
                <img
                  className="new-project-image-add-button"
                  src="https://vbio.blob.core.windows.net/public/assets/img/add-icon.png"
                  alt={dict.t("Adicionar imagem")}
                  title={dict.t("Adicionar imagem")}
                  onClick={this.addNewImage}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Field
                  name="totalValue"
                  label={`${dict.t("Valor Total do Projeto")} (BRL)`}
                  normalize={normalizerCurrency}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                  onChange={this.handleTotalValueChange}
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="numOfShares"
                  label={dict.t("Número de Cotas")}
                  normalize={normalizerNumber}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                  info={dict.t(
                    "Indique em quantas cotas o valor total de seu projeto será dividido."
                  )}
                  onChange={this.handleNumOfSharesChange}
                />
              </div>

              <div className="col-md-offset-1 col-md-2">
                <div className="form-input-label">{dict.t("Valor das Cotas")}</div>

                <div className="new-project-share-label">
                  {shareValue ? formatCurrency(shareValue) : "R$----"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Field
                  name="minimumValue"
                  label={dict.t("Valor Mínimo do Projeto")}
                  normalize={normalizerCurrency}
                  cssClass="form-input"
                  info={dict.t("Indique o valor mínimo para dar início ao seu projeto.")}
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="inOperation"
                  label={dict.t("Projeto em operação?")}
                  placeholder={dict.t("Selecione...")}
                  data={inOperationValues}
                  dataKey="value"
                  dataLabel="label"
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  info={dict.t("Indique se seu projeto já está em andamento.")}
                  component={Dropdown}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Field
                  name="startDate"
                  label={dict.t("Início da Captação")}
                  normalize={normalizerDate}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="endDate"
                  label={dict.t("Término da Captação")}
                  normalize={normalizerDate}
                  cssClass="form-input"
                  cssClassError="form-input-error"
                  component={Input}
                />
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }

  fetchDocumentTemplate() {
    const role = Authentication.getUser().personal.role;

    let url;

    if (role === "master") url = "/master/configs/documents/project-docs/template";
    else if (role === "proponentUser") url = "/proponent/documents/project-template";

    Request.get(url, values => {
      let documentTemplates;

      if (values && values.length > 0) {
        documentTemplates = values.map(doc => {
          doc[language] = languageTools.merge(doc.PT, doc[language]);
          return doc[language].url;
        });
      }

      this.setState({ documentTemplates });
    });
  }

  getExpectedIndicatorsFields() {
    return this.state.expectedIndicators.map((indicator, index) => {
      return (
        <Fragment key={index}>
          <div className="new-project-indicator-field-container">
            <Field
              name={`expectedIndicators[${index}]`}
              cssClass="form-input"
              cssClassError="form-input-error"
              containerStyle={{ marginBottom: "30px" }}
              component={Input}
            />
          </div>

          <img
            className="new-project-remove-indicator-button"
            src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon.png"
            alt={dict.t("Remover indicador")}
            title={dict.t("Remover indicador")}
            onClick={() => this.handleIndicatorRemove(index)}
          />
        </Fragment>
      );
    });
  }

  getImageFields() {
    let i = 0;

    return this.state.images.map((image, index) => {
      if (!image || image === "empty") this.props.change(`images[${i}]`, null);

      const element = (
        <Fragment key={index}>
          {!image ? null : (
            <div className="col-md-2">
              <Field
                name={`images[${i}]`}
                image={image}
                component={ImageInput}
                closeButton={true}
                onChange={this.handleImageChange}
                onClose={this.handleImageRemove}
                style={{ backgroundSize: "cover" }}
              />
            </div>
          )}
        </Fragment>
      );

      i++;

      return element;
    });
  }

  handleDocumentChange = value => {
    this.setState({ document: value[0] });
  };

  handleDocumentChange2 = value => {
    this.setState({ document2: value[0] });
  };

  addNewIndicator = () => {
    let expectedIndicators = this.state.expectedIndicators;
    expectedIndicators.push("");

    this.setState({ expectedIndicators });
  };

  addNewImage = () => {
    let images = this.state.images;
    images.push("empty");

    this.setState({ images, imageChange: true });
  };

  handleImageChange = image => {
    let images = this.state.images;

    const index = image.name.match(/([0-9]+)/g);
    images[index] = image.files[0];

    this.setState({ images });
  };

  handleImageRemove = image => {
    let { images } = this.state;

    const index = image.name.match(/([0-9]+)/g);
    images[index] = null;

    this.setState({ images });
  };

  handleIndicatorRemove = index => {
    let { expectedIndicators } = this.state;
    expectedIndicators.splice(index, 1);

    this.setState({ expectedIndicators });
  };

  handleTotalValueChange = e => {
    this.setState({ totalValue: e.target.value });
  };

  handleNumOfSharesChange = e => {
    this.setState({ numOfShares: e.target.value });
  };

  handleSubmit = values => {
    values.images = this.state.images;

    if (this.props.onSubmit) this.props.onSubmit(values);
  };
}



export default reduxForm({
  form: "newProjectDetailsForm",
})(DetailsForm);


