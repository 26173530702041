import React, { Component } from "react";

export default class Dropdown extends Component {
  render() {
    return (
      <div className={`form-group ${this.props.componentClass}`}>
        <div className={`form-input-label ${this.props.labelClass}`}>{this.props.label}</div>

        <select
          className={`form-control form-input ${this.props.selectClass}`}
          style={this.props.style}
          onChange={this.props.onChange}
          defaultValue={"placeholder"}
          {...this.props.input}
        >
          {this.options}
        </select>
      </div>
    );
  }

  get options() {
    const { options } = this.props;

    if (!options) return [];
    if (!options.length) return [];

    return options.map((item, i) => {
      return (
        <option key={i} value={item.value}>
          {item.title}
        </option>
      );
    });
  }
}
