export default [
  { id: "AC", fullName: "Acre", shortName: "AC" },
  { id: "AL", fullName: "Alagoas", shortName: "AL" },
  { id: "AP", fullName: "Amapá", shortName: "AP" },
  { id: "AM", fullName: "Amazonas", shortName: "AM" },
  { id: "BA", fullName: "Bahia", shortName: "BA" },
  { id: "CE", fullName: "Ceará", shortName: "CE" },
  { id: "DF", fullName: "Distrito Federal", shortName: "DF" },
  { id: "ES", fullName: "Espírito Santo", shortName: "ES" },
  { id: "GO", fullName: "Goiás", shortName: "GO" },
  { id: "MA", fullName: "Maranhão", shortName: "MA" },
  { id: "MT", fullName: "Mato Grosso", shortName: "MT" },
  { id: "MS", fullName: "Mato Grosso do Sul", shortName: "MS" },
  { id: "MG", fullName: "Minas Gerais", shortName: "MG" },
  { id: "PA", fullName: "Pará", shortName: "PA" },
  { id: "PB", fullName: "Paraíba", shortName: "PB" },
  { id: "PR", fullName: "Paraná", shortName: "PR" },
  { id: "PE", fullName: "Pernambuco", shortName: "PE" },
  { id: "PI", fullName: "Piauí", shortName: "PI" },
  { id: "RJ", fullName: "Rio de Janeiro", shortName: "RJ" },
  { id: "RN", fullName: "Rio Grande do Norte", shortName: "RN" },
  { id: "RS", fullName: "Rio Grande do Sul", shortName: "RS" },
  { id: "RO", fullName: "Rondônia", shortName: "RO" },
  { id: "RR", fullName: "Roraima", shortName: "RR" },
  { id: "SC", fullName: "Santa Catarina", shortName: "SC" },
  { id: "SP", fullName: "São Paulo", shortName: "SP" },
  { id: "SE", fullName: "Sergipe", shortName: "SE" },
  { id: "TO", fullName: "Tocantins", shortName: "TO" },
];
