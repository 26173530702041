
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de login
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../constants/ValidationFields";
import Input from "../../lib/Forms/Input";
import Submit from "./Submit";

import Request from "../../lib/BackendRequest";
import Alert from "../../lib/Alert";

import Authentication from "../../lib/Authentication";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const dict = new multiLang(multiLang.getLanguage());



class Form extends Component {
  render() {
    return (
      <div className="form-container login-form-container">
        <div className="col-md-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <Field
              name="email"
              label={dict.t("E-mail")}
              validate={[VF.Required, VF.Email]}
              cssClass="form-input"
              cssClassError="form-input-error"
              component={Input}
            />

            <Field
              name="password"
              type="password"
              label={dict.t("Senha")}
              validate={[VF.Required]}
              cssClass="form-input"
              cssClassError="form-input-error"
              component={Input}
            />

            <Submit forgotPasswordRoute="/login/esqueci-minha-senha" />
          </form>
        </div>
      </div>
    );
  }

  handleSubmit = values => {
    Request.post("/user/login", values, json => {
      if (json.success) {
        Authentication.login(json.token, json.user);

        if (this.props.router.location.state && this.props.router.location.state.nextPathname)
          this.props.router.push(this.props.router.location.state.nextPathname);
        else this.props.router.push("/");
      } else {
        Alert.error(
          dict.t("O usuário ou a senha estão incorretos. Por gentileza, tente novamente.")
        );
      }
    });
  };
}



export default reduxForm({
  form: "loginForm",
})(Form);


