
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Somente a Barra de Voltar padrão para forms.
//


import React, { Component } from "react";
import { Link } from "react-router";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const dict = new multiLang(multiLang.getLanguage());



export default class List extends Component {
  static defaultProps = {
    language: "PT",
    sending: false,
  };

  render() {
    const backLabel = dict.t("Voltar");

    return (
      <div className="row back-button-container">
        <Link to={this.props.backRoute}>
          <div className="form-back-button" onClick={this.handleBackClick}>
            {`< ${backLabel}`}
          </div>
        </Link>
      </div>
    );
  }

  handleBackClick = () => {
    if (!this.props.backRoute && this.props.router) this.props.router.goBack();
  };
}


