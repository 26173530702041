
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling, Diego Pinto
//
// Descrição: Componente de formulário para entrada de arquivo
//


import React, { Component } from "react";

import multiLang from "../multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class FileInput extends Component {
  constructor(props) {
    super(props);

    this.files = null;
  }

  render() {
    const {
      input: { value: omitValue, ...inputProps },
      meta: omitMeta,
      ...props
    } = this.props;

    return (
      <div className="form-group">
        <div className="form-input-label">{props.label}</div>
        <div className="form-input-file">
          {props.attach ? (
            <img
              src="https://vbio.blob.core.windows.net/public/assets/img/attached-icon.png"
              className="form-inputted-file-img"
              alt={dict.t("Enviar arquivo")}
              title={dict.t("Enviar arquivo")}
            />
          ) : (
            ""
          )}

          <input
            {...inputProps}
            {...props}
            type="file"
            onChange={this.handleChange}
            title={dict.t("Enviar arquivo")}
          />
        </div>
      </div>
    );
  }

  handleChange = e => {
    const {
      input: { value: omitValue, ...inputProps },
    } = this.props;

    this.files = e.target.files;

    if (inputProps.onChange) inputProps.onChange(this.files);
  };
}


