
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Cadastro de projeto - Gratificações
//


import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import VF from "../../../constants/ValidationFields";
import { normalizerNumber } from "../../../lib/Normalizer";

import Input from "../../../lib/Forms/Input";
import TextArea from "../../../lib/Forms/TextArea";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const dict = new multiLang(multiLang.getLanguage());

const dummyGratification = {
  numOfSharesStart: undefined,
  numOfSharesEnd: undefined,
  description: "",
};



class GratificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gratifications: [dummyGratification],
    };
  }

  componentWillMount() {
    this.setState({
      gratifications: this.props.defaultValues.gratifications || [dummyGratification],
    });

    this.props.initialize(this.props.defaultValues);
  }

  render() {
    return (
      <Fragment>
        <div className="new-project-text">{dict.t("new-project-gratification-description")}</div>

        <div className="form-body">
          <form>
            {this.getGratifications()}

            <div className="row">
              <div className="col-sm-4 col-sm-offset-8">
                <div
                  className="button new-project-base-button new-project-add-gratification-button"
                  style={{ float: "right", marginBottom: "30px", padding: "12px" }}
                  onClick={this.addNewGratification}
                >
                  <img
                    className="button-icon new-project-add-gratification-icon"
                    src="https://vbio.blob.core.windows.net/public/assets/img/plus-icon.png"
                    alt=""
                  />
                  {dict.t("Adicionar gratificação")}
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }

  getGratifications() {
    return this.state.gratifications.map((gratification, index) => {
      return (
        <Fragment key={index}>
          <div className="new-project-section-title">
            <div>
              {`${dict.t("Gratificação")} ${index + 1}`}
              <img
                className="new-project-remove-gratification-button"
                src="https://vbio.blob.core.windows.net/public/assets/img/delete-icon.png"
                alt={dict.t("Remover gratificação")}
                title={dict.t("Remover gratificação")}
                onClick={() => this.handleGratificationRemove(index)}
              />
            </div>

            <p style={{ fontSize: "15px" }}>
              {dict.t("Indique a gratificação e o número de cotas para alcançá-la.")}
            </p>
          </div>

          <div className="form-input-label">{dict.t("Número de cotas")}</div>

          <div className="row">
            <div className="col-xs-2 col-sm-1">
              <Field
                name={`gratifications[${index}].numOfSharesStart`}
                validate={[VF.Number]}
                normalize={normalizerNumber}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
                containerStyle={{ marginRight: "-30px" }}
              />
            </div>

            <div
              className="col-xs-2 col-sm-1 form-input-label"
              style={{ margin: "15px 0", textAlign: "center" }}
            >
              {dict.t("à")}
            </div>

            <div className="col-xs-2 col-sm-1">
              <Field
                name={`gratifications[${index}].numOfSharesEnd`}
                validate={[VF.Number]}
                normalize={normalizerNumber}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={Input}
                containerStyle={{ marginLeft: "-30px" }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Field
                name={`gratifications[${index}].description`}
                label={dict.t("Gratificação") + " PT-BR"}
                rows={4}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={TextArea}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Field
                name={`gratifications[${index}].description_EN`}
                label={dict.t("Gratificação") + " EN"}
                rows={4}
                cssClass="form-input"
                cssClassError="form-input-error"
                component={TextArea}
              />
            </div>
          </div>
        </Fragment>
      );
    });
  }

  addNewGratification = () => {
    let gratifications = this.state.gratifications;
    gratifications.push(dummyGratification);

    this.setState({ gratifications });
  };

  handleGratificationRemove = index => {
    let { gratifications } = this.state;
    gratifications.splice(index, 1);

    this.setState({ gratifications });
  };
}



export default reduxForm({
  form: "newProjectGratificationForm",
})(GratificationForm);


