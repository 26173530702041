
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Barra de submit padrão para forms.
//


import React, { Component } from "react";
import { Link } from "react-router";

import multiLang from "../../lib/multilang/multiLang";



const dict = new multiLang(multiLang.getLanguage());



export default class List extends Component {
  static defaultProps = {
    language: "PT",
    sending: false,
    saving: false,
  };

  render() {
    const label = this.props.buttonLabel ? this.props.buttonLabel : dict.t("Enviar");
    const labelSave = this.props.buttonLabelSave ? this.props.buttonLabelSave : dict.t("Salvar");
    const sendingLabel = this.props.buttonLabelSending
      ? this.props.buttonLabelSending
      : dict.t("Enviando...");
    const savingLabel = this.props.buttonLabelSaving
      ? this.props.buttonLabelSaving
      : dict.t("Salvando...");

    const backLabel = dict.t("Voltar");

    const buttonContainerStyle = Object.assign({ float: "right" }, this.props.buttonStyle);

    return (
      <div className="row form-submit" style={this.props.style}>
        {this.props.backButton !== "0" ? (
          <Link to={this.props.backRoute}>
            <div
              className="form-back-button"
              onClick={this.handleBackClick}
              style={this.props.backStyle}
            >
              {`< ${backLabel}`}
            </div>
          </Link>
        ) : (
          ""
        )}

        {!this.props.backOnly ? (
          <div style={buttonContainerStyle}>
            <button
              type="button"
              className="form-submit-button button new-project-save-button"
              onClick={this.props.onSave}
              style={{ float: "none", marginRight: "30px", marginBottom: "20px" }}
            >
              <img className="button-icon" src="https://vbio.blob.core.windows.net/public/assets/img/save-icon.png" alt={dict.t("Salvar")} />
              {this.props.sending ? savingLabel : labelSave}
            </button>

            <button
              type="button"
              className="form-submit-button button"
              onClick={this.props.onSubmit}
              style={{ float: "none" }}
            >
              {this.props.sending ? sendingLabel : label}
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  handleBackClick = () => {
    if (!this.props.backRoute && this.props.router) this.props.router.goBack();
  };
}


