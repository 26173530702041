import React, { Component } from "react";
import { Link } from "react-router";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import Authentication from "../../../../lib/Authentication";

import Input from "../../../../lib/Forms/Input";
import RadioButton from "../../../../lib/Forms/RadioButton";

import { formatCurrency, formatOnlyNumbers } from "../../../../lib/FormatString";
import multiLang from "../../../../lib/multilang/multiLang";
import { normalizerNumber, normalizerCurrency } from "../../../../lib/Normalizer";
import VF from "../../../../constants/ValidationFields";

import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import StandAloneCheckbox from "../../../Commons/Checkbox";

import Config from "../../../../config";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



class SupportInternForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      share: 0,
      termsOfUse: false,
    };
  }

  componentWillMount() {
    this.props.initialize({
      openValue: "R$ 0,00",
    });
  }

  render() {
    return (
      <div id="project-intern-support">
        <div className="container">
          <div className="row clear">
            <div className="col-md-12">
              <h2 style={{ fontSize: "24px", margin: 0 }} className="page-title">
                {dict.t("Apoiar")}
              </h2>
            </div>
          </div>

          <form onSubmit={this.props.handleSubmit(this.handleSubmit)} style={{ marginTop: "10px" }}>
            <div className="row">
              <div className="col-md-4">
                <div>{dict.t("Número de cotas")}</div>

                <Field
                  name="fixedShare"
                  placeholder={dict.t("Número de cotas")}
                  cssClass="form-input"
                  type="text"
                  normalize={normalizerNumber}
                  cssClassError="form-input-error"
                  containerStyle={{ marginBottom: "20px" }}
                  component={Input}
                  onChange={this.fixedShareChange}
                />
              </div>

              <div className="col-md-4">
                <div>
                  <span>{dict.t("Cota aberta")}</span>
                </div>

                <Field
                  name="openValue"
                  placeholder={dict.t("Insira o valor aqui")}
                  cssClass="form-input"
                  type="text"
                  normalize={normalizerCurrency}
                  cssClassError="form-input-error"
                  onBlur={this.openValueChange}
                  containerStyle={{ marginBottom: "20px" }}
                  component={Input}
                />
              </div>

              <div className="col-md-2">
                <div>{`${dict.t("Valor da transação")}*`}</div>
                <div className="project-inter-support-value">
                  {this.totalTransactionValueWithFee}
                </div>
              </div>
              <div className="col-md-2">
                <div>{dict.t("Número de cotas")}</div>
                <div className="project-inter-support-value">{this.totalShare}</div>
              </div>
            </div>

            <div className="project-intern-fee-text">
              {`* ${dict.t("Valor acrescido de 18% de taxa de administração")}`}
            </div>

            <div>
              <div className="project-intern-support-type-item">
                <Field
                  name="backup"
                  label={dict.t("Apoio público")}
                  labelStyle={{ fontWeight: "bold" }}
                  info={dict.t(
                    "O nome da empresa apoiadora será visível e será possível divulgar este apoio em qualquer meio de comunicação."
                  )}
                  id="public"
                  validate={[VF.Required]}
                  cssClass="form-input-checkbox"
                  cssClassError="form-input-error"
                  component={RadioButton}
                  onChange={this.handleBackupChange}
                />
              </div>

              <div className="project-intern-support-type-item">
                <Field
                  name="backup"
                  label={dict.t("Apoio anônimo")}
                  info={dict.t(
                    "O nome da empresa apoiadora não será visível e esta não poderá divulgar seu apoio em qualquer meio de comunicação."
                  )}
                  id="anonymous"
                  validate={[VF.Required]}
                  cssClass="form-input-checkbox"
                  cssClassError="form-input-error"
                  component={RadioButton}
                  onChange={this.handleBackupChange}
                />
              </div>
            </div>

            <div className="row margin-space">
              <div className="col-md-12">
                <StandAloneCheckbox onChange={this.handleTermsOfUseChange} />

                <span className="project-intern-terms-text" style={{ marginLeft: "16px" }}>
                  {`${dict.t("Aceito os")} `}
                </span>

                <Link to="/termo-de-uso/apoio">
                  <span className="text-link">{dict.t("Termos de uso")}</span>
                </Link>

                <button className="button project-intern-submit-button">{dict.t("Apoiar")}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  get minValue() {
    return formatCurrency(this.props.project.minimumValue);
  }

  fixedShareChange = ev => {
    this.setState({ share: ev.target.value });

    this.props.change("openValue", "0,00");
  };

  openValueChange = ev => {
    let value = parseFloat(
      ev.target.value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(/,/g, ".")
    );

    const shareValue = this.shareValue;
    const minValue = this.shareValue;

    const minValueFormatted = normalizerCurrency(minValue.toFixed(2).toString());

    if (!isNaN(value) && value !== 0) {
      if (value < minValue) {
        setTimeout(() => this.props.change("openValue", minValueFormatted, 100));
        value = minValue;
      }

      const share = value / shareValue;

      this.setState({ share: share.toFixed(2) });
    }

    this.props.change("fixedShare", 0);
  };

  get maxPossibleValue() {
    const { project } = this.props;

    return project.totalValue - project.supportedValue;
  }

  get totalTransactionValue() {
    const shareValue = this.shareValue;

    return formatCurrency(this.state.share * shareValue);
  }

  get totalTransactionValueWithFee() {
    const shareValue = this.shareValue;

    return formatCurrency(this.state.share * shareValue * (1 + Config.generalSupportFee));
  }

  get totalShare() {
    return this.state.share.toString().replace(".", ",");
  }

  get maxShares() {
    const { project } = this.props;

    const shareValue = this.shareValue;

    const shareSupported = project.supportedValue / shareValue;

    return project.numOfShares - shareSupported;
  }

  get shareValue() {
    const { project } = this.props;

    return project.totalValue / project.numOfShares;
  }

  handleBackupChange = e => {
    console.log(e.target.value);
  };

  handleTermsOfUseChange = value => {
    this.setState({ termsOfUse: value });
  };

  handleSubmit = values => {
    const value = formatOnlyNumbers(this.totalTransactionValue) / 100;

    if (this.state.termsOfUse && value !== 0) {
      let numOfShares = values.fixedShare;

      if (!numOfShares || numOfShares === 0) {
        numOfShares = value / this.shareValue;
      }

      const support = {
        project: this.props.params.id,
        value: value,
        numOfShares: numOfShares,
        public: values.backup === "public",
        supporter: Authentication.getUser().personal.companyId,
        supporterUser: Authentication.getUser().personal.id,
        supportFee: Config.generalSupportFee,
      };

      const url = "/supporter/projects/support";

      Request.post(url, support, msg => {
        Alert.success(
          dict.t(
            "Obrigado, suas cotas foram reservadas com sucesso. Aguarde contato de nossa equipe."
          ),
          () => {
            window.location.reload();
          }
        );
      });
    }
  };
}


export default reduxForm({
  form: "supportForm",
})(SupportInternForm);

