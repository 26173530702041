
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Meus projetos - página interna do projeto
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import Loader from "../../Loader";

import ScreenSize from "../../Commons/ScreenSize";

import Authentication from "../../../lib/Authentication";
import Request from "../../../lib/BackendRequest";

import languageTools from "../../../lib/languageTools";

import downloadFile from "../../../lib/downloadFile";

import Carousel from "../../Commons/Carousel";
import ProgressBar from "../../ProgressBar";

import BadgeStack from "../../Commons/BadgeStack";

import { formatCurrency } from "../../../lib/FormatString";
import Date from "../../../lib/Date";

import biomesArray from "../../../constants/biomes";
import odsArray from "../../../constants/ods";
import legalFitnessArray from "../../../constants/legalFitness";

import supportStatus from "../../../constants/supportStatus";

import userRoutes from "../../../constants/userRoutes";
import backendUserRoutes from "../../../constants/backendUserRoutes";

import multiLang from "../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const smallScreenWidth = 992;



export default class MyProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectReady: false,
      supportsReady: false,
      badgesWidth: {
        legalBadges: 0,
        areaBadges: 0,
        odsBadges: 0,
      },
    };
  }

  componentWillMount() {
    this.fetchProject();
  }

  render() {
    if (!this.state.projectReady || !this.state.supportsReady) return <Loader />;

    const { project } = this.state;

    const linkStyle = {
      textDecoration: "none",
      color: "#24A862",
      margin: "0 0",
    };

    const role = Authentication.getUser().personal.role;

    const editButton =
      project.status === "notSubmited" &&
      project.proponent === Authentication.getUser().personal.companyId;

    return (
      <div className="page-margin-top footer-margin-logged">
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="container">
          <div className="my-projects-intern-title">{project[language].shortName}</div>

          {editButton ? (
            <img
              className="my-projects-intern-edit-button"
              src="https://vbio.blob.core.windows.net/public/assets/img/edit-icon-2.png"
              alt={dict.t("Editar projeto")}
              title={dict.t("Editar projeto")}
              onClick={e => this.handleEditClick(e, project._id)}
            />
          ) : null}

          <div className="my-projects-intern-subtitle">{project[language].name}</div>

          {role === "proponentUser" && project.alert ? (
            <div className="my-projects-alert-container">
              <div className="my-projects-alert-label">{dict.t("Atenção!")}</div>

              <div className="my-projects-alert-card-container">
                <div className="card my-projects-alert">{project.alert}</div>

                <div className="form-input-error-detail" />
              </div>
            </div>
          ) : null}
        </div>

        <div className="container">
          <div className="my-projects-intern-carousel-container">
            <Carousel
              className="my-projects-intern-carousel"
              prevArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/previous-arrow.png"
              nextArrowImage="https://vbio.blob.core.windows.net/public/assets/img/carousel/next-arrow.png"
              items={this.composeCarouselItems(project.images)}
            />

            <div className="my-projects-intern-badges-container">
              {this.getProjectBadges(project)}
            </div>
          </div>
        </div>

        <Goals project={project} />

        <Operation project={project} screenWidth={this.state.screenWidth} />

        <Values project={project} />

        <div className="container">
          {this.state.supports && this.state.supports.length > 0 ? (
            !this.state.smallScreen ? (
              <div className="my-projects-intern-support-list-container">
                {this.composeSupportListHeader()}
                {role === "supporterUser"
                  ? this.composeSupportListSupporter(this.state.supports)
                  : null}
                {role === "proponentUser"
                  ? this.composeSupportListProponent(this.state.supports)
                  : null}
              </div>
            ) : (
              <Fragment>
                <div className="my-projects-intern-support-list-title">
                  {role === "supporterUser" ? dict.t("Detalhes da compra") : null}
                  {role === "proponentUser" ? dict.t("Detalhes das transações") : null}
                </div>
                {role === "supporterUser"
                  ? this.composeSupportListMobileSupporter(this.state.supports)
                  : null}
                {role === "proponentUser"
                  ? this.composeSupportListMobileProponent(this.state.supports)
                  : null}
              </Fragment>
            )
          ) : null}

          <div className="row my-projects-intern-bottom-bar">
            <div className="col-xs-6">
              <Link to={`/${userRoutes(role)}meus-projetos`} style={linkStyle}>
                {`< ${dict.t("Voltar para meus projetos")}`}
              </Link>
            </div>

            <div className="col-xs-6 text-right">
              <Link to={`/${userRoutes(role)}projetos/${project._id}`} style={linkStyle}>
                {`${dict.t("ver projeto completo")} >`}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  composeCarouselItems(items) {
    if (!items) return "";

    return items.map((item, index) => {
      return (
        <div key={index} style={{ position: "relative", height: "100%", textAlign: "center" }}>
          <img className="project-intern-carousel-image" src={item} alt="" />
        </div>
      );
    });
  }

  fetchProject() {
    const role = Authentication.getUser().personal.role;
    const url = `/${backendUserRoutes(role)}projects/${this.props.params.id}`;

    Request.get(url, values => {
      values[language] = languageTools.merge(values.PT, values[language]);

      console.log(values);

      this.setState({ project: values, projectReady: true });

      this.fetchSupports();
    });
  }

  fetchSupports() {
    const role = Authentication.getUser().personal.role;
    let url;

    if (role === "supporterUser")
      url = `/supporter/supports/${Authentication.getUser().personal.companyId}`;
    else if (role === "proponentUser") url = `/proponent/supports/${this.props.router.params.id}`;

    Request.get(url, values => {
      values = values.filter(support => support.project);

      let supports;

      if (role === "supporterUser")
        supports = values.filter(item => item.project._id === this.state.project._id);
      else if (role === "proponentUser") supports = values;

      this.setState({ supports, supportsReady: true });
    });
  }

  getProjectBadges(project) {
    let legalBadges = [];
    let areaBadges = [];
    let odsBadges = [];

    project.legalFitness.forEach(legalFitness => {
      legalBadges.push({
        icon: `/assets/img/badges/legal-fitness/${legalFitness}.png`,
        label: this.getLegalFitnessLabel(legalFitness),
      });
    });

    this.sortODSs(project.ODS).forEach(ODS => {
      odsBadges.push({
        icon: `/assets/img/badges/ods/${language}/${ODS}.png`,
        label: this.getODSLabel(ODS),
      });
    });

    project.areasOfInterest.forEach(area => {
      areaBadges.push({ icon: area.image, label: area[language].name });
    });

    const style = {
      margin: "0 20px",
      display: "inline-block",
      verticalAlign: "middle",
    };

    const { screenWidth } = this.state;

    let legalBadgeSize = 60;
    let badgeSize = 55;

    if (screenWidth < 768) {
      legalBadgeSize = 50;
      badgeSize = 45;
    }

    return (
      <Fragment>
        <BadgeStack
          items={legalBadges}
          group={this.state.groupBadges}
          badgeWidth={legalBadgeSize}
          style={style}
          onResize={width => this.handleBageResize("legalBadges", width)}
        />

        <BadgeStack
          items={areaBadges}
          group={this.state.groupBadges}
          badgeWidth={badgeSize}
          style={style}
          onResize={width => this.handleBageResize("areaBadges", width)}
        />

        <BadgeStack
          items={odsBadges}
          group={this.state.groupBadges}
          badgeWidth={badgeSize}
          style={style}
          onResize={width => this.handleBageResize("odsBadges", width)}
        />
      </Fragment>
    );
  }

  getLegalFitnessLabel(legalFitness) {
    return dict.t(legalFitnessArray.find(item => item.name === legalFitness).label);
  }

  getODSLabel(ods) {
    return dict.t(odsArray.find(item => item.name === ods).label);
  }

  sortODSs(items) {
    return items.sort((a, b) => {
      a = a.match(/ods-[\d]*/g)[0];
      a = a.replace(/[^\d]/g, "");

      b = b.match(/ods-[\d]*/g)[0];
      b = b.replace(/[^\d]/g, "");

      return b - a;
    });
  }

  composeSupportListHeader() {
    const role = Authentication.getUser().personal.role;

    const headerData = {
      supporterUser: [
        { label: "Código", cols: 2 },
        { label: "Cotas", cols: 1 },
        { label: "Valor apoio", cols: 2 },
        { label: "Valor Vbio", cols: 2 },
        { label: "Valor total", cols: 2 },
        { label: "Data", cols: 1 },
        { label: "Status", cols: 1 },
        { label: "Recibo", cols: 1 },
      ],

      proponentUser: [
        { label: "Nome da empresa", cols: 1 },
        { label: "Código", cols: 2 },
        { label: "Cotas", cols: 1 },
        { label: "Valor apoio", cols: 2 },
        { label: "Valor Vbio", cols: 2 },
        { label: "Valor total", cols: 2 },
        { label: "Data", cols: 1 },
        { label: "Status", cols: 1 },
      ],
    };

    const data = headerData[role];

    const style =
      role === "supporterUser" ? { margin: "10px 15px 0 15px" } : { margin: "10px 15px 0 15px" };

    return (
      <div className="row">
        <div className="col-sm-12 my-projects-intern-support-list-title">
          {role === "supporterUser" ? dict.t("Detalhes da compra") : null}
          {role === "proponentUser" ? dict.t("Detalhes das transações") : null}
        </div>

        <div className="my-projects-intern-support-list-header" style={style}>
          {this.composeHeaderItems(data)}
        </div>
      </div>
    );
  }

  composeHeaderItems(items) {
    return items.map((item, index) => {
      return (
        <div key={index} className={`col-md-${item.cols}`}>
          {dict.t(item.label)}
        </div>
      );
    });
  }

  composeSupportListSupporter(supports) {
    if (!supports) return null;

    return supports.map((support, index) => {
      return (
        <div key={index} className="row my-projects-intern-support-list-item">
          <div className="my-projects-intern-support-list-item-body">
            <div className="col-md-2">{support.token}</div>

            <div className="col-sm-1">{support.numOfShares}</div>

            <div className="col-sm-2">{formatCurrency(support.value)}</div>

            <div className="col-sm-2">{formatCurrency(support.value * support.supportFee)}</div>

            <div className="col-sm-2">
              {formatCurrency(support.value * (1 + support.supportFee))}
            </div>

            <div className="col-sm-1">{Date.formatWithoutTime(support.createdAt)}</div>

            <div className="col-sm-1">
              {supportStatus.find(status => status.name === support.status).label}
            </div>

            {support.status === "approved" ? (
              <div className="col-sm-1">
                <img
                  src="https://vbio.blob.core.windows.net/public/assets/img/download-icon.png"
                  alt={dict.t("Baixar recibo")}
                  onClick={() => this.handleSupportDownloadClick(support._id)}
                  style={{ cursor: "pointer", height: "26px" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  }

  composeSupportListProponent(supports) {
    if (!supports) return null;

    return supports.map((support, index) => {
      const name = support.supporter ? support.supporter.trade : dict.t("Apoiador anônimo");

      return (
        <div key={index} className="row my-projects-intern-support-list-item">
          <div className="my-projects-intern-support-list-item-body">
            <div className="col-md-1">{name}</div>

            <div className="col-md-2">{support.token}</div>

            <div className="col-md-1">{support.numOfShares}</div>

            <div className="col-md-2">{formatCurrency(support.value)}</div>

            <div className="col-md-2">{formatCurrency(support.value * support.supportFee)}</div>

            <div className="col-md-2">
              {formatCurrency(support.value * (support.supportFee + 1))}
            </div>

            <div className="col-md-1">{Date.formatWithoutTime(support.createdAt)}</div>

            <div className="col-md-1">
              {supportStatus.find(status => status.name === support.status).label}
            </div>
          </div>
        </div>
      );
    });
  }

  composeSupportListMobileSupporter(supports) {
    if (!supports) return null;

    return supports.map((support, index) => {
      const name = support.supporter ? support.supporter.trade : dict.t("Apoiador anônimo");

      return (
        <div key={index} className="card" style={{ marginBottom: "15px" }}>
          <div className="my-projects-intern-support-list-item-body">
            <div className="">{this.composeSupportListItemMobile("Código", support.token)}</div>

            <div className="">
              {this.composeSupportListItemMobile("Cotas", support.numOfShares)}
            </div>

            <div>
              {this.composeSupportListItemMobile("Valor apoio", formatCurrency(support.value))}
            </div>

            <div>
              {this.composeSupportListItemMobile(
                "Valor Vbio",
                formatCurrency(support.value * support.supportFee)
              )}
            </div>

            <div className="">
              {this.composeSupportListItemMobile(
                "Valor total",
                formatCurrency(support.value * (1 + support.supportFee))
              )}
            </div>

            <div className="">
              {this.composeSupportListItemMobile("Data", Date.formatWithoutTime(support.createdAt))}
            </div>

            <div className="">
              {this.composeSupportListItemMobile(
                "Status",
                supportStatus.find(status => status.name === support.status).label
              )}
            </div>

            <div className="">
              <strong style={{ fontSize: "13px" }}>{`${dict.t("Recibo")}: `}</strong>
              <img
                src="https://vbio.blob.core.windows.net/public/assets/img/download-icon.png"
                alt={dict.t("Baixar recibo")}
                onClick={() => this.handleSupportDownloadClick(support._id)}
                style={{ cursor: "pointer", height: "18px", margin: "8px 8px" }}
              />
            </div>
          </div>
        </div>
      );
    });
  }

  composeSupportListMobileProponent(supports) {
    if (!supports) return null;

    return supports.map((support, index) => {
      const name = support.supporter ? support.supporter.trade : dict.t("Apoiador anônimo");

      return (
        <div key={index} className="card" style={{ marginBottom: "15px" }}>
          <div className="my-projects-intern-support-list-item-body">
            <div className="">{this.composeSupportListItemMobile("Nome da empresa", name)}</div>

            <div className="">{this.composeSupportListItemMobile("Código", support.token)}</div>

            <div className="">
              {this.composeSupportListItemMobile("Cotas", support.numOfShares)}
            </div>

            <div className="">
              {this.composeSupportListItemMobile("Valor apoio", formatCurrency(support.value))}
            </div>

            <div className="">
              {this.composeSupportListItemMobile(
                "Valor Vbio",
                formatCurrency(support.value * support.supportFee)
              )}
            </div>

            <div className="">
              {this.composeSupportListItemMobile(
                "Valor total",
                formatCurrency(support.value * (support.supportFee + 1))
              )}
            </div>

            <div className="">
              {this.composeSupportListItemMobile("Data", Date.formatWithoutTime(support.createdAt))}
            </div>

            <div className="">
              {this.composeSupportListItemMobile(
                "Status",
                supportStatus.find(status => status.name === support.status).label
              )}
            </div>
          </div>
        </div>
      );
    });
  }

  composeSupportListItemMobile(header, label) {
    return (
      <Fragment>
        <strong style={{ fontSize: "13px" }}>{`${dict.t(header)}: `}</strong>
        {label}
      </Fragment>
    );
  }

  handleSupportDownloadClick = id => {
    const url = this.state.supports.find(support => support._id === id).receipt;

    if (url) downloadFile(url);
  };

  handleEditClick = (e, id) => {
    e.preventDefault();
    this.props.router.push(`/proponente/projetos/editar/${id}`);
  };

  adjustBadges() {
    const { badgesWidth, screenWidth } = this.state;

    const totalWidth =
      badgesWidth["legalBadges"] + badgesWidth["areaBadges"] + badgesWidth["odsBadges"];

    const group = totalWidth >= screenWidth * 0.6;

    this.setState({ groupBadges: group });
  }

  handleScreenResize = screen => {
    const { width } = screen;

    this.setState(
      {
        smallScreen: width <= smallScreenWidth,
        screenWidth: width,
      },
      () => this.adjustBadges()
    );
  };

  handleBageResize = (id, width) => {
    let { badgesWidth } = this.state;

    badgesWidth[id] = width;

    this.setState({ badgesWidth }, () => this.adjustBadges());
  };
}

class Goals extends Component {
  render() {
    let { project, screenWidth } = this.props;

    let labelFontSize = "17px";

    const configs = {
      iconHeight: "30px",
      labelFontSize,
      labelMargin: "12px",
      locationMarginBottom: "10px",
      progressHeight: "15px",
      progressMarkerHeight: "31px",
      progressMarkerWidth: "2px",
      progressMarkerFontSize: "12px",
      progressMarginTop: "50px",
      textFontSize: "12px",
    };

    const supportedPercent = 100 * (project.supportedValue / project.totalValue);
    const targetPercent = 100 * (project.minimumValue / project.totalValue);

    return (
      <div id="project-intern-goals" style={{ marginTop: "60px" }}>
        <div className="container">
          <div className="vitrine-main-project-details-container col-sm-12">
            <div className="row">
              <div
                className="vitrine-main-project-location"
                style={{ marginBottom: configs.locationMarginBottom }}
              >
                <img
                  className="vitrine-main-project-details-icon"
                  src="https://vbio.blob.core.windows.net/public/assets/img/location-icon.png"
                  alt={project.state}
                  title={project.state}
                  style={{ height: configs.iconHeight }}
                />
                <div
                  className="vitrine-main-project-name vitrine-main-project-details-text"
                  style={{
                    fontSize: configs.labelFontSize,
                    marginLeft: configs.labelMargin,
                  }}
                >
                  {`${project.city} - ${project.state}`}
                </div>
              </div>

              <div className="vitrine-main-project-details-biomes-container">
                {this.composeBiomes(
                  project.biomes,
                  configs.iconHeight,
                  configs.labelFontSize,
                  configs.labelMargin
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: configs.progressMarginTop }}>
              <ProgressBar
                height={configs.progressHeight}
                markerHeight={configs.progressMarkerHeight}
                markerWidth={configs.progressMarkerWidth}
                markerFontSize={configs.progressMarkerFontSize}
                value={supportedPercent}
                markerLabel={dict.t("meta mínima")}
                markerPos={targetPercent.toFixed(0)}
              />
            </div>

            <div className="row" style={{ textAlign: "center", marginTop: "16px" }}>
              <Fragment>
                <div
                  className="vitrine-main-project-details-small-text"
                  style={{ fontSize: configs.textFontSize, float: "left" }}
                >
                  {`${formatCurrency(project.supportedValue)} ${dict.t("de")} ${formatCurrency(
                    project.totalValue
                  )}`}
                </div>

                <div
                  className="vitrine-main-project-details-small-text"
                  style={{ fontSize: configs.textFontSize, float: "right" }}
                >
                  {`${dict.t("prazo")}: ${project.endDate}`}
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }

  composeBiomes(biomes, iconHeight, labelFontSize, labelMargin) {
    if (!biomes || !biomes.length) return null;

    return biomes.map((biome, index) => {
      const label = biomesArray.find(item => item.name === biome).label;

      return (
        <div className="vitrine-main-project-biome" key={biome}>
          <img
            className="vitrine-main-project-details-icon"
            src="https://vbio.blob.core.windows.net/public/assets/img/biome-icon.png"
            alt={label}
            title={label}
            style={{ height: iconHeight }}
          />
          <div
            className="vitrine-main-project-name vitrine-main-project-details-text"
            style={{ fontSize: labelFontSize, display: "inline-block" }}
          >
            {label}
          </div>
        </div>
      );
    });
  }
}

class Operation extends Component {
  render() {
    const { project } = this.props;

    let style = {};

    const { screenWidth } = this.props;

    if (screenWidth < 768) {
      style = { fontSize: "20px", marginBottom: "20px", textAlign: "center" };
    }

    return (
      <div className="container">
        <div className="my-projects-intern-operation">
          <div className="row clear">
            <div className="col-sm-6" style={style}>
              {project.inOperation ? dict.t("Projeto em operação") : dict.t("Projeto")}
            </div>

            {this.download}
          </div>
        </div>
      </div>
    );
  }

  get download() {
    if (!this.props.project.document) return null;

    let className = "col-sm-6 text-right";
    let style = {};

    const { screenWidth } = this.props;

    if (screenWidth < 768) {
      className = "col-sm-6";
      style = { fontSize: "20px", textAlign: "center" };
    }

    return (
      <div className={className}>
        <div style={{ color: "#00522C", textDecoration: "none" }} style={style}>
          {dict.t("Documento completo")}
          <img
            className="my-projects-intern-operation-download"
            src="https://vbio.blob.core.windows.net/public/assets/img/download.png"
            alt="Download"
            title="Download"
            onClick={() => this.handleDownloadClick()}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    );
  }
}

class Values extends Component {
  render() {
    return (
      <div className="container">
        <div className="my-projects-intern-values">
          <div className="row clear">
            <div className="col-sm-4">
              <div className="project-intern-values-title">{dict.t("valor do projeto")}</div>
              <div className="project-intern-values-value">{this.totalValue}</div>
            </div>

            <div className="col-sm-4">
              <div className="project-intern-values-title">{dict.t("valor da cota")}</div>
              <div className="project-intern-values-value">{this.shareValueFormatted}</div>
            </div>

            <div className="col-sm-4">
              <div className="project-intern-values-title">{dict.t("cotas apoiadas")}</div>
              <div className="project-intern-values-value">{this.shareSupported}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  get totalValue() {
    return formatCurrency(this.props.project.totalValue);
  }

  get shareValueFormatted() {
    return formatCurrency(this.shareValue);
  }

  get shareSupported() {
    const { project } = this.props;

    const shareValue = this.shareValue;

    const shareSupported = this.props.project.supportedValue / shareValue;

    return `${shareSupported.toFixed(2).replace(".", ",")}/${project.numOfShares}`;
  }

  get shareValue() {
    return this.props.project.totalValue / this.props.project.numOfShares;
  }
}
