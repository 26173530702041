
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Header
//


import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import Authentication from "../../lib/Authentication";

import masterAccess from "../../lib/masterAccess";

import Request from "../../lib/BackendRequest";

import ScreenSize from "../Commons/ScreenSize";

import Menu from "../Menu";
import BurgerMenu from "../Menu/Burger";

import menuItems from "../../constants/menuItems";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const dict = new multiLang(multiLang.getLanguage());

const burgerMenuScreenWidth = 1200;



export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuStye: "normal",
      menuOpen: false,
    };
  }

  componentWillMount() {
    this.getMenuItemsByRole(true);

    if (
      Authentication.getUser().personal &&
      Authentication.getUser().personal.role === "master"
    )
      this.fetchRecents();
  }

  componentWillReceiveProps() {
    this.getMenuItemsByRole();

    if (
      Authentication.getUser().personal &&
      Authentication.getUser().personal.role === "master"
    )
      this.fetchRecents();
  }

  getMenuItemsByRole(flag = false) {
    let mainMenu = [];
    let itemsSelector = "open";
    let role;

    if (Authentication.isAuthenticated()) {
      role = Authentication.getUser().personal.role;
      itemsSelector = role;

      if (itemsSelector === "proponentUser") {
        const status = Authentication.getUser().personal.companyStatus;
        itemsSelector += status !== "approved" ? "Pending" : "";
        mainMenu = menuItems[itemsSelector];
      } else if (itemsSelector === "master") {
        mainMenu.push(menuItems[itemsSelector][0]);

        if (masterAccess("projectApprove")) {
          mainMenu.push(menuItems[itemsSelector][1]);
        }

        if (masterAccess("supportApprove")) {
          mainMenu.push(menuItems[itemsSelector][2]);
          mainMenu.push(menuItems[itemsSelector][3]);
        }

        if (masterAccess("proponentApprove")) {
          mainMenu.push(menuItems[itemsSelector][4]);
        }

        if (masterAccess("blogPost")) {
          mainMenu.push(menuItems[itemsSelector][5]);
        }

        mainMenu.push(menuItems[itemsSelector][6]);
        let menuSettingsIndex = mainMenu.length - 1;
        mainMenu[menuSettingsIndex].subItems = [];

        if (masterAccess("admin")) {
          mainMenu[menuSettingsIndex].subItems.push({
            label: dict.t("Usuários"),
            route: "/master/configuracao/usuarios",
          });
          mainMenu[menuSettingsIndex].subItems.push({
            label: dict.t("Perfis"),
            route: "/master/configuracao/perfis",
          });
        }

        if (masterAccess("settings")) {
          mainMenu[menuSettingsIndex].subItems.push({
            label: dict.t("Áreas de interesse"),
            route: "/master/configuracao/areas-de-interesse",
          });
          mainMenu[menuSettingsIndex].subItems.push({
            label: dict.t("Banners da home"),
            route: "/master/configuracao/home-banners",
          });
          mainMenu[menuSettingsIndex].subItems.push({
            label: dict.t("Projetos da home"),
            route: "/master/configuracao/home-projetos",
          });
          mainMenu[menuSettingsIndex].subItems.push({
            label: dict.t("Documentos"),
            route: "/master/configuracao/documentos",
          });
        }

        mainMenu[menuSettingsIndex].subItems.push({
          label: dict.t("Alterar minha senha"),
          route: "/meu/altera-senha",
        });

        mainMenu[menuSettingsIndex].subItems.push({
          label: dict.t("Sair"),
          route: "/logout",
        });
      } else {
        mainMenu = menuItems[itemsSelector];
      }
    } else {
      mainMenu = menuItems[itemsSelector];
    }

    this.setState({ items: mainMenu });
  }

  fetchRecents() {
    let url = "/master/projects/newest-pending-date";

    if (masterAccess("projectApprove")) {
      Request.get(url, (values) => {
        this.checkPendingProjects(values.newest);
      });
    }

    if (masterAccess("supportApprove")) {
      url = "/master/supports/newest-pending-date";

      Request.get(url, (values) => {
        this.checkPendingSupports(values.newest);
      });
    }

    if (masterAccess("proponentApprove")) {
      url = "/master/proponents/newest-pending-date";

      Request.get(url, (values) => {
        this.checkPendingProponents(values.newest);
      });
    }
  }

  checkPendingProjects(newest) {
    const lastCheck = localStorage.getItem("userLastCheckProjects");
    const pendingProjects =
      lastCheck !== "undefined"
        ? this.compareISODates(lastCheck, newest)
        : true;

    const { items } = this.state;

    const item = items.find((item) => item.name === "projects");

    if (item) {
      item.notification = pendingProjects;
      this.setState({ items });
    }
  }

  checkPendingSupports(newest) {
    const lastCheck = localStorage.getItem("userLastCheckSupports");
    const pendingSupports =
      lastCheck !== "undefined"
        ? this.compareISODates(lastCheck, newest)
        : true;

    const { items } = this.state;

    const item = items.find((item) => item.name === "supports");

    if (item) {
      item.notification = pendingSupports;
      this.setState({ items });
    }
  }

  checkPendingProponents(newest) {
    const lastCheck = localStorage.getItem("userLastCheckProponents");
    const pendingProponents =
      lastCheck !== "undefined"
        ? this.compareISODates(lastCheck, newest)
        : true;

    const { items } = this.state;

    const item = items.find((item) => item.name === "proponents");

    if (item) {
      item.notification = pendingProponents;
      this.setState({ items });
    }
  }

  compareISODates(a, b) {
    a = new Date(a);
    b = new Date(b);

    return a < b;
  }

  render() {
    const { items } = this.state;

    return (
      <Fragment>
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="header">
          {!this.props.hideMenu && this.state.menuStyle === "burger" ? (
            <BurgerMenu
              isOpen={this.state.menuOpen}
              items={items}
              router={this.props.router}
              onClose={this.handleBurgerClose}
            />
          ) : null}

          <div className="container header-container">
            <div className="row header-row">
              <div className="col-md-2 header-logo">
                <Link to="/">
                  <img
                    className="header-logo"
                    src="https://vbio.app/assets/img/header/logo.svg"
                    alt="Home"
                    title="Home"
                  />
                </Link>
              </div>

              {!this.props.hideMenu && this.state.menuStyle === "burger" ? (
                <div className="header-menu-button">
                  <img
                    src="https://vbio.blob.core.windows.net/public/assets/img/menu-icon.png"
                    alt="Menu"
                    onClick={this.handleMenuClick}
                  />
                </div>
              ) : null}

              {!this.props.hideMenu && this.state.menuStyle === "normal" ? (
                <Menu items={items} router={this.props.router} />
              ) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  handleScreenResize = (screen) => {
    const { width } = screen;
    let menuStyle;

    if (width < burgerMenuScreenWidth) menuStyle = "burger";
    else menuStyle = "normal";

    this.setState({ menuStyle });
  };

  handleMenuClick = () => {
    this.setState({ menuOpen: true });
  };

  handleBurgerClose = () => {
    this.setState({ menuOpen: false });
  };
}


