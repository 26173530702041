
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Configuração dos projetos da home - master
//


import React, { Component } from "react";

import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";
import Loader from "../../../Loader/index.js";

import List from "../../List/Zebra";

import masterAccess from "../../../../lib/masterAccess";

import languageTools from "../../../../lib/languageTools";

import multiLang from "../../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const numOfProjects = 6;

const dummyProject = {
  PT: { shortName: "Não selecionado" },
  EN: { shortName: "Not selected" },
};


export default class HomeProjects extends Component {
  mainButtons = [
    {
      type: "edit",
      route: "/master/configuracao/home-projetos/destaque",
      removeIdFromRoute: true,
    },
  ];

  projectsButtons = [
    {
      type: "edit",
      route: "/master/configuracao/home-projetos/vitrine",
    },

    {
      type: "move",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      mainProjReady: false,
      showCaseProjsReady: false,
      mainProject: [],
      projects: [],
    };
  }

  componentWillMount() {
    this.fetchMainProject();
    this.fetchShowCaseProjects();
  }

  render() {
    if (!this.state.mainProjReady || !this.state.showCaseProjsReady) return <Loader />;

    let canEdit = false;

    if (masterAccess("settings")) canEdit = true;

    const mainProject = this.state.mainProject;
    mainProject[language] = languageTools.merge(mainProject.PT, mainProject[language]);

    const projects = this.state.projects.map(project => {
      project[language] = languageTools.merge(project.PT, project[language]);
      return project;
    });

    return (
      <div className="container footer-margin-logged" style={{ paddingBottom: "30px" }}>
        <div className="banner-list-container">
          <List
            title={dict.t("Destaque")}
            headerKey={language}
            headerKeyInArray={"shortName"}
            items={[mainProject]}
            buttons={canEdit ? this.mainButtons : null}
            style={{ height: "60px" }}
          />
        </div>

        <div className="stats-list-container">
          <List
            title={dict.t("Projetos")}
            headerKey={language}
            headerKeyInArray={"shortName"}
            items={projects}
            buttons={canEdit ? this.projectsButtons : null}
            onSort={this.handleShowcaseSort}
            style={{ height: "60px" }}
          />
        </div>
      </div>
    );
  }

  getStatHeaderComponent(props) {
    return <div className="list-zebra-item-header-text">{props.item.order + 1 + "."}</div>;
  }

  fetchMainProject() {
    Request.get("/master/projects/role/main", values => {
      if (!values.idProject) values.idProject = dummyProject;

      this.setState({ mainProjReady: true, mainProject: values.idProject });
    });
  }

  fetchShowCaseProjects() {
    Request.get("/master/projects/role/showcase-projects", values => {
      const newProjects = new Array(numOfProjects - values.projects.length)
        .fill({})
        .map((item, index) => {
          return { _id: index, ...dummyProject };
        });

      values.projects = [...values.projects, ...newProjects];

      this.setState({ showCaseProjsReady: true, projects: values.projects });
    });
  }

  handleShowcaseSort = items => {
    items.map(item => item._id);

    const record = {
      projects: items,
    };

    Request.post("/master/projects/role/showcase-projects", record, msg => {
      this.fetchShowCaseProjects();
      Alert.success(dict.t("Alterações salvas com sucesso"));
    });
  };
}


