
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Régis Ebeling
//
// Descrição: Página Como Apoiar
//


import React, { Component } from "react";

import ReactHtmlParser from "react-html-parser";

import Back from "../Commons/Back";
import Loader from "../Loader";

import Request from "../../lib/BackendRequest";

import languageTools from "../../lib/languageTools";

import multiLang from "../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default class HowToSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      records: [],
    };
  }

  componentWillMount() {
    this.fetchRecords();
  }

  render() {
    const { records, ready } = this.state;

    if (!ready) return <Loader />;

    records[language] = languageTools.merge(records.PT, records[language]);

    return (
      <div className="terms-of-use-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <Back router={this.props.router} />
              </div>

              <div className="row terms-of-use-title">
                {this.props.router.params.userType === "privacidade"
                  ? dict.t("Política de privacidade")
                  : dict.t("Termo de uso")}
              </div>

              <div className="row terms-of-use-text">
                <div className="terms-of-use-text">{ReactHtmlParser(records[language].text)}</div>
              </div>

              <div className="row">
                <Back router={this.props.router} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  fetchRecords() {
    Request.get(`/home/term-of-use/${this.props.router.params.userType}`, values => {
      this.setState({ ready: true, records: values });
    });
  }
}

