
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Upload de arquivos no backend - Amazon S3
//


import config from "../config";

const url = config.backend_url + config.file.upload;



export default function(fileGroup) {
  return new Promise((resolve, reject) => {
    const data = new FormData();

    if (!Array.isArray(fileGroup)) fileGroup = [fileGroup];

    fileGroup.forEach(files => {
      if (!Array.isArray(files) && typeof files !== "object") files = [files];

      data.append("files[]", files[0]);
      data.append("filename", files[0].name);
    });

    fetch(url, {
      body: data,
      method: "POST",
    })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
}


