import React, { Component } from "react";
import { Link } from "react-router";
import ScreenSize from "../Commons/ScreenSize";
import Authentication from "../../lib/Authentication";
import multiLang from "../../lib/multilang/multiLang";
import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



const currentYear = new Date().getFullYear();



export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      smallScreen: false,
      windowSize: {},
    };
  }

  render() {
    return (
      <div className="row footer">
        <ScreenSize onScreenResize={this.handleScreenResize} />
        <div className="container">
          <div className="row">
            {Authentication.isAuthenticated()
              ? this.composeRestrictAreaFooter()
              : this.composeRestrictAreaFooter()}
          </div>
        </div>
      </div>
    );
  }

  composeOpenAreaFooter() {
    return (
      <div className="footer-restrict-container">
        <img className="footer-logo" src="https://vbio.blob.core.windows.net/public/assets/img/footer/logo.svg" alt="VBIO" title="VBIO" />

        <div className="footer-text" style={{ marginTop: "20px" }}>
          <Link to="/termo-de-uso/site">{dict.t("Termos de uso")}</Link>
          {" | "}
          <Link to="/termo-de-uso/privacidade">{dict.t("Política de privacidade")}</Link>
          {" | "}
          <a
            href="http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2015/Lei/L13123.htm"
            target="_blank"
          >
            {dict.t("Lei da Biodiversidade")}
          </a>
        </div>

        <div className="footer-text" style={{ marginTop: "10px" }}>
          <p>{`${currentYear} © VBIO. ${dict.t("Todos os direitos reservados")}.`}</p>
        </div>
      </div>
    );
  }

  composeRestrictAreaFooter() {
    return (
      <div className="footer-restrict-container">
        <img className="footer-logo" src="https://vbio.blob.core.windows.net/public/assets/img/footer/logo.svg" alt="VBIO" title="VBIO" />
        <div className="footer-text" style={{ marginTop: "20px" }}>
          <Link to="/termo-de-uso/site">{dict.t("Termos de uso")}</Link>
          {" | "}
          <Link to="/termo-de-uso/privacidade">{dict.t("Política de privacidade")}</Link>
          {" | "}
          <a
            href="http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2015/Lei/L13123.htm"
            target="_blank"
          >
            {dict.t("Lei da Biodiversidade")}
          </a>
        </div>
        <div className="footer-text" style={{ marginTop: "10px" }}>
          <p>{`${currentYear} © VBIO. ${dict.t("Todos os direitos reservados")}.`}</p>
        </div>
      </div>
    );
  }
}