
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Dados de apoiador
//


import React, { Fragment } from "react";

import CardField from "../CardField";

import multiLang from "../../../lib/multilang/multiLang";



const language = multiLang.getLanguage();
const dict = new multiLang(language);



export default function(props) {
  const { supporter } = props;
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-6 no-side-padding">
          <CardField label={dict.t("Razão Social")} value={supporter.company} />
        </div>

        <div className="col-md-6 no-side-padding">
          <CardField label={dict.t("Nome Fantasia")} value={supporter.trade} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 no-side-padding">
          <CardField label={dict.t("CNPJ")} value={supporter.cnpj} />
        </div>

        <div className="col-md-6 no-side-padding">
          <CardField label={dict.t("Telefone")} value={supporter.phone} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 no-side-padding">
          <CardField label={dict.t("Setor")} value={supporter.sector} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-7 no-side-padding">
          <CardField label={dict.t("Endereço")} value={supporter.streetName} />
        </div>

        <div className="col-md-2 no-side-padding">
          <CardField label={dict.t("Número")} value={supporter.addressNumber} />
        </div>

        <div className="col-md-3 no-side-padding">
          <CardField label={dict.t("Complemento")} value={supporter.complement} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 no-side-padding">
          <CardField label={dict.t("CEP")} value={supporter.cep} />
        </div>

        <div className="col-md-4 no-side-padding">
          <CardField label={dict.t("Bairro")} value={supporter.neighborhood} />
        </div>

        <div className="col-md-3 no-side-padding">
          <CardField label={dict.t("Cidade")} value={supporter.city} />
        </div>

        <div className="col-md-2 no-side-padding">
          <CardField label={dict.t("Estado")} value={supporter.state} />
        </div>
      </div>
    </Fragment>
  );
}


