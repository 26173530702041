
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Geoffrey Hinton
//
// Descrição: Lista de projetos pendentes - master
//


import React, { Component } from "react";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Dropdown from "../../../Commons/Dropdown";

import moment from "moment";
import Back from "../../../Commons/Back";
import Alert from "../../../../lib/Alert";

import List from "../../List/Zebra";
import ProjectSearchForm from "../../../Commons/ProjectSearchForm";

import ItemBody from "./ItemBody";

import multiLang from "../../../../lib/multilang/multiLang";

import "./style.css";



const language = multiLang.getLanguage();
const dict = new multiLang(language);

const numOfProjects = 6;



export default class ShowCaseProjects extends Component {
  handleButtonClick = item => {
    if (!this.props.params.id) this.handleMainProject(item._id);
    else this.handleShowcase(this.state.projects, item._id);
  };

  handleShowcase = (items, itemId) => {
    items = this.state.showcaseProjects.map(item => item._id);

    for (let i = 0; i < items.length; i++) {
      if (items[i] === this.props.params.id || items[i] === 0) {
        items[i] = itemId;
        break;
      }
    }

    const record = {
      projects: items,
    };

    Request.post("/master/projects/role/showcase-projects", record, msg => {
      Alert.success(dict.t("Alterações salvas com sucesso"), () => {
        this.props.router.push("/master/configuracao/home-projetos");
      });
    });
  };

  handleMainProject = item => {
    const record = {
      idProject: item,
    };

    Request.post("/master/projects/role/main", record, msg => {
      Alert.success(dict.t("Alterações salvas com sucesso"), () => {
        this.props.router.push("/master/configuracao/home-projetos");
      });
    });
  };

  buttons = [
    {
      type: "text",
      getLabel: item => dict.t("Escolher"),
      onClick: this.handleButtonClick,
    },
  ];

  sortByOptions = [
    {
      title: dict.t("Todas"),
      value: "all",
    },
    {
      title: dict.t("Nome"),
      value: "name",
    },
    {
      title: dict.t("Data de cadastro"),
      value: "createdAt",
    },
    {
      title: dict.t("Data de edição"),
      value: "updatedAt",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      records: [],
      projects: null,
      showcaseProjects: null,
    };
  }

  handleSortBy = ev => {
    let { records } = this.state;

    switch (ev.target.value) {
      case "createdAt":
        records.sort((opt1, opt2) =>
          moment(opt1.createdAt).diff(moment(opt2.createdAt), "minutes")
        );
        this.setState({ records });
        break;
      case "updatedAt":
        records.sort((opt1, opt2) =>
          moment(opt1.updatedAt).diff(moment(opt2.updatedAt), "minutes")
        );
        this.setState({ records });
        break;
      case "name":
        records.sort((opt1, opt2) => {
          if (opt1[dict.lang].name && opt2[dict.lang].name)
            return opt1[dict.lang].name.localeCompare(opt2[dict.lang].name);

          return 0;
        });
        this.setState({ records });
        break;
      default:
        break;
    }
  };

  componentWillMount() {
    this.fetchShowcaseCongig();
  }

  render() {
    if (!this.state.projects || !this.state.showcaseProjects) return <Loader />;

    return (
      <div className="container footer-margin-logged" style={{ paddingBottom: "30px" }}>
        <div className="page-title" style={{ marginTop: "30px" }}>
          {dict.t("Escolher Projeto")}
        </div>

        <ProjectSearchForm
          //title="Escolher Projeto"
          buttonLabel={dict.t("Buscar Projetos")}
          projects={this.state.projects}
          onFilter={this.handleProjectFilter}
        />

        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <Dropdown
              label="Ordenar por"
              selectClass="searchOptionsSelect"
              options={this.sortByOptions}
              onChange={this.handleSortBy}
            />
          </div>
        </div>

        <div style={{ marginRight: "15px", marginLeft: "15px" }}>
          <List
            items={this.state.records}
            itemBodyComponent={ItemBody}
            buttons={this.buttons}
            style={{ height: "80px" }}
          />
        </div>

        <div className="row" style={{ marginTop: "50px" }}></div>

        <Back router={this.props.router} />
      </div>
    );
  }

  fetchShowcaseCongig() {
    Request.get("/master/projects/role/showcase-projects", values => {
      const newProjects = new Array(numOfProjects - values.projects.length)
        .fill({})
        .map((item, index) => {
          return { _id: index };
        });

      values.projects = [...values.projects, ...newProjects];

      this.setState({ showcaseProjects: values.projects });
      this.fetchProjects();
    });
  }

  fetchProjects() {
    Request.get("/master/projects/approved", values => {
      values = values.filter(
        item => !this.state.showcaseProjects.find(item2 => item2._id === item._id)
      );
      this.setState({ projects: values, records: values });
    });
  }

  handleProjectFilter = records => {
    this.setState({ records });
  };
}


