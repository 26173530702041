
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Home
//


import React, { Component, Fragment } from "react";

import Authentication from "../../lib/Authentication.js";

import Default from "./Default.js";
import Supporter from "./Supporter.js";



export default class Home extends Component {
  render() {
    return <Fragment>{this.getHomeComponent()}</Fragment>;
  }

  getHomeComponent() {
    if (Authentication.isAuthenticated()) {
      switch (Authentication.getUser().personal.role) {
        case "master":
          this.props.router.push("/master/dashboard");
        case "supporterUser":
          return <Supporter />;
        case "proponentUser":
          return <Supporter />;
        default:
          return <Default />;
      }
    } else return <Default />;
  }
}


