
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Formulário de edição de estatísticas
//


import React, { Component } from "react";
import { reduxForm } from "redux-form";

import StatPartialForm from "./StatPartialForm";
import Submit from "../../../..//lib/Forms/Submit";

import ScreenSize from "../../../Commons/ScreenSize";

import Loader from "../../../Loader";
import Request from "../../../../lib/BackendRequest";
import Alert from "../../../../lib/Alert";

import "./style.css";



const smallScreenWidth = 768;



class StatsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
    };
  }

  componentWillMount() {
    this.fetchRecords();
  }

  render() {
    if (!this.state.ready) return <Loader />;

    let backStyle;
    let paddingBottom = "50px";

    if (this.state.smallScreen) {
      backStyle = { bottom: "-50px" };
      paddingBottom = "80px";
    }

    return (
      <div className="container form-container footer-margin-logged" style={{ paddingBottom }}>
        <ScreenSize onScreenResize={this.handleScreenResize} />

        <div className="col-md-12 form-body">
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <div style={{ marginBottom: "30px" }}>{this.getPartials()}</div>

            <Submit backRoute="/master/configuracao/home-banners" backStyle={backStyle} />
          </form>
        </div>
      </div>
    );
  }

  getPartials() {
    let items = [];

    for (let index = 0; index < this.state.numOfRecords; index++) {
      items.push(<StatPartialForm key={index} index={index} />);
    }

    return items;
  }

  fetchRecords() {
    Request.get("/master/home/statistics", values => {
      this.setState({ ready: true, record: values, numOfRecords: values.length });
      this.props.initialize(values);
    });
  }

  handleSubmit = values => {
    Request.post("/master/home/statistics/all", values, msg => {
      this.fetchRecords();
      Alert.success("Alterações salvas com sucesso", () => {
        this.props.router.push("/master/configuracao/home-banners");
      });
    });
  };

  handleScreenResize = screen => {
    const { smallScreen } = this.state;

    if (!smallScreen && screen.width <= smallScreenWidth) this.setState({ smallScreen: true });
    else if (smallScreen && screen.width > smallScreenWidth) this.setState({ smallScreen: false });
  };
}



export default reduxForm({
  form: "statsForm",
})(StatsForm);


