/*
 * Library containing helper methods do display ALERTS to the user.
 * Here we are currently using Bootstrap-Sweetalert
 * @see https://github.com/lipis/bootstrap-sweetalert
 */

import swal from "sweetalert";

export default {
  /*
   * Display an error alert
   * @param text string
   * @param config object
   * @return void
   */
  error(text, callback = () => {}, config = {}) {
    this.call(
      {
        text: text,
        title: "Ops",
        icon: "error",
        ...config,
      },
      callback
    );
  },

  /*
   * Display a success alert
   * @param text string
   * @param config object
   * @return void
   */
  success(text, callback = () => {}, config = {}) {
    this.call(
      {
        title: "Confirmado",
        text: text,
        icon: "success",
        ...config,
      },
      callback
    );
  },

  /*
   * Ask a confirmation to the user
   * @param text string
   * @param callback function
   * @param config object
   * @return void
   */
  confirm(text, callback, config = {}) {
    this.call(
      {
        title: "ATENÇÃO!",
        text: text,
        icon: "warning",
        buttons: ["Não", "Sim"],
        ...config,
      },
      callback
    );
  },

  /*
   * Alert for ajax requests
   * @param text string
   * @param callback function
   * @param config object
   * @return void
   */
  request(text, callback, config = {}) {
    this.call(
      {
        title: "Enviando dados...",
        text: text,
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
        ...config,
      },
      callback
    );
  },

  loading(text, callback, config = {}) {
    this.call(
      {
        title: "Carregando...",
        text: text,
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
        ...config,
      },
      callback
    );
  },

  /*
   * Underground function of alert
   * @param callback function
   * @param config object
   * @return void
   */
  call(config, callback = () => {}) {
    swal(config).then(v => {
      callback(v);
    });
  },

  /*
   * Underground function of alert
   * @param config object
   * @return void
   */
  callSimple(config) {
    swal(config);
  },

  close() {
    swal.close();
  },
};
