const normalizerSlug = (str, previousValue) => {
  if (!str) return str;

  str = str.toLowerCase();

  let from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç/,:; ";
  let to = "aaaaaeeeeeiiiiooooouuuunc-----";

  for (let i = 0, l = from.length; i < l; i++)
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));

  str = str
    .replace(/[^a-z0-9 -._~]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  //remove leading numbers
  str = str.replace(/^\d+/, "");

  return str;
};

export default normalizerSlug;
