
//    _____ _                      _    _             _
//   / ____| |                    | |  | |           | |
//  | (___ | |__   __ _ _ __ ___  | |__| |_   _ _ __ | |_ ___ _ __
//   \___ \| '_ \ / _` | '__/ _ \ |  __  | | | | '_ \| __/ _ \ '__|
//   ____) | | | | (_| | | |  __/ | |  | | |_| | | | | ||  __/ |
//  |_____/|_| |_|\__,_|_|  \___| |_|  |_|\__,_|_| |_|\__\___|_|
//
// Autor: Diego Pinto
//
// Descrição: Menu
//


import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import MenuItems from "./MenuItems";

import SubMenu from "./SubMenu";

import "./style.css";



export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subMenuItems: [],
      subMenuShow: false,
      lastItem: null,
    };
  }

  componentWillMount() {
    this.setState({ subMenuShow: false });
  }

  componentWillReceiveProps() {
    this.setState({ subMenuShow: false });
  }

  render() {
    return (
      <Fragment>
        <div ref="menu" className="col-md-7 menu" onMouseMove={this.handleMouseMove}>
          <MenuItems
            items={this.props.items}
            router={this.props.router}
            subMenuOpenId={this.state.subMenuShow ? this.state.lastItem : null}
            hideSubItems={true}
            onItemClick={this.handleItemClick}
          />

          <SubMenu
            items={this.state.subMenuItems}
            show={this.state.subMenuShow}
            config={this.state.subMenuConfig}
            onItemClick={this.handleSubMenuItemCLick}
          />
        </div>
      </Fragment>
    );
  }

  handleMouseMove = e => {
    const bounds = ReactDOM.findDOMNode(this.refs["menu"]).getBoundingClientRect();

    const x = e.pageX - bounds.left;
    const y = e.pageY - bounds.top;
    const width = bounds.width;

    this.setState({ mousePos: { x, y }, width: width });
  };

  handleItemClick = item => {
    if (!item.subItems) {
      this.setState({ subMenuShow: false });
      return;
    }

    let show;

    if (item.id === this.state.lastItem) {
      show = !this.state.subMenuShow;
    } else {
      show = true;
    }

    const subMenuConfig = {
      pos: { x: this.state.mousePos.x, y: 70 },
      side: item.subMenuSide,
      parentWidth: this.state.width,
    };

    this.setState({
      subMenuItems: item.subItems,
      subMenuShow: show,
      subMenuConfig: subMenuConfig,
      lastItem: item.id,
    });
  };

  handleSubMenuItemCLick = () => {
    this.setState({ subMenuShow: false });
  };
}


